import { authToken, baseUrl } from "../actions";

export const getCustomerApi = (request) => {
    const CUSTOMER_LIST = `${baseUrl}staff/customer/v2`;

    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.data)
    }

    return fetch(CUSTOMER_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}

export const getCustomerDropdownApi = (pageNo, searchKey) => {
    let CUSTOMER_LIST = searchKey !== undefined ? `${baseUrl}staff/customers?searchKey=${searchKey}&page=${pageNo}` : `${baseUrl}staff/customers?page=${pageNo}`;

    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(CUSTOMER_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const createCustomerApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/customers`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getOneCustomerApi = (customerId) => {
    const CUSTOMER_LIST = `${baseUrl}staff/customers/${customerId}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(CUSTOMER_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const updateCustomerApi = (request) => {
    const UPDATE_CUSTOMER = `${baseUrl}staff/customers`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(UPDATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
