import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { reduxForm, Field } from 'redux-form';
import Form from 'react-bootstrap/Form';
import { connect } from "react-redux";
import validate from "./validate";
import Rock from "../assets/images/rock.png";
import Mi from "../assets/images/mi.png";
import MySelect from "./MySelect";
import Orange from "../assets/images/rou.gif";
import { components } from "react-select";



const renderField = ({ input, placeholder, type, meta: { touched, error } }) => (
    <div  >
        <label></label>
        <div>
            <input  {...input} placeholder={placeholder} type={type} className=" dis-part" />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

function EmailPopUp(props) {
    const { show, onHide, mailData, handleSubmit, load, getCC, reset, getTo } = props;
    const [items, setItems] = useState([]);
    const [item, setItem] = useState([]);

    const [optionSelected, setoptionSelected] = useState(false);
    const [selected, setSelected] = useState(false);


    const handleChange = (selected) => {
        setoptionSelected(selected);
        getCC(selected);
    };

    const handleChanged = (selected) => {
        setSelected(selected);
        getTo(selected);
    };

    useEffect(() => {
        if (mailData) {
            let values = []
            let arr = [];
            mailData?.cc?.forEach((item) => {
                values.push({
                    "value": item,
                    "label": item
                });
            })
            mailData?.to?.forEach((item) => {
                arr.push({
                    "value": item,
                    "label": item
                });
            })
            setItems(values);
            setItem(arr);
        }
    }, [mailData]);



    return (
        <>
            <Modal show={show} onHide={() => {
                onHide();
                reset();
                setoptionSelected(false);
            }} size="md" className="email-body">
                <form onSubmit={handleSubmit}>
                    <Modal.Header className="orange-head">
                        <Modal.Title><img src={Mi} alt=".." className="mi" /> EMAIL</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Control
                                className=" dis-part"
                                name="from"
                                placeholder={`From : ${mailData?.from}`}
                                disabled />
                        </Form.Group>
                        <Form.Group className="mb-1 select-border" controlId="formBasicEmail">
                            <span>To :</span>
                            <MySelect
                                options={item}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{ Option }}
                                onChange={handleChanged}
                                allowSelectAll={true}
                                value={selected}
                            />
                        </Form.Group>
                        <Form.Group className="mb-1 select-border" controlId="formBasicEmail">
                            <span>CC :</span>
                            <MySelect
                                options={items}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{ Option }}
                                onChange={handleChange}
                                allowSelectAll={true}
                                value={optionSelected}
                            />
                        </Form.Group>
                        <Form.Group className="mb-1 sub-border" controlId="formBasicEmail">
                            <span>Subject :</span>
                            <Field
                                name="subject"
                                type="text"
                                component={renderField}
                            // placeholder="Subject"
                            />
                        </Form.Group>
                    </Modal.Body><br></br>
                    <Modal.Footer>
                        <button type="button" className="close-btn" onClick={onHide}>
                            Close
                        </button>
                        {load ? <button type="button" className="sent-button" >
                            Loading <img src={Orange} alt=".." className="roc" />
                        </button>
                            : <button type="submit" className="sent-button" >
                                Send <img src={Rock} alt=".." className="roc" />
                            </button>}
                    </Modal.Footer>
                </form>

            </Modal>
        </>
    );
}

function mapStateToProps(state) {
    return {
        mailData: state.po.mailData,
        initialValues: state.po.mailData,
        load: state.po.load
    }
}

const SecondaryForm = reduxForm({
    form: "SecondaryEmailForm",
    enableReinitialize: true,
    validate
})(EmailPopUp);

export default connect(mapStateToProps)(SecondaryForm);
