import { vendorpoConstants } from "../constants";

const initialState = {
    loading: false,
    vendorposuccess_msg: "",
    data: [],
    error: [],
    status: 0,
    token: "",
    vendorpo: [],
    page: "create",
    sortType: ""
};

export default function vendorpoReducer(state = initialState, { type, response }) {
    switch (type) {
        case vendorpoConstants.GET_VENDORPO:
            return {
                ...state,
                loading: true,
            };
        case vendorpoConstants.GET_VENDORPO_SUCCESS:
            return {
                ...state,
                loading: false,
                vendorpo_list: response.data,
                allData: response.data,

            };
        case vendorpoConstants.GET_VENDORPO_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
                vendorposuccess_msg: "",
            };

        case vendorpoConstants.CREATE_VENDORPO_INIT:
        case vendorpoConstants.UPDATE_VENDORPO_INIT:
            return {
                ...state,
                loading: true,
                vendorposuccess_msg: "",
            }
        case vendorpoConstants.CREATE_VENDORPO_SUCCESS:
        case vendorpoConstants.UPDATE_VENDORPO_SUCCESS:
            return {
                ...state,
                loading: false,
                vendorposuccess_msg: response,
                status: response.status
            }
        case vendorpoConstants.CREATE_VENDORPO_FAILURE:
        case vendorpoConstants.UPDATE_VENDORPO_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            }

        case vendorpoConstants.GET_VENDORPO_INIT:
            return {
                ...state,
                loading: true,
                page: ""
            }
        case vendorpoConstants.GET_VENDORPO_SUCCESSD:
            return {
                ...state,
                loading: false,
                vendorpo: response.data,
                status: response.status,
                page: "edit"
            }
        case vendorpoConstants.GET_VENDORPO_FAILURED:
            return {
                ...state,
                loading: false,
                error: response,
            }

        case vendorpoConstants.CHANGE_PAGE:
            return {
                ...state,
                loading: false,
                page: "create"
            }

        default:
            return state;
    }
}
