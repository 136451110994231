import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import { customerConstants } from "../constants";
import { getCustomerApi, createCustomerApi, getOneCustomerApi, updateCustomerApi, getCustomerDropdownApi } from "../services";

export function* customerlistSaga(payload) {
    try {
        const response = yield call(getCustomerApi, payload);
        yield put({ type: customerConstants.GET_CUSTOMERS_NEW_SUCCESS, response });
    } catch (error) {
        yield put({ type: customerConstants.GET_CUSTOMERS_NEW_FAILURE, error });
    }
}

export function* customerDropdownSaga(payload) {
    try {
        const response = yield call(getCustomerDropdownApi, payload.pageNo, payload.searchKey);
        yield put({ type: customerConstants.GET_CUSTOMERS_SUCCESS, response });
    } catch (error) {
        yield put({ type: customerConstants.GET_CUSTOMERS_FAILURE, error });
    }
}


export function* createcustomerSaga(payload) {
    try {
        const response = yield call(createCustomerApi, payload);
        yield put({ type: customerConstants.CREATE_CUSTOMER_SUCCESS, response });
        // ... Prevent reload & showing updated list - testing
        // yield put({ type: customerConstants.GET_CUSTOMERS, customerlistSaga })
    } catch (error) {
        yield put({ type: customerConstants.CREATE_CUSTOMER_FAILURE, error });
    }
}
export function* getCustomerSaga(payload) {
    try {
        const response = yield call(getOneCustomerApi, payload.customerId);
        yield put({ type: customerConstants.GET_CUSTOMER_SUCCESS, response });
    } catch (error) {
        yield put({ type: customerConstants.GET_CUSTOMER_FAILURE, error });
    }
}
export function* updatecustomerSaga(payload) {
    try {
        const response = yield call(updateCustomerApi, payload);
        yield put({ type: customerConstants.UPDATE_CUSTOMER_SUCCESS, response });
    } catch (error) {
        yield put({ type: customerConstants.UPDATE_CUSTOMER_FAILURE, error });
    }
}

export default function* customerSaga() {
    yield takeEvery(customerConstants.GET_CUSTOMERS, customerDropdownSaga);
    yield takeEvery(customerConstants.GET_CUSTOMERS_NEW, customerlistSaga);
    yield takeLatest(customerConstants.CREATE_CUSTOMER_INIT, createcustomerSaga);
    yield takeLatest(customerConstants.GET_CUSTOMER_INIT, getCustomerSaga);
    yield takeLatest(customerConstants.UPDATE_CUSTOMER_INIT, updatecustomerSaga);
}