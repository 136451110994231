export const productConstants = {
    CREATE_PRODUCT_INIT: "CREATE_PRODUCT_INIT",
    CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",
    CREATE_PRODUCT_FAILURE: "CREATE_PRODUCT_FAILURE",

    UPDATE_PRODUCT_INIT: "UPDATE_PRODUCT_INIT",
    UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
    UPDATE_PRODUCT_FAILURE: "UPDATE_PRODUCT_FAILURE",

    GET_PRODUCT_UNIT: "GET_PRODUCT_UNIT",
    GET_PRODUCT_UNIT_SUCCESS: "GET_PRODUCT_UNIT_SUCCESS",
    GET_PRODUCT_UNIT_FAILURE: "GET_PRODUCT_UNIT_FAILURE",

    GET_SINGLE_PRODUCT: "GET_SINGLE_PRODUCT",
    GET_SINGLE_PRODUCT_SUCCESS: "GET_SINGLE_PRODUCT_SUCCESS",
    GET_SINGLE_PRODUCT_FAILURE: "GET_SINGLE_PRODUCT_FAILURE",

    GET_PRODUCTS: "GET_PRODUCTS_LIST",
    GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_LIST_SUCCESS",
    GET_PRODUCTS_FAILURE: "GET_PRODUCTS_LIST_FAILURE",

    GET_BLUK_PRODUCTS: "GET_BLUK_PRODUCTS_LIST",
    GET_BLUK_PRODUCTS_SUCCESS: "GET_BLUK_PRODUCTS_LIST_SUCCESS",
    GET_BLUK_PRODUCTS_FAILURE: "GET_BLUK_PRODUCTS_LIST_FAILURE",

    GET_PRODUCTS_HISTORY: "GET_PRODUCTS_HISTORY",
    GET_PRODUCTS_HISTORY_SUCCESS: "GET_PRODUCTS_HISTORY_SUCCESS",
    GET_PRODUCTS_HISTORY_FAILURE: "GET_PRODUCTS__HISTORY_FAILURE",

    ADD_STOCK: "ADD_STOCK_LIST",
    ADD_STOCK_SUCCESS: "ADD_STOCK_LIST_SUCCESS",
    ADD_STOCK_FAILURE: "ADD_STOCK_LIST_FAILURE",

    GET_PRODUCT_DOWNLOAD: "GET_PRODUCT_DOWNLOAD_LIST",
    GET_PRODUCT_DOWNLOAD_SUCCESS: "GET_PRODUCT_DOWNLOAD_LIST_SUCCESS",
    GET_PRODUCT_DOWNLOAD_FAILURE: "GET_PRODUCT_DOWNLOAD_LIST_FAILURE",

    GET_STOCK: "GET_STOCK",

    CHANGE_PAGE: "CHANGE_PAGE",
    CLEAR_SINGLE_PRODUCT: "CLEAR_SINGLE_PRODUCT",
    CLEAR_CSV_DATA: "CLEAR_CSV_DATA",
    CLEAR_STOCK_PRODUCT: "CLEAR_SINGLE_PRODUCT",
    GET_PARTICLUAR_PRODUCT: "GET_PARTICLUAR_PRODUCT",

    // TODO: Product Bulk upload download

    GET_BULK_DOWNLOAD: "GET_BULK_DOWNLOAD_LIST",
    GET_BULK_DOWNLOAD_SUCCESS: "GET_BULK_DOWNLOAD_LIST_SUCCESS",
    GET_BULK_DOWNLOAD_FAILURE: "GET_BULK_DOWNLOAD_LIST_FAILURE",

    UPDATE_BULK_INIT: "UPDATE_BULK_INIT",
    UPDATE_BULK_SUCCESS: "UPDATE_BULK_SUCCESS",
    UPDATE_BULK_FAILURE: "UPDATE_BULK_FAILURE",

    CLEAR_SUCCESS_MSG: "CLEAR_SUCCESS_MSG"
}


