import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
    getProductById,
    dateFormat,
    clearSingleProduct,
    getBulkProducts,
    createBoqProduct,
    getBoqProjectDetail,
    getProduct,
    editBoqProduct,
    displayCurrency
} from "../../../../../actions";
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import validate from '../../../createpurchaseorder/productdetails/validate';

const renderField = ({ input, placeholder, maxLength, defaultValue, type, readOnly, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength}
                defaultValue={defaultValue}
                readOnly={readOnly} />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

class AddPoProduct extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            quantity: 0,
            discount: 0,
            productId: 0,
            items: [],
            total: 0,
            groupId: this.props.groupId,
            singleProduct: this.props.singleProduct,
        }
        this.myRef = React.createRef();
        this.customerChange = this.customerChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.getQuantity = this.getQuantity.bind(this);
        this.addGroupProduct = this.addGroupProduct.bind(this);
        this.clearSingleProductTrigger = this.clearSingleProductTrigger.bind(this);
        this.scrollToDiv = this.scrollToDiv.bind(this);
    }
    componentDidMount() {
        this.props.dispatch(getBulkProducts(""));
    }
    getQuantity(e) {
        this.setState({
            quantity: e.target.value
        })
        const { initialValues } = this.props;
        let date1 = new Date();
        let oldDate = dateFormat(initialValues?.effectiveOnDate);
        let currentDate = dateFormat(date1);
        let checkDate = currentDate < oldDate;
        let price;
        if (initialValues.unitPrice) {
            price = initialValues.unitPrice
        } else {
            price = checkDate ? initialValues.currentPrice : initialValues.effectivePrice;
        }
        let discount1 = Number(e.target.value * price);
        this.setState({
            total: discount1
        })
    }
    customerChange(e) {
        this.props.bulkProducts?.content?.map((item) => {
            if (e[0] === (`${item.productName} - ${item.articleId}`)) {
                this.props.dispatch(getProductById(item?.productId));
            }
        });
    }
    inputChange(text) {
        const ele = document.getElementById("async-example");
        if (ele !== null) {
            ele.style.transform = "translate3d(0px, -310px, 0px)";
            ele.style.height = "300px";
        }
        this.props.dispatch(getBulkProducts(text));
    }
    scrollToDiv = () => {
        // window.scrollTo(0, window.pageYOffset + 100);
        window.scroll({
            top: window.pageYOffset + 100,
            left: 0,
            behavior: 'smooth'
        });
        // const div = document.getElementById('table-bluee');
        // if (div !== null) {
        //     document.getElementById('table-bluee').scrollIntoView({ behavior: 'smooth', block: "start" });
        // }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.boqProductSuccess !== this.props.boqProductSuccess) {
            const { initialValues, boqProductSuccess, str, onHide, data } = this.props;
            if (boqProductSuccess.status === 200) {
                if (str === "edit") {
                    onHide();
                }
                this.setState({
                    total: 0
                })
                this.props.dispatch(getBoqProjectDetail(this.props.purchaseId));
                toast(boqProductSuccess.data);
            } else {
                toast(boqProductSuccess.data);
            }
            initialValues.articleId = "";
            initialValues.unitName = "";
            initialValues.currentPrice = "";
            initialValues.effectivePrice = "";
            initialValues.totalCost = "";
            this.props.dispatch(clearSingleProduct());
            this.props.reset();
            //clear typehead
            // document.getElementsByClassName("rbt-close")[0].click();
        }
        if (prevProps.bulkProducts !== this.props.bulkProducts) {
            this.setState({
                items: this.props.bulkProducts?.content,
            });
        }
    }
    addGroupProduct(formData) {
        this.scrollToDiv();
        const { data } = this.props;
        if (!formData.Quantity) {
            throw new SubmissionError({
                Quantity: 'Please fill the field *',
            })
        }
        if (formData.productId !== undefined) {
            const { str } = this.props;
            if (str !== "edit") {
                let data = {
                    "projectId": this.props.projectId,
                    "productId": formData.productId,
                    "quantity": formData.Quantity
                }
                document.getElementsByClassName("rbt-close")[0].click();
                this.props.dispatch(createBoqProduct(data));
            } else {
                let submitData = {
                    "billOfQuantityProductId": data?.billOfQuantityProductId,
                    "quantity": formData.Quantity,
                    "productId": formData.productId
                }
                this.props.dispatch(editBoqProduct(submitData));
            }
        } else {
            toast("Choose a product")
        }
    }
    clearSingleProductTrigger() {
        this.props.dispatch(clearSingleProduct());
    }

    render() {
        const { handleSubmit, show, onHide, reset, initialValues, productLoad, str, data } = this.props;
        const { total } = this.state;
        let date1 = new Date();
        let oldDate = dateFormat(initialValues?.effectiveOnDate);
        let currentDate = dateFormat(date1);
        let checkDate = currentDate < oldDate;
        let a = '';
        if (initialValues?.unitPrice !== undefined) {
            a = 'unitPrice'
        } else {
            a = checkDate ? "currentPrice" : "effectivePrice"
        }
        return (
            <>
                {show ? <div
                    className='mini-popup product_pop'
                    show={show}
                    onHide={() => {
                        this.setState({
                            total: 0,
                            quantity: 0,
                            discount: 0,
                        })
                        initialValues.articleId = "";
                        initialValues.unitName = "";
                        initialValues.totalCost = ""
                        initialValues.currentPrice = "";
                        initialValues.effectivePrice = "";
                        reset();
                        onHide();
                    }}
                    size="xl"
                >
                    <div className="modal-staff-here main">
                        <form onSubmit={handleSubmit(this.addGroupProduct)}>
                            <Row className="modal-row">
                                <Col md={1}>
                                    <i class="fas fa-arrow-left" style={{ marginTop: "20px" }} onClick={() => {
                                        this.setState({
                                            total: 0,
                                            quantity: 0,
                                            discount: 0,
                                        })
                                        initialValues.articleId = "";
                                        initialValues.unitName = "";
                                        initialValues.currentPrice = "";
                                        initialValues.effectivePrice = "";
                                        initialValues.totalCost = ""
                                        reset();
                                        onHide();
                                    }}></i>
                                </Col>
                                <Col md={11} style={{ marginTop: "10px", marginLeft: "-70px", width: "120%" }}>
                                    <label class="form-control-placeholder type-form" for="name" >Product Name <span>*</span></label>
                                    {this.state.items?.length >= 0 && str !== "edit" ?
                                        <div>
                                            <Typeahead
                                                onChange={this.customerChange}
                                                ref={(ref) => this._typeahead = ref}
                                                id="async-example"
                                                style={{ width: "105%" }}
                                                labelKey={this.state.items.map(item => item.productName)}
                                                onInputChange={this.inputChange}
                                                options={this.state.items.map(item => `${item.productName} - ${item.articleId}`)}
                                                clearButton={true}
                                                instanceRef={this.myRef}
                                                className="new-bot"
                                            />
                                            <div className="triangle-bottom" style={{ marginTop: "-22px", right: "-10px" }}></div>
                                        </div>
                                        : <div>
                                            <p className="border-area" dangerouslySetInnerHTML={{ __html: data.productName }}></p>
                                        </div>}
                                </Col>
                                <Col md={1} style={{ marginLeft: "40px" }}>
                                    <label class="form-control-placeholder" for="name" >Qty <span>*</span></label>
                                    <Field
                                        name="Quantity"
                                        type="text"
                                        component={renderField}
                                        onChange={this.getQuantity}
                                        maxLength="8"
                                    />
                                </Col>
                                <Col md={7}>
                                    <div className='add_product_new'>
                                        <div>
                                            <p>Article ID</p>
                                            <h3>{initialValues?.articleId || 0}</h3>
                                        </div>
                                        <div>
                                            <p>Available Stock</p>
                                            <h3>{initialValues?.totalStock}</h3>
                                        </div>
                                        <div>
                                            <p>Unit Name</p>
                                            <h3>{initialValues?.unitName}</h3>
                                        </div>
                                        {/* <div>
                                            <p>Unit Cost</p>
                                            <h3 style={{ textAlign: "right" }}>{str === "edit" ? data?.unitPrice : checkDate ? initialValues?.currentPrice : initialValues?.effectivePrice}</h3>
                                        </div>
                                        <div>
                                            <p>Total Cost</p>
                                            <h3 style={{ textAlign: "right" }}>{total === 0 ? isNaN(data?.totalPrice) ? "" : displayCurrency(data?.totalPrice) : displayCurrency(total)} </h3>
                                        </div> */}
                                    </div>
                                </Col>
                                {/* <Col md={3}>
                                    <label class="form-control-placeholder" for="name" >Available Stock </label>
                                    <Field
                                        name="totalStock"
                                        type="number"
                                        component={renderField}
                                        readOnly
                                    />
                                </Col>
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name" >Article ID </label>
                                    <Field
                                        name="articleId"
                                        type="text"
                                        component={renderField}
                                        readOnly
                                    />
                                </Col>

                                <Col md={2}>
                                    <label class="form-control-placeholder" for="name" >Unit Name </label>
                                    <Field
                                        name="unitName"
                                        component={renderField}
                                        readOnly
                                    />
                                </Col>
                                <Col md={2}>
                                    <label class="form-control-placeholder" for="name" >Unit Cost </label>
                                    <Field
                                        name={a}
                                        type="text"
                                        component={renderField}
                                        readOnly
                                    />
                                </Col>
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name" >Total Cost </label>
                                    <Form.Label></Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        name="totalCost"
                                        value={total === 0 ? isNaN(initialValues?.totalCost) ? "" : initialValues?.totalCost : total}
                                    />
                                </Col> */}
                                <Field
                                    name="productId"
                                    type="hidden"
                                    component={renderField}
                                />
                                <Col md={3}>
                                    {!productLoad ? <button type="submit" className="modal-btn sub-modal-btn" style={{ width: "120%" }}>
                                        {str === "edit" ? "Update" : "Add"} Product
                                    </button> : <button type="button" className="modal-btn sub-modal-btn" style={{ width: "120%" }}>Loading..</button>}
                                </Col>
                            </Row>
                        </form>
                    </div>
                </div> : null}
            </>
        );
    }
}

function mapStateToProps(state) {
    let item = state.product.singleProduct;
    let page = state.po.page;
    let newItem = page === "edit" ? getProduct() : {};
    let data = {
        'articleId': newItem?.articleId,
        'productName': newItem.productName,
        'Quantity': newItem.quantity,
        'unitName': newItem?.unit?.unitName,
        'productId': newItem?.productId,
        'unitPrice': newItem?.currentPrice,
        'totalStock': newItem?.inStockQuantity,
        'totalCost': newItem?.currentPrice * newItem.quantity
    };
    let initValues = item !== null ?
        {
            'articleId': item?.articleId,
            'discount': 0,
            'unitName': item?.unitName,
            'productId': item?.productId,
            'currentPrice': isNaN(item?.currentPrice) ? 0 : item?.currentPrice * (50 / 100),
            'effectivePrice': isNaN(item?.effectivePrice) ? 0 : item?.effectivePrice * (50 / 100),
            'totalStock': item?.totalStock
        } : data;

    return {
        initialValues: initValues,
        productLoad: state.boq.productLoad,
        bulkProducts: state.product.bulkProducts,
        singleProduct: state.product.singleProduct,
        boqProductSuccess: state.boq.boqProductSuccess,
    }
}

const BoqProductForm = reduxForm({
    form: "AddPoProduct",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate
})(AddPoProduct);

export default connect(mapStateToProps)(BoqProductForm);