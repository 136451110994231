export const groupConstants = {
    GET_GROUP: "GET_GROUP_LIST",
    GET_GROUP_SUCCESS: "GET_GROUP_LIST_SUCCESS",
    GET_GROUP_FAILURE: "GET_GROUP_LIST_FAILURE",

    CREATE_GROUP_INIT: "CREATE_GROUP_INIT",
    CREATE_GROUP_SUCCESS: "CREATE_GROUP_SUCCESS",
    CREATE_GROUP_FAILURE: "CREATE_GROUP_FAILURE",

    GET_GROUP_INIT: "GET_GROUP_INIT",
    GET_GROUP_SUCCESS: "GET_GROUP_SUCCESS",
    GET_GROUP_FAILURE: "GET_GROUP_FAILURE",

    UPDATE_GROUP_INIT: "UPDATE_GROUP_INIT",
    UPDATE_GROUP_SUCCESS: "UPDATE_GROUP_SUCCESS",
    UPDATE_GROUP_FAILURE: "UPDATE_GROUP_FAILURE",

    CHANGE_PAGE: "CHANGE_PAGE",
    CLEAR_GROUP_VALUES: "CLEAR_GROUP_VALUES"
}