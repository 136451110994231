export const projectsConstants = {
    GET_PROJECTS: "GET_PROJECTS_LIST",
    GET_PROJECTS_SUCCESS: "GET_PROJECTS_LIST_SUCCESS",
    GET_PROJECTS_FAILURE: "GET_PROJECTS_LIST_FAILURE",

    CREATE_PROJECTS_INIT: "CREATE_PROJECTS_INIT",
    CREATE_PROJECTS_SUCCESS: "CREATE_PROJECTS_SUCCESS",
    CREATE_PROJECTS_FAILURE: "CREATE_PROJECTS_FAILURE",

    GET_SINGLE_PROJECTS_INIT: "GET_SINGLE_PROJECTS_INIT",
    GET_SINGLE_PROJECTS_SUCCESS: "GET_SINGLE_PROJECTS_SUCCESS",
    GET_SINGLE_PROJECTS_FAILURE: "GET_SINGLE_PROJECTS_FAILURE",

    UPDATE_PROJECTS_INIT: "UPDATE_PROJECTS_INIT",
    UPDATE_PROJECTS_SUCCESS: "UPDATE_PROJECTS_SUCCESS",
    UPDATE_PROJECTS_FAILURE: "UPDATE_PROJECTS_FAILURE",

    CHANGE_PAGE: "CHANGE_PAGE",
}