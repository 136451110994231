import { authToken, baseUrl } from "../actions";


export const getDeliverApi = (searchKey) => {
    var DELIVER_LOGS;
        DELIVER_LOGS = `${baseUrl}staff/listOfProjectsForOutwards`;

    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(DELIVER_LOGS, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}