import { authToken,baseUrl } from "../actions";

export const getAllUnitsApi = () => {
    const UNITS_LIST = `${baseUrl}public/dropdown/units`;
    //  "https://estimationapi.corpfield.com/admin/units";
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(UNITS_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const addUnitApi = (request) => {
    const ADD_UNIT =  `${baseUrl}admin/units`;
    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(request)
    };

    return fetch(ADD_UNIT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const updateUnitApi = (request) => {
    const UPDATE_UNIT =  `${baseUrl}admin/units`;
    const parameters = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(request)
    };

    return fetch(UPDATE_UNIT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};