import { staffConstants } from "../constants";

export const addStaff = (formData) => {
    return {
        type: staffConstants.STAFF_REQUEST,
        formData,
    }
}

export const getStaffList = (pageNo, searchKey, sort, filterData) => {
    return {
        type: staffConstants.GET_STAFF,
        pageNo,
        searchKey,
        sort,
        filterData
    };
};



export const getStaffById = (staffId) => {
    return {
        type: staffConstants.GET_SINGLE_STAFF,
        staffId
    };
};

export const updateStaff = (formData) => {
    return {
        type: staffConstants.UPDATE_STAFF_REQUEST,
        formData,
    }
}

export const updateStaffStatus = (userId, status) => {
    return {
        type: staffConstants.UPDATE_STAFF_STATUS_REQUEST,
        userId,
        status,
    }
}



export const changePage = () => {
    return {
        type: staffConstants.CHANGE_PAGE,
    }
}
