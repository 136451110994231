import { vendorpoConstants } from "../constants";

export const getVendorPoList = (item) => {
    return {
        type: vendorpoConstants.GET_VENDORPO,
        item
    }
}
export const createVendorPO = (formData) => {
    return {
        type: vendorpoConstants.CREATE_VENDORPO_INIT,
        formData
    }
}
export const getVendorPo = (purchaseOrderId) => {
    return {
        type: vendorpoConstants.GET_VENDORPO_INIT,
        purchaseOrderId
    }
}
export const updateVendorPo = (formData) => {
    return {
        type: vendorpoConstants.UPDATE_VENDORPO_INIT,
        formData
    }
}
export const changePage = () => {
    return {
        type: vendorpoConstants.CHANGE_PAGE,
    }
}