export const dashboardConstants = {
    GET_DASHBOARD: "GET_DASHBOARD_LIST",
    GET_DASHBOARD_SUCCESS: "GET_DASHBOARD_LIST_SUCCESS",
    GET_DASHBOARD_FAILURE: "GET_DASHBOARD_LIST_FAILURE",

    GET_DASHBOARD_COUNT: "GET_DASHBOARD_COUNT_LIST",
    GET_DASHBOARD_COUNT_SUCCESS: "GET_DASHBOARD_COUNT_LIST_SUCCESS",
    GET_DASHBOARD_COUNT_FAILURE: "GET_DASHBOARD_COUNT_LIST_FAILURE",

    GET_DASHBOARD_OUTOFSTOCK: "GET_DASHBOARD_OUTOFSTOCK_LIST",
    GET_DASHBOARD_OUTOFSTOCK_SUCCESS: "GET_DASHBOARD_OUTOFSTOCK_LIST_SUCCESS",
    GET_DASHBOARD_OUTOFSTOCK_FAILURE: "GET_DASHBOARD_OUTOFSTOCK_LIST_FAILURE",
}