import { groupConstants } from "../constants";

export const getGroupList = (pageNo, searchKey, sort) => {
    return {
        type: groupConstants.GET_GROUP,
        pageNo,
        searchKey,
        sort
    }
}
export const createGroup = (formData) => {
    return {
        type: groupConstants.CREATE_GROUP_INIT,
        formData
    }
}
export const getGroup = (quotationMaterialGroupId) => {
    return {
        type: groupConstants.GET_GROUP_INIT,
        quotationMaterialGroupId
    }
}
export const updateGroup = (formData) => {
    return {
        type: groupConstants.UPDATE_GROUP_INIT,
        formData
    }
}

export const clearValuesOfGroup = () => {
    return {
        type: groupConstants.CLEAR_GROUP_VALUES,
    }
}
export const changePage = () => {
    return {
        type: groupConstants.CHANGE_PAGE,
    }
}