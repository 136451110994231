export const reportPurchaseConstants = {
    GET_REPORT_PURCHASE: "GET_REPORT_PURCHASE_LIST",
    GET_REPORT_PURCHASE_SUCCESS: "GET_REPORT_PURCHASE_LIST_SUCCESS",
    GET_REPORT_PURCHASE_FAILURE: "GET_REPORT_PURCHASE_LIST_FAILURE",

    GET_REPORT_PURCHASE_DOWNLOAD: "GET_REPORT_PURCHASE_DOWNLOAD_LIST",
    GET_REPORT_PURCHASE_DOWNLOAD_SUCCESS: "GET_REPORT_PURCHASE_DOWNLOAD_LIST_SUCCESS",
    GET_REPORT_PURCHASE_DOWNLOAD_FAILURE: "GET_REPORT_PURCHASE_DOWNLOAD_LIST_FAILURE",

    CLEAR_REPORT_CSV_DATA : "CLEAR_REPORT_CSV_DATA"
}