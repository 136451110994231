import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { camelize, changeClassName, changeStockName, getDashboardList, getStaffProfile, getDashboardCount, getDashboardOutOfStock, displayCurrency } from "../../actions";
import DashboardCard from "./DashboardCard";
import Spinner from "../../Components/loader";
import parse from 'html-react-parser';
import "../../assets/css/button.css";
const role = localStorage.getItem('userRole');


class Quotation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allStaffs: [],
      allQuotation: [],
      allCount: [],
      outstock: [],
      show: false,
      quotationId: "",
      revised: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.staffHandler = this.staffHandler.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getDashboardList());
    this.props.dispatch(getStaffProfile());
    this.props.dispatch(getDashboardOutOfStock());
    this.props.dispatch(getDashboardCount());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.quotation_list !== this.props.quotation_list) {
      this.setState({
        allQuotation: this.props.quotation_list,
      });
    }
    if (prevProps.count !== this.props.count) {
      this.setState({
        allCount: this.props.count,
      });
    }
    if (prevProps.out_stock !== this.props.out_stock) {
      this.setState({
        outstock: this.props.out_stock,
      });
    }
  }

  handleShow() {
    window.location.hash = "/" + role + "/quotations";
  }

  staffHandler() {
    if (role === "admin") {
      window.location.hash = "/" + role + "/staff";
    }
  }

  render() {
    const { allQuotation, allCount, outstock } = this.state;

    return (
      <Row>
        <Col md={12}>
          <DashboardCard data={allCount} />
        </Col>
        <Col md={7}>
          <Card className="quotation-part">
            <h6>Recent Quotations</h6>
            <Link to={"/" + role + "/quotations"}>
              <p>View all</p>
            </Link>
            <i className="fas fa-arrow-right"></i>
            <div className="table-class">
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Reference ID</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Sales Executive</th>
                    <th style={{ textAlign: "center" }}>Status</th>
                    <th style={{ textAlign: "right" }}>Grand Total</th>
                  </tr>
                </thead>
                <tbody>
                  {allQuotation && allQuotation.length > 0 ? (
                    allQuotation.map((quotation) => (
                      <tr key={quotation?.referenceId} style={{ cursor: "pointer" }}>
                        <td onClick={(e) => this.handleShow()}>{quotation?.referenceId}</td>
                        <td onClick={(e) => this.handleShow()}>{quotation?.customerFirstName} {quotation?.customerLastName}</td>
                        <td onClick={(e) => this.handleShow()}>{quotation?.salesExecutive || "-"}</td>
                        <td style={{ textAlign: "center" }}>
                          <button style={{ width: "120px" }} className={changeClassName(quotation?.status)} onClick={(e) => this.handleShow()} >
                            {quotation?.status}
                          </button>
                        </td>
                        <td style={{ textAlign: "right" }}>{displayCurrency(Number(quotation?.grandTotal))}</td>
                      </tr>
                    ))
                  ) : !this.props.loading ?
                    <h1 className="empty-datas">No Records Found</h1> :
                    <Spinner />
                  }
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
        <Col md={5}>
          <Card className="quotation-part">
            <h6>Products Nearing Out Of Stock</h6>
            <Link to={"/" + role + "/product"}>
              <p>View all</p>
            </Link>
            <i className="fas fa-arrow-right"></i>
            <div className="table-class">
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Article ID</th>
                    <th scope="col">Product Name</th>
                    <th style={{ textAlign: "right" }}>Available Stock</th>
                  </tr>
                </thead>
                <tbody>
                  {outstock && outstock?.length > 0 ? (
                    outstock?.map((outstock) => (
                      <tr key={outstock?.articleId} style={{ cursor: "pointer" }}>
                        <td style={{ textOverflow: "ellipsis", overflow: "clip", maxWidth: "90px" }}>{outstock?.articleId || "-"}</td>
                        <td className="dashboard_table_item">
                          {outstock?.productName || "-"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <button className={changeStockName(outstock?.availableStock)} >
                            {outstock?.availableStock}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : !this.props.loading ?
                    <h1 className="empty-datas">No Records Found</h1> :
                    <Spinner />
                  }
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.dashboard.loading,
    quotation_list: state.dashboard.quotation_list,
    count: state.dashboard.count,
    out_stock: state.dashboard.out_stock
  };
};

export default connect(mapStateToProps)(Quotation);



{/* <Col md={4}>
          <Card className="quotation-part">
            <h6>Staffs</h6>
            {role !== "staff" ? <Link to={"/" + role + "/staff"}>
              <p>View all</p>
              <i class="fas fa-arrow-right"></i>
            </Link> : <></>
            }
            <div className="table-class">
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Staff Name</th>
                    <th scope="col">Role</th>
                    <th scope="col">Quotations</th>
                  </tr>
                </thead>
                <tbody>
                  {allStaffs && allStaffs.length > 0 ? (
                    allStaffs.map((staff) => {
                      let role = staff.role !== "undefined" ? camelize(staff.role) : "-";
                      let ln = staff.lastName !== "undefined" ? staff.lastName : " ";
                      return (
                        <tr key={staff.userId} style={{ cursor: "pointer" }}>
                          <td onClick={(e) => this.staffHandler()}>{`${staff.firstName ?? ""} ${ln ?? ""}`}</td>
                          <td onClick={(e) => this.staffHandler()}>{role ?? <div className="center"><p>-</p></div>}</td>
                          <td onClick={(e) => this.staffHandler()}>{staff.quotationsCreated ?? 75}</td>
                        </tr>
                      )
                    })
                  ) : !this.props.loading ?
                    <h1 className="empty-datass">No Records Found</h1> :
                    <Spinner />
                  }
                </tbody>
              </table>
            </div>
          </Card>
        </Col> */}
