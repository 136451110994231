import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { connect } from "react-redux";
import Navbar from "../../layouts/Navbar";
import Sidebar from "../../layouts/Sidebar";
import Orange from "../../assets/images/pl-file.svg";
import View from "./View";
import Spinner from "../../Components/loader";
import { getQutationTemplates } from "../../actions";


class Templates extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      templates: [],
      temp: [],
      show: false
    }
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleShow(item) {
    this.setState({
      show: true,
      temp: item
    })
  }

  handleClose() {
    this.setState({
      show: false
    })
  }


  componentDidMount() {
    this.props.dispatch(getQutationTemplates());
  }
  componentDidUpdate(prevProps) {
    if (prevProps.quotationTemplates !== this.props.quotationTemplates) {
      this.setState({
        templates: this.props.quotationTemplates
      });
    }
  }
  render() {
    const { templates, show, temp } = this.state;

    return (
      <div>
        <Navbar />
        <Row className="dashboard-page">
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <Card className="staff-page">
              <img src={Orange} alt="..." className="head-img" />
              <h5>Available Templates</h5>
              <Row className="template">
                {templates && templates.length > 0 ? templates.map((item, i) => (
                  <Col md={3} key={i}>
                    <Card className="template-card">
                      <img src={item.templateSampleUrl} alt="..." onClick={(e) => this.handleShow(item)} />
                      <p>{item.templateName}</p>
                      <button className="select-template" style={{ color: "#fff" }} onClick={(e) => this.handleShow(item)}>
                        View
                      </button>
                    </Card>

                  </Col>
                )) : !this.props.loading ?
                  <h1 className="empty-data">No Data Found</h1> :
                  <Spinner />
                }
              </Row>
            </Card>
          </Col>
        </Row>
        <View temp={temp} show={show} onHide={this.handleClose} />

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.qutation.loading,
    quotationTemplates: state.qutation.quotationTemplates
  }
}


export default connect(mapStateToProps)(Templates);
