import { inwardConstants } from "../constants";

export const getVerifiedProject = (productId) => {
    return {
        type: inwardConstants.GET_VERIFIED_PROJECTS,
        productId
    };
};

export const getInwardLogs = (item) => {
    return {
        type: inwardConstants.GET_INWARD_LOGS,
        item
    };
};

export const getReceivedInward = (item) => {
    return {
        type: inwardConstants.GET_RECEIVED_INWARD,
        item
    };
};

export const addReturnInwardProduct = (formData) => {
    return {
        type: inwardConstants.ADD_RETURN_INWARD_PRODUCT_INIT,
        formData
    };
};

export const downloadInwardCsv = (item) => {
    return {
        type: inwardConstants.DOWNLOAD_INWARD_CSV,
        item
    }
}

export const downloadYetToReceiveCsv = (item) => {
    return {
        type: inwardConstants.DOWNLOAD_YET_TO_RECEIVE_CSV,
        item
    }
}