import { put, call, takeEvery } from "redux-saga/effects";
import { poConstants } from "../constants";
import {
    addPoInwardApi,
    cancelPoApi,
    clonePoApi,
    convertToPoApi,
    createPoFooterApi,
    createPoProductApi,
    createPoProjectApi,
    deletePoApi,
    deletePoFooterApi,
    deletePoProductApi,
    deletePoProjectApi,
    editPoCustomProductApi,
    generatePoApi,
    getBoqProjectDropdownApi,
    getConfirmProductsApi,
    addPoInwardProductsApi,
    getPoByIdApi,
    getPoConvertProductsApi,
    getPoFooterApi,
    getPoInwardListApi,
    getPoMailDetailsApi,
    getPoProjectDetailApi,
    getPoVerifiedProductsApi,
    getVerticesDropdownApi,
    previewPoApi,
    revisePoApi,
    sendPoApi,
    sendPoMailApi,
    updatePoFooterApi,
    updatePoIgnoreFieldsApi,
    updatePoProductApi,
    updatePoProjectApi,
    verifyPoApi,
    getVendorPoCsv
} from "../services";

export function* poProjectSaga(payload) {
    try {
        const response = yield call(getPoProjectDetailApi, payload.id);
        yield put({ type: poConstants.GET_PO_PROJECTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.GET_PO_PROJECTS_FAILURE, error });
    }
}
export function* getVerticesSaga(payload) {
    try {
        const response = yield call(getVerticesDropdownApi, payload);
        yield put({ type: poConstants.GET_VERTICS_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.GET_VERTICS_FAILURE, error });
    }
}
export function* getBoqProjectSaga(payload) {
    try {
        const response = yield call(getBoqProjectDropdownApi, payload);
        yield put({ type: poConstants.GET_BOQ_PROJECTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.GET_BOQ_PROJECTS_FAILURE, error });
    }
}
export function* createPoProjectSaga(payload) {
    try {
        const response = yield call(createPoProjectApi, payload);
        yield put({ type: poConstants.CREATE_PO_PROJECT_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.CREATE_PO_PROJECT_FAILURE, error });
    }
}
export function* updatePoProjectSaga(payload) {
    try {
        const response = yield call(updatePoProjectApi, payload);
        yield put({ type: poConstants.UPDATE_PO_PROJECT_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.UPDATE_PO_PROJECT_FAILURE, error });
    }
}
export function* deletePoProjectSaga(payload) {
    try {
        const response = yield call(deletePoProjectApi, payload.id);
        yield put({ type: poConstants.DELETE_PO_PROJECT_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.DELETE_PO_PROJECT_FAILURE, error });
    }
}

export function* getVendorPoDownloadCsvSaga(payload) {
    try {
        const response = yield call(getVendorPoCsv, payload.id);
        yield put({ type: poConstants.VENDOR_PO_DOWNLOAD_CSV_SUCCESS, response});
    } catch (error) {
        yield put({ type: poConstants.VENDOR_PO_DOWNLOAD_CSV_FAILURE, error });
    }
}

export function* createPoProductSaga(payload) {
    try {
        const response = yield call(createPoProductApi, payload);
        yield put({ type: poConstants.CREATE_PO_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.CREATE_PO_PRODUCT_FAILURE, error });
    }
}
export function* updatePoProductSaga(payload) {
    try {
        const response = yield call(updatePoProductApi, payload);
        yield put({ type: poConstants.UPDATE_PO_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.UPDATE_PO_PRODUCT_FAILURE, error });
    }
}
export function* deletePoProductSaga(payload) {
    try {
        const response = yield call(deletePoProductApi, payload.id);
        yield put({ type: poConstants.DELETE_PO_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.DELETE_PO_PRODUCT_FAILURE, error });
    }
}
export function* deletePoSaga(payload) {
    try {
        const response = yield call(deletePoApi, payload.id);
        yield put({ type: poConstants.DELETE_PO_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.DELETE_PO_FAILURE, error });
    }
}
export function* updatePoIgnoreFieldsSaga(payload) {
    try {
        const response = yield call(updatePoIgnoreFieldsApi, payload);
        yield put({ type: poConstants.UPDATE_PO_IGNORE_FIELDS_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.UPDATE_PO_IGNORE_FIELDS_FAILURE, error });
    }
}
export function* getPoFooterSaga(payload) {
    try {
        const response = yield call(getPoFooterApi, payload.id);
        yield put({ type: poConstants.GET_PO_FOOTER_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.GET_PO_FOOTER_FAILURE, error });
    }
}
export function* createPoFooterSaga(payload) {
    try {
        const response = yield call(createPoFooterApi, payload);
        yield put({ type: poConstants.CREATE_PO_FOOTER_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.CREATE_PO_FOOTER_FAILURE, error });
    }
}
export function* updatePoFooterSaga(payload) {
    try {
        const response = yield call(updatePoFooterApi, payload);
        yield put({ type: poConstants.UPDATE_PO_FOOTER_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.UPDATE_PO_FOOTER_FAILURE, error });
    }
}
export function* deletePoFooterSaga(payload) {
    try {
        const response = yield call(deletePoFooterApi, payload.id);
        yield put({ type: poConstants.DELETE_PO_FOOTER_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.DELETE_PO_FOOTER_FAILURE, error });
    }
}
export function* previewPoSaga(payload) {
    try {
        const response = yield call(previewPoApi, payload.id);
        yield put({ type: poConstants.PREVIEW_PO_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.PREVIEW_PO_FAILURE, error });
    }
}
export function* generatePoSaga(payload) {
    try {
        const response = yield call(generatePoApi, payload.id);
        yield put({ type: poConstants.GENERATE_PO_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.GENERATE_PO_FAILURE, error });
    }
}
export function* sendPoSaga(payload) {
    try {
        const response = yield call(sendPoApi, payload.id);
        yield put({ type: poConstants.SENT_PO_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.SENT_PO_FAILURE, error });
    }
}
export function* clonePoSaga(payload) {
    try {
        const response = yield call(clonePoApi, payload.id);
        yield put({ type: poConstants.CLONE_PO_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.CLONE_PO_FAILURE, error });
    }
}
export function* revisePoSaga(payload) {
    try {
        const response = yield call(revisePoApi, payload.id);
        yield put({ type: poConstants.REVISE_PO_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.REVISE_PO_FAILURE, error });
    }
}
export function* cancelPoSaga(payload) {
    try {
        const response = yield call(cancelPoApi, payload.id);
        yield put({ type: poConstants.CANCEL_PO_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.CANCEL_PO_FAILURE, error });
    }
}
export function* getConfirmProductsSaga(payload) {
    try {
        const response = yield call(getConfirmProductsApi, payload);
        yield put({ type: poConstants.GET_CONVERT_PRODUCTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.GET_CONVERT_PRODUCTS_FAILURE, error });
    }
}
export function* getPoByIdSaga(payload) {
    try {
        const response = yield call(getPoByIdApi, payload.id);
        yield put({ type: poConstants.GET_PO_BY_ID_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.GET_PO_BY_ID_FAILURE, error });
    }
}
export function* getPoVerifyProductsSaga(payload) {
    try {
        const response = yield call(getPoVerifiedProductsApi, payload.id);
        yield put({ type: poConstants.GET_PO_VERIFIED_PRODUCTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.GET_PO_VERIFIED_PRODUCTS_FAILURE, error });
    }
}
export function* verifyPoSaga(payload) {
    try {
        const response = yield call(verifyPoApi, payload);
        yield put({ type: poConstants.VERIFY_PO_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.VERIFY_PO_FAILURE, error });
    }
}
export function* getPoInwardSaga(payload) {
    try {
        const response = yield call(getPoInwardListApi, payload);
        yield put({ type: poConstants.GET_PO_INWARD_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.GET_PO_INWARD_FAILURE, error });
    }
}
export function* addPoInwardSaga(payload) {
    try {
        const response = yield call(addPoInwardApi, payload);
        yield put({ type: poConstants.ADD_PO_INWARD_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.ADD_PO_INWARD_FAILURE, error });
    }
}
export function* getPoConvertProductsSaga(payload) {
    try {
        const response = yield call(getPoConvertProductsApi, payload);
        yield put({ type: poConstants.GET_PO_CONVERTED_PRODUCTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.GET_PO_CONVERTED_PRODUCTS_FAILURE, error });
    }
}
export function* addPoInwardProductsSaga(payload) {
    try {
        const response = yield call(addPoInwardProductsApi, payload);
        yield put({ type: poConstants.CREATE_PO_INWARD_PRODUCTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.CREATE_PO_INWARD_PRODUCTS_FAILURE, error });
    }
}
export function* updatePoCustomProductSaga(payload) {
    try {
        const response = yield call(editPoCustomProductApi, payload);
        yield put({ type: poConstants.UPDATE_CUSTOM_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.UPDATE_CUSTOM_PRODUCT_FAILURE, error });
    }
}
export function* convertToPoSaga(payload) {
    try {
        const response = yield call(convertToPoApi, payload);
        yield put({ type: poConstants.CONVERT_TO_PO_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.CONVERT_TO_PO_FAILURE, error });
    }
}
export function* getPoMailDetailsSage(payload) {
    try {
        const response = yield call(getPoMailDetailsApi, payload.id);
        yield put({ type: poConstants.GET_MAIL_DETAILS_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.GET_MAIL_DETAILS_FAILURE, error });
    }
}
export function* sendPoMailSaga(payload) {
    try {
        const response = yield call(sendPoMailApi, payload);
        yield put({ type: poConstants.SENT_PO_MAIL_SUCCESS, response });
    } catch (error) {
        yield put({ type: poConstants.SENT_PO_MAIL_FAILURE, error });
    }
}
export default function* purchaseOrderSaga() {
    yield takeEvery(poConstants.GET_PO_PRODUCTS, poProjectSaga);
    yield takeEvery(poConstants.GET_VERTICS, getVerticesSaga);
    yield takeEvery(poConstants.GET_BOQ_PROJECTS, getBoqProjectSaga);
    yield takeEvery(poConstants.CREATE_PO_PROJECT, createPoProjectSaga);
    yield takeEvery(poConstants.UPDATE_PO_PROJECT, updatePoProjectSaga);
    yield takeEvery(poConstants.DELETE_PO_PROJECT, deletePoProjectSaga);
    yield takeEvery(poConstants.CREATE_PO_PRODUCT, createPoProductSaga);
    yield takeEvery(poConstants.UPDATE_PO_PRODUCT, updatePoProductSaga);
    yield takeEvery(poConstants.DELETE_PO_PRODUCT, deletePoProductSaga);
    yield takeEvery(poConstants.DELETE_PO, deletePoSaga);
    yield takeEvery(poConstants.UPDATE_PO_IGNORE_FIELDS, updatePoIgnoreFieldsSaga);
    yield takeEvery(poConstants.GET_PO_FOOTER, getPoFooterSaga);
    yield takeEvery(poConstants.CREATE_PO_FOOTER, createPoFooterSaga);
    yield takeEvery(poConstants.UPDATE_PO_FOOTER, updatePoFooterSaga);
    yield takeEvery(poConstants.DELETE_PO_FOOTER, deletePoFooterSaga);
    yield takeEvery(poConstants.PREVIEW_PO, previewPoSaga);
    yield takeEvery(poConstants.GENERATE_PO, generatePoSaga);
    yield takeEvery(poConstants.CLONE_PO, clonePoSaga);
    yield takeEvery(poConstants.REVISE_PO, revisePoSaga);
    yield takeEvery(poConstants.SENT_PO, sendPoSaga);
    yield takeEvery(poConstants.CANCEL_PO, cancelPoSaga);
    yield takeEvery(poConstants.VERIFY_PO, verifyPoSaga);
    yield takeEvery(poConstants.GET_CONVERT_PRODUCTS, getConfirmProductsSaga);
    yield takeEvery(poConstants.GET_PO_BY_ID, getPoByIdSaga);
    yield takeEvery(poConstants.GET_PO_VERIFIED_PRODUCTS, getPoVerifyProductsSaga);
    yield takeEvery(poConstants.GET_PO_INWARD, getPoInwardSaga);
    yield takeEvery(poConstants.ADD_PO_INWARD, addPoInwardSaga);
    yield takeEvery(poConstants.GET_PO_CONVERTED_PRODUCTS, getPoConvertProductsSaga);
    yield takeEvery(poConstants.UPDATE_CUSTOM_PRODUCT, updatePoCustomProductSaga);
    yield takeEvery(poConstants.CONVERT_TO_PO, convertToPoSaga);
    yield takeEvery(poConstants.GET_MAIL_DETAILS, getPoMailDetailsSage);
    yield takeEvery(poConstants.SENT_PO_MAIL, sendPoMailSaga);
    yield takeEvery(poConstants.CREATE_PO_INWARD_PRODUCTS, addPoInwardProductsSaga);
    yield takeEvery(poConstants.VENDOR_PO_DOWNLOAD_CSV, getVendorPoDownloadCsvSaga);

}
