import React from "react";
import { Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Navbar from "../../layouts/Navbar";
import Sidebar from "../../layouts/Sidebar";
import Filter from "../../assets/images/filter.svg";
import cust from "../../assets/images/yellow1.svg";
import Search from "../../assets/images/search.svg";
import { connect } from "react-redux";
import { getProjectsList, changeProjectClassName } from "../../actions";
import Spinner from "../../Components/loader";
import Pagination from "../../Components/Pagination";
import { toast } from "react-toastify";
import ProjectView from "./ProjectView";

class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            show: false,
            success_msg: '',
            allData: [],
            sortType: "asc",
            searchKey: "",
            sortField: "",
            pageNo: 0,
            sort: {},
            items: [],
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.onChange = this.onChange.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
        this.sortHandler = this.sortHandler.bind(this);
    }
    handleShow(item) {
        this.setState({
            show: true,
            items: item,
        });
    }
    handleClose() {
        this.setState({
            show: false,
        });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.allData !== this.props.allData) {
            this.setState({
                allData: this.props.allData,
            });
        }
        if (prevProps.success_msg !== this.props.success_msg) {
            this.setState({
                success_msg: this.props.success_msg,
            });
            const { pageNo, searchKey, sort, sortField } = this.state;
            if (this.props.success_msg.status === 200) {
                this.setState({
                    show: false
                });
                toast(this.props.success_msg.data);
                if (sortField === "") {
                    this.props.dispatch(getProjectsList(pageNo, searchKey));
                } else {
                    this.props.dispatch(getProjectsList(pageNo, searchKey, sort));
                }
            } else {
                toast(this.props.success_msg.data);
            }
        }
    }
    componentDidMount() {
        this.props.dispatch(getProjectsList(0));
    }
    onChange(pageNo) {
        this.setState({
            pageNo: pageNo
        });
        const { searchKey, sortType, sortField } = this.state;
        let sortKey = sortType === 'asc' ? 'desc' : 'asc';
        let sort = {
            sortType: sortKey,
            sortField: sortField
        }
        if (sortField === "") {
            this.props.dispatch(getProjectsList(pageNo, searchKey));
        } else {
            this.props.dispatch(getProjectsList(pageNo, searchKey, sort));
        }
    }
    searchHandler(e) {
        const { sort, sortField } = this.state;
        this.setState({
            searchKey: e.target.value
        });
        if (sortField === "") {
            this.props.dispatch(getProjectsList(0, e.target.value));
        } else {
            this.props.dispatch(getProjectsList(0, e.target.value, sort));

        }
    }
    sortHandler(sortField) {
        const { pageNo, searchKey } = this.state;
        this.setState({
            sortField: sortField
        });
        if (this.state.sortType === 'desc') {
            this.setState({
                sortType: 'asc'
            })
        } else {
            this.setState({
                sortType: 'desc'
            })
        }
        let sort = {
            sortType: this.state.sortType,
            sortField: sortField
        }
        this.setState({
            sort: sort
        })
        this.props.dispatch(getProjectsList(pageNo, searchKey, sort));
    }

    render() {
        const {
            items,
            show,
            allData,
        } = this.state;

        let data = {
            totalElements: allData.totalElements,
            totalPages: allData.totalPages,
            offset: allData?.pageable?.offset,
            numberOfElements: allData.numberOfElements,
            num: (allData?.number) + 1
        }
        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <div className="page-head-split">
                                <div className="head-colums">
                                    <div className="head-part-tabs-one">
                                        <img src={cust} alt="..." className="head-imgs" />
                                        <h5>Projects</h5>
                                    </div>
                                    <div className="customer-search">
                                        <span><img src={Search} alt=".." className="search-img" /></span>
                                        <Form.Control type="search" placeholder="Search" onChange={this.searchHandler} className="main-search" />
                                    </div>
                                </div>
                            </div>
                            <div className="table-class">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                PO Number <img onClick={(e) => this.sortHandler("pop.purchase_order_id")} src={Filter} alt="..." />
                                            </th>
                                            <th scope="col">
                                                Project Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("pop.project_name")} />
                                            </th>
                                            <th scope="col" >
                                                Sales Executive <img src={Filter} alt="..." onClick={(e) => this.sortHandler("s.first_name")} />
                                            </th>
                                            <th scope="col" style={{ textAlign: "center" }}  >Status <img src={Filter} alt="..." onClick={(e) => this.sortHandler("s.first_name")} /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <>
                                            {allData.content && allData.content.length > 0 ? allData.content.map((item, i) => (
                                                <tr key={i} onClick={() => this.handleShow(item)} style={{ cursor: "pointer" }}>
                                                    <td>{item.purchaseOrderNumber}</td>
                                                    <td className="inward_new_size" >
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item.projectName || "-"}</Tooltip>}>
                                                            <span className="d-inline-block">
                                                                {item.projectName || "-"}
                                                            </span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td style={{ textAlign: "left" }} >{item.salesExecutive || <div className="center_align"><p>-</p></div>}</td>
                                                    <td style={{ textAlign: "center" }} >
                                                        <button style={{ width: "140px" }} className={changeProjectClassName(item.status)} onClick={(e) => this.handleShow(item)} >
                                                            {item.status}
                                                        </button>
                                                    </td>
                                                </tr>
                                            )) : !this.props.loading ?
                                                <h1 className="empty-data">No Records Found</h1> :
                                                <Spinner />
                                            }
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                        <ProjectView
                            show={show}
                            onHide={this.handleClose}
                            item={items}
                        />
                        <Pagination data={data} onChange={this.onChange} />
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.projects.loading,
        page: state.projects.page,
        success_msg: state.projects.success_msg,
        allData: state.projects.allData,
        projects: state.projects.projects,
    }
}

export default connect(mapStateToProps)(Projects);