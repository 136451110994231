import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Open from "../assets/images/lock-open.svg";
import "../assets/css/popup.css";

function PopUp(props) {
  const { show, onHide, handleShows } = props;

  console.log(props.show);

  return (
    <>
      <Modal
        show={props.show}
        onHide={onHide}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-success">
          <img src={Open} alt="..." />
          <h6>
            Default password has been sent to your registered email address.
          </h6>
        </div>
      </Modal>
    </>
  );
}

export default PopUp;
