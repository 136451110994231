import { reportOaConstants } from "../constants";

// report oa List
export const getReportOa = (data) => {
    return {
        type: reportOaConstants.GET_REPORT_OA,
        data
    }
}

// report oa confirm popup list
export const getReportConfirmOa = (payload) => {
    return {
        type: reportOaConstants.GET_REPORT_CONFIRM_OA,
        payload
    }
}

// report solo page list
export const getReportOaList = (data) => {
    return {
        type: reportOaConstants.GET_REPORT_OA_PAGE,
        data
    }
}

// report solo page form submit
export const addReportOaListConfirm = (formData) => {
    return {
        type: reportOaConstants.GET_REPORT_OA_PAGE_FORM,
        formData
    }
}

// oa confirm listing
export const listReportSubMenu = (formData) => {
    return {
        type: reportOaConstants.GET_REPORT_OA_REPORT,
        formData
    }
}

// oa confirm csv download
export const oaConfirmCsv = (formData) => {
    return {
        type: reportOaConstants.DOWNLOAD_CSV_REPORT_OA,
        formData
    }
}

// oa REPORTS csv download
export const oaReportCsv = (formData) => {
    return {
        type: reportOaConstants.DOWNLOAD_CSV_REPORT_OA_REPORT,
        formData
    }
}

// TODO: oa confirm pdf download

export const oaPdfView = (formData) => {
    return {
        type: reportOaConstants.OA_PDF_REPORT_OA,
        formData
    }
}

// TODO: oa confirm download

export const oaDownload = (formData) => {
    return {
        type: reportOaConstants.OA_PDF_DOWNLOAD_OA,
        formData
    }
}

export const oaMs = (formData) => {
    return {
        type: reportOaConstants.GET_REPORT_OA_MS,
        formData
    }
}

export const oaMsCsv = (formData) => {
    return {
        type: reportOaConstants.GET_REPORT_OA_MS_CSV,
        formData
    }
}

export const oaClearMsCsv = () => {
    return {
        type: reportOaConstants.CLEAR_MS_CSV,
    }
}

export const msInward = (formData) => {
    return {
        type: reportOaConstants.OA_MS_INWARD,
        formData
    }
}

export const createPoMsInwardProducts = (formData) => {
    return {
        type: reportOaConstants.CREATE_PO_MS_INWARD_PRODUCTS,
        formData
    }
}

// TODO: oa csv

export const oaCSV = (formData) => {
    return {
        type: reportOaConstants.OA_CSV,
        formData
    }
}


export const clearPdfView = () => {
    return {
        type: reportOaConstants.CLEAR_PDF_REPORT_OA,
    }
}

export const clearCsvView = () => {
    return {
        type: reportOaConstants.CLEAR_CSV_REPORT_OA,
    }
}

export const clearInwardStatus = () => {
    return {
        type: reportOaConstants.CLEAR_INWARD_STATUS
    }
}