import { authToken, baseUrl } from "../actions";
const role = localStorage.getItem('userRole');

export const addStaffApi = (request) => {
  const ADD_STAFF_API_ENDPOINT = `${baseUrl}admin/staff`;
  // "https://estimationapi.corpfield.com/admin/staff";
  const parameters = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    body: request,
  }

  return fetch(ADD_STAFF_API_ENDPOINT, parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    }
    );
}

export const getStaffApi = (pageNo, searchKey, sort = {}, filterData = {}) => {

  let STAFF_LIST;


  if (Object.keys(filterData).length === 2 || Object.keys(sort).length === 2) {
    if (Object.keys(sort).length === 2 && Object.keys(filterData).length !== 2) {
      STAFF_LIST = `${baseUrl}admin/staff?sortField=${sort.sortField}&sortMethod=${sort.sortType}&page=${pageNo}&searchKey=${searchKey}`;
    } else {
      if (filterData.status !== "" && filterData.role === "") {
        STAFF_LIST = `${baseUrl}admin/staff?status=${filterData.status}&page=${pageNo}&searchKey=${searchKey}`;
      } if (filterData.role !== "" && filterData.status === "") {
        STAFF_LIST = `${baseUrl}admin/staff?role=${filterData.role}&page=${pageNo}&searchKey=${searchKey}`;
      } 
      if (filterData.status !== "" && filterData.role !== "") {
        STAFF_LIST = `${baseUrl}admin/staff?role=${filterData.role}&status=${filterData.status}&page=${pageNo}&searchKey=${searchKey}`;
      } if (filterData.role !== "" && Object.keys(sort).length === 2) {
        STAFF_LIST = `${baseUrl}admin/staff?role=${filterData.role}&sortField=${sort.sortField}&sortMethod=${sort.sortType}&page=${pageNo}&searchKey=${searchKey}`;
      } if (filterData.status !== "" && Object.keys(sort).length === 2) {
        STAFF_LIST = `${baseUrl}admin/staff?status=${filterData.status}&sortField=${sort.sortField}&sortMethod=${sort.sortType}&page=${pageNo}&searchKey=${searchKey}`;
      } if ((filterData.status  !== "" && (filterData.role !== "")) && Object.keys(sort).length === 2) {
        STAFF_LIST = `${baseUrl}admin/staff?role=${filterData.role}&status=${filterData.status}&sortField=${sort.sortField}&sortMethod=${sort.sortType}&page=${pageNo}&searchKey=${searchKey}`;
      }
    }
  } else {
    STAFF_LIST = searchKey !== undefined ? `${baseUrl}admin/staff?searchKey=${searchKey}&page=${pageNo}` : `${baseUrl}admin/staff?page=${pageNo}`;
  }


  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  return fetch(STAFF_LIST, parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};

export const getStaffProfileApi = () => {
  const STAFF_PROFILE = `${baseUrl}staff/profile`;

  const parameters = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      redirect: "follow",
    },
  };

  return fetch(STAFF_PROFILE, parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};

export const getStaffByIdApi = (staffId) => {
  const STAFF_LIST = `${baseUrl}admin/staff/${staffId}`;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      redirect: "follow",
    },
  };

  return fetch(STAFF_LIST, parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};



export const updateStaffApi = (request) => {
  const UPDATE_STAFF_API_ENDPOINT = role === "staff" ? `${baseUrl}staff/profile` : `${baseUrl}admin/staff`;

  const parameters = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    body: request,
  }

  return fetch(UPDATE_STAFF_API_ENDPOINT, parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    }
    );
}


export const updateStaffStatusApi = (userId, status) => {
  const UPDATE_STAFF_STATUS_API_ENDPOINT = `${baseUrl}admin/staff/${userId}/status/${status}`;

  const parameters = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }

  return fetch(UPDATE_STAFF_STATUS_API_ENDPOINT, parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    }
    );
}


export const updatePasswordApi = (request) => {
  const UPDATE_PASSWORD_ENDPOINT = `${baseUrl}staff/password`;
  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(request)
  }

  return fetch(UPDATE_PASSWORD_ENDPOINT, parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    }
    );
}
