import React, { useRef, useState } from "react";
import { Overlay, Popover, Col, Row, Form } from "react-bootstrap";
import filter from "../../assets/images/filt.svg";


function StaffFilter(props) {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  const [role, setRole] = useState("");

  const { error, onSubmit, reset } = props;

  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const status = [
    { id: 0, value: "Active" },
    { id: 1, value: "Inactive" },
  ];

  const roles = [
    { id: 1, value: "Staff" },
    { id: 2, value: "Sales Executive" },
  ]

  const filterChange = (e) => {
    const target = e.target;
    var value = target.value;
    setValue(value);
  }

  const roleChange = (e) => {
    const target = e.target;
    var value = target.value;
    setRole(value);
  }

  const submitHandler = (e) => {
    e.preventDefault();

    let data = {
      "status": value === "" ?  "" :  value === "Active" ? 1 : 0,
      "role" :  role.toUpperCase()
    }
    onSubmit(data);
    setTarget(null);
    setShow(false);
  }

  const resetHandler = () => {
    reset();
    setValue("");
    setRole("");
    setTarget(null);
    setShow(false);
  }


  return (
    <div ref={ref} className="filter-background">
      <button onClick={handleClick} className="filter-button staff">
        <img src={filter} alt="..." width="18px" style={{ paddingRight: "5px" }} /> Filter
      </button>

      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Body>
            <div className="filter-page">
              <Form onSubmit={submitHandler}>
                <p>Status</p>

                {status.map((item, index) =>
                  <div className="customer-check">
                    <Form.Check
                      key={index}
                      checked={item.value === value ? true : false}
                      onChange={filterChange}
                      inline label={item.value}
                      name="group1"
                      defaultValue={item.value}
                      type="checkbox"
                      className="form-check-label"
                    />
                  </div>
                )}

                <p className="role-id">Role</p>

                {roles.map((item, index) =>
                  <div className="customer-check">
                    <Form.Check
                      key={index}
                      checked={item.value === role ? true : false}
                      onChange={roleChange}
                      inline label={item.value}
                      name="group1"
                      defaultValue={item.value}
                      type="checkbox"
                      className=""
                    />
                  </div>
                )}
                {error && <strong>{error}</strong>}
                <div>
                  <Row>
                    <Col md={6}>
                      <button className="modal-price reset" type="button" onClick={resetHandler}>
                        Reset</button>
                    </Col>
                    <Col md={6}>
                      <button type="submit" className="modal-btn modal-apply">
                        Apply
                      </button>
                    </Col>
                  </Row>

                </div>
              </Form>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}


export default StaffFilter;

