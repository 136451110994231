import React from "react";
import { Card, Form } from "react-bootstrap";
import { connect } from "react-redux";
import Re from "../../../assets/images/violet1.svg";
import { Link } from "react-router-dom";
import { getSingleExecutive, changeClassName, changeDateFormat, getExecutiveUrl, previewQuotation } from "../../../actions";
import Navbar from "../../../layouts/Navbar";
import Search from "../../../assets/images/search.svg";
import Filter from "../../../assets/images/filter.svg";
import Sidebar from "../../../layouts/Sidebar";
import Spinner from "../../../Components/loader";
import Pagination from "../../../Components/Pagination";
import ExcutiveIdFilter from "./ExcutiveIdFilter";
import View from "./View";

const role = localStorage.getItem('userRole');
class ExecutivePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exectiveId: this.props?.match?.params?.id,
      single_list: [],
      filterData: [`status=Generated,Confirmed`],
      sortField: "",
      pageNo: 0,
      searchKey: "",
      sortType: "desc",
      show: false,
      data: {},
      previewQutationTxt: undefined
    }
    this.onChange = this.onChange.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleShow(row) {
    this.setState({
      show: true,
      data: row
    })
    this.props.dispatch(previewQuotation(row.quotationId));
  }
  handleClose() {
    this.setState({
      show: false,
      previewQutationTxt: undefined
    })
  }
  onChange(pageNo) {
    const { filterData, searchKey, sortField, sortType } = this.state;
    this.setState({
      pageNo: pageNo
    })
    const url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
    let newUrl = "";
    if (url.startsWith("0=")) {
      newUrl = url.slice(2, url.length);
    } else {
      newUrl = url;
    }
    this.props.dispatch(getSingleExecutive(this.state.exectiveId, newUrl));
  }
  searchHandler(e) {
    const { filterData, pageNo, sortField, sortType } = this.state;
    this.setState({
      searchKey: e.target.value
    });
    const url = getExecutiveUrl(pageNo, e.target.value, filterData, sortField, sortType);
    let newUrl = "";
    if (url.startsWith("0=")) {
      newUrl = url.slice(2, url.length);
    } else {
      newUrl = url;
    }
    this.props.dispatch(getSingleExecutive(this.state.exectiveId, newUrl));
  }
  sortHandler(sortField) {
    const { pageNo, exectiveId, searchKey, filterData } = this.state;
    this.setState({
      sortField: sortField
    });
    if (this.state.sortType === 'desc') {
      this.setState({
        sortType: 'asc'
      })
    } else {
      this.setState({
        sortType: 'desc'
      })
    };
    let sort = {
      sortType: this.state.sortType,
      sortField: sortField
    }
    this.setState({
      sort: sort
    });
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, this.state.sortType);
    let newUrl = "";
    if (url.startsWith("0=")) {
      newUrl = url.slice(2, url.length);
    } else {
      newUrl = url;
    }
    this.props.dispatch(getSingleExecutive(exectiveId, newUrl));
  }
  filterSubmit(filterData) {
    const { pageNo, searchKey, sortType, sortField } = this.state;
    this.setState({
      filterData: filterData
    })
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
    this.props.dispatch(getSingleExecutive(this.state.exectiveId, url));
  }
  resetHandler() {
    const { pageNo, sortField, sortType, searchKey } = this.state;
    this.setState({
      filterData: {}
    })
    let url = getExecutiveUrl(pageNo, searchKey, {}, sortField, sortType);

    this.props.dispatch(getSingleExecutive(this.state.exectiveId, url));
  }
  componentDidUpdate(prevProps) {
    if (prevProps.single_list !== this.props.single_list) {
      this.setState({
        single_list: this.props.single_list,
      });
      if (this.props.single_list?.totalElements <= 10) {
        this.setState({
          pageNo: 0
        });
      }
    } if (prevProps.previewQutationTxt !== this.props.previewQutationTxt) {
      this.setState({
        previewQutationTxt: this.props.previewQutationTxt,
      });
    }
  }
  componentDidMount() {
    this.props.dispatch(getSingleExecutive(this.state.exectiveId, 'page=0&status=Generated,Confirmed'));
  }

  render() {
    const { single_list, show, previewQutationTxt } = this.state;
    let data = {
      totalElements: single_list?.totalElements,
      totalPages: single_list?.totalPages,
      offset: single_list?.pageable?.offset,
      numberOfElements: single_list?.numberOfElements,
      num: (single_list?.number) + 1
    }

    return (
      <div>
        <Navbar />
        <div className="dashboard-page">
          <div className="dashboard-sidebar">
            <Sidebar />
          </div>
          <div className="dashboard-main">
            <Card className="staff-page">
              <div className="page-head-split">
                <div className="head-colums">
                  <div className="head-part-tab">
                    <img src={Re} alt="..." className="head-imgs" />
                    <Link to={"/" + role + "/" + "sale_executive"} className="under-line">
                      <h5>Sale Reports</h5>
                    </Link>
                    <i className="fas fa-arrow-right" style={{ paddingBottom: "10px" }}></i>
                    <Link to={"/" + role + "/" + "sale_executive"} className="under-line">
                      <h6>Sales Executive</h6>
                    </Link>
                    <i style={{ marginLeft: "200px", marginTop: "6px" }} className="fas fa-arrow-right"></i>
                    <h6 style={{ marginLeft: "220px", marginTop: "12px" }}>{this.props?.match?.params?.name}</h6>
                  </div>
                  <div className="search-sale">
                    <span><img src={Search} alt=".." className="search-img" /></span>
                    <Form.Control type="search" placeholder="Search" onChange={this.searchHandler} className="main-search" />
                  </div>
                </div>
                <ExcutiveIdFilter
                  onSubmit={this.filterSubmit}
                  reset={this.resetHandler}
                />
              </div>
              <div className="table-class" style={{ marginTop: "20px" }}>
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">
                        Reference Id <img src={Filter} alt="..." onClick={(e) => this.sortHandler("q.quotation_reference_id")} />
                      </th>
                      <th scope="col">
                        Customer Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("c.customer_first_name")} />
                      </th>
                      <th >
                        Location<img src={Filter} alt="..." onClick={(e) => this.sortHandler("c.customer_city")} />
                      </th>
                      <th>
                        Created By <img src={Filter} alt="..." onClick={(e) => this.sortHandler("issuedStaff")} />
                      </th>
                      <th scope="col">
                        Created At <img src={Filter} alt="..." onClick={(e) => this.sortHandler("q.created_at")} />
                      </th>
                      <th scope="col">
                        Status <img src={Filter} alt="..." onClick={(e) => this.sortHandler("qs.quotation_status")} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {single_list.content && single_list.content.length > 0 ? (
                      single_list.content.map((one) => (
                        <tr key={one.salesExecutiveId} onClick={() => this.handleShow(one)}>
                          <td >{one.referenceId}</td>
                          <td>{one.customerFirstName}</td>
                          <td>{one.location ?? "-"}</td>
                          <td>{one.createdBy}</td>
                          <td>{changeDateFormat(one.createdAt)}</td>
                          <td>    <button className={changeClassName(one.status)} >
                            {one.status}
                          </button></td>
                        </tr>
                      ))
                    ) : !this.props.loading ?
                      <h1 className="empty-data">No Records Found</h1> :
                      <Spinner />
                    }
                  </tbody>

                </table>
              </div>
            </Card>

            <Pagination data={data} onChange={this.onChange} />
            <View
              show={show}
              onHide={this.handleClose}
              data={this.state.data}
              preview={previewQutationTxt}
            />
          </div>
        </div>
      </div>

    )
  }
}

const mapStateToProps = (state) => {

  return {
    loading: state.staffexecutive.loading,
    previewQutationTxt: state.qutation.previewQutation,
    single_list: state.staffexecutive.single_list,
  };
};
export default connect(mapStateToProps)(ExecutivePage);
