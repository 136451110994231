export const adminConstants = {
    GET_STAFF_PROFILE: "GET_STAFF_PROFILE_LIST",
    GET_STAFF_PROFILE_SUCCESS: "GET_STAFF_PROFILE_LIST_SUCCESS",
    GET_STAFF_PROFILE_FAILURE: "GET_STAFF_PROFILE_LIST_FAILURE",

    UPDATE_ADMIN_REQUEST: "UPDATE_ADMIN_REQUEST",
    UPDATE_ADMIN_SUCCESS: "UPDATE_ADMIN_SUCCESS",
    UPDATE_ADMIN_FAILURE: "UPDATE_ADMIN_FAILURE",

    UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
    UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
    UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",
}
