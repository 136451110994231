import React from "react";
import Navbar from "../../layouts/Navbar";
import Sidebar from "../../layouts/Sidebar";
import Quotation from "./Quotation";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="dashboard-page">
          <div className="dashboard-sidebar">
            <Sidebar />
          </div>
          <div className="dashboard-main">
            <Quotation />
          </div>
        </div>
      </div>
    );
  }
}


export default Dashboard;
