import { staffexecutiveConstants } from "../constants";

const initialState = {
    loading: false,
    data: [],
    error: [],
    token: "",
    status: 0,
    page: "staffExecutive"
};

export default function staffexecutiveReducer(state = initialState, { type, response }) {
    switch (type) {
        case staffexecutiveConstants.GET_STAFF_EXECUTIVE:
        case staffexecutiveConstants.SINGLE_EXECUTIVE:
            return {
                ...state,
                loading: true,
            };
            
        case staffexecutiveConstants.GET_STAFF_EXECUTIVE_SUCCESS:
            return {
                ...state,
                loading: false,
                staffexecutive_list: response.data.content,
                allData: response.data,
                page: "staffExecutive"
            };
            case staffexecutiveConstants.SINGLE_EXECUTIVE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    single_list: response.data,
                };
        case staffexecutiveConstants.GET_STAFF_EXECUTIVE_FAILURE:
        case staffexecutiveConstants.SINGLE_EXECUTIVE_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
}
