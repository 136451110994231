import React, { Fragment, useState, useEffect } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getReportConfirmOa, htmlTagRemove, verifyDateFormat, oaCSV, exportToJson, clearCsvView } from "../../../actions";
import { connect } from 'react-redux';
import Spinner from "../../../Components/loader";
import Art from "../../../assets/images/art.svg";
import Load from "../../../assets/images/rou.gif";



const OaConfirm = (props) => {

    const { show, onHide, data, loading, onConfirm, oaIdDispatch, oaCsvDispatch, loadingcsv, csvDataDown, oaClearDispatch } = props;

    const [oaId, setOaId] = useState(null);

    const csv = (val) => {
        const data = {
            "oaNumber": val?.oaNumber
        }
        oaCsvDispatch(data);
    }

    useEffect(() => {
        if (show) {
            setOaId({
              oaNo  :  data?.oaNumber, 
              productId  : data?.productId
            })
        } else {
            setOaId(null);
            oaClearDispatch();
        }
    }, [show])

    useEffect(() => {
        if (oaId !== null) {
            oaIdDispatch(oaId)
        }
    }, [oaId])

    useEffect(() => {
        if (csvDataDown !== null) {
            exportToJson(csvDataDown, "OA Confirm list");
        }
    }, [csvDataDown])

    return (
        <Fragment>
            <Modal
                show={show}
                size="xl"
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <div className="modal-staff">
                    <div className="head">
                        <div>
                            <i class="fas fa-arrow-left" onClick={onHide}></i>
                            <h4>OA Confirm</h4>
                        </div>
                        <div>
                            {loadingcsv ?
                                <button className="download_btn_pdf" >
                                    <img src={Load} alt=".." /> CSV
                                </button> :
                                <button className="download_btn" onClick={() => csv(data)}>
                                    <img src={Art} alt=".." /> CSV
                                </button>}
                        </div>
                    </div>
                </div>
                <div className="table-class" style={{ padding: "20px" }}>
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th>Product Id</th>
                                <th>Product Name</th>
                                <th>OA Number</th>
                                <th>PO Number</th>
                                <th>Art No</th>
                                <th>Confirm Qty</th>
                                <th>Created At</th>
                                <th>Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ?
                                <div>
                                    <h4 className="empty-data">  <Spinner /></h4>
                                </div> : onConfirm == null || onConfirm === undefined || onConfirm?.length === 0 ? (
                                    <h4 className="empty-data">Not Found</h4>
                                ) : onConfirm.map((item, a) => (
                                    <tr key={a}>
                                        <td>{item?.productId}</td>
                                        <td className="tool-space">
                                            <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {htmlTagRemove(item?.productName)}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    {htmlTagRemove(item?.productName)}
                                                </span>
                                            </OverlayTrigger>

                                        </td>
                                        <td>{item?.oaNumber}</td>
                                        <td>{item?.poNumber}</td>
                                        <td>{item?.articleId}</td>
                                        <td className="txt-center">{item?.lastConfirmedQuantity}</td>
                                        <td>{verifyDateFormat(item?.createdAt)}</td>
                                        <td>{item?.remark}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    const { oa } = state;
    return {
        loading: oa.loading,
        onConfirm: oa.onConfirm,
        loadingcsv: oa.loadingcsv,
        csvDataDown: oa.csvDataDown
    }
}

const mapDispatchToProps = (dispatch) => ({
    oaIdDispatch: (formData) => dispatch(getReportConfirmOa(formData)),
    oaCsvDispatch: (formData) => dispatch(oaCSV(formData)),
    oaClearDispatch: (formData) => dispatch(clearCsvView(formData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OaConfirm)