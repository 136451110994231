import React from "react";
import { Overlay, Popover, Col, Row, Form } from "react-bootstrap";
import filter from "../../../assets/images/filt.svg";
import { qutationDateFormat } from "../../../actions";
import { DatePicker } from "antd";
import moment from "moment";
import "antd/dist/antd.css";

class VendorFilter extends React.Component {
    constructor() {
        super();
        this.state = {
            status: {
                "DRAFT": false,
                "GENERATED": false,
                "SENT": false,
                "VERIFIED": false,
                "PARTIALLY_VERIFIED": false,
                "CANCELLED": false,
                "RECEIVED": false,
                "PARTIALLY_RECEVIED": false,
                "REVISED": false,
            },
            show: false,
            cur: "",
            eff: "",
            target: null,
        };
        this.myRef = React.createRef();
        this.statusHandler = this.statusHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.resetHandler = this.resetHandler.bind(this);
        this.currentHandler = this.currentHandler.bind(this);
        this.effHandler = this.effHandler.bind(this);
    }


    effHandler = (val) => {
        let item = val === null ? "" : qutationDateFormat(val._d)
        this.setState({
            cur: item
        })
    }
    currentHandler = (val) => {
        let item = val === null ? "" : qutationDateFormat(val._d)
        this.setState({
            eff: item
        })
    }

    statusHandler = (event) => {
        const { name, checked } = event.target;
        this.setState((prevState) => {
            const status = prevState.status;
            status[name] = checked;
            return status;
        });
    };

    submitHandler = (e) => {
        e.preventDefault();
        const { status, eff, cur } = this.state;
        const { onSubmit } = this.props;

        const addedStatus = Object.keys(status)
            .filter((key) => status[key]).map((item) => item)
            .join(",");

        let data = {
            "status": addedStatus,
            "fromDate": eff,
            "toDate": cur
        }
        onSubmit(data);
        this.setState({
            show: false,
            target: null
        })
    }


    resetHandler = () => {
        const { reset } = this.props;
        reset();
        this.setState({
            status: {
                "DRAFT": false,
                "GENERATED": false,
                "SENT": false,
                "VERIFIED": false,
                "PARTIALLY_VERIFIED": false,
                "CANCELLED": false,
                "RECEIVED": false,
                "PARTIALLY_RECEIVED": false,
                "REVISED": false,
            },
            cur: "",
            eff: "",
            show: false,
            target: null
        })
    }

    handleClick = (event) => {
        this.setState({
            show: !this.state.show,
            target: event.target
        })
    };


    render() {
        const { show, target, ref, status, eff, cur } = this.state;
        const disabledDate = (current) => current && current > moment().endOf("day");
        const checkDisabledDate = (current) => (current && current > moment().endOf("day")) || moment(eff).startOf("day") > current;

        return (
            <div ref={this.myRef} className="filter-background">
                <button onClick={this.handleClick} className="product-filter-button">
                    <img src={filter} alt="..." width="18px" style={{ paddingRight: "5px" }} /> Filter
                </button>
                <Overlay
                    show={show}
                    target={target}
                    placement="bottom"
                    container={ref}
                    containerPadding={20}
                >
                    <Popover id="popover-contained">
                        <Popover.Body>
                            <div className="filter-page">
                                <Form onSubmit={this.submitHandler}>
                                    <p className="role-id">Status</p>
                                    <div className="customer-check">
                                        <Form.Check
                                            checked={status.DRAFT}
                                            onChange={this.statusHandler}
                                            inline label="Draft"
                                            name="DRAFT"
                                            defaultValue="Draft"
                                            type="checkbox"
                                        />
                                    </div>
                                    <div className="customer-check">
                                        <Form.Check
                                            checked={status.GENERATED}
                                            onChange={this.statusHandler}
                                            inline label="Generated"
                                            name="GENERATED"
                                            defaultValue="GENERATED"
                                            type="checkbox"
                                            className="form-check-label"
                                        />
                                    </div>
                                    <div className="customer-check">
                                        <Form.Check
                                            checked={status.REVISED}
                                            onChange={this.statusHandler}
                                            inline label="Revised"
                                            name="REVISED"
                                            defaultValue="Revised"
                                            type="checkbox"
                                            className="form-check-label"
                                        />
                                    </div>
                                    <div className="customer-check">
                                        <Form.Check
                                            checked={status.CANCELLED}
                                            onChange={this.statusHandler}
                                            inline label="Cancelled"
                                            name="CANCELLED"
                                            defaultValue=" Cancelled"
                                            type="checkbox"
                                        />
                                    </div>
                                    <div className="customer-check">
                                        <Form.Check
                                            checked={status.SENT}
                                            onChange={this.statusHandler}
                                            inline label="Sent"
                                            name="SENT"
                                            defaultValue="SENT"
                                            type="checkbox"
                                        />
                                    </div>
                                    <div className="customer-check">
                                        <Form.Check
                                            checked={status["PARTIALLY_VERIFIED"]}
                                            onChange={this.statusHandler}
                                            inline label="Partially Verified"
                                            name="PARTIALLY_VERIFIED"
                                            defaultValue=" Partially Verified"
                                            type="checkbox"
                                        />
                                    </div>
                                    <div className="customer-check">
                                        <Form.Check
                                            checked={status.VERIFIED}
                                            onChange={this.statusHandler}
                                            inline label="Verified"
                                            name="VERIFIED"
                                            defaultValue="Verified"
                                            type="checkbox"
                                        />
                                    </div>
                                    <div className="customer-check">
                                        <Form.Check
                                            checked={status["PARTIALLY_RECEIVED"]}
                                            onChange={this.statusHandler}
                                            inline label="Partially Received"
                                            name="PARTIALLY_RECEIVED"
                                            defaultValue=" Partially Received"
                                            type="checkbox"
                                        />
                                    </div>
                                    <div className="customer-check">
                                        <Form.Check
                                            checked={status.RECEIVED}
                                            onChange={this.statusHandler}
                                            inline label="Received"
                                            name="RECEIVED"
                                            defaultValue=" Received"
                                            type="checkbox"
                                        />
                                    </div>
                                    <p className="new-ss">Custom Date</p>
                                    <Form.Group controlId="formBasicDate">
                                        <DatePicker
                                            format="DD-MM-YYYY"
                                            placeholder={eff !== "" ? eff : "From Date"}
                                            className="form-control"
                                            onChange={this.currentHandler}
                                            disabledDate={disabledDate}
                                        />
                                    </Form.Group><br></br>
                                    <Form.Group controlId="formBasicDate">
                                        <DatePicker
                                            format="DD-MM-YYYY"
                                            placeholder={cur !== "" ? cur : "To Date"}
                                            className="form-control"
                                            disabledDate={checkDisabledDate}
                                            onChange={this.effHandler}
                                        />
                                    </Form.Group>

                                    <div>
                                        <Row>
                                            <Col md={6}>
                                                <button className="modal-price reset" type="button" onClick={this.resetHandler}>
                                                    Reset</button>
                                            </Col>
                                            <Col md={6}>
                                                <button type="submit" className="modal-btn modal-apply">
                                                    Apply
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </div>

                        </Popover.Body>
                    </Popover>
                </Overlay>
            </div >
        );
    }
}

export default VendorFilter;




