export const poConstants = {
    GET_PO_PRODUCTS: "GET_PO_PROJECTS_LIST",
    GET_PO_PROJECTS_SUCCESS: "GET_PO_PROJECTS_LIST_SUCCESS",
    GET_PO_PROJECTS_FAILURE: "GET_PO_PROJECTS_LIST_FAILURE",

    GET_VERTICS: "GET_VERTICS_LIST",
    GET_VERTICS_SUCCESS: "GET_VERTICS_LIST_SUCCESS",
    GET_VERTICS_FAILURE: "GET_VERTICS_LIST_FAILURE",

    GET_BOQ_PROJECTS: "GET_BOQ_PROJECTS",
    GET_BOQ_PROJECTS_SUCCESS: "GET_BOQ_PROJECTS_SUCCESS",
    GET_BOQ_PROJECTS_FAILURE: "GET_BOQ_PROJECTS_FAILURE",

    CREATE_PO_PROJECT: "CREATE_PO_PROJECT",
    CREATE_PO_PROJECT_SUCCESS: "CREATE_PO_PROJECT_SUCCESS",
    CREATE_PO_PROJECT_FAILURE: "CREATE_PO_PROJECT_FAILURE",

    UPDATE_PO_PROJECT: "UPDATE_PO_PROJECT",
    UPDATE_PO_PROJECT_SUCCESS: "UPDATE_PO_PROJECT_SUCCESS",
    UPDATE_PO_PROJECT_FAILURE: "UPDATE_PO_PROJECT_FAILURE",

    DELETE_PO_PROJECT: "DELETE_PO_PROJECT",
    DELETE_PO_PROJECT_SUCCESS: "DELETE_PO_PROJECT_SUCCESS",
    DELETE_PO_PROJECT_FAILURE: "DELETE_PO_PROJECT_FAILURE",

    CREATE_PO_PRODUCT: "CREATE_PO_PRODUCT",
    CREATE_PO_PRODUCT_SUCCESS: "CREATE_PO_PRODUCT_SUCCESS",
    CREATE_PO_PRODUCT_FAILURE: "CREATE_PO_PRODUCT_FAILURE",

    UPDATE_PO_PRODUCT: "UPDATE_PO_PRODUCT",
    UPDATE_PO_PRODUCT_SUCCESS: "UPDATE_PO_PRODUCT_SUCCESS",
    UPDATE_PO_PRODUCT_FAILURE: "UPDATE_PO_PRODUCT_FAILURE",

    DELETE_PO_PRODUCT: "DELETE_PO_PRODUCT",
    DELETE_PO_PRODUCT_SUCCESS: "DELETE_PO_PRODUCT_SUCCESS",
    DELETE_PO_PRODUCT_FAILURE: "DELETE_PO_PRODUCT_FAILURE",

    DELETE_PO: "DELETE_PO",
    DELETE_PO_SUCCESS: "DELETE_PO_SUCCESS",
    DELETE_PO_FAILURE: "DELETE_PO_FAILURE",

    UPDATE_PO_IGNORE_FIELDS: "UPDATE_PO_IGNORE_FIELDS",
    UPDATE_PO_IGNORE_FIELDS_SUCCESS: "UPDATE_PO_IGNORE_FIELDS_SUCCESS",
    UPDATE_PO_IGNORE_FIELDS_FAILURE: "UPDATE_PO_IGNORE_FIELDS_FAILURE",

    GET_PO_FOOTER: "GET_PO_FOOTER_LIST",
    GET_PO_FOOTER_SUCCESS: "GET_PO_FOOTER_LIST_SUCCESS",
    GET_PO_FOOTER_FAILURE: "GET_PO_FOOTER_LIST_FAILURE",

    CREATE_PO_FOOTER: "CREATE_PO_FOOTER",
    CREATE_PO_FOOTER_SUCCESS: "CREATE_PO_FOOTER_SUCCESS",
    CREATE_PO_FOOTER_FAILURE: "CREATE_PO_FOOTER_FAILURE",

    UPDATE_PO_FOOTER: "UPDATE_PO_FOOTER",
    UPDATE_PO_FOOTER_SUCCESS: "UPDATE_PO_FOOTER_SUCCESS",
    UPDATE_PO_FOOTER_FAILURE: "UPDATE_PO_FOOTER_FAILURE",

    DELETE_PO_FOOTER: "DELETE_PO_FOOTER",
    DELETE_PO_FOOTER_SUCCESS: "DELETE_PO_FOOTER_SUCCESS",
    DELETE_PO_FOOTER_FAILURE: "DELETE_PO_FOOTER_FAILURE",

    PREVIEW_PO: "PREVIEW_PO",
    PREVIEW_PO_SUCCESS: "PREVIEW_PO_SUCCESS",
    PREVIEW_PO_FAILURE: "PREVIEW_PO_FAILURE",

    GENERATE_PO: "GENERATE_PO",
    GENERATE_PO_SUCCESS: "GENERATE_PO_SUCCESS",
    GENERATE_PO_FAILURE: "GENERATE_PO_FAILURE",

    SENT_PO: "SENT_PO",
    SENT_PO_SUCCESS: "SENT_PO_SUCCESS",
    SENT_PO_FAILURE: "SENT_PO_FAILURE",

    CLONE_PO: "CLONE_PO",
    CLONE_PO_SUCCESS: "CLONE_PO_SUCCESS",
    CLONE_PO_FAILURE: "CLONE_PO_FAILURE",

    REVISE_PO: "REVISE_PO",
    REVISE_PO_SUCCESS: "REVISE_PO_SUCCESS",
    REVISE_PO_FAILURE: "REVISE_PO_FAILURE",

    CANCEL_PO: "CANCEL_PO",
    CANCEL_PO_SUCCESS: "CANCEL_PO_SUCCESS",
    CANCEL_PO_FAILURE: "CANCEL_PO_FAILURE",

    VERIFY_PO: "VERIFY_PO",
    VERIFY_PO_SUCCESS: "VERIFY_PO_SUCCESS",
    VERIFY_PO_FAILURE: "VERIFY_PO_FAILURE",

    GET_CONVERT_PRODUCTS: "GET_CONVERT_PRODUCTS_LIST",
    GET_CONVERT_PRODUCTS_SUCCESS: "GET_CONVERT_PRODUCTS_LIST_SUCCESS",
    GET_CONVERT_PRODUCTS_FAILURE: "GET_CONVERT_PRODUCTS_LIST_FAILURE",

    GET_PO_BY_ID: "GET_PO_BY_ID",
    GET_PO_BY_ID_SUCCESS: "GET_PO_BY_ID_SUCCESS",
    GET_PO_BY_ID_FAILURE: "GET_PO_BY_ID_FAILURE",

    GET_PO_VERIFIED_PRODUCTS: "GET_PO_VERIFIED_PRODUCTS_LIST",
    GET_PO_VERIFIED_PRODUCTS_SUCCESS: "GET_PO_VERIFIED_PRODUCTS_LIST_SUCCESS",
    GET_PO_VERIFIED_PRODUCTS_FAILURE: "GET_PO_VERIFIED_PRODUCTS_LIST_FAILURE",

    GET_PO_CONVERTED_PRODUCTS: "GET_PO_CONVERTED_PRODUCTS_LIST",
    GET_PO_CONVERTED_PRODUCTS_SUCCESS: "GET_PO_CONVERTED_PRODUCTS_LIST_SUCCESS",
    GET_PO_CONVERTED_PRODUCTS_FAILURE: "GET_PO_CONVERTED_PRODUCTS_LIST_FAILURE",

    GET_PO_INWARD: "GET_PO_INWARD_LIST",
    GET_PO_INWARD_SUCCESS: "GET_PO_INWARD_LIST_SUCCESS",
    GET_PO_INWARD_FAILURE: "GET_PO_INWARD_LIST_FAILURE",

    ADD_PO_INWARD: "ADD_PO_INWARD",
    ADD_PO_INWARD_SUCCESS: "ADD_PO_INWARD_SUCCESS",
    ADD_PO_INWARD_FAILURE: "ADD_PO_INWARD_FAILURE",

    CREATE_PO_INWARD_PRODUCTS: "CREATE_PO_INWARD_PRODUCTS",
    CREATE_PO_INWARD_PRODUCTS_SUCCESS: "CREATE_PO_INWARD_PRODUCTS_SUCCESS",
    CREATE_PO_INWARD_PRODUCTS_FAILURE: "CREATE_PO_INWARD_PRODUCTS_FAILURE",

    UPDATE_CUSTOM_PRODUCT: "UPDATE_CUSTOM_PRODUCT",
    UPDATE_CUSTOM_PRODUCT_SUCCESS: "UPDATE_CUSTOM_PRODUCT_SUCCESS",
    UPDATE_CUSTOM_PRODUCT_FAILURE: "UPDATE_CUSTOM_PRODUCT_FAILURE",

    CONVERT_TO_PO: "CONVERT_TO_PO",
    CONVERT_TO_PO_SUCCESS: "CONVERT_TO_PO_SUCCESS",
    CONVERT_TO_PO_FAILURE: "CONVERT_TO_PO_FAILURE",

    GET_MAIL_DETAILS: "GET_MAIL_DETAILS_LIST",
    GET_MAIL_DETAILS_SUCCESS: "GET_MAIL_DETAILS_LIST_SUCCESS",
    GET_MAIL_DETAILS_FAILURE: "GET_MAIL_DETAILS_LIST_FAILURE",


    SENT_PO_MAIL: "SENT_PO_MAIL",
    SENT_PO_MAIL_SUCCESS: "SENT_PO_MAIL_SUCCESS",
    SENT_PO_MAIL_FAILURE: "SENT_PO_MAIL_FAILURE",

    CLEAR_PO_DETAILS: "CLEAR_PO_DETAILS",
    CLEAR_MAIL_DETAILS: "CLEAR_MAIL_DETAILS",

    CLEAR_PREVIEW: "CLEAR_PREVIEW",
    CHANGE_PAGE: "CHANGE_PAGE",
    CHANGE_ADD_PAGE: "CHANGE_ADD_PAGE",
    CLEAR_PO_PROJECT: "CLEAR_PO_PROJECT",
    CLEAR_VERIFY_PRODUCTS: "CLEAR_VERIFY_PRODUCTS",

    VENDOR_PO_DOWNLOAD_CSV: "VENDOR_PO_DOWNLOAD_CSV",
    VENDOR_PO_DOWNLOAD_CSV_SUCCESS: "VENDOR_PO_DOWNLOAD_CSV_SUCCESS",
    VENDOR_PO_DOWNLOAD_CSV_FAILURE: "VENDOR_PO_DOWNLOAD_CSV_FAILURE",

    CLEAR_CSV: "CLEAR_CSV",
    CLEAR_CSV_SUCCESS: "CLEAR_CSV_SUCCESS",
    CLEAR_CSV_FAILURE: "CLEAR_CSV_FAILURE",
}