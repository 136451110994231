import { get } from "lodash";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { changeDateFormat, displayCurrency } from "../../actions";
import "../../assets/css/popup.css";
import Spinner from "../../Components/loader";

function ViewHistory(props) {
    const { history ,get} = props;
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);
    const handleShow = (e) => {
        e.preventDefault();
        get();
        setOpen(true);
    }
    return (
        <>
            <button className="modal-price" onClick={handleShow}>
                View Price Update history
                <i class="fas fa-arrow-right"></i>
            </button>
            <Modal
                show={open}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-staff main">
                    <div className="head">
                        <div>
                            <i class="fas fa-arrow-left" onClick={handleClose}></i>
                            <h4>PRICE UPDATE HISTORY</h4>
                        </div>
                    </div>
                    <div className="table-class history">
                        <table class="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">Current Price</th>
                                    <th scope="col">New Price</th>
                                    <th>Added On</th>
                                    <th scope="col">Effective On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {history ?? history?.length > 0 ? history.map((item, i) => (
                                    <tr key={i}>
                                        <td>{displayCurrency(item.currentPrice)}</td>
                                        <td>{displayCurrency(item.newPrice)}</td>
                                        <td>{changeDateFormat(item.addedOn)}</td>
                                        <td>{changeDateFormat(item.effectiveOn)}</td>
                                    </tr>
                                )) : <Spinner />}
                            </tbody>
                        </table>
                    </div>
                </div >
            </Modal >
        </>
    );
}

export default ViewHistory;
