import { Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';
const role = localStorage.getItem('userRole');
const admin_default = "/" + role + "/dashboard";
const other_default = "/" + role + "/dashboard";
const OpenRoutes = () => role ? (
    <Redirect to={role === "admin" ? admin_default : other_default} />
) : (
    <PublicRoutes />
);

function App() {
    return (
        <div className="app">
            <Switch>
                <Route path={"/" + role} component={PrivateRoutes} />
                <Route path="/" component={OpenRoutes} />
            </Switch>
            <ToastContainer
                position="top-center"
                theme="dark"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </div>
    )
}

export default App;
