import React from "react";
import { Modal } from "react-bootstrap";
import Logo from "../assets/images/main.svg";

function CloneStatus(props) {
    const { show, onHide, str } = props;
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div className="modal-success">
                    <img src={Logo} alt=".." className="spin" width="200px" />
                    <h5 >{str}</h5>
                </div>
            </Modal>
        </>
    );
}

export default CloneStatus;
