import { reportPurchaseConstants } from "../constants";

export const getReportPurchase = (pageNo, filterData) => {
    return {
        type: reportPurchaseConstants.GET_REPORT_PURCHASE,
        pageNo,
        filterData
    }
}

export const getReportDownloadCsv = (pageNo, filterData) => {
    return {
        type: reportPurchaseConstants.GET_REPORT_PURCHASE_DOWNLOAD,
        pageNo,
        filterData
    }
}

export const clearReportCsvData = () => {
    return {
        type: reportPurchaseConstants.CLEAR_REPORT_CSV_DATA,
    }
}
