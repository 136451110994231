import React from "react";
import { Card, Form } from "react-bootstrap";
import Navbar from "../../layouts/Navbar";
import Sidebar from "../../layouts/Sidebar";
import Filter from "../../assets/images/filter.svg";
import Plus from "../../assets/images/pl.svg";
import cust from "../../assets/images/green1.svg";
import Search from "../../assets/images/search.svg";
import { connect } from "react-redux";
import { getCustomers, createCustomer, getCustomer, updateCustomer, changePage, getCustomersList } from "../../actions";
import Spinner from "../../Components/loader";
import CustomerForm from "./CustomerForm";
import Pagination from "../../Components/Pagination";
import { toast } from "react-toastify";
import { reset, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";

const role = localStorage.getItem('userRole');
class Customers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allCustomers: [],
            customer: [],
            show: false,
            filterData: {
                "sortField": "",
                "sortMethod": "DESC",
                "searchKey": "",
                "pageNumber": 0,
                "pageSize": 10
            },
            success_msg: '',
            allData: [],
            str: ""
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
        this.sortHandler = this.sortHandler.bind(this);
        this.customeNameHandler = this.customeNameHandler.bind(this);
    }
    customeNameHandler(e) {
        setTimeout(() => {
            this.props.dispatch(getCustomersList(0, e.target.value));
        }, 500)
    }
    handleShow() {
        this.props.dispatch(changePage());
        this.setState({ show: true, str: "create", });
    }
    handleClose() {
        this.setState(
            { show: false, }
        );
    }
    componentDidUpdate(prevProps) {
        if (prevProps.customers_list !== this.props.customers_list) {
            this.setState({
                allCustomers: this.props.customers_list,
            });
        }
        if (prevProps.allData !== this.props.allData) {
            this.setState({
                allData: this.props.allData,
            });
        } if (prevProps.customer !== this.props.customer) {
            this.setState({
            });
        }
        if (prevProps.success_msg !== this.props.success_msg) {
            this.setState({
                success_msg: this.props.success_msg,
            });
            const { filterData } = this.state;
            if (this.props.success_msg.status === 200) {
                this.setState({
                    show: false
                });
                this.props.dispatch(getCustomers(filterData));
                toast(this.props.success_msg.data);
            } else {
                toast(this.props.success_msg.data);
            }
        }
    }
    componentDidMount() {
        const { filterData } = this.state;
        this.props.dispatch(getCustomers(filterData));
    }
    handleSubmit(formData, dispatch) {
        if (!formData.firstName) {
            throw new SubmissionError({
                firstName: 'Please fill the field *',
            })
        } else if (formData?.firstName?.trim().length === 0) {
            throw new SubmissionError({
                firstName: 'Please fill the field *',
            })
        }
        if (formData) {
            if (this.props.page === "edit") {
                const data = { ...formData };
                if (formData?.active === "true" || formData?.active === true) {
                    data.active = true;
                } else if (formData?.active === "false" || formData?.active === false) {
                    data.active = false;
                }
                this.props.dispatch(updateCustomer(data));
            }
            else {
                this.props.dispatch(createCustomer(formData));
                dispatch(reset("customerForm"));
            }
        } else {
            toast("Please fill all the required fields");
        }
    }
    handleEdit(row) {
        this.setState({
            customer: row,
            show: true,
            customer: row,
            str: "edit"
        });
        this.props.dispatch(getCustomer(row.customerId));
    }
    onChange(pageNo) {
        let data = { ...this.state.filterData };
        data.pageNumber = pageNo;
        this.setState({
            filterData: data
        })
        this.props.dispatch(getCustomers(data));
    }
    searchHandler(e) {
        const { filterData } = this.state;
        let data = { ...filterData };
        data.searchKey = e.target.value;
        data.pageNumber = 0;
        this.setState({
            filterData: data
        })
        this.props.dispatch(getCustomers(data));
    }
    sortHandler(sortField) {
        const { filterData } = this.state;
        let data = { ...filterData };
        data.sortField = sortField;
        data.sortMethod = filterData.sortMethod === "ASC" ? "DESC" : "ASC";
        this.setState({
            filterData: data
        })
        this.props.dispatch(getCustomers(data));
    }
    render() {
        const {
            allCustomers,
            show,
            allData,
            str
        } = this.state;


        let data = {
            totalElements: allData.totalElements,
            totalPages: allData.totalPages,
            offset: allData?.pageable?.offset,
            numberOfElements: allData.numberOfElements,
            num: (allData?.number) + 1
        }


        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <div className="page-head-split">
                                <div className="head-colums">
                                    <div className="head-part-tab">
                                        <img src={cust} alt="..." className="head-imgs" />
                                        <h5> IDS Directory Customer</h5>
                                    </div>
                                    <div className="tab-links">
                                        <Link to={"/" + role + "/customers"} className="active">Customers</Link>
                                        {role === "admin" ? <Link to={"/" + role + "/customers/staff"}>Staff</Link> : null}
                                        <Link to={"/" + role + "/customers/vendor"}>Vendors</Link>
                                    </div>
                                    <div className="customer-search">
                                        <span><img src={Search} alt=".." className="search-img" /></span>
                                        <Form.Control type="search" placeholder="Search" onChange={this.searchHandler} className="main-search" />
                                    </div>
                                    <button className="add-staff" onClick={this.handleShow}>
                                        <img src={Plus} alt="" /> Add Customer
                                    </button>
                                    <CustomerForm
                                        customerChange={this.customeNameHandler}
                                        handleShow={this.handleShow}
                                        show={show}
                                        onHide={this.handleClose}
                                        onSubmit={this.handleSubmit}
                                        str={str}
                                    />
                                </div>
                            </div>
                            <div className="table-class">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            {/* <th scope="col">
                                                ID <img onClick={(e) => this.sortHandler("c.customer_id")} src={Filter} alt="..." />
                                            </th> */}
                                            <th scope="col">
                                                Name <img onClick={(e) => this.sortHandler("c.customer_first_name")} src={Filter} alt="..." />
                                            </th>
                                            <th scope="col">
                                                Location <img src={Filter} alt="..." onClick={(e) => this.sortHandler("c.customer_city")} />
                                            </th>
                                            <th scope="col">
                                                Contact <img src={Filter} alt="..." onClick={(e) => this.sortHandler("c.customer_phone")} />
                                            </th>
                                            <th scope="col">
                                                Email <img src={Filter} alt="..." onClick={(e) => this.sortHandler("c.customer_email")} />
                                            </th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <>
                                            {allCustomers && allCustomers.length > 0 ? allCustomers.map((item, i) => (
                                                <tr key={i} onClick={() => this.handleEdit(item)} style={{ cursor: "pointer" }}>
                                                    {/* <td>{item.customerId}</td> */}
                                                    <td>{item.customerName}</td>
                                                    <td>{item.customerCity || <div className="center_align"><p>-</p></div>}</td>
                                                    <td>{item.customerPhone !== null ? `+91 ${item.customerPhone}` : "" || <div className="center_align"><p>-</p></div>}</td>
                                                    <td>{item.customerEmail || <div className="center_align"><p>-</p></div>}</td>
                                                    <td>
                                                        <button className="Edit-staff" onClick={() => this.handleEdit(item)}>
                                                            Edit
                                                        </button>
                                                    </td>
                                                </tr>
                                            )) : !this.props.loading ?
                                                <h1 className="empty-data">No Records Found</h1> :
                                                <Spinner />
                                            }
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                        <Pagination data={data} onChange={this.onChange} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.customer.loading,
        customers_list: state.customer.customers_list_new,
        page: state.customer.page,
        success_msg: state.customer.success_msg,
        allData: state.customer.allData,
        customer: state.customer.customer,
    }
}

export default connect(mapStateToProps)(Customers);