import React, { useRef } from "react";
import { Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import "../../../assets/css/view.css";
import Logo from "../../../assets/images/main.svg";

function View(props) {
    const componentRef = useRef();
    const { show, onHide, data, preview } = props;
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-staff preview">
                    <div className="modal-staff head">
                        <div>
                            <div onClick={onHide}>
                                <i class="fas fa-arrow-left"></i>
                            </div>
                            <h4 className="mod_head">
                                {data?.referenceId ?? "Q-1421"}
                            </h4>
                        </div>
                    </div>
                    <Row className="preview-img " style={{ minHeight: "700px" }}>
                        <div className="go-hr"></div>
                        {preview !== undefined ? <div className="template-two-design" ref={componentRef} dangerouslySetInnerHTML={{ __html: `${preview}` }} /> :
                            <div>
                                <img src={Logo} alt=".." className="spin rt-imgss" width="100px" />
                                <h2 className="rt-img">Please Wait...</h2>
                            </div>}
                    </Row>
                </div>
            </Modal>
        </>
    )
}

function mapStateToProps(state) {
    return {
        previewQutationTxt: state.qutation.previewQutation,
    }
}

export default connect(mapStateToProps)(View);