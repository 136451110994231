import { authToken, baseUrl } from "../actions";

export const getVendorApi = (item) => {
    var VENDOR_LIST = `${baseUrl}staff/vendors?${item}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(VENDOR_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}


export const getVendorByIdApi = (vendorId) => {
    const VENDOR_LIST = `${baseUrl}staff/vendor/${vendorId}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(VENDOR_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}




export const createVendorApi = (request) => {
    const CREATE_VENDOR = `${baseUrl}staff/vendors`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_VENDOR, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}

export const updateVendorApi = (request) => {
    const UPDATE_VENDORS = `${baseUrl}staff/vendors`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(UPDATE_VENDORS, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}

export const getVendorSecondaryMailsApi = (id) => {
    var VENDOR_LIST = `${baseUrl}staff/vendorSecondaryEmail/${id}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(VENDOR_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}

export const createVendorSecondaryEmailApi = (request) => {
    const CREATE_VENDOR = `${baseUrl}staff/vendorSecondaryEmail`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_VENDOR, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}

export const updateVendorSecondaryEmailApi = (request) => {
    const CREATE_VENDOR = `${baseUrl}staff/vendorSecondaryEmail`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_VENDOR, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
