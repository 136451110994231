export const outwardConstants = {
    GET_OUTWARDS_LOGS: "GET_OUTWARDS_LOGS_LIST",
    GET_OUTWARDS_LOGS_SUCCESS: "GET_OUTWARDS_LOGS_LIST_SUCCESS",
    GET_OUTWARDS_LOGS_FAILURE: "GET_OUTWARDS_LOGS_LIST_FAILURE",

    CREATE_CO_PRODUCT: "CREATE_CO_PRODUCT",
    CREATE_CO_PRODUCT_SUCCESS: "CREATE_CO_PRODUCT_SUCCESS",
    CREATE_CO_PRODUCT_FAILURE: "CREATE_CO_PRODUCT_FAILURE",

    EDIT_CO_PRODUCT: "EDIT_CO_PRODUCT",
    EDIT_CO_PRODUCT_SUCCESS: "EDIT_CO_PRODUCT_SUCCESS",
    EDIT_CO_PRODUCT_FAILURE: "EDIT_CO_PRODUCT_FAILURE",

    DELETE_CO_PRODUCT: "DELETE_CO_PRODUCT",
    DELETE_CO_PRODUCT_SUCCESS: "DELETE_CO_PRODUCT_SUCCESS",
    DELETE_CO_PRODUCT_FAILURE: "DELETE_CO_PRODUCT_FAILURE",

    GET_CO_PRODUCT_LIST: "GET_CO_PRODUCT_LIST",
    GET_CO_PRODUCT_LIST_SUCCESS: "GET_CO_PRODUCT_LIST_SUCCESS",
    GET_CO_PRODUCT_LIST_FAILURE: "GET_CO_PRODUCT_LIST_FAILURE",

    GET_CO_PROJECTS: "GET_CO_PROJECTS_LIST",
    GET_CO_PROJECTS_SUCCESS: "GET_CO_PROJECTS_LIST_SUCCESS",
    GET_CO_PROJECTS_FAILURE: "GET_CO_PROJECTS_LIST_FAILURE",

    GET_VENDOR_PROJECTS: "GET_VENDOR_PROJECTS",
    GET_VENDOR_PROJECTS_SUCCESS: "GET_VENDOR_PROJECTS_SUCCESS",
    GET_VENDOR_PROJECTS_FAILURE: "GET_VENDOR_PROJECTS_FAILURE",

    UPDATE_CO_OUTWARD: "UPDATE_CO_OUTWARD",
    UPDATE_CO_OUTWARD_SUCCESS: "UPDATE_CO_OUTWARD_SUCCESS",
    UPDATE_CO_OUTWARD_FAILURE: "UPDATE_CO_OUTWARD_FAILURE",

    GET_VENDOR_PRODUCTS: "GET_VENDOR_PRODUCTS_LIST",
    GET_VENDOR_PRODUCTS_SUCCESS: "GET_VENDOR_PRODUCTS_LIST_SUCCESS",
    GET_VENDOR_PRODUCTS_FAILURE: "GET_VENDOR_PRODUCTS_LIST_FAILURE",

    CREATE_VENDOR_PRODUCT: "CREATE_VENDOR_PRODUCT",
    CREATE_VENDOR_PRODUCT_SUCCESS: "CREATE_VENDOR_PRODUCT_SUCCESS",
    CREATE_VENDOR_PRODUCT_FAILURE: "CREATE_VENDOR_PRODUCT_FAILURE",

    EDIT_VENDOR_PRODUCT: "EDIT_VENDOR_PRODUCT",
    EDIT_VENDOR_PRODUCT_SUCCESS: "EDIT_VENDOR_PRODUCT_SUCCESS",
    EDIT_VENDOR_PRODUCT_FAILURE: "EDIT_VENDOR_PRODUCT_FAILURE",

    DELETE_VENDOR_PRODUCT: "DELETE_VENDOR_PRODUCT",
    DELETE_VENDOR_PRODUCT_SUCCESS: "DELETE_VENDOR_PRODUCT_SUCCESS",
    DELETE_VENDOR_PRODUCT_FAILURE: "DELETE_VENDOR_PRODUCT_FAILURE",

    EDIT_RETURN_REVIEW: "EDIT_RETURN_REVIEW",
    EDIT_RETURN_REVIEW_SUCCESS: "EDIT_RETURN_REVIEW_SUCCESS",
    EDIT_RETURN_REVIEW_FAILURE: "EDIT_RETURN_REVIEW_FAILURE",

    UPDATE_RETURN_OUTWARD_PRODUCT: "UPDATE_RETURN_OUTWARD_PRODUCT",
    UPDATE_RETURN_OUTWARD_PRODUCT_SUCCESS: "UPDATE_RETURN_OUTWARD_PRODUCT_SUCCESS",
    UPDATE_RETURN_OUTWARD_PRODUCT_FAILURE: "UPDATE_RETURN_OUTWARD_PRODUCT_FAILURE",

    DOWNLOAD_OUTWARD_CSV: "DOWNLOAD_OUTWARD_CSV",
    DOWNLOAD_OUTWARD_CSV_SUCCESS: "DOWNLOAD_OUTWARD_CSV_SUCCESS",
    DOWNLOAD_OUTWARD_CSV_FAILURE: "DOWNLOAD_OUTWARD_CSV_FAILURE",
    
    CHANGE_ADD_PAGE: "CHANGE_ADD_PAGE",
    CHANGE_PAGE: "CHANGE_PAGE",
};
