import { put, call, takeEvery } from "redux-saga/effects";
import { getreportcustomerApi } from "../services/reportcustomerServices";
import { reportcustomerconstants } from "../constants";

export function* reportcustomerSaga(payload) {
    try {
        const response = yield call(getreportcustomerApi, payload.pageNo, payload.filterData);
        yield put({ type: reportcustomerconstants.GET_REPORT_CUSTOMER_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportcustomerconstants.GET_REPORT_CUSTOMER_FAILURE, error });
    }
}

export default function* reportcustomer() {
    yield takeEvery(reportcustomerconstants.GET_REPORT_CUSTOMER, reportcustomerSaga);
}