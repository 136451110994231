import { projectsConstants } from "../constants";

export const getProjectsList = (pageNo, searchKey, sort) => {
    return {
        type: projectsConstants.GET_PROJECTS,
        pageNo,
        searchKey,
        sort
    }
}
export const createProjects = (formData) => {
    return {
        type: projectsConstants.CREATE_PROJECTS_INIT,
        formData
    }
}
export const getProjects = (purchaseOrderNumber) => {
    return {
        type: projectsConstants.GET_SINGLE_PROJECTS_INIT,
        purchaseOrderNumber
    }
}
export const updateProjects = (formData) => {
    return {
        type: projectsConstants.UPDATE_PROJECTS_INIT,
        formData
    }
}
export const changePage = () => {
    return {
        type: projectsConstants.CHANGE_PAGE,
    }
}