import React from 'react'
import { reduxForm } from 'redux-form';
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import parse from 'html-react-parser';

const ProjectView = props => {
    const { show, onHide, item } = props;
    return (
        <>
            <Modal
                show={show}
                size="lg"
                onHide={() => {
                    onHide();
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-staff">
                    <div className="head">
                        <div>
                            <i class="fas fa-arrow-left" onClick={() => {
                                onHide();
                            }}></i>
                            <h4>{item?.projectName}</h4>
                        </div>
                    </div>
                    <div className="table-class space-part">
                        <table class="table table-borderless size-height">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        Product Name
                                    </th>
                                    <th scope="col" className='txt-center'>
                                        Ordered Qty
                                    </th>
                                    <th scope="col" className='txt-center'>
                                        Available Qty
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.productQuantityDetail !== undefined && item.productQuantityDetail.length > 0 ?
                                    item.productQuantityDetail.map((item, i) => (
                                        <tr key={i} style={{ backgroundColor: item.quantity > item.totalQuantity && "#FFCD0329" }}>
                                            <td className="inward_new_size">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.productName || "-")}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        {parse(item.productName || "-")}
                                                    </span>
                                                </OverlayTrigger>
                                            </td>
                                            <td className='txt-center'>{item.quantity}</td>
                                            <td className='txt-center'>{item.totalQuantity}</td>
                                        </tr>
                                    )) : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
        </>
    )
}

function mapStateToProps(state) {
    return {
        confirmQuotDropdown: state.boq.confirmQuotDropdown,
    }
}

const Form = reduxForm({
    form: "ProjectView",
    destroyOnUnmount: false,
    enableReinitialize: true,
    // validate
})(ProjectView);

export default connect(mapStateToProps)(Form);
