import React from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Field, reduxForm, SubmissionError, reset } from "redux-form";
import { connect } from "react-redux";
import Navbar from "../../../../layouts/Navbar";
import Sidebar from "../../../../layouts/Sidebar";
import Orange from "../../../../assets/images/green2.svg";
import { Typeahead } from 'react-bootstrap-typeahead';
import VendorForm from "../../../vendor/VendorForm";
import { toast } from "react-toastify";
import { getVendorById, changePage, createVendorPO, getVendorList, getPoById, updateVendorPo, createVendor, getTitle, getTitleStr } from "../../../../actions";
const role = localStorage.getItem('userRole');


const textField = ({ input, name, label, placeholder, defaultValue, disabled, type, meta: { touched, error, warning } }) => (
    <Form.Group className="mb-3" controlId={label}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
            {...input}
            name={name}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            defaultValue={defaultValue}
        />
        {touched && error && <p className="require">{error}</p>}
    </Form.Group>
)
class VendorDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            id: this.props.match.params.id,
            empty: "",
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.customerChange = this.customerChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.addVendorDetails = this.addVendorDetails.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    prevPage() {
        window.location.hash = `/${role}/vendor_po`;
    }
    handleShow() {
        this.props.dispatch(changePage());
        this.setState({ show: true, empty: "create" });
    }
    handleClose() {
        this.setState({ show: false, empty: "" });
    }
    customerChange(e) {
        this.props.allData?.content?.map((item) => {
            if (item.name === e[0]) {
                this.props.dispatch(getVendorById(item?.vendorId));
            }
        });
    }
    inputChange(text, e) {
        this.props.dispatch(getVendorList(0, text));
    }
    handleSubmit(formData, dispatch) {
        if (!formData.firstName) {
            throw new SubmissionError({
                firstName: 'Please fill the field *',
            })
        } else if (formData?.firstName?.trim().length === 0) {
            throw new SubmissionError({
                firstName: 'Please fill the field *',
            })
        }
        if (formData) {
            this.props.dispatch(createVendor(formData));
            dispatch(reset("vendorForm"));
        } else {
            toast("Please fill all the required fields");
        }
    }

    addVendorDetails(values) {
        if (this.props.match.params.id !== "add") {
            const { poData } = this.props;
            if (poData.vendor.vendorId !== values.vendorId) {
                let formData = {
                    "vendorId": values.vendorId,
                    "purchaseOrderId": this.props.match.params.id
                }
                this.props.dispatch(updateVendorPo(formData));
            } else {
                window.location.hash = "/" + role + "/purchase_order/vendor_po/product-detail/" + this.props.match.params.id;
            }
        } else {
            if (values.firstName === "") {
                toast("Please Select a vendor");
                return false;
            }
            let formData = {
                "vendorId": values.vendorId,
                "templateId": 6
            }
            this.props.dispatch(createVendorPO(formData));
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.vendorposuccess_msg !== this.props.vendorposuccess_msg) {
            this.setState({
                vendorposuccess_msg: this.props.vendorposuccess_msg,
            });
            if (this.props.vendorposuccess_msg.status === 200) {
                toast(this.props.vendorposuccess_msg.message);
                const { vendorposuccess_msg } = this.props;

                window.location.hash = "/" + role + "/vendor_po/product-detail/" + vendorposuccess_msg.data?.purchaseOrderId;
            } else {
                toast(this.props.vendorposuccess_msg.message);
            }
        } if (prevProps.success_msg !== this.props.success_msg) {
            if (this.props.success_msg.status === 200) {
                this.setState({
                    show: false
                });
                toast(this.props.success_msg.data);
                this.props.dispatch(getVendorList());
            } else {
                toast(this.props.success_msg.data);
            }
        }
    }
    componentDidMount() {
        this.props.dispatch(changePage());
        this.props.dispatch(getVendorList());
        if (this.props.match.params.id !== "add") {
            this.props.dispatch(getPoById(this.props.match.params.id));
        }
    }
    render() {
        const { allData, handleSubmit, load, ignoredFields } = this.props;
        const { empty } = this.state;
        const { history } = this.props;

        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <img src={Orange} alt="..." className="head-img" />
                            <h5 className='po_link' onClick={() => window.location.hash = "/" + role + "/vendor_po"}>Purchase Order</h5>
                            <i className="fas fa-arrow-right" style={{ marginTop: "10px" }}></i>
                            <h6>{getTitleStr()}</h6>
                            <hr className="line-new"></hr>
                            <div className="order-page-title">
                                <Link to={"/" + role + "/vendor_po/vendor-detail/" + this.props.match.params.id} className="active">
                                    Vendor Details
                                </Link>
                                <Link to={"/" + role + "/vendor_po/product-detail/" + this.props.match.params.id} >
                                    Product Details
                                </Link>
                                {ignoredFields.includes("FOOTER") ? <></> : <Link to={"/" + role + "/vendor_po/footer/" + this.props.match.params.id} >
                                    Footer
                                </Link>}
                                {ignoredFields.includes("TERMS_CONDITION") ? <></> : <Link to={"/" + role + "/vendor_po/termsAndConditions/" + this.props.match.params.id} >
                                    Terms & Conditions
                                </Link>}
                            </div>
                            <Row>
                                <Col md={8}></Col>
                                <Col md={3}>
                                    <VendorForm
                                        handleShow={this.handleShow}
                                        show={this.state?.show}
                                        onHide={this.handleClose}
                                        vendorbutton="vendorbutton"
                                        onSubmit={this.handleSubmit}
                                        empty={empty}
                                    />
                                </Col>
                            </Row>
                            <form onSubmit={handleSubmit(this.addVendorDetails)}>
                                <Row className="vendor-details">
                                    <Col md={9}>
                                        <label className="span-name">Select Vendor</label>
                                        {allData?.content?.length >= 0 ? <Typeahead
                                            onChange={this.customerChange}
                                            id="async-example"
                                            labelKey={allData?.content?.map(item => item.name)}
                                            onInputChange={this.inputChange}
                                            options={allData?.content?.map(item => item.name)}
                                            placeholder="Choose a Vendor..."
                                        /> : null}
                                    </Col>
                                    <span className="customer-span" style={{ marginTop: "40px" }}>Or</span>
                                    <Col md={2}></Col>
                                    <Col md={3} className="top-space">
                                        <Form.Label>Initials</Form.Label>
                                        <Field name="vendorInitial" component="select" className="form-control form-select" disabled={true} >
                                            <option value="" disabled={true}>Select One</option>
                                            <option value="Mr" >Mr</option>
                                            <option value="M/s" >M/s</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss" >Miss</option>
                                        </Field>
                                    </Col>
                                    <Col md={3} className="top-space">
                                        <Field
                                            name="firstName"
                                            type="text"
                                            component={textField}
                                            label="First Name"
                                            placeholder="Enter First Name"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3} className="top-space">
                                        <Field
                                            name="lastName"
                                            type="text"
                                            component={textField}
                                            label="Last Name"
                                            placeholder="Enter Last Name"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3} className="top-space">
                                        <Field
                                            name="contactNumber"
                                            type="text"
                                            component={textField}
                                            label="Contact Number"
                                            placeholder="Enter Contact Number"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="email"
                                            type="text"
                                            component={textField}
                                            label="Email"
                                            placeholder="Enter Email"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="gstNumber"
                                            type="text"
                                            component={textField}
                                            label="Gst Number"
                                            placeholder="Enter Gst Number"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="addressLine1"
                                            type="text"
                                            component={textField}
                                            label="Address Line 1"
                                            placeholder="Enter Address Line 1"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="addressLine2"
                                            type="text"
                                            component={textField}
                                            label="Address Line 2"
                                            placeholder="Enter Address Line 2"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="city"
                                            type="text"
                                            component={textField}
                                            label="City"
                                            placeholder="Enter City"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="state"
                                            type="text"
                                            component={textField}
                                            label="State"
                                            placeholder="Enter State"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="pinCode"
                                            type="text"
                                            component={textField}
                                            label="Pincode"
                                            placeholder="Enter Pincode"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="vendorId"
                                            type="hidden"
                                            component={textField}
                                            placeholder="Enter Pincode"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3} >
                                        <button type="button" className="cancel-btn" onClick={() => history.push(`/${role}/vendor_po`)} style={{ marginTop: "50px" }}>Cancel</button>
                                    </Col>
                                    <Col md={6} >
                                    </Col>
                                    <Col md={3} >
                                        <button type="submit" className="modal-btn save" style={{ marginTop: "50px", marginLeft: "150px" }}>Save & Proceed</button>
                                    </Col>
                                </Row>
                            </form>
                            <br></br>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    let initValues;
    let id = state.po.poData?.purchaseOrderId;
    let poData = state.po.poData?.vendor;
    let vendor = state.vendor.vendor;
    let ignoreData = id ? state.po.poData?.ignoredFields : ["FOOTER", "TERMS_CONDITION"];
    let newVendor = id !== null ? {
        'vendorInitial': poData ? poData.initials : '',
        'firstName': poData ? poData.firstName : '',
        'lastName': poData ? poData.lastName : '',
        'contactNumber': poData ? poData.contactNumber : '',
        'gstNumber': poData ? poData.gstNumber : '',
        'email': poData ? poData.email : '',
        'addressLine1': poData ? poData.addressLine1 : '',
        'addressLine2': poData ? poData.addressLine2 : '',
        'city': poData ? poData.city : '',
        'state': poData ? poData.state : '',
        'pinCode': poData ? poData.pinCode : '',
        'vendorId': poData ? poData.vendorId : '',
    } : vendor;

    initValues = Object.keys(vendor).length !== 0 ? vendor : newVendor;


    return {
        initialValues: initValues,
        load: state.po.load,
        ignoredFields: ignoreData.length > 0 ? ignoreData : ["FOOTER", "TERMS_CONDITION"],
        poData: state.po.poData,
        allData: state.vendor.allData,
        vendor: state.vendor.vendor,
        success_msg: state.vendor.success_msg,
        vendorposuccess_msg: state.vendorpo.vendorposuccess_msg,
    }
}
const VendorDetailsForm = reduxForm({
    form: 'purchaseOrderFormRedux',
    destroyOnUnmount: false,
    enableReinitialize: true,
    // validate
})(VendorDetails)

export default connect(mapStateToProps)(VendorDetailsForm);