import { authToken, baseUrl } from "../actions";


export const getVendorPoApi = (item) => {
    let VENDORPO_LIST = `${baseUrl}staff/purchaseOrder?${item}`
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(VENDORPO_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};


export const createVendorPoApi = (request) => {
    const CREATE_VENDORPO = `${baseUrl}staff/purchaseOrder`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request)
    };

    return fetch(CREATE_VENDORPO, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}


export const getOneVendorPoApi = (purchaseOrderId) => {
    const VENDORPO_LIST = `${baseUrl}staff/purchaseOrder/${purchaseOrderId}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(VENDORPO_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const updateVendorPoApi = (request) => {
    const UPDATE_VENDORPO = `${baseUrl}staff/purchaseOrder`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(UPDATE_VENDORPO, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
