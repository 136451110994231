export const inwardConstants = {
    GET_VERIFIED_PROJECTS: "GET_VERIFIED_PROJECTS_LIST",
    GET_VERIFIED_PROJECTS_SUCCESS: "GET_VERIFIED_PROJECTS_LIST_SUCCESS",
    GET_VERIFIED_PROJECTS_FAILURE: "GET_VERIFIED_PROJECTS_LIST_FAILURE",

    GET_INWARD_LOGS: "GET_INWARD_LOGS_LIST",
    GET_INWARD_LOGS_SUCCESS: "GET_INWARD_LOGS_LIST_SUCCESS",
    GET_INWARD_LOGS_FAILURE: "GET_INWARD_LOGS_LIST_FAILURE",

    GET_RECEIVED_INWARD: "GET_RECEIVED_INWARD_LIST",
    GET_RECEIVED_INWARD_SUCCESS: "GET_RECEIVED_INWARD_LIST_SUCCESS",
    GET_RECEIVED_INWARD_FAILURE: "GET_RECEIVED_INWARD_LIST_FAILURE",

    ADD_RETURN_INWARD_PRODUCT_INIT: "ADD_RETURN_INWARD_PRODUCT_INIT",
    ADD_RETURN_INWARD_PRODUCT_SUCCESS: "ADD_RETURN_INWARD_PRODUCT_SUCCESS",
    ADD_RETURN_INWARD_PRODUCT_FAILURE: "ADD_RETURN_INWARD_PRODUCT_FAILURE",

    DOWNLOAD_INWARD_CSV: "DOWNLOAD_INWARD_CSV",
    DOWNLOAD_INWARD_CSV_SUCCESS: "DOWNLOAD_INWARD_CSV_SUCCESS",
    DOWNLOAD_INWARD_CSV_FAILURE: "DOWNLOAD_INWARD_CSV_FAILURE",

    DOWNLOAD_YET_TO_RECEIVE_CSV: "DOWNLOAD_YET_TO_RECEIVE_CSV",
    DOWNLOAD_YET_TO_RECEIVE_CSV_SUCCESS: "DOWNLOAD_YET_TO_RECEIVE_CSV_SUCCESS",
    DOWNLOAD_YET_TO_RECEIVE_CSV_FAILURE: "DOWNLOAD_YET_TO_RECEIVE_CSV_FAILURE",
};
