import React, { useRef } from "react";
import { Modal, Row } from "react-bootstrap";
import copy from "../../../assets/images/copy.svg";
import edit from "../../../assets/images/edit.svg";
import back from "../../../assets/images/out.svg";
import mail from "../../../assets/images/mail.svg";
import logout from "../../../assets/images/log_out.svg";
import check from "../../../assets/images/check.svg";
import download from "../../../assets/images/download.svg";
import trash from "../../../assets/images/trash1.svg";
import transfer from "../../../assets/images/transfer.svg";
import circle from "../../../assets/images/x-circle.svg";
import file from "../../../assets/images/file.svg";
import Rou from "../../../assets/images/rou.gif";
import { reduxForm } from 'redux-form';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import "../../../assets/css/view.css";
import Logo from "../../../assets/images/main.svg";
import { exportToJson, onBoqDownloadCsv, clearCSV } from "../../../actions";
import { useEffect } from "react";

function BOQView(props) {

    const componentRef = useRef();
    const { show, onHide, data, previewBoqText, onBoqCsvDownloadDispatch, csvDataDownload, goTo, load, item, loading, clearCsvDispatch } = props;
    let status = data;

    const csv = () => {
        onBoqCsvDownloadDispatch({
            id: item.billOfQuantityId
        });
    }

    useEffect(() => {
        if (csvDataDownload !== null) {
            exportToJson(csvDataDownload, "BOQ CSV")
            setTimeout(() => {
                clearCsvDispatch();
            }, 1000);
        }
    }, [csvDataDownload])

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-staff preview">
                    <div className="modal-staff head">
                        <div>
                            <div onClick={onHide}>
                                <i class="fas fa-arrow-left"></i>
                            </div>
                            <h4 className="mod_head">
                                {item?.billOfQuantityNumber ?? "Q-1421"}
                            </h4>
                        </div>
                        <div>
                            {status === "DRAFT" ? <button type="button" className="select-template generate" disabled={load} onClick={props.generate} >
                                <img src={check} alt="" />   Generate
                            </button> : null}
                            {status === "DRAFT" ? <button className="select-template delete_quot" disabled={load} onClick={() => props.delete(data)}>
                                <img src={trash} alt="" />   Delete BOQ
                            </button> : null}
                            <button type="button" className="select-template clone" disabled={load} onClick={props.clone}>
                                <img src={copy} alt="" />   Clone
                            </button>
                            {status === "GENERATED" || status === "SENT" || status === "DRAFT" ? <button className="select-template edit_quot" style={{ marginTop: "15px" }} onClick={() => goTo()}>
                                <img src={status === "GENERATED" || status === "SENT" ? file : edit} alt="" />
                                {status === "GENERATED" || status === "SENT" ? "Revise" : "Edit BOQ"}
                            </button> : null}
                            {status === "CONVERTED" ? <button className="select-template transfer" style={{ marginTop: "15px" }} onClick={props.internal} >
                                <img src={transfer} alt="" /> Internal Transfer
                            </button> : null}
                            {status === "PARTIALLY_RECEIVED" ? <button className="select-template deliver_boq" style={{ marginTop: "15px" }} onClick={props.deliver} >
                                <img src={logout} alt="" /> Deliver
                            </button> : null}
                            <ReactToPrint
                                trigger={() => <button className="select-template download"> <img src={download} alt="" /> Download</button>}
                                content={() => componentRef.current}
                            />
                            {loading ? <button className="select-template csv-download"> <img src={Rou} alt="" /> CSV </button> :
                                <button className="select-template csv-download" onClick={csv}> <img src={download} alt="" /> CSV </button>}
                            {status === "GENERATED" ? <button type="button" className="select-template send_mail" onClick={props.send} disabled={load}>
                                <img src={mail} alt="" width="16px" />  Send Email
                            </button> : null}
                            {status === "GENERATED" || status === "SENT" || status === "VERIFIED" ? < button className="select-template delete_quot cancel" onClick={props.cancel}>
                                <img src={circle} alt="" /> Cancel
                            </button> : null}
                            <img src={back} alt="" onClick={onHide} style={{ marginTop: "5px" }} className="backSXXZ_im     g" />
                        </div>
                    </div>
                    <Row className="preview-img " style={{ minHeight: "700px" }}>
                        <div className="go-hr"></div>
                        {previewBoqText !== "" ? <div className="template-two-design" ref={componentRef} dangerouslySetInnerHTML={{ __html: `${previewBoqText}` }} /> :
                            <div>
                                <img src={Logo} alt=".." className="spin rt-imgss" width="100px" />
                                <h2 className="rt-img">Please Wait...</h2>
                            </div>}
                    </Row>
                </div>
            </Modal>
        </>
    )
}

function mapStateToProps(state) {
    return {
        previewBoqText: state.boq.previewBoqText,
        csvDataDownload: state.boq.csvDataDownload,
        loading: state.boq.loading
    }
}

const Form = reduxForm({
    form: "ViewForm",
    enableReinitialize: true,
    destroyOnUnmount: false,
})(BOQView);


const mapDispatchToProps = (dispatch) => ({
    onBoqCsvDownloadDispatch: (formData) => dispatch(onBoqDownloadCsv(formData)),
    clearCsvDispatch: (formData) => dispatch(clearCSV(formData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Form);