import { Button, Checkbox, Col, DatePicker, Form, Input, Row } from 'antd';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Card, Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { connect } from 'react-redux';
// import filter from "../../../assets/images/filt.svg";
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { displayCurrency, exportToJson, filterDateFormat, htmlTagRemove, listReportSubMenu, oaConfirmCsv, oaPdfView, clearPdfView, shortStr } from '../../../actions';
import "../../../assets/css/button.css";
import "../../../assets/css/popup.css";
import Art from "../../../assets/images/art.svg";
import Re from "../../../assets/images/green2.svg";
import pdf_icon from "../../../assets/images/pdf.svg";
import Load from "../../../assets/images/rou.gif";
import download from "../../../assets/images/download.svg";
import Search from "../../../assets/images/search.svg";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import Logo from "../../../assets/images/main.svg";
import ReactToPrint from 'react-to-print';
import "../../../assets/css/view.css";


const role = localStorage.getItem('userRole');

const OaConfirmPage = (props) => {

    const componentRef = useRef();

    const { oaAll, loading, oaData, oaListDispatch, oaCsvDispatch, csvData, oaPdfDispatch, pdfView, oaPdfClearDispatch } = props;

    // listing data
    const [pageNo, setPageNo] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const [checkedList, setCheckedList] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [initValues, setInitValues] = useState({
        "pageNumber": pageNo,
        "pageSize": 10,
        "fromDate": null,
        "toDate": null,
        "searchKey": searchKey
    })

    const onFetchListTickets = (values) => {
        oaListDispatch(values)
    }

    const onChange = () => {
        initValues.pageNumber = pageNo + 1;
        setInitValues(initValues);
        setPageNo(pageNo + 1);
    }

    const searchHandler = (e) => {
        initValues.searchKey = e.target.value;
        let data = { ...initValues };
        data.searchKey = e.target.value;
        data.pageNumber = e.target.value !== "" ? 0 : initValues.pageNumber;
        setInitValues(data);
        setSearchKey(e.target.value);
        setPageNo(0);
    }

    useEffect(() => {
        onFetchListTickets(initValues);
        oaCsvDispatch({
            "fromDate": null,
            "toDate": null,
            "searchKey": searchKey
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNo, searchKey]);





    // Filter data
    const filterRef = useRef();
    const [filterform] = Form.useForm();


    const [fromdate, setFromdate] = useState(null);
    const [todate, setTodate] = useState(null);

    const filterHandler = () => {
        let data = {
            "fromDate": filterDateFormat(fromdate),
            "toDate": filterDateFormat(todate),
        }
        setInitValues(data);
        onFetchListTickets(data)
        setPageNo(0);
        window.scrollTo(0, 0);
    }


    const resetHandler = () => {
        let data = {
            "fromDate": "",
            "toDate": "",
        }
        setInitValues(data);
        onFetchListTickets(data);
        setPageNo(0);
        window.scrollTo(0, 0);
    }

    const fromHandler = (val) => {
        let item = val === null ? "" : (val._d)
        setFromdate(item)
    }
    const toHandler = (val) => {
        let item = val === null ? "" : (val._d)
        setTodate(item)
    }

    const pdf = () => {
        let data = {
            "orderAcceptanceConfirmationIds": checkedList,
        }
        oaPdfDispatch(data);
    }

    const onChecboxChange = (e, list) => {
        const isChecked = e.target.checked;
        setCheckedList((prevCheckedList) => {
            if (isChecked) {
                return [...prevCheckedList, list.orderAcceptanceConfirmationId];
            } else {
                return prevCheckedList.filter((item) => item !== list.orderAcceptanceConfirmationId);
            }
        });
    };

    const onCheckAllChange = (e) => {
        const isChecked = e.target.checked;
        setCheckedList(isChecked ? oaAll.content.map(item => item.orderAcceptanceConfirmationId) : []);
        setCheckAll(isChecked);
    };

    const onscroll = () => {
        if (checkAll) {
            setCheckedList(oaAll.content.map(item => item.orderAcceptanceConfirmationId));
        }
    }


    const disabledDate = (current) => current && current > moment().endOf("day");
    const checkDisabledDate = (current) => (current && current > moment().endOf("day")) || moment(fromdate).startOf("day") > current;

    const content = (
        <div className="drop-data">
            <Row>
                <Col md={24} xs={24}>
                    <Form
                        form={filterform}
                        ref={filterRef}
                        name="filter_info"
                        onFinish={filterHandler}
                        layout="vertical">
                        <Form.Item label="From Date" name="fromDate">
                            <DatePicker onChange={fromHandler} disabledDate={disabledDate} />
                        </Form.Item>
                        <Form.Item label="To Date" name="toDate">
                            <DatePicker onChange={toHandler} disabledDate={checkDisabledDate} />
                        </Form.Item>
                        <Row gutter={[24, 0]}>
                            <Col md={12}>
                                <Form.Item>
                                    <Button className="service-btn" type="primary" htmlType="submit" block>Apply </Button>
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item>
                                    <Button className="service-btn reset" type="primary"
                                        onClick={() => {
                                            resetHandler();
                                            filterRef.current.resetFields();
                                        }} block>Reset </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );


    // csv download
    const csv = () => {
        exportToJson(csvData, "CSV Confirm List")
    }


    const [showPopup, setShowPopUp] = useState(false);

    const openPopup = () => {
        setShowPopUp(true);
    }

    const closePopup = () => {
        onFetchListTickets(initValues);
        setShowPopUp(false);
        oaPdfClearDispatch();
    }


    useEffect(() => {
        if (pdfView !== null) {
            openPopup();
        }
    }, [pdfView])



    return (
        <Fragment>
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <div className="page-head-split">
                                <div className="head-colums">
                                    <div className="head-part-tab">
                                        <img src={Re} alt="..." className="head-imgs" />
                                        <h5>MC</h5>
                                    </div>
                                    <div className="tab-links">
                                        <Link to={"/" + role + "/oa"}>OA Details</Link>
                                        <Link to={"/" + role + "/oa/oa_report"} >PSO</Link>
                                        <Link to={"/" + role + "/oa/oaConfirmPage"} className="active">MC</Link>
                                        <Link to={"/" + role + "/oa/oaDownload"}>MC Report</Link>
                                        <Link to={"/" + role + "/oa/ms"}>MS</Link>
                                    </div>
                                    <div className="customer-search oa-search">
                                        <span><img src={Search} alt=".." className="search-imgs" /></span>
                                        <Input type="search" onChange={searchHandler} placeholder="Search" className="main-search" />
                                    </div>
                                    <div className='filter-background new-filter-set'>
                                        {/* <Popover content={content} placement="bottomLeft" trigger="click"   >
                                            <button className="filter-button" style={{ marginTop: "10px" }}>
                                                <img src={filter} alt="..." width="18px" style={{ paddingRight: "5px" }} /> Filter
                                            </button>
                                        </Popover> */}
                                        {checkedList?.length !== 0 ?
                                            <> {loading ?
                                                <button className="download_btn_pdf">
                                                    <img src={Load} alt=".." /> PDF
                                                </button> :
                                                <button className="download_btn_pdf" onClick={pdf}>
                                                    <img src={pdf_icon} alt=".." /> PDF
                                                </button>}
                                            </>
                                            : null}

                                        <button className="download_btn" onClick={csv}>
                                            <img src={Art} alt=".." /> CSV
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="infinite-table" id="scrollableDiv">
                                <InfiniteScroll
                                    dataLength={oaAll?.content?.length}
                                    next={onChange}
                                    hasMore={oaAll?.content?.length < oaData?.totalElements}
                                    loader={<p className='txt-center'><h4>Loading...</h4></p>}
                                    endMessage={<p className='txt-center'>{oaAll?.content?.length === 0 ? <p className='no-data-hg'> <b >{loading ? <Spinner /> : "No Data Found"}</b></p> : <b>Yay! You have seen it all</b>}</p>}
                                    refreshFunction={() => { }}
                                    pullDownToRefresh
                                    scrollableTarget="scrollableDiv"
                                    pullDownToRefreshThreshold={60}
                                    pullDownToRefreshContent={<h3 className='txt-center'>&#8595; Pull down to refresh</h3>}
                                    releaseToRefreshContent={<h3 className='txt-center'>&#8593; Release to refresh</h3>}
                                    onScroll={onscroll}
                                >
                                    <table class="table table-borderless uniq-table">
                                        <thead>
                                            <tr>
                                                <th>PO No</th>
                                                <th>PO Date</th>
                                                <th>OA No</th>
                                                <th>OA Date</th>
                                                <th>Customer</th>
                                                <th>Project</th>
                                                <th>Art No</th>
                                                <th>Product Name</th>
                                                <th className='txt-right'>OA Qty</th>
                                                <th className='txt-right'>Con. Qty</th>
                                                <th className='txt-center'>
                                                    <Checkbox onChange={onCheckAllChange} checked={checkAll} />
                                                </th>
                                                <th>Remark</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {oaAll?.content.map((item, a) => (
                                                <tr key={a} >
                                                    <td>{shortStr(item?.poNumber)}</td>
                                                    <td>{filterDateFormat(item?.poGeneratedDate)}</td>
                                                    <td>{item?.oaNumber}</td>
                                                    <td>{filterDateFormat(item?.oaDate)}</td>
                                                    <td>
                                                        <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled">  {item.customerName}</Tooltip>}>
                                                            <span className="d-inline-block value-add">
                                                                {item?.customerName}
                                                            </span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {item.projectName}</Tooltip>}>
                                                            <span className="d-inline-block value-add">
                                                                {item?.projectName}
                                                            </span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>{item?.articleId}</td>
                                                    <td className='product_name'>
                                                        <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {htmlTagRemove(item?.productName) || "-"}</Tooltip>}>
                                                            <span className="d-inline-block">
                                                                {htmlTagRemove(item?.productName) || "-"}
                                                            </span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td className='cell-txt-center'>{item.oaQuantity}</td>
                                                    <td className='cell-txt-center'>{item?.lastConfirmedQuantity}</td>
                                                    <td className='txt-center'> <Checkbox onChange={(e) => onChecboxChange(e, item)} checked={checkedList.includes(item.orderAcceptanceConfirmationId)} /></td>
                                                    <td>{item?.remark}</td>
                                                </tr>))}
                                        </tbody>
                                    </table>

                                </InfiniteScroll>
                            </div>
                        </Card>
                    </div>
                </div>
                <Modal
                    show={showPopup}
                    size="xl"
                    onHide={closePopup}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >
                    <div className="modal-staff preview">
                        <div className="modal-staff head">
                            <div>
                                <div onClick={closePopup}>
                                    <i class="fas fa-arrow-left"></i>
                                </div>
                                <h4 className="mod_head">
                                    OA Confirm
                                </h4>
                            </div>
                            <div>
                                <ReactToPrint
                                    trigger={() => <button className="select-template download"> <img src={download} alt="..." /> Download</button>}
                                    content={() => componentRef.current}
                                />
                            </div>
                        </div>
                        <Row className="preview-img " style={{ minHeight: "700px" }}>
                            <div className="go-hr"></div>
                            {pdfView !== null ? <div className="template-two-design" ref={componentRef} dangerouslySetInnerHTML={{ __html: `${pdfView}` }} /> :
                                <div>
                                    <img src={Logo} alt=".." className="spin rt-imgss" width="100px" />
                                    <h2 className="rt-img">Please Wait...</h2>
                                </div>}
                        </Row>
                    </div>
                </Modal>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    const { oa } = state;
    return {
        oaData: oa.oaAll,
        oaAll: { content: oa.newData },
        loading: oa.loading,
        csvData: oa.csvData,
        pdfView: oa.pdfView,
    }
}

const mapDispatchToProps = (dispatch) => ({
    oaListDispatch: (formData) => dispatch(listReportSubMenu(formData)),
    oaCsvDispatch: (formData) => dispatch(oaConfirmCsv(formData)),
    oaPdfDispatch: (formData) => dispatch(oaPdfView(formData)),
    oaPdfClearDispatch: (formData) => dispatch(clearPdfView(formData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OaConfirmPage)

