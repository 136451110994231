import { put, call, takeEvery } from "redux-saga/effects";
import {
    createCoProductApi,
    createVendorProductApi,
    deleteCoProductApi,
    deleteVendorProductApi,
    getCoProductListApi,
    getCoProjectListApi,
    getOutwardCsvApi,
    getOutwardLogsApi,
    getVendorProductListApi,
    getVendorProjectListApi,
    updateCoOutwardtApi,
    updateCoProductApi,
    updateOutwardReturnProductApi,
    updateReviewApi,
    updateVendorProductApi
} from "../services";
import { outwardConstants } from "../constants";

export function* getOutwardListSaga(payload) {
    try {
        const response = yield call(getOutwardLogsApi, payload.item);
        yield put({ type: outwardConstants.GET_OUTWARDS_LOGS_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.GET_OUTWARDS_LOGS_FAILURE, error });
    }
}
export function* createCoProductSaga(payload) {
    try {
        const response = yield call(createCoProductApi, payload);
        yield put({ type: outwardConstants.CREATE_CO_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.CREATE_CO_PRODUCT_FAILURE, error });
    }
}
export function* updateCoProductSaga(payload) {
    try {
        const response = yield call(updateCoProductApi, payload);
        yield put({ type: outwardConstants.EDIT_CO_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.EDIT_CO_PRODUCT_FAILURE, error });
    }
}
export function* deleteCoProductSaga(payload) {
    try {
        const response = yield call(deleteCoProductApi, payload.id);
        yield put({ type: outwardConstants.DELETE_CO_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.DELETE_CO_PRODUCT_FAILURE, error });
    }
}
export function* getCoProductSaga(payload) {
    try {
        const response = yield call(getCoProductListApi, payload);
        yield put({ type: outwardConstants.GET_CO_PRODUCT_LIST_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.GET_CO_PRODUCT_LIST_FAILURE, error });
    }
}
export function* createVendorProductSaga(payload) {
    try {
        const response = yield call(createVendorProductApi, payload);
        yield put({ type: outwardConstants.CREATE_VENDOR_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.CREATE_VENDOR_PRODUCT_FAILURE, error });
    }
}
export function* updateVendorProductSaga(payload) {
    try {
        const response = yield call(updateVendorProductApi, payload);
        yield put({ type: outwardConstants.EDIT_VENDOR_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.EDIT_VENDOR_PRODUCT_FAILURE, error });
    }
}
export function* deleteVendorProductSaga(payload) {
    try {
        const response = yield call(deleteVendorProductApi, payload.id);
        yield put({ type: outwardConstants.DELETE_VENDOR_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.DELETE_VENDOR_PRODUCT_FAILURE, error });
    }
}
export function* getVendorProductSaga(payload) {
    try {
        const response = yield call(getVendorProductListApi, payload);
        yield put({ type: outwardConstants.GET_VENDOR_PRODUCTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.GET_VENDOR_PRODUCTS_FAILURE, error });
    }
}
export function* getCoProjectSaga(payload) {
    try {
        const response = yield call(getCoProjectListApi, payload.id);
        yield put({ type: outwardConstants.GET_CO_PROJECTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.GET_CO_PROJECTS_FAILURE, error });
    }
}
export function* getVendorProjectSaga(payload) {
    try {
        const response = yield call(getVendorProjectListApi, payload.id);
        yield put({ type: outwardConstants.GET_VENDOR_PROJECTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.GET_VENDOR_PROJECTS_FAILURE, error });
    }
}
export function* updateCoOutwardSaga(payload) {
    try {
        const response = yield call(updateCoOutwardtApi, payload);
        yield put({ type: outwardConstants.UPDATE_CO_OUTWARD_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.UPDATE_CO_OUTWARD_FAILURE, error });
    }
}
export function* updateReviewSaga(payload) {
    try {
        const response = yield call(updateReviewApi, payload);
        yield put({ type: outwardConstants.EDIT_RETURN_REVIEW_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.EDIT_RETURN_REVIEW_FAILURE, error });
    }
}
export function* updateOutwardReturnProductSaga(payload) {
    try {
        const response = yield call(updateOutwardReturnProductApi, payload.id);
        yield put({ type: outwardConstants.UPDATE_RETURN_OUTWARD_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.UPDATE_RETURN_OUTWARD_PRODUCT_FAILURE, error });
    }
}
export function* downloadCsvSaga(payload) {
    try {
        const response = yield call(getOutwardCsvApi, payload.item);
        yield put({ type: outwardConstants.DOWNLOAD_OUTWARD_CSV_SUCCESS, response });
    } catch (error) {
        yield put({ type: outwardConstants.DOWNLOAD_OUTWARD_CSV_FAILURE, error });
    }
}
export default function* outwardSaga() {
    yield takeEvery(outwardConstants.GET_OUTWARDS_LOGS, getOutwardListSaga);
    yield takeEvery(outwardConstants.CREATE_CO_PRODUCT, createCoProductSaga);
    yield takeEvery(outwardConstants.EDIT_CO_PRODUCT, updateCoProductSaga);
    yield takeEvery(outwardConstants.DELETE_CO_PRODUCT, deleteCoProductSaga);
    yield takeEvery(outwardConstants.GET_CO_PRODUCT_LIST, getCoProductSaga);
    yield takeEvery(outwardConstants.GET_CO_PROJECTS, getCoProjectSaga);
    yield takeEvery(outwardConstants.GET_VENDOR_PROJECTS, getVendorProjectSaga);
    yield takeEvery(outwardConstants.UPDATE_CO_OUTWARD, updateCoOutwardSaga);
    yield takeEvery(outwardConstants.CREATE_VENDOR_PRODUCT, createVendorProductSaga);
    yield takeEvery(outwardConstants.EDIT_VENDOR_PRODUCT, updateVendorProductSaga);
    yield takeEvery(outwardConstants.DELETE_VENDOR_PRODUCT, deleteVendorProductSaga);
    yield takeEvery(outwardConstants.GET_VENDOR_PRODUCTS, getVendorProductSaga);
    yield takeEvery(outwardConstants.EDIT_RETURN_REVIEW, updateReviewSaga);
    yield takeEvery(outwardConstants.UPDATE_RETURN_OUTWARD_PRODUCT, updateOutwardReturnProductSaga);
    yield takeEvery(outwardConstants.DOWNLOAD_OUTWARD_CSV, downloadCsvSaga);
}

