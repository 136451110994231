import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import { vendorConstants } from "../constants";
import {
    getVendorApi,
    getVendorByIdApi,
    createVendorApi,
    updateVendorApi,
    getVendorSecondaryMailsApi,
    createVendorSecondaryEmailApi,
    updateVendorSecondaryEmailApi
} from "../services";

export function* vendorListSaga(payload) {
    try {
        const response = yield call(getVendorApi, payload.item);
        yield put({ type: vendorConstants.GET_VENDOR_SUCCESS, response });
    } catch (error) {
        yield put({ type: vendorConstants.GET_VENDOR_FAILURE, error });
    }
}

export function* singleVendorSaga(payload) {
    try {
        const response = yield call(getVendorByIdApi, payload.vendorId);
        yield put({ type: vendorConstants.GET_VENDOR_STAFF_SUCCESS, response });


    } catch (error) {
        yield put({ type: vendorConstants.GET_VENDOR_STAFF_FAILURE, error });
    }
}

export function* createVendorSaga(payload) {
    try {
        const response = yield call(createVendorApi, payload);
        yield put({ type: vendorConstants.CREATE_VENDOR_SUCCESS, response });
    } catch (error) {
        yield put({ type: vendorConstants.CREATE_VENDOR_FAILURE, error });
    }
}

export function* updateVendorSaga(payload) {
    try {
        const response = yield call(updateVendorApi, payload);
        yield put({ type: vendorConstants.UPDATE_VENDOR_SUCCESS, response });
    } catch (error) {
        yield put({ type: vendorConstants.UPDATE_VENDOR_FAILURE, error });
    }
}

export function* vendorSeondaryEmailsSaga(payload) {
    try {
        const response = yield call(getVendorSecondaryMailsApi, payload.id);
        yield put({ type: vendorConstants.GET_VENDOR_SECONDARY_MAIL_SUCCESS, response });
    } catch (error) {
        yield put({ type: vendorConstants.GET_VENDOR_SECONDARY_MAIL_FAILURE, error });
    }
}

export function* createVendorSecondaryEmailSaga(payload) {
    try {
        const response = yield call(createVendorSecondaryEmailApi, payload);
        yield put({ type: vendorConstants.CREATE_VENDOR_SECONDARY_MAIL_SUCCESS, response });
    } catch (error) {
        yield put({ type: vendorConstants.CREATE_VENDOR_SECONDARY_MAIL_FAILURE, error });
    }
}

export function* updateVendorSecondaryEmailSaga(payload) {
    try {
        const response = yield call(updateVendorSecondaryEmailApi, payload);
        yield put({ type: vendorConstants.UPDATE_VENDOR_SECONDARY_MAIL_SUCCESS, response });
    } catch (error) {
        yield put({ type: vendorConstants.UPDATE_VENDOR_SECONDARY_MAIL_FAILURE, error });
    }
}


export default function* vendorSaga() {
    yield takeEvery(vendorConstants.GET_VENDOR, vendorListSaga);
    yield takeEvery(vendorConstants.GET_VENDOR_STAFF, singleVendorSaga);
    yield takeLatest(vendorConstants.CREATE_VENDOR_INIT, createVendorSaga);
    yield takeLatest(vendorConstants.UPDATE_VENDOR_INIT, updateVendorSaga);
    yield takeLatest(vendorConstants.GET_VENDOR_SECONDARY_MAIL, vendorSeondaryEmailsSaga);
    yield takeLatest(vendorConstants.CREATE_VENDOR_SECONDARY_MAIL_INIT, createVendorSecondaryEmailSaga);
    yield takeLatest(vendorConstants.UPDATE_VENDOR_SECONDARY_MAIL_INIT, updateVendorSecondaryEmailSaga);

}