import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../assets/css/privacy.css";




function Terms_Condition() {
  return (

    <div class="c15 s-new">
      <p class="c9">
        <Link to="/"> <span className="arg">&nbsp; <i class="fas fa-arrow-left"></i></span></Link>
        <span class="c10">IDS Group</span
        ><br></br><span class="c7">&nbsp;Terms and Conditions</span>
      </p>
      <p class="c16">
        <span class="c3"
        >Last Changes to Terms and Conditions: March 20, 2022</span
        >
      </p>
      <h2 class="c13" id="h.no366yi90oor">
        <span class="c2">1. Introduction</span>
      </h2>
      <p class="c4">
        <span class="c0"
        >These Website Standard Terms And Conditions (these &ldquo;Terms&rdquo;
          or these &ldquo;Website Standard Terms And Conditions&rdquo;) contained
          herein on this webpage, shall govern your use of this website, including
          all pages within this website (collectively referred to herein below as
          this &ldquo;Website&rdquo;). These Terms apply in full force and effect
          to your use of this Website and by using this Website, you expressly
          accept all terms and conditions contained herein in full. You must not
          use this Website, if you have any objection to any of these Website
          Standard Terms And Conditions.</span
        >
      </p>
      <p class="c4">
        <span class="c0"
        >This Website is not for use by any minors (defined as those who are not
          at least 16 years of age), and you must not use this Website if you are
          a minor.</span
        >
      </p>
      <h2 class="c1" id="h.ttk2vxlan99m">
        <span class="c2">2. Intellectual Property Rights</span>
      </h2>
      <p class="c4">
        <span class="c0"
        >Other than content you own, which you may have opted to include on this
          Website, under these Terms, Innovative Door Solution Group and/or its
          licensors own all rights to the intellectual property and material
          contained in this Website, and all such rights are reserved. You are
          granted a limited license only, subject to the restrictions provided in
          these Terms, for purposes of viewing the material contained on this
          Website.</span
        >
      </p>
      <h2 class="c1" id="h.gavc60wdftyl">
        <span class="c2">3. Restrictions</span>
      </h2>
      <p class="c4">
        <span class="c0"
        >You are expressly and emphatically restricted from all of the
          following:</span
        >
      </p>
      <ol class="c8 lst-kix_r07u1f46347-0 start" start="1">
        <li class="c6 li-bullet-0">
          <span class="c0">publishing any Website material in any media;</span>
        </li>
        <li class="c6 li-bullet-0">
          <span class="c0"
          >selling, sublicensing and/or otherwise commercializing any Website
            material;</span
          >
        </li>
        <li class="c6 li-bullet-0">
          <span class="c0"
          >publicly performing and/or showing any Website material;</span
          >
        </li>
        <li class="c6 li-bullet-0">
          <span class="c0"
          >using this Website in any way that is, or may be, damaging to this
            Website;</span
          >
        </li>
        <li class="c6 li-bullet-0">
          <span class="c0"
          >using this Website in any way that impacts user access to this
            Website;</span
          >
        </li>
        <li class="c6 li-bullet-0">
          <span class="c0"
          >using this Website contrary to applicable laws and regulations, or in
            a way that causes, or may cause, harm to the Website, or to any person
            or business entity;</span
          >
        </li>
        <li class="c6 li-bullet-0">
          <span class="c0"
          >engaging in any data mining, data harvesting, data extracting or any
            other similar activity in relation to this Website, or while using
            this Website;</span
          >
        </li>
        <li class="c6 li-bullet-0">
          <span class="c0"
          >using this Website to engage in any advertising or marketing;</span
          >
        </li>
      </ol>
      <p class="c4">
        <span class="c0"
        >Certain areas of this Website are restricted from access by you and
          Innovative Door Solution Group may further restrict access by you to any
          areas of this Website, at any time, in its sole and absolute discretion.
          Any user ID and password you may have for this Website are confidential
          and you must maintain confidentiality of such information.</span
        >
      </p>
      <h2 class="c1" id="h.h1n7ptxws318">
        <span class="c2">4. Your Content</span>
      </h2>
      <p class="c4">
        <span class="c0"
        >In these Website Standard Terms And Conditions, &ldquo;Your
          Content&rdquo; shall mean any audio, video, text, images or other
          material you choose to display on this Website. With respect to Your
          Content, by displaying it, you grant Innovative Door Solution Group a
          non-exclusive, worldwide, irrevocable, royalty-free, sublicensable
          license to use, reproduce, adapt, publish, translate and distribute it
          in any and all media.</span
        >
      </p>
      <p class="c4">
        <span class="c0"
        >Your Content must be your own and must not be infringing on any third
          party&rsquo;s rights. Innovative Door Solution Group reserves the right
          to remove any of Your Content from this Website at any time, and for any
          reason, without notice.</span
        >
      </p>
      <h2 class="c1" id="h.ltiicx2z4y6m">
        <span class="c2">5. No warranties</span>
      </h2>
      <p class="c4">
        <span class="c0"
        >This Website is provided &ldquo;as is,&rdquo; with all faults, and
          Innovative Door Solution Group makes no express or implied
          representations or warranties, of any kind related to this Website or
          the materials contained on this Website. Additionally, nothing contained
          on this Website shall be construed as providing consult or advice to
          you.</span
        >
      </p>
      <h2 class="c1" id="h.d9xrclkqi5kc">
        <span class="c2">6. Limitation of liability</span>
      </h2>
      <p class="c4">
        <span class="c0"
        >In no event shall Innovative Door Solution Group , nor any of its
          officers, directors and employees, be liable to you for anything arising
          out of or in any way connected with your use of this Website, whether
          such liability is under contract, tort or otherwise, and Innovative Door
          Solution Group , including its officers, directors and employees shall
          not be liable for any indirect, consequential or special liability
          arising out of or in any way related to your use of this Website.</span
        >
      </p>
      <h2 class="c1" id="h.gqeqq85bktrz">
        <span class="c2">7. Indemnification</span>
      </h2>
      <p class="c4">
        <span class="c0"
        >You hereby indemnify to the fullest extent Innovative Door Solution
          Group from and against any and all liabilities, costs, demands, causes
          of action, damages and expenses (including reasonable attorney&rsquo;s
          fees) arising out of or in any way related to your breach of any of the
          provisions of these Terms.</span
        >
      </p>
      <h2 class="c1" id="h.odi1zncubgyr">
        <span class="c2">8. Severability</span>
      </h2>
      <p class="c4">
        <span class="c0"
        >If any provision of these Terms is found to be unenforceable or invalid
          under any applicable law, such unenforceability or invalidity shall not
          render these Terms unenforceable or invalid as a whole, and such
          provisions shall be deleted without affecting the remaining provisions
          herein.</span
        >
      </p>
      <h2 class="c1" id="h.t0hd8gz4e58m">
        <span class="c2">9. Variation of Terms</span>
      </h2>
      <p class="c4">
        <span class="c0"
        >Innovative Door Solution Group is permitted to revise these Terms at
          any time as it sees fit, and by using this Website you are expected to
          review such Terms on a regular basis to ensure you understand all terms
          and conditions governing use of this Website.</span
        >
      </p>
      <h2 class="c1" id="h.b84rhab0ev11">
        <span class="c2">10. Assignment</span>
      </h2>
      <p class="c4">
        <span class="c0"
        >Innovative Door Solution Group shall be permitted to assign, transfer,
          and subcontract its rights and/or obligations under these Terms without
          any notification or consent required. However, .you shall not be
          permitted to assign, transfer, or subcontract any of your rights and/or
          obligations under these Terms.</span
        >
      </p>
      <h2 class="c1" id="h.u6fpnotwc7y1">
        <span class="c2">11. Entire Agreement</span>
      </h2>
      <p class="c4">
        <span class="c0"
        >These Terms, including any legal notices and disclaimers contained on
          this Website, constitute the entire agreement between Innovative Door
          Solution Group and you in relation to your use of this Website, and
          supersede all prior agreements and understandings with respect to the
          same.</span
        >
      </p>
      <h2 class="c1" id="h.yffg5wsczsm1">
        <span class="c2">12. Governing Law &amp; Jurisdiction</span>
      </h2>
      <p class="c4">
        <span class="c11"
        >These Terms will be governed by and construed in accordance with the
          laws of the State of Tamil Nadu, and you submit to the non-exclusive
          jurisdiction of the state and federal courts located in Tamil Nadu for
          the resolution of any disputes.</span
        >
      </p>
      <p class="c5"><span class="c12"></span></p>
    </div>
  )
}
export default Terms_Condition;
