import { put, call, takeEvery } from "redux-saga/effects";
import { getVerifiedProjectsApi, getInwardLogsApi, addReturnInwardProductApi, getInwardCsvApi, getReceivedInwardsApi, getYetToReceiveCsvApi } from "../services";
import { inwardConstants } from "../constants";

export function* getVerifiedProjectSaga(payload) {
    try {
        const response = yield call(getVerifiedProjectsApi, payload.productId);
        yield put({ type: inwardConstants.GET_VERIFIED_PROJECTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: inwardConstants.GET_VERIFIED_PROJECTS_FAILURE, error });
    }
}
export function* getInwardListSaga(payload) {
    try {
        const response = yield call(getInwardLogsApi, payload.item);
        yield put({ type: inwardConstants.GET_INWARD_LOGS_SUCCESS, response });
    } catch (error) {
        yield put({ type: inwardConstants.GET_INWARD_LOGS_FAILURE, error });
    }
}
export function* getReceivedInwardSaga(payload) {
    try {
        const response = yield call(getReceivedInwardsApi, payload.item);
        yield put({ type: inwardConstants.GET_RECEIVED_INWARD_SUCCESS, response });
    } catch (error) {
        yield put({ type: inwardConstants.GET_RECEIVED_INWARD_FAILURE, error });
    }
}
export function* addReturnInwardSaga(payload) {
    try {
        const response = yield call(addReturnInwardProductApi, payload);
        yield put({ type: inwardConstants.ADD_RETURN_INWARD_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: inwardConstants.ADD_RETURN_INWARD_PRODUCT_FAILURE, error });
    }
}
export function* downloadCsvSaga(payload) {
    try {
        const response = yield call(getInwardCsvApi, payload.item);
        yield put({ type: inwardConstants.DOWNLOAD_INWARD_CSV_SUCCESS, response });
    } catch (error) {
        yield put({ type: inwardConstants.DOWNLOAD_INWARD_CSV_FAILURE, error });
    }
}
export function* downloadYetToReceiveCsvSaga(payload) {
    try {
        const response = yield call(getYetToReceiveCsvApi, payload.item);
        yield put({ type: inwardConstants.DOWNLOAD_YET_TO_RECEIVE_CSV_SUCCESS, response });
    } catch (error) {
        yield put({ type: inwardConstants.DOWNLOAD_YET_TO_RECEIVE_CSV_FAILURE, error });
    }
}
export default function* inwardSaga() {
    yield takeEvery(inwardConstants.GET_VERIFIED_PROJECTS, getVerifiedProjectSaga);
    yield takeEvery(inwardConstants.GET_INWARD_LOGS, getInwardListSaga);
    yield takeEvery(inwardConstants.GET_RECEIVED_INWARD, getReceivedInwardSaga);
    yield takeEvery(inwardConstants.ADD_RETURN_INWARD_PRODUCT_INIT, addReturnInwardSaga);
    yield takeEvery(inwardConstants.DOWNLOAD_INWARD_CSV, downloadCsvSaga);
    yield takeEvery(inwardConstants.DOWNLOAD_YET_TO_RECEIVE_CSV, downloadYetToReceiveCsvSaga);

}