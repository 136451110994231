import React from 'react'
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { createPoInwardProducts, showFixedDigitsValue, showStockValue } from '../../../actions';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import Spinner from '../../../Components/loader';

class InwardProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            quotationId: "",
            items: [],
            inwardData: [],
            index: null,
            newArr: [],
            inwardValues: []
        }
        this.productHandler = this.productHandler.bind(this);
        this.selectHandler = this.selectHandler.bind(this);
        this.selectProHandler = this.selectProHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }
    selectHandler(e) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((i) => i = e.target.checked);
        this.setState({
            openedItem: newItems,
        })
    }
    selectProHandler(e, index) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((item, i) => index === i ? e.target.checked : item);
        this.setState({
            openedItem: newItems
        })
    }
    changeHandler = (e, i) => {
        const { data } = this.state;
        let a = Number(data[i].balance);
        let b = e.target.value;
        if (b && !/^([0-9]{1,5})+(\.[0-9]{0,2})?$/i.test(b)) {
            toast("Enter valid digits Ex(19.00,23456.45,200.34)");
            return false;
        }
        if (+b <= a) {
            data[i].inwardQuantity = b;
            this.setState({
                data: data,
                index: i
            })
        } else {
            toast("Pls enter the number less than or equal to " + a);
        }
    }
    productHandler(e) {
        this.props.inwardData?.map((item) => {
            if (item?.purchaseOrderId == e.target.value) {
                let data = [];
                item?.projectList.map((val) => {
                    data.push(val);
                })
                this.setState({
                    data: data,
                    purchaseOrderId: item?.purchaseOrderId
                })
            }
        });
    }
    submitHandler() {
        const { data } = this.state;
        let pro = [];
        data?.map((item, i) => {
            let items = {};
            if (Number(item.inwardQuantity) !== 0) {
                items["projectProductId"] = item.purchaseProjectProductId;
                items["stockQty"] = item.inwardQuantity;
                pro.push(items);
            }
        });
        this.props.dispatch(createPoInwardProducts(pro));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.inwardProductsSuccess !== this.props.inwardProductsSuccess) {
            if (this.props.inwardProductsSuccess.status === 200) {
                this.props.listAllData();
                this.setState({
                    data: []
                })
                this.props.onHide();
                toast(this.props.inwardProductsSuccess.data);
            } else {
                toast(this.props.inwardProductsSuccess.data);
            }
        } if (prevProps.inwardData !== this.props.inwardData) {
            this.props.inwardData.filter((item) => {
                if (item.purchaseOrderId === this.props.id) {
                    const values = item.projectProductList?.map((i) => i.inwardQuantity);
                    this.setState({
                        data: item.projectProductList,
                        inwardValues: values
                    })
                }
            });
        }
    }

    render() {
        const { show, onHide, load, id } = this.props;
        const { data, inwardValues } = this.state;
        return (
            <>
                <Modal
                    show={show}
                    size="xl"
                    onHide={() => {
                        onHide();
                        this.setState({
                            data: []
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-staff">
                        <div className="head">
                            <div>
                                <i class="fas fa-arrow-left" onClick={() => {
                                    onHide();
                                }}></i>
                                <h4>INWARD PRODUCTS</h4>
                            </div>
                        </div>
                        {/* <select name="referenceId" className='form-control  mt-4' onChange={(e) => this.productHandler(e)} style={{ margin: "0 30px", width: "95%" }}>
                            <option value="">Select One</option>
                            {inwardData ?? inwardData?.length > 0 ? inwardData?.map((unit, i) => (
                                <option value={unit?.purchaseOrderId} key={i}>{unit?.purchaseOrderReferenceNumber}</option>)) : ""}
                        </select> */}
                        <div className="table-class space-part">
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Project
                                        </th>
                                        <th scope="col">
                                            Article ID
                                        </th>
                                        <th scope="col">
                                            Product
                                        </th>
                                        <th scope="col" style={{ textAlign: "center" }}>
                                            PO Qty
                                        </th>
                                        <th scope="col" style={{ textAlign: "center" }}>
                                            Inward
                                        </th>
                                        <th scope="col" style={{ textAlign: "center" }}>
                                            Balance
                                        </th>
                                        <th scope="col" style={{ textAlign: "center" }}>
                                            Available Stock
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.length !== 0 ? data?.map((item, i) => (
                                        <tr key={i} >
                                            <td className="new_bulk_size">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item?.projectName || "-"}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        {item?.projectName || "-"}
                                                    </span>
                                                </OverlayTrigger>
                                            </td>
                                            <td>{item?.articleId || "-"}</td>
                                            <td className="new_bulk_size">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.productName || "-")}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        {parse(item.productName || "-")}
                                                    </span>
                                                </OverlayTrigger>
                                            </td>
                                            <td style={{ textAlign: "center" }}>{item?.quantity}</td>
                                            <td style={{ textAlign: "center" }}>
                                                <input type="text"
                                                    className='convert_po_input'
                                                    value={item?.inwardQuantity}
                                                    onChange={(e) => this.changeHandler(e, i)} /></td>
                                            <td style={{ textAlign: "center" }}> {`${inwardValues[i] - item.inwardQuantity === 0 ? 0 : showFixedDigitsValue(inwardValues[i], item.inwardQuantity)}`} </td>
                                            <td style={{ textAlign: "center" }}> {`${showStockValue(item.inwardQuantity, item?.availableStock)}`}</td>
                                        </tr >
                                    )) : !this.props.loading ?
                                        <h1 className="empty-data">No Records Found</h1> :
                                        <Spinner />
                                    }
                                </tbody>
                            </table >
                        </div >
                        <center style={{ padding: "15px" }}>
                            {!load ?
                                <button className="customer-btn col-md-6" disabled={data.length === 0} onClick={() => this.submitHandler()}>Inward Products</button>
                                : <button className="customer-btn col-md-6">Loading...</button>}
                        </center >
                    </div >
                </Modal >
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        convertBoqList: state.boq.convertBoqList,
        inwardData: state.po.inwardData,
        load: state.po.load,
        loading: state.po.loading,
        confirmQuotDropdown: state.boq.confirmQuotDropdown,
        confirmQuotProduct: state.boq.confirmQuotProduct,
        addInwardData: state.po.addInwardData,
        inwardProductsSuccess: state.po.inwardProductsSuccess
    }
}

export default connect(mapStateToProps)(InwardProducts);
