import React from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import Navbar from "../../layouts/Navbar";
import Sidebar from "../../layouts/Sidebar";
import Filter from "../../assets/images/filter.svg";
import Plus from "../../assets/images/pl.svg";
import cust from "../../assets/images/green1.svg";
import Search from "../../assets/images/search.svg";
import { connect } from "react-redux";
import {
    getVendorList,
    getVendorById,
    createVendor,
    updateVendor,
    changePage,
    getExecutiveUrl,
    createVendorSecondaryEmail,
    getVendorSecondaryEmailList,
    updateVendorSecondaryEmail
} from "../../actions";
import Spinner from "../../Components/loader";
import VendorForm from "./VendorForm";
import Pagination from "../../Components/Pagination";
import { toast } from "react-toastify";
import { reset, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import SecondaryEmailForm from "./SecondaryEmailForm";
import Add from "../../assets/images/add-ss.png";

const role = localStorage.getItem('userRole');

class Vendor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vendor: [],
            show: false,
            secondaryPopup: false,
            success_msg: '',
            allData: [],
            sortType: "asc",
            searchKey: "",
            vendorId: null,
            sortField: "",
            pageNo: 0,
            filterData: {},
            sort: {},
            empty: "",
            secondaryStr: null
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
        this.sortHandler = this.sortHandler.bind(this);
        this.secondaryMailOpen = this.secondaryMailOpen.bind(this);
        this.secondaryMailClose = this.secondaryMailClose.bind(this);
        this.secondaryMailSubmit = this.secondaryMailSubmit.bind(this);

    }
    handleShow() {
        this.props.dispatch(changePage());
        this.setState({ show: true, empty: "create" });
    }
    handleClose() {
        this.setState({ show: false });
    }
    secondaryMailOpen(item) {
        console.log(item);
        this.props.dispatch(getVendorSecondaryEmailList(item.vendorId));
        this.setState({
            secondaryPopup: true,
            vendorId: item.vendorId,
            secondaryStr: item.isSecondaryEmail
        })
    }
    secondaryMailClose() {
        this.setState({
            secondaryPopup: false
        })
    }
    secondaryMailSubmit(formData) {
        if (!formData.email0) {
            throw new SubmissionError({
                email0: 'Please fill the field *',
            })
        }
        const data = [];
        Object.values(formData).map(function (key, index) {
            if (key !== undefined && key !== "") {
                data.push(key);
            }
        });
        let submitData = {
            vendorId: this.state.vendorId,
            vendorEmails: data
        }
        if (this.state.secondaryStr) {
            this.props.dispatch(updateVendorSecondaryEmail(submitData));
        } else {
            this.props.dispatch(createVendorSecondaryEmail(submitData));
        }
    }
    handleSubmit(formData, dispatch) {
        if (!formData.firstName) {
            throw new SubmissionError({
                firstName: 'Please fill the field *',
            })
        } else if (formData?.firstName?.trim().length === 0) {
            throw new SubmissionError({
                firstName: 'Please fill the field *',
            })
        }
        if (formData) {
            if (this.props.page === "edit") {
                this.props.dispatch(updateVendor(formData));
            } else {
                this.props.dispatch(createVendor(formData));
                dispatch(reset("vendorForm"));
            }
        } else {
            toast("Please fill all the required fields");
        }
    }
    handleEdit(row) {
        this.setState({
            vendor: row,
            show: true,
            vendor: row,
            empty: "edit"
        });
        this.props.dispatch(getVendorById(row.vendorId));
    }
    onChange(pageNo) {
        const { filterData, searchKey, sortField, sortType } = this.state;
        this.setState({
            pageNo: pageNo
        })
        const url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
        this.props.dispatch(getVendorList(url));
    }
    searchHandler(e) {
        const { filterData, sortField, sortType } = this.state;
        this.setState({
            searchKey: e.target.value
        });
        const url = getExecutiveUrl(0, e.target.value, filterData, sortField, sortType);
        this.props.dispatch(getVendorList(url));
    }
    sortHandler(sortField) {
        const { pageNo, searchKey, filterData } = this.state;
        this.setState({
            sortField: sortField
        });
        if (this.state.sortType === 'desc') {
            this.setState({
                sortType: 'asc'
            })
        } else {
            this.setState({
                sortType: 'desc'
            })
        };
        let sort = {
            sortType: this.state.sortType,
            sortField: sortField
        }
        this.setState({
            sort: sort
        });
        let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, this.state.sortType);
        this.props.dispatch(getVendorList(url));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.allData !== this.props.allData) {
            this.setState({
                allData: this.props.allData,
                pageNo: this.props.allData?.pageable?.pageNumber
            });
        } if (prevProps.success_msg !== this.props.success_msg) {
            this.setState({
                success_msg: this.props.success_msg,
            });
            const { pageNo, searchKey, sort, sortField } = this.state;
            if (this.props.success_msg.status === 200) {
                this.setState({
                    show: false
                });
                toast(this.props.success_msg.data);
                if (sortField === "") {
                    this.props.dispatch(getVendorList(pageNo, searchKey));
                } else {
                    this.props.dispatch(getVendorList(pageNo, searchKey, sort));
                }
            } else {
                toast(this.props.success_msg.data);
            }
        } if (prevProps.mail_success_msg !== this.props.mail_success_msg) {
            console.log(this.props.mail_success_msg);
            const { pageNo, searchKey, sort, sortField } = this.state;
            if (this.props.mail_success_msg.status === 200) {
                this.setState({
                    secondaryPopup: false
                });
                toast(this.props.mail_success_msg.data);
                if (sortField === "") {
                    this.props.dispatch(getVendorList(pageNo, searchKey));
                } else {
                    this.props.dispatch(getVendorList(pageNo, searchKey, sort));
                }
            } else {
                toast(this.props.mail_success_msg.data);
            }
        }
    }
    componentDidMount() {
        this.props.dispatch(getVendorList("page=0"));
    }
    render() {
        const {
            show,
            allData,
            empty,
            secondaryPopup
        } = this.state;

        let data = {
            totalElements: allData.totalElements,
            totalPages: allData.totalPages,
            offset: allData?.pageable?.offset,
            numberOfElements: allData.numberOfElements,
            num: (allData?.number) + 1
        }


        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <div className="page-head-split">
                                <div className="head-colums">
                                    <div className="head-part-tab">
                                        <img src={cust} alt="..." className="head-imgs" />
                                        <h5> IDS Directory Vendor</h5>
                                    </div>
                                    <div className="tab-links">
                                        <Link to={"/" + role + "/customers"} >Customers</Link>
                                        {role === "admin" ? <Link to={"/" + role + "/customers/staff"}>Staff</Link> : null}
                                        <Link to={"/" + role + "/customers/vendor"} className="active">Vendors</Link>
                                    </div>
                                    <div className="customer-search">
                                        <span><img src={Search} alt=".." className="search-img" /></span>
                                        <Form.Control type="search" placeholder="Search" onChange={this.searchHandler} className="main-search" />
                                    </div>
                                    <VendorForm
                                        handleShow={this.handleShow}
                                        show={show}
                                        onHide={this.handleClose}
                                        onSubmit={this.handleSubmit}

                                        empty={empty}
                                    />
                                </div>
                            </div>
                            <div className="table-class">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                Name <img onClick={(e) => this.sortHandler("v.first_name")} src={Filter} alt="..." />
                                            </th>
                                            <th scope="col">
                                                Location <img src={Filter} alt="..." onClick={(e) => this.sortHandler("v.city")} />
                                            </th>
                                            <th scope="col">
                                                Contact <img src={Filter} alt="..." onClick={(e) => this.sortHandler("v.contact_number")} />
                                            </th>
                                            <th scope="col">
                                                Primary Email Address<img src={Filter} alt="..." onClick={(e) => this.sortHandler("v.email")} />
                                            </th>
                                            <th scope="col">
                                                Secondary Email Address<img src={Filter} alt="..." onClick={(e) => this.sortHandler("v.email")} />
                                            </th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <>
                                            {allData.content && allData.content.length > 0 ? allData.content.map((item, i) => (
                                                <tr key={i} >
                                                    <td onClick={() => this.handleEdit(item)} style={{ cursor: "pointer" }}>{item.name}</td>
                                                    <td onClick={() => this.handleEdit(item)} style={{ cursor: "pointer" }}>{item.location || <div className="center_align"><p>-</p></div>}</td>
                                                    <td onClick={() => this.handleEdit(item)} style={{ cursor: "pointer" }}>{item.contact !== null ? `+91 ${item.contact}` : "" || <div className="center_align"><p>-</p></div>}</td>
                                                    <td onClick={() => this.handleEdit(item)} style={{ cursor: "pointer" }}>{item.email || <div className="center_align"><p>-</p></div>}</td>
                                                    <td className="adds-email" onClick={() => this.secondaryMailOpen(item)}>
                                                        {/* <img src={Add}/> */}
                                                        {item.isSecondaryEmail ? "Update" : "Add"} Secondary Email
                                                    </td>
                                                    <td>
                                                        <button className="Edit-staff" onClick={() => this.handleEdit(item)}>
                                                            Edit
                                                        </button>
                                                    </td>
                                                </tr>
                                            )) : !this.props.loading ?
                                                <h1 className="empty-data">No Records Found</h1> :
                                                <Spinner />
                                            }
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                        <SecondaryEmailForm
                            show={secondaryPopup}
                            onHide={this.secondaryMailClose}
                            onSubmit={this.secondaryMailSubmit}
                            str={this.state.secondaryStr}
                        />
                        <Pagination data={data} onChange={this.onChange} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.vendor.loading,
        page: state.vendor.page,
        success_msg: state.vendor.success_msg,
        allData: state.vendor.allData,
        vendor: state.vendor.vendor,
        mail_success_msg: state.vendor.mail_success_msg,
        vendor_emails: state.vendor.vendor_emails?.vendorEmails

    }
}

export default connect(mapStateToProps)(Vendor);




