import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { reduxForm, Field } from 'redux-form';
import Form from 'react-bootstrap/Form';
import { connect } from "react-redux";
import validate from "./validate";
import Select from 'react-select';
import Rock from "../assets/images/rock.png";
import Mi from "../assets/images/mi.png";
import Orange from "../assets/images/rou.gif";


const renderField = ({ input, placeholder, type, meta: { touched, error } }) => (
    <div  >
        <label></label>
        <div>
            <input  {...input} placeholder={placeholder} type={type} className=" dis-part" />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
)

function BoqEmailPopUp(props) {
    const { show, onHide, mailData, handleSubmit, load, getCC } = props;
    const [items, setItems] = useState([]);
    const productHandler = (e) => {
        getCC(e)
    }
    useEffect(() => {
        if (mailData) {
            let values = []
            mailData?.cc?.forEach((item) => {
                values.push({
                    "value": item,
                    "label": item
                });
            })
            setItems(values);
        }
    }, [mailData]);


    return (
        <>
            <Modal show={show} onHide={onHide} size="md" className="email-body">
                <form onSubmit={handleSubmit}>
                    <Modal.Header className="orange-head">
                        <Modal.Title><img src={Mi} alt=".." className="mi" />  EMAIL</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Control
                                className=" dis-part"
                                name="from"
                                placeholder={`From : ${mailData?.from}`}
                                disabled />
                        </Form.Group>
                        <Form.Group className="mb-1 mail-border" controlId="formBasicEmail">
                            <span>To :</span>
                            <Field
                                name="to"
                                type="text"
                                component={renderField}
                            // placeholder="To :"
                            />
                        </Form.Group>
                        <Form.Group className="mb-1 select-border" controlId="formBasicEmail">
                            <span>CC :</span>
                            <Select
                                // placeholder="Search a name"
                                onChange={(e) => productHandler(e)}
                                options={items}
                                isMulti
                                isSearchable={true}
                            />
                        </Form.Group>
                        <Form.Group className="mb-1 sub-border" controlId="formBasicEmail">
                            <span>Subject :</span>
                            <Field
                                name="subject"
                                type="text"
                                component={renderField}
                            // placeholder="Subject"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="close-btn" onClick={onHide}>
                            Close
                        </button>
                        {load ? <button type="button" className="sent-button" >
                              <img src={Orange} alt=".." className="roc" />
                        </button>
                            : <button type="submit" className="sent-button" >
                                Send <img src={Rock} alt=".." className="roc" />
                            </button>}
                    </Modal.Footer>
                </form>

            </Modal>
        </>
    );
}

function mapStateToProps(state) {
    return {
        mailData: state.boq.mailData,
        initialValues: state.boq.mailData,
        load: state.boq.load
    }
}

const SecondaryForm = reduxForm({
    form: "SecondaryEmailForm",
    enableReinitialize: true,
    validate
})(BoqEmailPopUp);

export default connect(mapStateToProps)(SecondaryForm);
