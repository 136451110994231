import { put, call, takeEvery } from "redux-saga/effects";
import { staffConstants } from "../constants";
import { addStaffApi, getStaffApi, getStaffByIdApi, updateStaffApi, updateStaffStatusApi } from "../services";

export function* stafflistSaga(payload) {
    try {
        const response = yield call(getStaffApi, payload.pageNo, payload.searchKey, payload.sort, payload.filterData);
        yield put({ type: staffConstants.GET_STAFF_SUCCESS, response });

    } catch (error) {
        yield put({ type: staffConstants.GET_STAFF_FAILURE, error });
    }
}
export function* addStaffSaga(payload) {
    try {
        const response = yield call(addStaffApi, payload.formData);
        yield put({ type: staffConstants.STAFF_SUCCESS, response });

        // yield put({ type: staffConstants.GET_STAFF, stafflistSaga })
    } catch (error) {
        yield put({ type: staffConstants.STAFF_FAILURE, error });
    }
}
export function* singleStaffSaga(payload) {
    try {
        const response = yield call(getStaffByIdApi, payload.staffId);
        yield put({ type: staffConstants.GET_SINGLE_STAFF_SUCCESS, response });


    } catch (error) {
        yield put({ type: staffConstants.GET_SINGLE_STAFF_FAILURE, error });
    }
}
export function* updateStaffSaga(payload) {
    try {
        const response = yield call(updateStaffApi, payload.formData);
        yield put({ type: staffConstants.UPDATE_STAFF_SUCCESS, response });
    } catch (error) {
        yield put({ type: staffConstants.UPDATE_STAFF_FAILURE, error });
    }
}
export function* updateStaffStatusSaga(payload) {
    try {
        const response = yield call(updateStaffStatusApi, payload.userId, payload.status);
        yield put({ type: staffConstants.UPDATE_STAFF_STATUS_SUCCESS, response });

        // yield put({ type: staffConstants.GET_STAFF, stafflistSaga })
    } catch (error) {
        yield put({ type: staffConstants.UPDATE_STAFF_STATUS_FAILURE, error });
    }
}


export default function* staffSaga() {
    yield takeEvery(staffConstants.GET_STAFF, stafflistSaga);
    yield takeEvery(staffConstants.STAFF_REQUEST, addStaffSaga);
    yield takeEvery(staffConstants.GET_SINGLE_STAFF, singleStaffSaga);
    yield takeEvery(staffConstants.UPDATE_STAFF_REQUEST, updateStaffSaga);
    yield takeEvery(staffConstants.UPDATE_STAFF_STATUS_REQUEST, updateStaffStatusSaga);
}
