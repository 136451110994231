import { billofquantityConstants } from "../constants";

const initialState = {
    loading: false,
    load: false,
    productLoad: false,
    data: [],
    error: [],
    boqData: [],
    projectSuccess: [],
    convertBoqList: [],
    confirmQuotDropdown: [],
    confirmQuotProduct: [],
    boqProjectData: [],
    footerList: [],
    footerSucess: [],
    deleteFooter: [],
    previewBoqText: "",
    boqIdData: [],
    boqList: [],
    boqProductSuccess: [],
    deleteBoqProduct: [],
    generateData: [],
    sendData: [],
    cloneData: [],
    reviseData: [],
    deleteBoq: [],
    customProductSuccess: [],
    projectOutwardData: [],
    internalTransferData: [],
    deliverBoqData: [],
    itSuccess: [],
    boqProductData: [],
    previewPackingSlipText: "",
    mailData: [],
    boqMailSuccess: [],
    csvDataDownload: null
};

export default function boqReducer(state = initialState, { type, response }) {
    switch (type) {
        case billofquantityConstants.GET_BILL_OF_QUANTITY:
        case billofquantityConstants.GET_CONVERT_BOQ_TO_PO:
        case billofquantityConstants.GET_BOQ_PROJECTS:
        case billofquantityConstants.GET_CONFIRM_QUOTATION_DROPDOWN:
        case billofquantityConstants.GET_CONFIRM_QUOTATION_PRODUCTS:
        case billofquantityConstants.GET_BOQ_BY_ID:
        case billofquantityConstants.GET_BOQ_MAIL_DETAILS:
            return {
                ...state,
                loading: true,
            };
        case billofquantityConstants.CREATE_BOQ:
        case billofquantityConstants.CREATE_BOQ_PROJECT:
        case billofquantityConstants.EDIT_BOQ_PROJECT:
        case billofquantityConstants.PREVIEW_BOQ:
        case billofquantityConstants.CREATE_BILL_OF_QUANTITY_INIT:
        case billofquantityConstants.UPDATE_BILL_OF_QUANTITY_INIT:
        case billofquantityConstants.GENERATE_BOQ:
        case billofquantityConstants.SENT_BOQ:
        case billofquantityConstants.CLONE_BOQ:
        case billofquantityConstants.DELETE_BOQ:
        case billofquantityConstants.REVISE_BOQ:
        case billofquantityConstants.GET_PROJECT_OUTWARD:
        case billofquantityConstants.GET_PROJECT_OUTWARD_V2:
        case billofquantityConstants.GET_INTERNAL_TRANSFER_PRODUCT:
        case billofquantityConstants.GET_BOQ_PRODUCT_DETAILS:
        case billofquantityConstants.PREVIEW_PACKING_SLIP:
        case billofquantityConstants.SENT_BOQ_MAIL:
            return {
                ...state,
                load: true,
            };
        case billofquantityConstants.CREATE_BOQ_PRODUCT:
        case billofquantityConstants.EDIT_BOQ_PRODUCT:
        case billofquantityConstants.DELETE_BOQ_PRODUCT:
        case billofquantityConstants.UPDATE_CUSTOM_BOQ_PRODUCT:
        case billofquantityConstants.DELIVER_BOQ:
        case billofquantityConstants.UPDATE_INTERNAL_TRANSFER:
            return {
                ...state,
                productLoad: true,
            };
        case billofquantityConstants.CREATE_BOQ_SUCCESS:
            return {
                ...state,
                load: false,
                boqData: response,
            };
        case billofquantityConstants.CREATE_BOQ_PROJECT_SUCCESS:
        case billofquantityConstants.EDIT_BOQ_PROJECT_SUCCESS:
            return {
                ...state,
                load: false,
                projectSuccess: response,
            };
        case billofquantityConstants.PREVIEW_BOQ_SUCCESS:
            return {
                ...state,
                load: false,
                previewBoqText: response,
            };
        case billofquantityConstants.CREATE_BILL_OF_QUANTITY_SUCCESS:
        case billofquantityConstants.UPDATE_BILL_OF_QUANTITY_SUCCESS:
            return {
                ...state,
                load: false,
                data: response,
            };
        case billofquantityConstants.GENERATE_BOQ_SUCCESS:
            return {
                ...state,
                load: false,
                generateData: response,
            };
        case billofquantityConstants.SENT_BOQ_SUCCESS:
            return {
                ...state,
                load: false,
                sendData: response,
            };
        case billofquantityConstants.CLONE_BOQ_SUCCESS:
            return {
                ...state,
                load: false,
                cloneData: response,
            };
        case billofquantityConstants.REVISE_BOQ_SUCCESS:
            return {
                ...state,
                load: false,
                reviseData: response,
            };
        case billofquantityConstants.DELETE_BOQ_SUCCESS:
            return {
                ...state,
                load: false,
                deleteBoq: response,
            };
        case billofquantityConstants.GET_PROJECT_OUTWARD_SUCCESS:
        case billofquantityConstants.GET_PROJECT_OUTWARD_V2_SUCCESS:
            return {
                ...state,
                load: false,
                projectOutwardData: response.data,
            };
        case billofquantityConstants.GET_INTERNAL_TRANSFER_PRODUCT_SUCCESS:
            return {
                ...state,
                load: false,
                internalTransferData: response,
            };
        case billofquantityConstants.GET_BOQ_PRODUCT_DETAILS_SUCCESS:
            return {
                ...state,
                load: false,
                boqProductData: response.data,
            };
        case billofquantityConstants.PREVIEW_PACKING_SLIP_SUCCESS:
            return {
                ...state,
                load: false,
                previewPackingSlipText: response,
            };
        case billofquantityConstants.SENT_BOQ_MAIL_SUCCESS:
            return {
                ...state,
                load: false,
                boqMailSuccess: response,
            };
        case billofquantityConstants.GET_CONVERT_BOQ_TO_PO_SUCCESS:
            return {
                ...state,
                loading: false,
                convertBoqList: response.data,
            };
        case billofquantityConstants.GET_CONFIRM_QUOTATION_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                confirmQuotDropdown: response.data,
            };
        case billofquantityConstants.GET_CONFIRM_QUOTATION_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                confirmQuotProduct: response.data,
            };
        case billofquantityConstants.GET_BOQ_MAIL_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                mailData: response.data,
            };
        case billofquantityConstants.GET_BOQ_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                boqProjectData: response.data,
            };
        case billofquantityConstants.GET_BILL_OF_QUANTITY_SUCCESS:
            return {
                ...state,
                loading: false,
                boqList: response.data,
            };
        case billofquantityConstants.GET_BOQ_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                boqIdData: response.data,
            };
        case billofquantityConstants.CREATE_BOQ_PRODUCT_SUCCESS:
        case billofquantityConstants.EDIT_BOQ_PRODUCT_SUCCESS:
            return {
                ...state,
                productLoad: false,
                boqProductSuccess: response,
            };
        case billofquantityConstants.UPDATE_CUSTOM_BOQ_PRODUCT_SUCCESS:
            return {
                ...state,
                productLoad: false,
                customProductSuccess: response,
            };
        case billofquantityConstants.UPDATE_INTERNAL_TRANSFER_SUCCESS:
            return {
                ...state,
                productLoad: false,
                itSuccess: response,
            };
        case billofquantityConstants.DELETE_BOQ_PRODUCT_SUCCESS:
            return {
                ...state,
                productLoad: false,
                deleteBoqProduct: response,
            };
        case billofquantityConstants.DELIVER_BOQ_SUCCESS:
            return {
                ...state,
                productLoad: false,
                deliverBoqData: response,
            };
        case billofquantityConstants.GET_BOQ_PROJECTS_FAILURE:
        case billofquantityConstants.GET_BILL_OF_QUANTITY_FAILURE:
        case billofquantityConstants.GET_CONVERT_BOQ_TO_PO_FAILURE:
        case billofquantityConstants.GET_CONFIRM_QUOTATION_DROPDOWN_FAILURE:
        case billofquantityConstants.GET_CONFIRM_QUOTATION_PRODUCTS_FAILURE:
        case billofquantityConstants.GET_BOQ_BY_ID_FAILURE:
        case billofquantityConstants.GET_BOQ_MAIL_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case billofquantityConstants.CREATE_BOQ_FAILURE:
        case billofquantityConstants.CREATE_BOQ_PROJECT_FAILURE:
        case billofquantityConstants.EDIT_BOQ_PROJECT_FAILURE:
        case billofquantityConstants.PREVIEW_BOQ_FAILURE:
        case billofquantityConstants.CREATE_BILL_OF_QUANTITY_FAILURE:
        case billofquantityConstants.UPDATE_BILL_OF_QUANTITY_FAILURE:
        case billofquantityConstants.GENERATE_BOQ_FAILURE:
        case billofquantityConstants.SENT_BOQ_FAILURE:
        case billofquantityConstants.CLONE_BOQ_FAILURE:
        case billofquantityConstants.DELETE_BOQ_FAILURE:
        case billofquantityConstants.REVISE_BOQ_FAILURE:
        case billofquantityConstants.GET_PROJECT_OUTWARD_FAILURE:
            case billofquantityConstants.GET_PROJECT_OUTWARD_V2_FAILURE:
        case billofquantityConstants.GET_INTERNAL_TRANSFER_PRODUCT_FAILURE:
        case billofquantityConstants.GET_BOQ_PRODUCT_DETAILS_FAILURE:
        case billofquantityConstants.PREVIEW_PACKING_SLIP_FAILURE:
        case billofquantityConstants.SENT_BOQ_FAILURE:
            return {
                ...state,
                load: false,
            };
        case billofquantityConstants.CLEAR_BOQ_ID_DATA:
            return {
                ...state,
                boqIdData: {},
            };
        case billofquantityConstants.CREATE_BOQ_PRODUCT_FAILURE:
        case billofquantityConstants.EDIT_BOQ_PRODUCT_FAILURE:
        case billofquantityConstants.DELETE_BOQ_PRODUCT_FAILURE:
        case billofquantityConstants.UPDATE_CUSTOM_BOQ_PRODUCT_FAILURE:
        case billofquantityConstants.DELETE_BOQ_FAILURE:
        case billofquantityConstants.DELIVER_BOQ_FAILURE:
        case billofquantityConstants.UPDATE_INTERNAL_TRANSFER_FAILURE:
            return {
                ...state,
                productLoad: false,
            };
        case billofquantityConstants.CLEAR_BOQ_PREVIEW:
            return {
                ...state,
                previewBoqText: "",
            }
        case billofquantityConstants.CHANGE_PAGE:
            return {
                ...state,
                page: "edit"
            };
        case billofquantityConstants.CHANGE_ADD_PAGE:
            return {
                ...state,
                page: "add"
            };
        case billofquantityConstants.CLEAR_BOQ_PROJECT:
            return {
                ...state,
                boqProjectData: []
            };
        case billofquantityConstants.CLEAR_CONFIRM_QUOT_DROPDOWN:
            return {
                ...state,
                confirmQuotDropdown: []
            };
        case billofquantityConstants.DOWNLOAD_BOQ_CSV:
            return {
                ...state,
                loading: true,
            };
        case billofquantityConstants.DOWNLOAD_BOQ_CSV_SUCCESS:
            return {
                ...state,
                loading: false,
                csvDataDownload: response
            };
        case billofquantityConstants.DOWNLOAD_BOQ_CSV_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case billofquantityConstants.CLEAR_CSV:
            return {
                ...state,
                csvDataDownload: null
            };
        case billofquantityConstants.CLEAR_PROJECT_OUTWARD:
            return {
                ...state,
                projectOutwardData: []
            }
        default:
            return state;
    }
}
