import { put, call, takeEvery } from "redux-saga/effects";
import { forgotPasswordApi, loginApi } from "../services";
import { authConstants } from "../constants";

export function* loginSaga(payload) {
    try {
        const response = yield call(loginApi, payload);
        yield put({ type: authConstants.LOGIN_SUCCESS, response });
    } catch (error) {
        console.log(error);
        yield put({ type: authConstants.LOGIN_FAILURE, error });
    }
}
export function* forgotPasswordSaga(payload) {
    try {
        const response = yield call(forgotPasswordApi, payload);
        yield put({ type: authConstants.FORGOT_PASSWORD_SUCCESS, response });
    } catch (error) {
        yield put({ type: authConstants.FORGOT_PASSWORD_FAILURE, error });
    }
}

export default function* authSaga() {
    yield takeEvery(authConstants.LOGIN_REQUEST, loginSaga);
    yield takeEvery(authConstants.FORGOT_PASSWORD_REQUEST, forgotPasswordSaga);

}