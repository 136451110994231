import { deliverConstants } from "../constants/deliverConstants";

const initialState = {
    loading: false,
    data: [],
    error: [],
};

export default function deliverReducer(state = initialState, { type, response }) {
    switch (type) {
            case deliverConstants.GET_DELIVER_LOGS:
                return {
                    ...state,
                    loading: true,
                };
            case deliverConstants.GET_DELIVER_LOGS_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    deliver_list: response.data,
                };
            case deliverConstants.GET_DELIVER_LOGS_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: response,
                };
        default:
            return state;
    }
}
