const validate = values => {
    const errors = {}
  
    const requiredFields = [
      'to',
      'subject'
    ]
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Please fill the field *';
      }
    })

    if (values.to && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.to)) {
        errors.to = 'Invalid Email Address *'
      }
  
    return errors
  }
  
  export default validate