import React from 'react'
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { checkStockName, deliverBoq, getBoqProductDetails, previewPackingSlip, showFixedDigitsValue, showStockValue, updateInternalTransfer } from '../../../actions';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import PackingSlip from './PackingSlip';


class Outward extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            quotationId: "",
            items: [],
            inwardData: [],
            packingPopup: false,
            receivedValues: [],
            deliveredValues: []
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.packingSlipShow = this.packingSlipShow.bind(this);
        this.packingSlipClose = this.packingSlipClose.bind(this);
    }
    changeHandler = (e, index) => {
        const { str } = this.props;
        if (str === "IT") {
            const { data } = this.state;
            let a = Number(data[index].balance);
            let b = e.target.value;
            if (b && !/^([0-9]{1,5})+(\.[0-9]{0,2})?$/i.test(b)) {
                toast("Enter valid digits Ex(19.00,23456.45,200.34)");
                return false;
            }
            data[index]["deliveredQuantity"] = b;
            if (+b <= a) {
                this.setState({
                    data: data
                })
            } else {
                toast("Pls enter the number less than or equal to " + a);
            }
        } else {
            const { data } = this.state;
            let a = Number(data[index].balance);
            let b = e.target.value;
            if (b && !/^([0-9]{1,5})+(\.[0-9]{0,2})?$/i.test(b)) {
                toast("Enter valid digits Ex(19.00,23456.45,200.34)");
                return false;
            }
            data[index]["receivedQuantity"] = b;
            if (+b <= a) {
                this.setState({
                    data: data
                })
            } else {
                toast("Pls enter the number less than balance");
            }
        }
    }
    packingSlipShow() {
        this.setState({ packingPopup: true });
    }
    packingSlipClose() {
        this.setState({ packingPopup: false });
    }
    submitHandler() {
        const { str, internalTransferData } = this.props;
        const { id } = this.props;
        let pro = [];
        if (str === "IT") {
            this.state.data?.map((item, i) => {
                if (Number(item.deliveredQuantity) > 0) {
                    let items = {};
                    items["purchaseProjectProductId"] = item.projectProductId;
                    items["outwardQuantity"] = Number(item.deliveredQuantity);
                    pro.push(items);
                }
            });
            let formData = {
                "billOfQuantityId": internalTransferData.data.billOfQuantityId,
                "projectProductReqDtoList": pro
            };
            this.props.dispatch(updateInternalTransfer(formData));
        } else {
            this.state.data?.map((item, i) => {
                if (Number(item.receivedQuantity) > 0) {
                    let items = {};
                    items["purchaseProjectProductId"] = item.purchaseProjectProductId;
                    items["outwardQuantity"] = Number(item.receivedQuantity);
                    pro.push(items);
                }
            });
            let formData = {
                "billOfQuantityId": id,
                "projectProductReqDtoList": pro
            };
            this.props.dispatch(deliverBoq(formData));
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.deliverBoqData !== this.props.deliverBoqData) {
            const { deliverBoqData } = this.props;
            if (deliverBoqData.status === 200) {
                this.setState({
                    data: [],
                    packingPopup: true
                })
                this.props.onHide();
                toast(deliverBoqData.data?.message);
                this.props.dispatch(getBoqProductDetails(deliverBoqData?.data?.packingReferenceId));
                this.props.dispatch(previewPackingSlip(deliverBoqData?.data?.packingReferenceId));
            } else {
                toast(deliverBoqData.data);
            }
        } if (prevProps.internalTransferData !== this.props.internalTransferData) {
            if (this.props.internalTransferData.status === 200) {
                if (this.props.str === "IT") {
                    const values = this.props.internalTransferData?.data?.productDetail?.map((i) => i.deliveredQuantity);
                    this.setState({
                        data: this.props.internalTransferData?.data?.productDetail,
                        deliveredValues: values
                    })
                }
            } else {
                if (this.props.str === "IT") {
                    toast(this.props.internalTransferData.data);
                }
            }
        } if (prevProps.data !== this.props.data) {
            if (this.props.str !== "IT") {
                const values = this.props.data?.outwardProductList?.map((i) => i.receivedQuantity);
                this.setState({
                    data: this.props.data?.outwardProductList,
                    receivedValues: values
                })
            }
        } if (prevProps.itSuccess !== this.props.itSuccess) {
            const { itSuccess } = this.props;
            if (itSuccess.status === 200) {
                this.setState({
                    data: [],
                    packingPopup: true
                })
                this.props.dispatch(getBoqProductDetails(itSuccess?.data?.packingReferenceId));
                this.props.dispatch(previewPackingSlip(itSuccess?.data?.packingReferenceId));
                this.props.onHide();
                toast(itSuccess?.data?.message);
            } else {
                toast(itSuccess.data);
            }
        }
    }

    render() {
        const { show, onHide, internalTransferData, load, data, str } = this.props;
        const { packingPopup, deliveredValues, receivedValues } = this.state;

        return (
            <>
                <Modal
                    show={show}
                    size="xl"
                    onHide={() => {
                        onHide();
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-staff">
                        <div className="head">
                            <div>
                                <i class="fas fa-arrow-left" onClick={() => {
                                    onHide();
                                }}></i>
                                <h4>{`BOQ NO. : ${data.billOfQuantityNumber ?? internalTransferData?.data?.billOfQuantityNumber} - ${data.customerName ?? internalTransferData?.data?.customerName}`}</h4>
                            </div>
                        </div>
                        <div className="table-class space-part">
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Product Name
                                        </th>
                                        <th scope="col" className='txt-center'>
                                            Qty
                                        </th>
                                        <th scope="col" className='txt-center'>
                                            Outward Qty
                                        </th>
                                        <th scope="col" className='txt-center'>
                                            Balance
                                        </th>
                                        <th scope="col" style={{ textAlign: "center" }}>
                                            Available Stock
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (() => {
                                            if (str === "IT") {
                                                return (
                                                    <>
                                                        {this.state.data?.length > 0 ? this.state.data?.map((item, i) => (
                                                            <tr key={i} >
                                                                <td className="inward_new_size">
                                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.productName || "-")}</Tooltip>}>
                                                                        <span className="d-inline-block">
                                                                            {parse(item.productName || "-")}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td className='txt-center'>{item?.quantity}</td>
                                                                <td className='txt-center'><input
                                                                    type="text"
                                                                    maxLength="8"
                                                                    className='convert_po_input'
                                                                    value={item?.deliveredQuantity}
                                                                    onChange={(e) => this.changeHandler(e, i)}
                                                                /></td>
                                                                <td className='txt-center'>
                                                                    {`${deliveredValues[i] - item.deliveredQuantity === 0 ? 0 : showFixedDigitsValue(deliveredValues[i], item.deliveredQuantity)}`}
                                                                </td>
                                                                <td className='txt-center'>{item?.availableStock}</td>
                                                            </tr >
                                                        )) : <h1 className="empty-data">No Records Found</h1>
                                                        }
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <>
                                                        {this.state.data?.length > 0 ? this.state.data?.map((item, i) => (
                                                            <tr key={i} >
                                                                <td className="inward_new_size">
                                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.productName || "-")}</Tooltip>}>
                                                                        <span className="d-inline-block">
                                                                            {parse(item.productName || "-")}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td className='txt-center'>{item?.quantity}</td>
                                                                <td className='txt-center'><input
                                                                    type="text"
                                                                    className='convert_po_input'
                                                                    value={item?.receivedQuantity}
                                                                    onChange={(e) => this.changeHandler(e, i)} /></td>
                                                                <td className='txt-center'>
                                                                    {`${receivedValues[i] - item.receivedQuantity === 0 ? 0 : showFixedDigitsValue(receivedValues[i], item.receivedQuantity)}`}
                                                                </td>
                                                                <td className='txt-center'>
                                                                    {item?.availableStock}
                                                                </td>
                                                            </tr >
                                                        )) : <h1 className="empty-data">No Records Found</h1>
                                                        }
                                                    </>
                                                )
                                            }
                                        })()
                                    }
                                </tbody>
                            </table >
                        </div >
                        <center style={{ padding: "15px" }}>
                            {!load ?
                                <button className="customer-btn col-md-6" onClick={() => this.submitHandler()}>Outward</button>
                                : <button className="customer-btn col-md-6">Loading...</button>}
                        </center >
                    </div >
                </Modal >
                <PackingSlip
                    show={packingPopup}
                    onHide={this.packingSlipClose}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        convertBoqList: state.boq.convertBoqList,
        load: state.boq.productLoad,
        internalTransferData: state.boq.internalTransferData,
        deliverBoqData: state.boq.deliverBoqData,
        projectOutwardData: state.boq.projectOutwardData,
        itSuccess: state.boq.itSuccess,
    }
}

export default connect(mapStateToProps)(Outward);
