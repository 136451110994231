import React from "react";
import { addUnit, changeDateFormat, changePageAdd, changePageEdit, getAllUnits, updateUnit } from "../../../actions";
import Spinner from "../../../Components/loader";
import UnitForm from "./UnitForm";
import plus from "../../../assets/images/pl.svg";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Sidebar from "../../../layouts/Sidebar";
import Navbar from "../../../layouts/Navbar";
import { Card } from "react-bootstrap";
import Re from "../../../assets/images/cat.svg";


const role = localStorage.getItem("userRole");
class Unit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success_msg: '',
      show: false,
      str: "",
      unit: [],
      units_list: [],
      unitId: 0,
      unitName: "",
      unitStatus: false,
      status: undefined
    }
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentDidUpdate(prevProps) {
    if (prevProps.units_list !== this.props.units_list) {
      this.setState({
        units_list: this.props.units_list,
      });
    }
    if (prevProps.success_msg !== this.props.success_msg) {
      this.setState({
        success_msg: this.props.success_msg,
        show: false
      });
      if (this.props.success_msg && this.props.page) {
        toast(this.props.success_msg);
        this.props.dispatch(getAllUnits());
      }
    }
  }

  componentDidMount() {
    this.props.dispatch(getAllUnits());
  }

  handleShow() {
    this.props.dispatch(changePageAdd());
    this.setState({ show: true, str: "add" });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleEdit(unit) {
    this.props.dispatch(changePageEdit());
    this.setState({
      show: true,
      str: "edit",
      unit: unit,
      unitName: unit.unitName,
      unitId: unit.unitId,
      unitStatus: unit.active,
    });
  }


  handleSubmit(formData) {
    if (this.state.str === "add") {

      if (/[^a-zA-Z ]/i.test(formData.unitName)) {
        toast("Only alphapets allowed..");
        return false;
      }
      if (formData.unitName !== undefined || formData.unitName !== "") {
        this.props.dispatch(addUnit(formData));
      }
    }
    if (this.state.str === "edit") {
      if (/[^a-zA-Z ]/i.test(formData.unitName)) {
        toast("Only alphapets allowed..");
        return false;
      }
      formData["unitId"] = this.state.unitId;
      if ((Object.keys(formData).length === 0)) {
        formData["unitStatus"] = this.state.unitStatus;
        formData["unitName"] = this.state.unitName;
        this.props.dispatch(updateUnit(formData));
      } else if (Object.keys(formData).length > 0 && (formData.unitStatus === undefined && formData.unitName !== undefined)) {
        formData["unitStatus"] = this.state.unitStatus;
        this.props.dispatch(updateUnit(formData));
      } else if (Object.keys(formData).length > 0 && formData.unitStatus !== undefined && formData.unitName === undefined) {
        formData["unitName"] = this.state.unitName;
        this.props.dispatch(updateUnit(formData));
      } else if (Object.keys(formData).length > 0 && formData.unitStatus === undefined && formData.unitName === undefined) {
        formData["unitStatus"] = this.state.unitStatus;
        formData["unitName"] = this.state.unitName;
        this.props.dispatch(updateUnit(formData));
      } else {
        this.props.dispatch(updateUnit(formData));
      }
    }
  }


  render() {
    const { show, str, unit, units_list } = this.state;

    return (
      <div>
        <Navbar />
        <div className="dashboard-page">
          <div className="dashboard-sidebar">
            <Sidebar />
          </div>
          <div className="dashboard-main">
            <Card className="staff-page">
              <div className="page-head-split">
                <div className="head-colums">
                  <div className="head-part-tab">
                    <img src={Re} alt="..." className="head-imgs" />
                    <h5>Settings</h5>
                  </div>
                  <div className="tab-links">
                    <Link to={"/" + role + "/gst"} >GST</Link>
                    <Link to={"/" + role + "/reference-id"} >Reference Id</Link>
                    <Link to={"/" + role + "/unit"} className="active" >Unit </Link>
                    <Link to={"/" + role + "/group"}>Quotation Group </Link>
                    <Link to={"/" + role + "/content-of-letter"}>Content Of the Letter</Link>
                    <Link to={"/" + role + "/terms-conditions"}>Terms & Condition</Link>
                  </div>
                </div>
              </div>
              <div className="unit-page">
                <button className="add-product create-staff" onClick={this.handleShow}>
                  <img src={plus} alt="" /> Add Unit
                </button>
                <UnitForm show={show} onHide={this.handleClose} onSubmit={this.handleSubmit} str={str} initialValues={unit} onChange={this.handleChange} />
                <div className="table-class">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Unit</th>
                        <th scope="col">Created On </th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {units_list ?? units_list.length > 0 ? units_list.map((unit) => (
                        <tr key={unit.unitId} onClick={() => this.handleEdit(unit)} style={{ cursor: "pointer" }}>
                          <td>{unit.unitName}</td>
                          <td>{changeDateFormat(unit.createdAt)}</td>
                          <td>
                            <button className={unit.active ? "generate_btn" : "cancelled-btn"}>{unit.active ? "ACTIVE" : "INACTIVE"}</button>
                          </td>
                          <td>
                            <button className="Edit-staff" onClick={() => this.handleEdit(unit)}>
                              Edit
                            </button>
                          </td>
                        </tr>
                      )) : <Spinner />}

                    </tbody>
                  </table>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    )
  }

}


const mapStateToProps = (state) => {
  return {
    loading: state.unit.loading,
    success_msg: state.unit.success_msg,
    page: state.unit.page,
    status: state.unit.status,
    units_list: state.unit.units_list
  }
}

export default connect(mapStateToProps)(Unit);
