export const packageConstants = {
    //create initial package and get temp packageId
    GET_INITIAL_PACKAGE_ID: "GET_INITIAL_PACKAGE_ID",
    GET_INITIAL_PACKAGE_ID_SUCCESS: "GET_INITIAL_PACKAGE_ID_SUCCESS",
    GET_INITIAL_PACKAGE_ID_FAILURE: "GET_INITIAL_PACKAGE_ID_FAILURE",

    //Get package product dropdown
    GET_PACKAGE_PRODUCT_DROPDOWN: "GET_PACKAGE_PRODUCT_DROPDOWN",
    GET_PACKAGE_PRODUCT_DROPDOWN_SUCCESS: "GET_PACKAGE_PRODUCT_DROPDOWN_SUCCESS",
    GET_PACKAGE_PRODUCT_DROPDOWN_FAILURE: "GET_PACKAGE_PRODUCT_DROPDOWN_FAILURE",

     //Get product dropdown
    GET_PRODUCT_DROPDOWN: "GET_PRODUCT_DROPDOWN",
    GET_PRODUCT_DROPDOWN_SUCCESS: "GET_PRODUCT_DROPDOWN_SUCCESS",
    GET_PRODUCT_DROPDOWN_FAILURE: "GET_PRODUCT_DROPDOWN_FAILURE",
    

    //edit package
    EDIT_PACKAGE: "EDIT PACKAGE",
    EDIT_PACKAGE_SUCCESS: "EDIT_PACKAGE_SUCCESS",
    EDIT_PACKAGE_FAILURE: "EDIT_PACKAGE_FAILURE",
    
    //get package list
    GET_PACKAGE_LIST: "GET_PACKAGE_LIST",
    GET_PACKAGE_LIST_SUCCESS: "GET_PACKAGE_LIST_SUCCESS",
    GET_PACKAGE_LIST_FAILURE: "GET_PACKAGE_LIST_FAILURE",

    //add products for package
    ADD_PACKAGE_PRODUCT: "ADD_PACKAGE_PRODUCT",
    ADD_PACKAGE_PRODUCT_SUCCESS: "ADD_PACKAGE_PRODUCT_SUCCESS",
    ADD_PACKAGE_PRODUCT_FAILURE: "ADD_PACKAGE_PRODUCT_FAILURE",

    //edit products for package:
    EDIT_PACKAGE_PRODUCT: "EDIT_PACKAGE_PRODUCT",
    EDIT_PACKAGE_PRODUCT_SUCCESS: "EDIT_PACKAGE_PRODUCT_SUCCESS",
    EDIT_PACKAGE_PRODUCT_FAILURE: "EDIT_PACKAGE_PRODUCT_FAILURE",

    //get products for packageId
    GET_PRODUCTS_FOR_PACKAGE: "GET_PRODUCTS_FOR_PACKAGE",
    GET_PRODUCTS_FOR_PACKAGE_SUCCESS: "GET_PRODUCTS_FOR_PACKAGE_SUCCESS",
    GET_PRODUCTS_FOR_PACKAGE_FAILURE: "GET_PRODUCTS_FOR_PACKAGE_FAILURE",

    CLEAR_INITIAL_PACKAGE: "CLEAR_INITIAL_PACKAGE",
    CLEAR_ADD_OR_EDIT_PRODUCT: "CLEAR_ADD_OR_EDIT_PRODUCT",
    CLEAR_ADD_OR_EDIT_PACKAGE: "CLEAR_ADD_OR_EDIT_PACKAGE",
    CLEAR_SINGLE_PACKAGE_DROPDOWN: "CLEAR_SINGLE_PACKAGE_DROPDOWN"
}