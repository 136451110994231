import React, { Fragment, useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Row, Col, Form, Button, Input } from "antd";
import { addReportOaListConfirm, htmlTagRemove } from "../../../actions";
import { connect } from 'react-redux';


const OaConfirm = (props) => {

    const { show, onHide, data, msg, loading, oaListFormDispatch, onFetchListTickets, initValues } = props;
    const [form] = Form.useForm();
    const Ref = useRef();

    const [refer, setRefer] = useState(null);

    const onFinish = (val) => {
        oaListFormDispatch(val);
        setRefer(val);
    }

    useEffect(() => {
        if (refer !== null) {
            onFetchListTickets(initValues);
            toast(msg?.data)
            onHide();
        }
    }, [msg])


    //  Add amount status
    const [input1, setInput1] = useState(0);
    const [input2, setInput2] = useState(0);


    const handleInput2Change = (e) => {
        setInput2(Number(e.target.value));
    };

    const sum = input1 - input2;




    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                "productName": htmlTagRemove(data?.productName),
                "oaNumber": data?.oaNumber,
                "articleId": data?.articleId,
                "poNumber": data?.poNumber,
                "unitPrice": data?.unitPrice,
                "oaQuantity": data?.oaQuantity,
                "remainingQuantity": data?.remainingQuantity,
                "remark": data?.remark,
                "productId": data?.productId,
            });
        }
        setInput1(data?.remainingQuantity);
    }, [data])


    return (
        <Fragment>
            <Modal
                show={show}
                size="lg"
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <div className="modal-staff">
                    <div className="head">
                        <div>
                            <i class="fas fa-arrow-left" onClick={onHide}></i>
                            <h4>OA Confirm</h4>
                        </div>
                    </div>
                </div>
                <div className="form-oa-main">
                    <div className="form-oa">
                        <Form
                            form={form}
                            onFinish={onFinish}
                            ref={Ref}
                            name="info"
                            layout="vertical">
                            <Row gutter={[32, 0]}>
                                <Col md={8}>
                                    <Form.Item name="productId" hidden>
                                        <Input disabled />
                                    </Form.Item>
                                    <Form.Item name="unitPrice" hidden>
                                        <Input disabled />
                                    </Form.Item>
                                    <Form.Item name="oaQuantity" hidden>
                                        <Input disabled />
                                    </Form.Item>
                                    <Form.Item name="poNumber" label="PO Number" rules={[{ required: true }]}>
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col md={8}>
                                    <Form.Item name="oaNumber" label="OA Number" rules={[{ required: true }]}>
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col md={8}>
                                    <Form.Item name="articleId" label="Art No" rules={[{ required: false }]}>
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col md={24}>
                                    <Form.Item name="productName" label="Product Name" rules={[{ required: true }]}>
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                                <Col md={8}>
                                    <Form.Item label="OA Quantity" rules={[{ required: true }]}>
                                        <Input disabled value={data?.oaQuantity} />
                                    </Form.Item>
                                </Col>
                                <Col md={8}>
                                    <Form.Item label="Remaining Quantity" rules={[{ required: true }]}>
                                        <Input disabled value={data?.remainingQuantity} />
                                    </Form.Item>
                                </Col>
                                <Col md={8}>
                                    <Form.Item name="remainingQuantity" label="Confirm Quantity" rules={[
                                        {
                                            required: true,
                                            message: 'Enter Confirm Quantity!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if(parseInt(value) === 0) {
                                                    return Promise.reject(new Error('Con.Qty should be greater than 0'))
                                                }
                                                if (input1 >= value) {
                                                    return Promise?.resolve();
                                                }
                                                return Promise?.reject(new Error('Con.Qty should be less than or equal to Rem.Qty'));
                                            },
                                        }),
                                    ]}>
                                        <Input placeholder="Confirm Quantity" value={input1} onChange={handleInput2Change} />
                                    </Form.Item>
                                </Col>
                                <Col md={24}>
                                    <Form.Item name="remark" label="Remarks" rules={[{ required: true, message: "Enter your Remarks!" }]}>
                                        <Input.TextArea placeholder="Your Remarks" maxLength={10} />
                                    </Form.Item>
                                </Col>
                                <Col md={24}>
                                    {loading ? <Form.Item>
                                        <Button>Loading...</Button>
                                    </Form.Item> :
                                        <Form.Item>
                                            <Button htmlType="submit">Confirm</Button>
                                        </Form.Item>}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>

            </Modal>
        </Fragment>
    )
}
const mapStateToProps = (state) => {
    const { oa } = state;
    return {
        loading: oa.loading,
        msg: oa.success
    }
}

const mapDispatchToProps = (dispatch) => ({
    oaListFormDispatch: (formData) => dispatch(addReportOaListConfirm(formData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OaConfirm)