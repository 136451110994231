export const settingconstants = {
    POST_GST: "POST_GST",
    POST_GST_SUCCESS: "POST_GST_SUCCESS",
    POST_GST_FAILURE: "POST_GST_FAILURE",
}
export const delieveryconstants = {
    POST_DELIEVERY_TIMELINE: "POST_DELIEVERY_TIMELINE",
    POST_DELIEVERY_TIMELINE_SUCCESS: "POST_DELIEVERY_TIMELINE_SUCCESS",
    POST_DELIEVERY_TIMELINE_FAILURE: "DELIEVERY_TIMELINE_FAILURE",
}
export const warrentyconstants = {
    POST_WARRENTY: "POST_WARRENTY",
    POST_WARRENTY_SUCCESS: "POST_WARRENTY_SUCCESS",
    POST_WARRENTY_FAILURE: "POST_WARRENTY_FAILURE",
}
export const referenceconstants = {
    POST_REFERENCE: "POST_REFERENCE",
    POST_REFERENCE_SUCCESS: "POST_REFERENCE_SUCCESS",
    POST_REFERENCE_FAILURE: "POST_REFERENCE_FAILURE",
}
export const bankconstants = {
    POST_BANK_ACCOUNT: "POST_BANK_ACCOUNT",
    POST_BANK_ACCOUNT_SUCCESS: "POST_BANK_ACCOUNT_SUCCESS",
    POST_BANK_ACCOUNT_FAILURE: "POST_BANK_ACCOUNT_FAILURE",
}
export const companyconstants = {
    POST_COMPANY_DETAILS: "POST_COMPANY_DETAILS",
    POST_COMPANY_DETAILS_SUCCESS: "POST_COMPANY_DETAILS_SUCCESS",
    POST_COMPANY_DETAILS_FAILURE: "POST_COMPANY_DETAILS_FAILURE",
}