import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Modal } from "react-bootstrap";
import SetPassword from "./setPassword";
import { connect } from "react-redux";
import profile from "../../assets/images/avathar1.jpg";
import validate from './validate';
import { camelize, S3_root } from '../../actions';
import { changeTitleStr } from '../../functions/Password';
import plus from "../../assets/images/pl.svg";

const renderField = ({ input, placeholder, maxLength, type, meta: { touched, error } }) => (
  <div>
    <label></label>
    <div>
      <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength} />
      {touched && error && <p className="require">{error}</p>}
    </div>
  </div>
);

const selectGender = ({ input, label, type, defaultChecked, meta: { touched, error } }) => (

  <div className="form-radio" >
    <div>
      <input {...input} placeholder={label} type={type} defaultChecked={defaultChecked} />
    </div>
    <label>{label}</label>
  </div>
);

const renderSelectField = ({ input, meta: { touched, error }, children }) => (
  <div>
      <div>
          <select {...input} className='form-control form-select'>
              {children}
          </select>
          {touched && error && <p className="require">{error}</p>}
      </div>
  </div>
);

const file_upload = ({ input, type, imgHandler, label, meta: { touched, error, warning } }) => {
  delete input.value

  return (
    <div className=''>
      <label htmlFor={input.name}>
        <input {...input} type={type} onChange={imgHandler} accept="image/*" />
        {touched && error && <p className="require">{error}</p>}
      </label>
    </div>
  )
}


const StaffForm = props => {

  const { error, handleSubmit, imgHandler, show, onHide,
    selectedFile, filePick, page, img, initialValues, getStatus, getPassword, reset, empty, handleShow } = props;

  const maleCheck = initialValues.gender === "male" ? true : false;
  const femaleCheck = initialValues.gender === "female" ? true : false;

  let imgUrl = img && img !== null ? S3_root + img : profile;

  const changeHandler = (e) => {
    getStatus(e.target.value);
  }

  const showModal = () => {
    reset();
    handleShow();
  }


  return (
    <>
      <button className="add-staff" onClick={showModal} >
        <img src={plus} alt="" /> Add staff
      </button>
      <Modal
        show={show}
        size="lg"
        onHide={() => {
          reset();
          onHide();
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-staff main">
          <div className="head">
            <div>
              <i class="fas fa-arrow-left" onClick={() => {
                reset();
                onHide();
              }}></i>
              <h4>{changeTitleStr(empty)} </h4>
            </div>

            {empty === "edit" ?
              <div>
                <Field name="status" component="select" className="form-control select-control" onChange={changeHandler} >
                  <option value="" disabled={true}>STATUS</option>
                  <option value="Active" >ACTIVE</option>
                  <option value="Inactive">INACTIVE</option>
                </Field>
                <div className="triangle-status"></div>
              </div>
              : null}
          </div>
          <form onSubmit={handleSubmit}>
            <Row className="modal-staff-body">
              <Col md={6} xs={6}>
                <h4 style={{ margin: "0 5px" }}> Personal Details</h4>
                <Row className="modal-form-row">
                  <Col md={6}>
                    <label class="form-control-placeholder" for="name">First Name  <span>*</span></label>
                    <Field name="firstName" type="text" component={renderField} maxLength="25" />
                  </Col>
                  <Col md={6}>
                    <label class="form-control-placeholder" for="name">Last Name <span>*</span></label>
                    <Field name="lastName" type="text" component={renderField} maxLength="25" />
                  </Col>
                  <Col md={12}>
                    <label class="form-control-placeholder" for="name">Email Address <span>*</span></label>
                    <Field name="email" type="email" component={renderField} />
                  </Col>
                  <Col md={12} className="call-field ">
                    <label class="form-control-placeholder" for="name">Phone Number <span>*</span></label>
                    <span className="call-now fie" > + 91 </span>
                    <Field name="phone" type="tel" component={renderField} maxLength="10" />
                  </Col>
                  <Col md={12} className="mt-4">
                    <label class="form-control-placeholder type-form"  for="name">Role <span>*</span></label>
                    <Field name="role" component={renderSelectField} className="form-control form-select"  >
                      <option value="" disabled={true} hidden={true}></option>
                      <option value="STAFF" >Staff</option>
                      <option value="SALES EXECUTIVE">Sales Executive</option>
                    </Field>
                    <div className="triangle-role"></div>
                    {error && <strong>{error}</strong>}
                  </Col>
                  <Col md={12} className="mt-3">
                    <h4 style={{ margin: "0px 0 5px 5px" }}>Gender</h4>
                    <div className='form-radio'>
                      <Field name="gender" component={selectGender} type="radio" value="male" label="Male" defaultChecked={maleCheck} />
                      <Field name="gender" component={selectGender} type="radio" value="female" label="Female" defaultChecked={femaleCheck} />
                    </div>
                  </Col>
                  <Field name="userId" type="hidden" component={renderField} placeholder="Enter Staff Id" />
                </Row>

                <SetPassword onGetPassword={getPassword} page={empty} userId={initialValues.userId} />
                {error && <strong>{error}</strong>}
                <div>
                  <button type="submit" className="modal-btn">
                    {empty === "edit" ? "Update Staff" : "Create Staff"}
                  </button>
                </div>
              </Col>

              <Col md={6} xs={6} className="profile">
                <h4>{empty === "edit" ? camelize(initialValues?.role) : "Staff"} Photo</h4>
                <img
                  src={filePick ? URL.createObjectURL(selectedFile) : imgUrl}
                  alt=".."
                  className="profile-imgs"
                />
                <label className="upload"> {empty === "edit" ? "Change photo" : "Upload photo"}
                  <Field name="profileImageUrl" type="file" label="Upload Photo" component={file_upload} imgHandler={imgHandler} />
                </label>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </>
  )
}

function mapStateToProps(state) {
  let page = state.staff.page;
  let initValues = page === "edit" ? state.staff.staff : {};

  return {
    initialValues: initValues,
    staff: state.staff.staff,
    page: state.staff.page,
    img: state.staff.img
  }
}

const Form = reduxForm({
  form: "StaffForm",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
})(StaffForm);

export default connect(mapStateToProps)(Form);
