const validate = values => {
    const errors = {}
    const requiredFields = [
        'productName',
        'thresholdValue',
        'unitId'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Please fill the field *'
        }
    })

    // if ((values.productName && /[^a-zA-Z0-9- !@#$%*?()/,.:]/.test(values.productName))) {
    //     errors.productName = "Not Valid *";
    // }
    // if (values?.productName?.trim().length === 0) {
    //     errors.productName = "Please fill the field * ";
    //   }
    if (!values.newPrice) {
        errors.newPrice = 'Please fill the field * '
    }
    if ((values.newPrice && /[^0-9.\\s]/gi.test(values.newPrice))) {
        errors.newPrice = 'only digits allowed * '
    }
    if ((values.thresholdValue && /[^0-9\\s]/gi.test(values.thresholdValue))) {
        errors.thresholdValue = 'only digits allowed * '
    }
    if (values.stockQty && !/^([0-9]{1,5})+(\.[0-9]{0,2})?$/i.test(values.stockQty)) {
        errors.stockQty = 'Enter valid digits Ex(19.00,23456.45,200.34)';
      }
    

    // if ((values.articleId && /[^a-zA-Z0-9- !@#$%*?/(),.:]/i.test(values.articleId))) {
    //     errors.articleId = 'Not Valid * '
    // }
    // if (!values.imageUrl) {
    //     errors.imageUrl = 'Please Upload a Product Photo *'
    // }
    return errors
}

export default validate