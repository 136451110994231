import { staffConstants } from "../constants";

const initialState = {
    loading: false,
    staff: [],
    data: [],
    error: [],
    token: "",
    page: "add",
    status: 0,
    img: "",
    success_msg: "",
    passwordData: [],
    statusData: []
};

export default function staffReducer(state = initialState, { type, response }) {
    switch (type) {
        case staffConstants.UPDATE_STAFF_STATUS_REQUEST:
        case staffConstants.GET_STAFF:
            return {
                ...state,
                loading: true,
            };
        case staffConstants.GET_STAFF_SUCCESS:
            return {
                ...state,
                loading: false,
                allData: response.data,
                staffs_list: response.data.content,
            };
        case staffConstants.GET_STAFF_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
                success_msg: ""
            };
        case staffConstants.STAFF_REQUEST:
        case staffConstants.UPDATE_STAFF_REQUEST:
            return {
                ...state,
                loading: true,
                success_msg: ""
            };
        case staffConstants.STAFF_SUCCESS:
        case staffConstants.UPDATE_STAFF_SUCCESS:
            return {
                ...state,
                loading: false,
                success_msg: response,
                status: response.status
            };
        case staffConstants.STAFF_FAILURE:
        case staffConstants.UPDATE_STAFF_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };


        case staffConstants.GET_SINGLE_STAFF:
            return {
                ...state,
                loading: true,
            };

        case staffConstants.GET_SINGLE_STAFF_SUCCESS:
            return {
                ...state,
                loading: false,
                staff: response.data,
                page: "edit",
                img: response.data.profileImageUrl,
            };

        case staffConstants.GET_SINGLE_STAFF_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };


        case staffConstants.UPDATE_STAFF_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                statusData: response
            };

        case staffConstants.UPDATE_STAFF_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };

        case staffConstants.CHANGE_PAGE:
            return {
                ...state,
                loading: false,
                page: "add",
                img: "",
                staff: [],
            }

        default:
            return state;
    }
}
