import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { rolesConfig } from './roles'; // List of components by role
import * as Routes from './index'; // List of components
import PageNotFound from '../pages/pagenotfound/PageNotFound';

class PrivateRoutes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			authRole: '',
			allowedRoutes: []
		};
	}
	componentDidMount() {
		let userRole = localStorage.getItem('userRole');
		if (userRole) {
			let roleArray = ['common', userRole];
			let allocatedRoutes = roleArray.reduce((acc, role) => {
				return [...rolesConfig[role].routes];
			}, []);

			allocatedRoutes = uniqBy(allocatedRoutes, 'url');
			this.setState({
				allowedRoutes: [...this.state.allowedRoutes, allocatedRoutes],
				authRole: userRole,
			});
		}
	}

	render() {

		return (
			<Switch>
				{this.state && this.state.allowedRoutes && this.state.allowedRoutes[0] && this.state.allowedRoutes[0].map((route) => (
					<Route exact key={route.url} component={Routes[route.component]} path={`${this.props.match.path}${route.url}`} />
				))}
				<Route component={PageNotFound}/>
			</Switch>
		);
	}
}

export default PrivateRoutes;
