import { dashboardConstants } from "../constants";

export const getDashboardList = () => {
    return {
        type: dashboardConstants.GET_DASHBOARD,
    };
};

export const getDashboardCount = () => {
    return {
        type: dashboardConstants.GET_DASHBOARD_COUNT,
    };
};

export const getDashboardOutOfStock = () => {
    return {
        type: dashboardConstants.GET_DASHBOARD_OUTOFSTOCK,
    };
}


