import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Space, Upload, message, Select } from 'antd';
import Out from "../../../../assets/images/out.svg";
import { useParams } from "react-router-dom";
import { newTempForm, clearNewForm, getGroupListQuotation, newUpdateTempForm } from "../../../../actions";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import profile from "../../../../assets/images/we.svg";
import { S3_root } from "../../../../actions";

const { Dragger } = Upload;
const { Option } = Select;

const FireDoorForm = (props) => {

    const dispatch = useDispatch();
    let { quotId } = useParams();
    const [form] = Form.useForm();

    const { show, loading, customHandleClose, newForm, newFormDispatch, clearFormDispatch, updateNewFormDispatch, groupId, editPopup, updateNewForm, unit_list } = props;

    const [files, setFiles] = useState(null);

    const onFinish = (values) => {
        if (!editPopup?.groupId) {
            const formData = new FormData();
            if (files !== null) {
                formData.append("customProductImage", files[0]?.originFileObj)
            }
            Object.keys(values).forEach((item) => {
                formData.append(item, values[item]);
            })
            formData.append("quotationId", quotId)
            updateNewFormDispatch(formData)
        } else {
            const formData = new FormData();
            if (files !== null) {
                formData.append("customProductImage", files[0]?.originFileObj)
            }
            Object.keys(values).forEach((item) => {
                formData.append(item, values[item]);
            })
            formData.append("quotationId", quotId)
            formData.append("quotationGroupId", groupId)
            newFormDispatch(formData)
        }
    };



    useEffect(() => {
        if (newForm?.status === 200) {
            dispatch(getGroupListQuotation(quotId));
            toast(newForm?.data);
            clearFormDispatch();
            form.setFieldsValue({
                "description1": null,
                "description2": null,
                "unitId1": null,
                "unitId2": null,
                "quantity1": null,
                "quantity2": null,
                "ratePerUnit1": null,
                "ratePerUnit2": null,
            });
            setFiles(null);
        } else if (newForm?.status !== 200) {
            toast(newForm?.data);
            clearFormDispatch();
        }
    }, [newForm])

    useEffect(() => {
        if (!show) {
            setFiles(null);
        }
    }, [show])

    useEffect(() => {
        if (!editPopup?.groupId) {
            form.setFieldsValue({
                "description1": editPopup?.description1,
                "description2": editPopup?.description2,
                "unitId1": Number(editPopup?.unitId1),
                "unitId2": Number(editPopup?.unitId2),
                "quantity1": editPopup?.quantity1,
                "quantity2": editPopup?.quantity2,
                "ratePerUnit1": editPopup?.ratePerUnit1,
                "ratePerUnit2": editPopup?.ratePerUnit2,
                "quotationGroupId": editPopup?.quotationGroupId,
                "customProductId": editPopup?.customProductId
            });
            setPreviewImage(editPopup?.customProductImageUrl ?? editPopup?.customProductImageUrl != null ? S3_root + editPopup?.customProductImageUrl : profile)
        } else {
            form.setFieldsValue({
                "description1": null,
                "description2": null,
                "unitId1": null,
                "unitId2": null,
                "quantity1": null,
                "quantity2": null,
                "ratePerUnit1": null,
                "ratePerUnit2": null,
            });
        }
    }, [editPopup])

    //  successfully updates
    useEffect(() => {
        if (updateNewForm?.status === 200) {
            dispatch(getGroupListQuotation(quotId));
            toast(updateNewForm?.data);
            clearFormDispatch();
            customHandleClose();
            setFiles(null);
        } else if (updateNewForm?.status !== 200) {
            toast(updateNewForm?.data);
            clearFormDispatch();
        }
    }, [updateNewForm])

    const [previewImage, setPreviewImage] = useState(null);

    const handleBeforeUpload = (file) => {
        // Read the file and create a data URL for preview
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
        return false; // Prevent automatic upload on selection
    };

    const handleImageUpload = (info) => {
        const file = info.fileList[0];
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            return false
        }
        setFiles(info.fileList);
    };

    const onDrop = (e) => {
        const file = e.dataTransfer.files[0];
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            return false
        }
        setFiles(e.dataTransfer.files)
    }
    // console.log(files)
    const handleImagePaste = (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        for (const item of items) {
            if (item.type.indexOf('image') !== -1) {
                const blob = item.getAsFile();
                const newFile = new File([blob], 'pasted-image.png', { type: blob.type });
                let data = { "0": { "originFileObj": newFile } }
                setFiles(data);
                setPreviewImage(URL.createObjectURL(blob));
                message.success('Image pasted and ready for upload!');
            }
        }
    };

    return (
        <Fragment>
            {show && <div
                show={show}
                onHide={customHandleClose}
                id="mini_pop"
                className='mini-popup product_pop'
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div class="head">
                    <div>
                        <i class="fas fa-arrow-left" aria-hidden="true" onClick={customHandleClose}></i>
                        <h4>{!editPopup?.groupId ? "EDIT" : "ADD NEW"} CUSTOM PRODUCT</h4>
                    </div>
                    <img src={Out} alt="" class="back_img" onClick={customHandleClose} />
                </div>
                <Form name="dynamic_form_nest_item"
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={{
                        "billOfMaterialReqList": [
                            { description: '', quantity: '' },
                        ]
                    }}
                    className="mini-popup-form"
                >
                    <Row gutter={[16, 0]}>
                        <Col md={18}>
                            <Row gutter={[16, 0]}>
                                <Col md={6}>
                                    {!editPopup?.groupId ?
                                        <>
                                            <Form.Item name="customProductId" label="" hidden={true}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="quotationGroupId" label="" hidden={true}>
                                                <Input />
                                            </Form.Item>

                                        </>
                                        : null}
                                    <label >Description</label>
                                    <Form.Item name="description1" label="" rules={[{ required: true, message: "Required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col md={6}>
                                    <label >Unit</label>
                                    <Form.Item name="unitId1" label="" rules={[{ required: true, message: "Required" }]}>
                                        <Select>
                                            {unit_list?.map((item, i) => (
                                                <Option key={i} value={item?.unitId}>{item?.unitName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={6}>
                                    <label >Quantity</label>
                                    <Form.Item name="quantity1" rules={[{ required: true, message: "Required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col md={6}>
                                    <label >Rate PerUnit</label>
                                    <Form.Item name="ratePerUnit1" rules={[{ required: true, message: "Required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col md={6}>
                                    <label >Description</label>
                                    <Form.Item name="description2" rules={[{ required: true, message: "Required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col md={6}>
                                    <label >Unit</label>
                                    <Form.Item name="unitId2" label="" rules={[{ required: true, message: "Required" }]}>
                                        <Select>
                                            {unit_list?.map((item, i) => (
                                                <Option key={i} value={item?.unitId}>{item?.unitName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={6}>
                                    <label >Quantity</label>
                                    <Form.Item name="quantity2" label="" rules={[{ required: true, message: "Required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col md={6}>
                                    <label>Rate PerUnit</label>
                                    <Form.Item name="ratePerUnit2" label="" rules={[{ required: true, message: "Required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <br></br>
                            {editPopup?.groupId || previewImage === null ? null :
                                <img src={Out} alt="" class="remove_img" onClick={() => setPreviewImage(null)} />}
                            {files === null ? null :
                                <img src={Out} alt="" class="remove_img" onClick={() => setFiles(null)} />}
                            <Upload.Dragger
                                multiple={false}
                                beforeUpload={(file) => handleBeforeUpload(file)}
                                onDrop={(e) => onDrop(e)}
                                onChange={handleImageUpload}
                                accept="image/*"
                            >
                                {files === null ?
                                    editPopup?.customProductImageUrl !== undefined && editPopup?.customProductImageUrl !== null ?
                                        <>
                                            {previewImage === null ? "Product Image" :
                                                <img className="size_logo" src={previewImage} />}
                                        </> :
                                        "Product Image" :
                                    <img
                                        src={previewImage}
                                        alt=".."
                                        className="size_logo"
                                    />
                                }
                            </Upload.Dragger>
                            <div onPaste={handleImagePaste} className="border-tag">
                                <h1>Click and Paste</h1>
                            </div>
                        </Col>
                        <Col md={18} />
                        <Col md={3} className="ant-cust-btn">
                            <Button onClick={customHandleClose} block>close</Button>
                        </Col>
                        <Col md={3} className="ant-cust-btn" >
                            {!loading ?
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" block>
                                        Submit
                                    </Button>
                                </Form.Item> :
                                <Form.Item>
                                    <Button type="primary" block>
                                        Loading...
                                    </Button>
                                </Form.Item>}
                        </Col>
                    </Row>
                </Form>
            </div>}
        </Fragment >
    )
}



const mapStateToProps = (state) => {
    const { qutation, unit } = state;
    return {
        loading: qutation.loading,
        newForm: qutation.newForm,
        updateNewForm: qutation.updateNewForm,
        unit_list: unit.units_list

    }
}

const mapDispatchToProps = (dispatch) => ({
    newFormDispatch: (formData) => dispatch(newTempForm(formData)),
    clearFormDispatch: () => dispatch(clearNewForm()),
    updateNewFormDispatch: (formData) => dispatch(newUpdateTempForm(formData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FireDoorForm)