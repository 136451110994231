import React from 'react'
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { changeDeliverClassName, clearProductOutwardAction, createPoInward } from '../../../actions';
import { toast } from 'react-toastify';
import Spinner from '../../../Components/loader';


class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            quotationId: "",
            items: [],
            inwardData: [],
            allData: []
        }
        this.productHandler = this.productHandler.bind(this);
        this.selectHandler = this.selectHandler.bind(this);
        this.selectProHandler = this.selectProHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }
    selectHandler(e) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((i) => i = e.target.checked);
        this.setState({
            openedItem: newItems,
        })
    }
    selectProHandler(e, index) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((item, i) => index === i ? e.target.checked : item);
        this.setState({
            openedItem: newItems
        })
    }
    changeHandler = (e, index) => {
        const { data } = this.state;
        let a = Number(data[index].quantity);
        let b = Number(e.target.value);
        if (b < a) {
            data[index].inwardQuantity = e.target.value;
            data[index].balance = data[index].quantity - Number(e.target.value);
            this.setState({
                data: data
            })
        } else {
            toast("Pls enter the number less than quantity");
        }
    }
    productHandler(e) {
        this.props.inwardData?.map((item) => {
            if (item?.purchaseOrderId == e.target.value) {
                let data = [];
                item?.projectList.map((val) => {
                    data.push(val);
                })
                this.setState({
                    data: data,
                    purchaseOrderId: item?.purchaseOrderId
                })
            }
        });
    }
    submitHandler() {
        const { data } = this.state;
        let pro = [];
        data?.map((item, i) => {
            let items = {};
            items["productId"] = item.productId;
            items["projectId"] = item.projectId;
            items["stockQty"] = Number(item.inwardQuantity);
            pro.push(items);
        });
        let formData = pro;
        this.props.dispatch(createPoInward(formData));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.projectOutwardData !== this.props.projectOutwardData) {
            this.setState({
                data: this.props.projectOutwardData
            })
        }
    }

    render() {
        const { show, onHide, handleShow, onClearProjectOutward } = this.props;
        const { data } = this.state;
        return (
            <>
                <Modal
                    show={show}
                    size="lg"
                    onHide={() => {
                        onHide();
                        onClearProjectOutward();
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-staff">
                        <div className="head">
                            <div>
                                <i class="fas fa-arrow-left" onClick={() => {
                                    onHide();
                                    onClearProjectOutward();
                                }}></i>
                                <h4>PROJECTS</h4>
                            </div>
                        </div>
                        <div className="table-class space-part">
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            BOQ No.
                                        </th>
                                        <th scope="col">
                                            Project Name
                                        </th>
                                        <th scope="col">
                                            Customer Name
                                        </th>
                                        <th scope="col" className='txt-center'>
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.load === true ? <Spinner /> :
                                       (data && data?.length !== 0) ?  Array.isArray(data) ? data.map((item, i) => (
                                        <tr key={i} onClick={() => handleShow(item)} style={{ cursor: "pointer" }}>
                                            <td>{item.billOfQuantityNumber}</td>
                                            <td className='new_bulk_size'>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item.projectName || "-"}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        {item.projectName || "-"}
                                                    </span>
                                                </OverlayTrigger>
                                            </td>
                                            <td>
                                                {item.customerName || <div className="center_align"><p>-</p></div>}</td>
                                            <td className='txt-center'>
                                                <button style={{ width: " 145px" }} className={changeDeliverClassName(item.status)} onClick={(e) => handleShow(item)} >
                                                    {item.status}
                                                </button>
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr onClick={() => handleShow(data)} style={{ cursor: "pointer" }}>
                                            <td>{data.billOfQuantityNumber}</td>
                                            <td className='new_bulk_size'>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {data.projectName || "-"}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        {data.projectName || "-"}
                                                    </span>
                                                </OverlayTrigger>
                                            </td>
                                            <td>
                                                {data.customerName || <div className="center_align"><p>-</p></div>}</td>
                                            <td className='txt-center'>
                                                <button style={{ width: " 145px" }} className={changeDeliverClassName(data.status)} onClick={(e) => handleShow(data)} >
                                                    {data.status}
                                                </button>
                                            </td>
                                        </tr>
                                    ) : <h1 className="empty-data">No Records Found</h1>
                                    }
                                </tbody>
                            </table >
                        </div >
                    </div >
                </Modal >
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        convertBoqList: state.boq.convertBoqList,
        inwardData: state.po.inwardData,
        load: state.boq.load,
        confirmQuotDropdown: state.boq.confirmQuotDropdown,
        confirmQuotProduct: state.boq.confirmQuotProduct,
        addInwardData: state.po.addInwardData,
        projectOutwardData: state.boq.projectOutwardData
    }
}

const mapDispatchToProps = (dispatch) => ({
    onClearProjectOutward: () => dispatch(clearProductOutwardAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
