import { Fragment, useState, useEffect, useRef } from "react";
import { Row, Card, Modal, Form } from "react-bootstrap";
import Re from "../../../assets/images/green2.svg";
import Spinner from "../../../Components/loader";
import { oaDownload, filterDateFormat } from "../../../actions";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Logo from "../../../assets/images/main.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import Art from "../../../assets/images/art.svg";
import download from "../../../assets/images/download.svg";
import ReactToPrint from 'react-to-print';
import Search from "../../../assets/images/search.svg";

const role = localStorage.getItem('userRole');

const OaDownload = (props) => {

    const { downloadContent, loading, downloadList, oaDownloadDispatch } = props;

    const componentRef = useRef();
    const [show, setShow] = useState(false);
    const [id, setId] = useState(null);
    const [pageNo, setPageNo] = useState(0);
    const [searchKey, setSearchKey] = useState("");

    const [initValues, setInitValues] = useState({
        "size": 20,
        "pageNo": pageNo,
        "fromDate": null,
        "toDate": null,
        "sizeOfPage": 20,
        "searchKey": searchKey
    })

    const onFetchListTickets = (values) => {
        oaDownloadDispatch(values)
    }

    const onChange = () => {
        initValues.pageNo = pageNo + 1;
        setInitValues(initValues);
        setPageNo(pageNo + 1);
    }

    const searchHandler = (e) => {
        initValues.searchKey = e.target.value;
        let data = { ...initValues };
        data.searchKey = e.target.value;
        data.pageNo = e.target.value !== "" ? 0 : initValues.pageNo;
        setInitValues(data);
        setSearchKey(e.target.value);
        setPageNo(0);
    }

    const showPopup = () => {
        setShow(true);
    }

    const closePopup = () => {
        setShow(false);
        setId(null);
    }

    const getId = (val) => {
        setId(val)
    }

    useEffect(() => {
        onFetchListTickets(initValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNo, searchKey]);

    // orderConfirmPdfId
    useEffect(() => {
        if (id !== null) {
            showPopup();
        }
    }, [id])

    return (
        <Fragment>
            <Navbar />
            <div className="dashboard-page">
                <div className="dashboard-sidebar">
                    <Sidebar />
                </div>
                <div className="dashboard-main">
                    <Card className="staff-page">
                        <div className="page-head-split">
                            <div className="head-colums">
                                <div className="head-part-tab">
                                    <img src={Re} alt="..." className="head-imgs" />
                                    <h5>MC Report</h5>
                                </div>
                                <div className="tab-links tab-down">
                                    <Link to={"/" + role + "/oa"}>OA Details</Link>
                                    <Link to={"/" + role + "/oa/oa_report"} >PSO</Link>
                                    <Link to={"/" + role + "/oa/oaConfirmPage"}>MC</Link>
                                    <Link to={"/" + role + "/oa/oaDownload"} className="active">MC Report</Link>
                                    <Link to={"/" + role + "/oa/ms"}>MS</Link>
                                </div>
                                <div className="customer-search oa-search">
                                    <span><img src={Search} alt=".." className="search-img" /></span>
                                    <Form.Control type="search" placeholder="Search"
                                        onChange={searchHandler}
                                        className="main-search" />
                                </div>
                            </div>
                        </div>
                        <div className="infinite-table" id="scrollableDiv">
                            <InfiniteScroll
                                dataLength={downloadContent?.content?.length}
                                next={onChange}
                                hasMore={downloadContent?.content?.length < downloadList?.totalElements}
                                loader={downloadContent?.content?.length === 0 ? <p></p> : <p className='txt-center'><h4>Loading...</h4></p>}
                                endMessage={<p className='txt-center'>{downloadContent?.content?.length === 0 ? <p className='no-data-hg'> <b >{loading ? <Spinner /> : "No Data Found"}</b></p> : <b>Yay! You have seen it all</b>}</p>}
                                refreshFunction={() => { }}
                                pullDownToRefresh
                                scrollableTarget="scrollableDiv"
                                pullDownToRefreshThreshold={60}
                                pullDownToRefreshContent={<h3 className='txt-center'>&#8595; Pull down to refresh</h3>}
                                releaseToRefreshContent={<h3 className='txt-center'>&#8593; Release to refresh</h3>}
                            >
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th>Confirmation Date</th>
                                            <th>OA Nos</th>
                                            <th className="txt-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {downloadContent?.content?.map((item, a) => (
                                            <tr key={a} onClick={() => getId(item)}>
                                                <td>{filterDateFormat(item?.createdAt)}</td>
                                                <td>{item?.oaNumbers}</td>
                                                <td className="txt-center">
                                                    <button className="download_btnn" >
                                                        <img src={Art} alt=".." /> Pdf
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        </div>
                    </Card>
                </div>
            </div>
            <Modal
                show={show}
                onHide={closePopup}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-staff preview">
                    <div className="modal-staff head">
                        <div>
                            <div onClick={closePopup}>
                                <i class="fas fa-arrow-left"></i>
                            </div>
                            <h4 className="mod_head">
                                {id?.oaConfirmPdfName}
                            </h4>
                        </div>
                        <div>
                            <ReactToPrint
                                trigger={() => <button className="select-template download"> <img src={download} alt="..." /> Download</button>}
                                content={() => componentRef.current}
                            />
                        </div>
                    </div>
                    <Row className="preview-img " style={{ minHeight: "700px" }}>
                        <div className="go-hr"></div>
                        {id !== null ? <div className="template-two-design" ref={componentRef} dangerouslySetInnerHTML={{ __html: `${id?.html}` }} /> :
                            <div>
                                <img src={Logo} alt=".." className="spin rt-imgss" width="100px" />
                                <h2 className="rt-img">Please Wait...</h2>
                            </div>}
                    </Row>
                </div>
            </Modal>
        </Fragment >
    )
}

const mapStateToProps = (state) => {
    const { oa } = state
    return {
        downloadList: oa.downloadAllList,
        downloadContent: { content: oa.downloadContent },
        loading: oa.loading
    }
}

const mapDispatchToProps = (dispatch) => ({
    oaDownloadDispatch: (formData) => dispatch(oaDownload(formData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OaDownload)