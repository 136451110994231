import React from "react";
import { Table } from "react-bootstrap";
import Delete from "../../../../assets/images/ux.svg";
import { connect } from "react-redux";
import Spinner from "../../../../Components/loader";
import DeletePopUp from "../../vendorpo/deletePopup";
import { deleteBoqProduct, deleteCoProduct, deletePoProduct, deleteVendorProduct } from "../../../../actions";
import Edit from "../../../../assets/images/ui.svg";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import { displayCurrency } from "../../../../actions";

class ViewProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deletePopup: false,
      id: ""
    }
    this.deleteItemOpen = this.deleteItemOpen.bind(this);
    this.deleteItemClose = this.deleteItemClose.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
  }
  deleteItemOpen(item) {
    this.setState({
      deletePopup: true,
      id: item?.purchaseProjectProductId ?? item?.billOfQuantityProductId ?? item?.counterSaleProductId ?? item?.outwardReturnProductId
    })
  }
  deleteItemClose() {
    this.setState({
      deletePopup: false
    })
  }
  deleteProduct() {
    if (this.props.str === "boq") {
      this.setState({
        deletePopup: false
      })
      this.props.dispatch(deleteBoqProduct(this.state.id));
    } else if (this.props.str === "co") {
      this.setState({
        deletePopup: false
      })
      this.props.dispatch(deleteCoProduct(this.state.id));
    } else if (this.props.str === "return") {
      this.setState({
        deletePopup: false
      })
      this.props.dispatch(deleteVendorProduct(this.state.id))
    }
    else {
      this.props.dispatch(deletePoProduct(this.state.id));
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.deletePoProduct !== this.props.deletePoProduct) {
      if (this.props.deletePoProduct.status === 200) {
        this.setState({
          deletePopup: false
        })
      } else {
        toast(this.props.deletePoProduct.data);
      }
    }
  }
  render() {
    const { data, str } = this.props;
    const { deletePopup } = this.state;
    return (
      <div className="table-back" style={{ width: str === "co" && "100%" }}>
        <Table responsive className="table-blue">
          <thead>
            <tr>
              <th> Article ID  </th>
              <th>Product Name</th>
              <th>QTY</th>
              <th>Unit</th>
              {str !== "boq" && str !== "co" && str !== "return" ? <th style={{ textAlign: 'right' }}>Unit Cost</th> : null}
              {str !== "boq" && str !== "co" && str !== "return" ? <th style={{ textAlign: 'right' }}>Total Cost</th> : null}
              <th>Actions</th>
            </tr>
          </thead>
          {data !== undefined && data?.length > 0 ? (
            data.map((item, i) => (
              <tr key={i}>
                <td style={{ textOverflow: "ellipsis", overflow: "clip", maxWidth: "110px" }}>{item?.articleId}</td>
                <td style={{ textOverflow: "ellipsis", width: str === "boq" || str === "co" ? "60%" : "50%" }} >{item?.productName !== null && parse(item?.productName)}</td>
                <td style={{ textAlign: 'center' }}>{item?.quantity ?? item?.outwardReturnQuantity}</td>
                <td style={{ textAlign: 'center', textOverflow: "ellipsis" }}>{item?.unit?.unitName}</td>
                {str !== "boq" && str !== "co" && str !== "return" && <td style={{ textAlign: 'right', whiteSpace: "nowrap" }}>{displayCurrency(item?.unitPrice)}</td>}
                {str !== "boq" && str !== "co" && str !== "return" && <td style={{ textAlign: 'right', whiteSpace: "nowrap" }}>{displayCurrency(item?.totalPrice)}</td>}
                <td style={{ width: "140px", textAlign: "center" }}>
                  <img src={Edit} alt="" className="Edit-staffs" onClick={() => this.props.handleShow(item)} />
                  <img src={Delete} alt="" className="delete-staff" onClick={() => this.deleteItemOpen(item)} />
                </td>
              </tr>
            ))
          ) : !this.props.loading ?
            <h1 style={{ marginLeft: str !== "boq" && str !== "co" && str !== "return" ? "0px" : "-50px" }} className="empty_data"><br></br><br></br>No Records Found</h1> :
            <Spinner />
          }
        </Table>
        <DeletePopUp
          show={deletePopup}
          onHide={this.deleteItemClose}
          deleteItem={this.deleteProduct}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deletePoProduct: state.po.deletePoProduct,
    deleteBoqProduct: state.boq.deleteBoqProduct
  }
}

export default connect(mapStateToProps)(ViewProducts);