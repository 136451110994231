import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/images/main.svg";
import profile from "../assets/images/avathar1.jpg";
import Out from "../assets/images/outs.svg";
import Set from "../assets/images/sets.svg";
import Profile from "../assets/images/profile-pic.svg";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getStaffProfile, updatePassword, updateAdmin, S3_root, clearSingleCustomer } from "../actions";
import ProfileForm from "./ProfileForm";
const role = localStorage.getItem('userRole');

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: "",
      password: "",
      isFilePicked: false,
      userId: 0,
      staffProfile: [],
      show: false,
      data: [],
      open: false,
      passwordClose: true,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.imageHandler = this.imageHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changePassword = this.changePassword.bind(this);

  }

  componentDidMount() {
    this.props.dispatch(getStaffProfile());
    this.props.dispatch(clearSingleCustomer());
  }

  handleShow() {
    this.props.dispatch(getStaffProfile());
    this.setState({ show: true, });
  }

  handleClose() {
    this.setState({
      show: false,
    });
  }



  handleSubmit(values) {
    var formData = new FormData();
    if (this.state.selectedFile) {
      formData.append("profileImage", this.state.selectedFile, this.state.selectedFile.name);
    }
    formData.append('userId', values.userId);
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('email', values.email);
    formData.append('phoneNumber', values.phone);
    formData.append('gender', values.gender);

    this.props.dispatch(updateAdmin(formData));
  }


  componentDidUpdate(prevProps) {
    if (prevProps.staffProfile !== this.props.staffProfile) {
      this.setState({
        staffProfile: this.props.staffProfile,
      });
    }
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
        show: false
      });
      console.log(this.props.data);
      if (this.props.data.status === 200) {
        toast("Profile Edited Successfully");
        this.props.dispatch(getStaffProfile());
      } else {
        toast(this.props.data.message);
      }
    }
    if (prevProps.passwordData !== this.props.passwordData) {
      this.setState({
        passwordData: this.props.passwordData,
      });

      if (this.props.passwordData.status === 200) {
        toast(this.props.passwordData.data);
      } else {
        toast(this.props.passwordData.data);
      }
    }

  }


  changePassword(data) {
    this.props.dispatch(updatePassword(data));
  }

  imageHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      isFilePicked: true,
    })
  }
  appLogout(e) {
    e.preventDefault();
    localStorage.clear();
    window.location = '/';
  }

  render() {
    const { show, selectedFile, isFilePicked, success_msg } = this.state;
    const { staffProfile, passwordData } = this.props;
    let img = staffProfile?.profileImageUrl;
    let imgUrl = img && img !== undefined ? S3_root + img : profile;



    return (
      <Row className="nav-top">
        <Col md={2}>
          <Link to="/dashboard">
            <img src={Logo} alt=".." className="spin" width="63px" />
            <p className="admin">IDS Group</p>
          </Link>
        </Col>
        <Col md={8}>
        </Col>
        <Col md={2}>
          <div class="dropdown">
            <button class="dropbtn">

              <div className="notification-profile">
                <img src={imgUrl} alt="..." width="35px" className="profiles" />
                <h6>{`${staffProfile?.firstName ?? "admin"} ${staffProfile?.lastName === undefined ? " " : staffProfile?.lastName !== "undefined" ? staffProfile.lastName : ""} `}</h6>
                <p>{staffProfile?.email ?? "admin"}</p>
              </div>
            </button>
            <div class="dropdown-content">
              <p onClick={this.handleShow}> <img src={Profile} />My Profile</p>
              {role === "admin" && <Link to={"/" + role + "/gst"} style={{ marginTop: "-13px" }}><img src={Set} />Settings</Link>}
              <Link to="/" onClick={this.appLogout}><img src={Out} />Log Out</Link>
            </div>
          </div>
          <ProfileForm onSubmit={this.handleSubmit} getPassword={this.getPasswordData} show={show}
            onHide={this.handleClose} changePass={this.changePassword}
            imgHandler={this.imageHandler} selectedFile={selectedFile} filePick={isFilePicked} success_msg={success_msg} />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.admin.loading,
    staffProfile: state.admin.staffProfile,
    data: state.admin.data,
    passwordData: state.admin.passwordData
  };
};

export default connect(mapStateToProps)(Navbar);
