import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import { Router, HashRouter } from 'react-router-dom';
import { history } from './helpers';
import App from "./App";
import configureStore from "./configureStore";
import * as serviceWorker from "./serviceWorker";
const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <HashRouter history={history}>
            <App />
        </HashRouter>
    </Provider>,
    document.getElementById("root")
);

serviceWorker.unregister();
