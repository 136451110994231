import React from "react";
import { Table } from "react-bootstrap";
import Delete from "../../../../assets/images/ux.svg";
import { connect } from "react-redux";
import Spinner from "../../../../Components/loader";
import { S3_root } from "../../../../actions";
import Edit from "../../../../assets/images/ui.svg";
import profile from "../../../../assets/images/we.svg";
import parse from 'html-react-parser';
import { displayCurrency, deleteQuotationProducts } from "../../../../actions";
import DeletePopUp from "../../../../Components/deletePopup";

class ViewProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deletePopup: false,
      id: null
    }
    this.deleteItemOpen = this.deleteItemOpen.bind(this);
    this.deleteItemClose = this.deleteItemClose.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
  }
  deleteItemOpen(item) {
    this.setState({
      deletePopup: true,
      id: item.groupProductId
    })
  }
  deleteItemClose() {
    this.setState({
      deletePopup: false
    })
  }
  deleteProduct() {
    const { id } = this.state;
    this.props.dispatch(deleteQuotationProducts(id));
    this.props.getAmounts();
    this.deleteItemClose();
  }
  render() {
    const { data, str, quotationData } = this.props;
    const { deletePopup } = this.state;
    return (
      <>
        <div className="table-back" style={{ width: "108%", marginLeft: "18px", padding: "20px" }}>
          <Table responsive className="table-blue"  >
            <thead>
              <tr>
                <th > Article ID  </th>
                <th > Image  </th>
                <th  >Product Name</th>
                <th >QTY</th>
                <th >Unit</th>
                <th>Unit Cost</th>
                <th>Dis(%)</th>
                <th>Total Cost</th>
                <th >GST</th>
                <th>Actions</th>
              </tr>
            </thead>
            {data !== undefined && data?.length > 0 ? (
              data.map((item, i) => (
                <tr key={i}>
                  <td style={{ textOverflow: "ellipsis", overflow: "clip" }}>{item?.articleId}</td>
                  <td className="txt-center">
                    <img width="30px" className="pro-imgs" src={item.productImageUrl ?? item.productImageUrl != null ? S3_root + item.productImageUrl : profile} />
                  </td>
                  <td className="td-class"> {parse(item?.productName)}</td>
                  <td className="txt-center">{item?.quantity}</td>
                  <td className="txt-center">{item?.unit}</td>
                  <td className="txt-right" style={{ width: "10%", maxWidth: "200px", whiteSpace: "pre-wrap" }} >{displayCurrency(item.unitCost)}</td>
                  <td className="txt-center">{item.discount}</td>
                  {/* <td>{displayCurrency(item.totalCost)}</td> */}
                  {/* <td style={{ textAlign: "right" }}>{displayCurrency(item?.unitCost - (item.unitCost * item.discount * 0.01))}</td> */}
                  <td className="txt-right" style={{ width: "12%", maxWidth: "230px", whiteSpace: "pre-wrap" }} >{displayCurrency((item?.unitCost - (item.unitCost * item.discount * 0.01)) * item.quantity)}</td>
                  <td className="txt-center">{quotationData?.gstPercent}</td>
                  <td style={{ width: "115px", textAlign: "center" }}>
                    <img src={Edit} alt="" className="Edit-staffs" onClick={() => this.props.handleShow(item)} />
                    <img src={Delete} alt="" className="delete-staff" onClick={() => this.deleteItemOpen(item)} />
                  </td>
                </tr>
              ))
            ) : !this.props.loading ?
              <h1 style={{ margin: "50px", color: "black" }} className="empty_data"><br></br><br></br>No Records Found</h1> :
              <Spinner />
            }
          </Table>
          <div id="table-bluee"></div>
          <DeletePopUp
            show={deletePopup}
            onHide={this.deleteItemClose}
            deleteItem={this.deleteProduct}
          />
        </div>

      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    quotationData: state.qutation.quotationData,
    deleteGroupProducts: state.qutation.deleteGroupProducts,
  }
}

export default connect(mapStateToProps)(ViewProducts);