export const reportOaConstants = {

    // report oa List
    GET_REPORT_OA: "GET_REPORT_OA_LIST",
    GET_REPORT_OA_SUCCESS: "GET_REPORT_OA_LIST_SUCCESS",
    GET_REPORT_OA_FAILURE: "GET_REPORT_OA_LIST_FAILURE",

    GET_REPORT_OA_MS: "GET_REPORT_OA_MS_LIST",
    GET_REPORT_OA_MS_SUCCESS: "GET_REPORT_OA_MS_LIST_SUCCESS",
    GET_REPORT_OA_MS_FAILURE: "GET_REPORT_OA_MS_LIST_FAILURE",

    GET_REPORT_OA_MS_CSV: "GET_REPORT_OA_MS_LIST_CSV",
    GET_REPORT_OA_MS_CSV_SUCCESS: "GET_REPORT_OA_MS_LIST_CSV_SUCCESS",
    GET_REPORT_OA_MS_CSV_FAILURE: "GET_REPORT_OA_MS_LIST_CSV_FAILURE",

    CLEAR_MS_CSV: "CLEAR_MS_CSV",

    OA_MS_INWARD: "OA_MS_INWARD",
    OA_MS_INWARD_SUCCESS: "OA_MS_INWARD_SUCCESS",
    OA_MS_INWARD_FAILURE: "OA_MS_INWARD_FAILURE",

    // report oa confirm popup list
    GET_REPORT_CONFIRM_OA: "GET_REPORT_CONFIRM_OA_LIST",
    GET_REPORT_CONFIRM_OA_SUCCESS: "GET_REPORT_CONFIRM_OA_LIST_SUCCESS",
    GET_REPORT_CONFIRM_OA_FAILURE: "GET_REPORT_CONFIRM_OA_LIST_FAILURE",

    // report solo page list
    GET_REPORT_OA_PAGE: "GET_REPORT_OA",
    GET_REPORT_OA_PAGE_SUCCESS: "GET_REPORT_OA_SUCCESS",
    GET_REPORT_OA_PAGE_FAILURE: "GET_REPORT_OA_FAILURE",

    // report solo page form submit
    GET_REPORT_OA_PAGE_FORM: "GET_REPORT_OA_FORM",
    GET_REPORT_OA_PAGE_FORM_SUCCESS: "GET_REPORT_OA_FORM_SUCCESS",
    GET_REPORT_OA_PAGE_FORM_FAILURE: "GET_REPORT_OA_FORM_FAILURE",

    // oa confirm listing
    GET_REPORT_OA_REPORT: "GET_REPORT_OA_LIST_REPORT",
    GET_REPORT_OA_SUCCESS_REPORT: "GET_REPORT_OA_LIST_SUCCESS_REPORT",
    GET_REPORT_OA_FAILURE_REPORT: "GET_REPORT_OA_LIST_FAILURE_REPORT",

    CREATE_PO_MS_INWARD_PRODUCTS: "CREATE_PO_MS_INWARD_PRODUCTS",
    CREATE_PO_MS_INWARD_PRODUCTS_SUCCESS: "CREATE_PO_MS_INWARD_PRODUCTS_SUCCESS",
    CREATE_PO_MS_INWARD_PRODUCTS_FAILURE: "CREATE_PO_MS_INWARD_PRODUCTS_FAILURE",

    CLEAR_INWARD_STATUS: "CLEAR_INWARD_STATUS",

    // oa confirm csv download

    DOWNLOAD_CSV_REPORT_OA: "DOWNLOAD_CSV_REPORT_OA_LIST",
    DOWNLOAD_CSV_REPORT_OA_SUCCESS: "DOWNLOAD_CSV_REPORT_OA_LIST_SUCCESS",
    DOWNLOAD_CSV_REPORT_OA_FAILURE: "DOWNLOAD_CSV_REPORT_OA_LIST_FAILURE",

    // oa report csv download

    DOWNLOAD_CSV_REPORT_OA_REPORT: "DOWNLOAD_CSV_REPORT_OA_REPORT_LIST",
    DOWNLOAD_CSV_REPORT_OA_REPORT_SUCCESS: "DOWNLOAD_CSV_REPORT_OA_REPORT_LIST_SUCCESS",
    DOWNLOAD_CSV_REPORT_OA_REPORT_FAILURE: "DOWNLOAD_CSV_REPORT_OA_REPORT_LIST_FAILURE",

    // TODO: oa confirm pdf download
    OA_PDF_REPORT_OA: "OA_PDF_REPORT_OA_LIST",
    OA_PDF_REPORT_OA_SUCCESS: "OA_PDF_REPORT_OA_LIST_SUCCESS",
    OA_PDF_REPORT_OA_FAILURE: "OA_PDF_REPORT_OA_LIST_FAILURE",

    // TODO: oa confirm download
    OA_PDF_DOWNLOAD_OA: "OA_PDF_DOWNLOAD_OA_LIST",
    OA_PDF_DOWNLOAD_OA_SUCCESS: "OA_PDF_DOWNLOAD_OA_LIST_SUCCESS",
    OA_PDF_DOWNLOAD_OA_FAILURE: "OA_PDF_DOWNLOAD_OA_LIST_FAILURE",


    // TODO: oa csv
    OA_CSV: "OA_CSV_LIST",
    OA_CSV_SUCCESS: "OA_CSV_LIST_SUCCESS",
    OA_CSV_FAILURE: "OA_CSV_LIST_FAILURE",


    CLEAR_PDF_REPORT_OA: "CLEAR_PDF_REPORT_OA_LIST",
    CLEAR_CSV_REPORT_OA: "CLEAR_CSV_REPORT_OA_LIST",
}