export const vendorConstants = {
    GET_VENDOR: "GET_VENDOR_LIST",
    GET_VENDOR_SUCCESS: "GET_VENDOR_LIST_SUCCESS",
    GET_VENDOR_FAILURE: "GET_VENDOR_LIST_FAILURE",

    //particular vendor get
    GET_VENDOR_STAFF: "GET_VENDOR_STAFF_LIST",
    GET_VENDOR_STAFF_SUCCESS: "GET_VENDOR_STAFF_LIST_SUCCESS",
    GET_VENDOR_STAFF_FAILURE: "GET_VENDOR_STAFF_LIST_FAILURE",

    CREATE_VENDOR_INIT: "CREATE_VENDOR_INIT",
    CREATE_VENDOR_SUCCESS: "CREATE_VENDOR_SUCCESS",
    CREATE_VENDOR_FAILURE: "CREATE_VENDOR_FAILURE",

    UPDATE_VENDOR_INIT: "UPDATE_VENDOR_INIT",
    UPDATE_VENDOR_SUCCESS: "UPDATE_VENDOR_SUCCESS",
    UPDATE_VENDOR_FAILURE: "UPDATE_VENDOR_FAILURE",

    CREATE_VENDOR_SECONDARY_MAIL_INIT: "CREATE_VENDOR_SECONDARY_MAIL_INIT",
    CREATE_VENDOR_SECONDARY_MAIL_SUCCESS: "CREATE_VENDOR_SECONDARY_MAIL_SUCCESS",
    CREATE_VENDOR_SECONDARY_MAIL_FAILURE: "CREATE_VENDOR_FAILURE",

    UPDATE_VENDOR_SECONDARY_MAIL_INIT: "UPDATE_VENDOR_SECONDARY_MAIL_INIT",
    UPDATE_VENDOR_SECONDARY_MAIL_SUCCESS: "UPDATE_VENDOR_SECONDARY_MAIL_SUCCESS",
    UPDATE_VENDOR_SECONDARY_MAIL_FAILURE: "UPDATE_VENDOR_FAILURE",

    GET_VENDOR_SECONDARY_MAIL: "GET_VENDOR_SECONDARY_MAIL_LIST",
    GET_VENDOR_SECONDARY_MAIL_SUCCESS: "GET_VENDOR_SECONDARY_MAIL_LIST_SUCCESS",
    GET_VENDOR_SECONDARY_MAIL_FAILURE: "GET_VENDOR_LIST_FAILURE",

    CHANGE_PAGE: "CHANGE_PAGE",
}