import { put, call, takeEvery } from "redux-saga/effects";
import { billofquantityConstants } from "../constants";
import {
    getBillOfQuantityApi,
    createBillOfQuantityApi,
    getOneBillOfQuantityApi,
    updateBillOfQuantityPoApi,
    getConvertBoqToPoList,
    getConfirmQuotDropdownApi,
    getConfirmQuotProductsApi,
    getBoqProjectDetailApi,
    createBoqApi,
    createBoqProjectApi,
    updateBoqProjectApi,
    createBoqProductApi,
    updateBoqProductApi,
    deleteBoqProductApi,
    previewBoqApi,
    getBoqByIdApi,
    generateBoqApi,
    sendBoqApi,
    cloneBoqApi,
    deleteBoqApi,
    reviseBoqApi,
    editBoqCustomProductApi,
    getProjectOutwardListApi,
    getProjectOutwardListV2Api,
    getInternalTransferProductApi,
    deliverBoqApi,
    updateInternalTransferApi,
    previewPackingSlipApi,
    getBoqProductDetailsApi,
    getBoqMailDetailsApi,
    sendBoqMailApi,
    csvBoqDownloadApi,
} from "../services";

export function* billofquantitylistSaga(payload) {
    try {
        const response = yield call(getBillOfQuantityApi, payload.item);
        yield put({ type: billofquantityConstants.GET_BILL_OF_QUANTITY_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.GET_BILL_OF_QUANTITY_FAILURE, error });
    }
}
export function* createBoqSaga(payload) {
    try {
        const response = yield call(createBoqApi, payload);
        yield put({ type: billofquantityConstants.CREATE_BOQ_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.CREATE_BOQ_FAILURE, error });
    }
}
export function* confirmQuotDropdownSaga(payload) {
    try {
        const response = yield call(getConfirmQuotDropdownApi, payload);
        yield put({ type: billofquantityConstants.GET_CONFIRM_QUOTATION_DROPDOWN_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.GET_CONFIRM_QUOTATION_DROPDOWN_FAILURE, error });
    }
}
export function* confirmQuotProductSaga(payload) {
    try {
        const response = yield call(getConfirmQuotProductsApi, payload.quotationId);
        yield put({ type: billofquantityConstants.GET_CONFIRM_QUOTATION_PRODUCTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.GET_CONFIRM_QUOTATION_PRODUCTS_FAILURE, error });
    }
}
export function* convertBoqToPoSaga(payload) {
    try {
        const response = yield call(getConvertBoqToPoList, payload);
        yield put({ type: billofquantityConstants.GET_CONVERT_BOQ_TO_PO_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.GET_CONVERT_BOQ_TO_PO_FAILURE, error });
    }
}
export function* createbillofquantitySaga(payload) {
    try {
        const response = yield call(createBillOfQuantityApi, payload);
        yield put({ type: billofquantityConstants.CREATE_BILL_OF_QUANTITY_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.CREATE_BILL_OF_QUANTITY_FAILURE, error });
    }
}
export function* getbillofquantitySaga(payload) {
    try {
        const response = yield call(getOneBillOfQuantityApi, payload.billofquantityId);
        yield put({ type: billofquantityConstants.SINGLE_BILL_OF_QUANTITY_SUCCESSD, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.SINGLE_BILL_OF_QUANTITY_FAILURED, error });
    }
}
export function* updatebillofquantitySaga(payload) {
    try {
        const response = yield call(updateBillOfQuantityPoApi, payload);
        yield put({ type: billofquantityConstants.UPDATE_BILL_OF_QUANTITY_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.UPDATE_BILL_OF_QUANTITY_FAILURE, error });
    }
}

// TODO: Boq csv Download
export function* csvBoqDownloadSaga(payload) {
    try {
        const response = yield call(csvBoqDownloadApi, payload);
        yield put({ type: billofquantityConstants.DOWNLOAD_BOQ_CSV_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.DOWNLOAD_BOQ_CSV_FAILURE, error });
    }
}

export function* boqProjectSaga(payload) {
    try {
        const response = yield call(getBoqProjectDetailApi, payload.id);
        yield put({ type: billofquantityConstants.GET_BOQ_PROJECTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.GET_BOQ_PROJECTS_FAILURE, error });
    }
}
export function* createBoqProjectSaga(payload) {
    try {
        const response = yield call(createBoqProjectApi, payload);
        yield put({ type: billofquantityConstants.CREATE_BOQ_PROJECT_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.CREATE_BOQ_PROJECT_FAILURE, error });
    }
}
export function* updateBoqProjectSaga(payload) {
    try {
        const response = yield call(updateBoqProjectApi, payload);
        yield put({ type: billofquantityConstants.EDIT_BOQ_PROJECT_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.EDIT_BOQ_PROJECT_FAILURE, error });
    }
}
export function* createBoqProductSaga(payload) {
    try {
        const response = yield call(createBoqProductApi, payload);
        yield put({ type: billofquantityConstants.CREATE_BOQ_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.CREATE_BOQ_PRODUCT_FAILURE, error });
    }
}
export function* updateBoqProductSaga(payload) {
    try {
        const response = yield call(updateBoqProductApi, payload);
        yield put({ type: billofquantityConstants.EDIT_BOQ_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.EDIT_BOQ_PRODUCT_FAILURE, error });
    }
}
export function* deleteBoqProductSaga(payload) {
    try {
        const response = yield call(deleteBoqProductApi, payload.id);
        yield put({ type: billofquantityConstants.DELETE_BOQ_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.DELETE_BOQ_PRODUCT_FAILURE, error });
    }
}
export function* previewBoqSaga(payload) {
    try {
        const response = yield call(previewBoqApi, payload.id);
        yield put({ type: billofquantityConstants.PREVIEW_BOQ_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.PREVIEW_BOQ_FAILURE, error });
    }
}
export function* getParticularBoqSaga(payload) {
    try {
        const response = yield call(getBoqByIdApi, payload.id);
        yield put({ type: billofquantityConstants.GET_BOQ_BY_ID_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.GET_BOQ_BY_ID_FAILURE, error });
    }
}
export function* generateBoqSaga(payload) {
    try {
        const response = yield call(generateBoqApi, payload.id);
        yield put({ type: billofquantityConstants.GENERATE_BOQ_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.GENERATE_BOQ_FAILURE, error });
    }
}
export function* sendBoqSaga(payload) {
    try {
        const response = yield call(sendBoqApi, payload.id);
        yield put({ type: billofquantityConstants.SENT_BOQ_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.SENT_BOQ_FAILURE, error });
    }
}
export function* cloneBoqSaga(payload) {
    try {
        const response = yield call(cloneBoqApi, payload.id);
        yield put({ type: billofquantityConstants.CLONE_BOQ_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.CLONE_BOQ_FAILURE, error });
    }
}
export function* reviseBoqSaga(payload) {
    try {
        const response = yield call(reviseBoqApi, payload.id);
        yield put({ type: billofquantityConstants.REVISE_BOQ_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.REVISE_BOQ_FAILURE, error });
    }
}
export function* deleteBoqSaga(payload) {
    try {
        const response = yield call(deleteBoqApi, payload.id);
        yield put({ type: billofquantityConstants.DELETE_BOQ_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.DELETE_BOQ_FAILURE, error });
    }
}
export function* updateBoqCustomProductSaga(payload) {
    try {
        const response = yield call(editBoqCustomProductApi, payload);
        console.log(response);
        yield put({ type: billofquantityConstants.UPDATE_CUSTOM_BOQ_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.UPDATE_CUSTOM_BOQ_PRODUCT_FAILURE, error });
    }
}
export function* getProjectOutwardSaga(payload) {
    try {
        const response = yield call(getProjectOutwardListApi, payload);
        yield put({ type: billofquantityConstants.GET_PROJECT_OUTWARD_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.GET_PROJECT_OUTWARD_FAILURE, error });
    }
}

export function* getProjectOutwardV2Saga(payload) {
    try {
        const response = yield call(getProjectOutwardListV2Api, payload);
        yield put({ type: billofquantityConstants.GET_PROJECT_OUTWARD_V2_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.GET_PROJECT_OUTWARD_V2_FAILURE, error });
    }
}

export function* getInternalTransferSaga(payload) {
    try {
        const response = yield call(getInternalTransferProductApi, payload.id);
        yield put({ type: billofquantityConstants.GET_INTERNAL_TRANSFER_PRODUCT_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.GET_INTERNAL_TRANSFER_PRODUCT_FAILURE, error });
    }
}
export function* deliverBoqSaga(payload) {
    try {
        const response = yield call(deliverBoqApi, payload);
        yield put({ type: billofquantityConstants.DELIVER_BOQ_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.DELIVER_BOQ_FAILURE, error });
    }
}
export function* updateInternalTransferSaga(payload) {
    try {
        const response = yield call(updateInternalTransferApi, payload);
        yield put({ type: billofquantityConstants.UPDATE_INTERNAL_TRANSFER_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.UPDATE_INTERNAL_TRANSFER_FAILURE, error });
    }
}
export function* previewPackingSlipSaga(payload) {
    try {
        const response = yield call(previewPackingSlipApi, payload.id);
        yield put({ type: billofquantityConstants.PREVIEW_PACKING_SLIP_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.PREVIEW_PACKING_SLIP_FAILURE, error });
    }
}
export function* getBoqProductDetailsSaga(payload) {
    try {
        const response = yield call(getBoqProductDetailsApi, payload.id);
        yield put({ type: billofquantityConstants.GET_BOQ_PRODUCT_DETAILS_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.GET_BOQ_PRODUCT_DETAILS_FAILURE, error });
    }
}
export function* getBoqMailDetailsSage(payload) {
    try {
        const response = yield call(getBoqMailDetailsApi, payload.id);
        yield put({ type: billofquantityConstants.GET_BOQ_MAIL_DETAILS_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.GET_BOQ_MAIL_DETAILS_FAILURE, error });
    }
}
export function* sendPoMailSaga(payload) {
    try {
        const response = yield call(sendBoqMailApi, payload);
        yield put({ type: billofquantityConstants.SENT_BOQ_MAIL_SUCCESS, response });
    } catch (error) {
        yield put({ type: billofquantityConstants.SENT_BOQ_MAIL_FAILURE, error });
    }
}
export default function* boqSaga() {
    yield takeEvery(billofquantityConstants.GET_BILL_OF_QUANTITY, billofquantitylistSaga);
    yield takeEvery(billofquantityConstants.GET_CONVERT_BOQ_TO_PO, convertBoqToPoSaga);
    yield takeEvery(billofquantityConstants.CREATE_BOQ, createBoqSaga);
    yield takeEvery(billofquantityConstants.CREATE_BOQ_PROJECT, createBoqProjectSaga);
    yield takeEvery(billofquantityConstants.EDIT_BOQ_PROJECT, updateBoqProjectSaga);
    yield takeEvery(billofquantityConstants.CREATE_BOQ_PRODUCT, createBoqProductSaga);
    yield takeEvery(billofquantityConstants.EDIT_BOQ_PRODUCT, updateBoqProductSaga);
    yield takeEvery(billofquantityConstants.DELETE_BOQ_PRODUCT, deleteBoqProductSaga);
    yield takeEvery(billofquantityConstants.GET_CONFIRM_QUOTATION_DROPDOWN, confirmQuotDropdownSaga);
    yield takeEvery(billofquantityConstants.GET_CONFIRM_QUOTATION_PRODUCTS, confirmQuotProductSaga);
    yield takeEvery(billofquantityConstants.CREATE_BILL_OF_QUANTITY_INIT, createbillofquantitySaga);
    yield takeEvery(billofquantityConstants.SINGLE_BILL_OF_QUANTITY_INIT, getbillofquantitySaga);
    yield takeEvery(billofquantityConstants.UPDATE_BILL_OF_QUANTITY_INIT, updatebillofquantitySaga);
    yield takeEvery(billofquantityConstants.GET_BOQ_PROJECTS, boqProjectSaga);
    yield takeEvery(billofquantityConstants.PREVIEW_BOQ, previewBoqSaga);
    yield takeEvery(billofquantityConstants.GET_BOQ_BY_ID, getParticularBoqSaga);
    yield takeEvery(billofquantityConstants.GENERATE_BOQ, generateBoqSaga);
    yield takeEvery(billofquantityConstants.SENT_BOQ, sendBoqSaga);
    yield takeEvery(billofquantityConstants.CLONE_BOQ, cloneBoqSaga);
    yield takeEvery(billofquantityConstants.REVISE_BOQ, reviseBoqSaga);
    yield takeEvery(billofquantityConstants.DELETE_BOQ, deleteBoqSaga);
    yield takeEvery(billofquantityConstants.UPDATE_CUSTOM_BOQ_PRODUCT, updateBoqCustomProductSaga);
    yield takeEvery(billofquantityConstants.GET_PROJECT_OUTWARD, getProjectOutwardSaga);
    yield takeEvery(billofquantityConstants.GET_INTERNAL_TRANSFER_PRODUCT, getInternalTransferSaga);
    yield takeEvery(billofquantityConstants.DELIVER_BOQ, deliverBoqSaga);
    yield takeEvery(billofquantityConstants.UPDATE_INTERNAL_TRANSFER, updateInternalTransferSaga);
    yield takeEvery(billofquantityConstants.PREVIEW_PACKING_SLIP, previewPackingSlipSaga);
    yield takeEvery(billofquantityConstants.GET_BOQ_PRODUCT_DETAILS, getBoqProductDetailsSaga);
    yield takeEvery(billofquantityConstants.GET_BOQ_MAIL_DETAILS, getBoqMailDetailsSage);
    yield takeEvery(billofquantityConstants.SENT_BOQ_MAIL, sendPoMailSaga);
    yield takeEvery(billofquantityConstants.DOWNLOAD_BOQ_CSV, csvBoqDownloadSaga);
    yield takeEvery(billofquantityConstants.GET_PROJECT_OUTWARD_V2, getProjectOutwardV2Saga);
}