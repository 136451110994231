import { authToken, baseUrl } from "../actions";


export const getDashboardApi = () => {
    const DASHBOARD_LIST = `${baseUrl}staff/dashboard/count`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
            redirect: "follow",
        },
    };

    return fetch(DASHBOARD_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};


export const getDashboardCountApi = () => {
    const DASHBOARD_COUNT_LIST = `${baseUrl}staff/dashboard/purchaseOrder/count`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
            redirect: "follow",
        },
    };

    return fetch(DASHBOARD_COUNT_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};


export const getDashboardOutofStockApi = () => {
    const DASHBOARD_OUT_OF_STOCK_LIST = `${baseUrl}staff/products/outOfStock`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
            redirect: "follow",
        },
    };

    return fetch(DASHBOARD_OUT_OF_STOCK_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};