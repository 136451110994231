import { reportcustomerconstants } from "../constants";

const initialState = {
    loading: false,
    data: [],
    error: [],
    token: "",
    status: 0,
};

export default function staffexecutiveReducer(state = initialState, { type, response }) {
    switch (type) {
        case reportcustomerconstants.GET_REPORT_CUSTOMER:
            return {
                ...state,
                loading: true,
            };
        case reportcustomerconstants.GET_REPORT_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                report_customer: response.data.content,
                allData: response.data,
            };
        case reportcustomerconstants.GET_REPORT_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
}
