import React, { useRef } from 'react'
import { reduxForm } from 'redux-form';
import { Modal, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import parse from 'html-react-parser';
import ReactToPrint from 'react-to-print';
import download from "../../../assets/images/dv.svg";

const PackingSlip = props => {
    const { show, onHide, previewPackingSlipText, boqProductData } = props;
    const componentRef = useRef();
    return (
        <>
            <Modal
                show={show}
                size="md"
                onHide={() => {
                    onHide();
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-staff">
                    <div className="head">
                        <div>
                            <i class="fas fa-arrow-left" onClick={() => {
                                onHide();
                            }}></i>
                            <h4>PACKING SLIP</h4>
                        </div>
                        <ReactToPrint
                            trigger={() => <img style={{ cursor: "pointer" }} src={download} alt="" />}
                            content={() => componentRef.current}
                        />
                    </div>
                    {previewPackingSlipText !== "" && <Row className="preview-img" style={{ display: "none" }}>
                        <div className="template-two-design" ref={componentRef} dangerouslySetInnerHTML={{ __html: `${previewPackingSlipText}` }} />
                    </Row>}
                    <div className="table-class" style={{ padding: "20px" }}>
                        <table class="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">

                                        Product Name
                                    </th>
                                    <th scope="col" style={{ textAlign: "center" }}>
                                        Qty.
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {boqProductData !== "Oops! an unexpected error occurred, please contact us" && boqProductData !== undefined && boqProductData?.length > 0 ?
                                    boqProductData?.map((item, i) => (
                                        <tr key={i} >
                                            <td >
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.productName || "-")}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        {parse(item.productName || "-")}
                                                    </span>
                                                </OverlayTrigger>
                                            </td>
                                            <td style={{ textAlign: "center" }}>{item?.quantity}</td>
                                        </tr>
                                    )) : <></>
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
            </Modal>

        </>
    )
}

function mapStateToProps(state) {
    return {
        boqProductData: state.boq.boqProductData,
        previewPackingSlipText: state.boq.previewPackingSlipText
    }
}

const Form = reduxForm({
    form: "PackingSlip",
    destroyOnUnmount: false,
    enableReinitialize: true,
    // validate
})(PackingSlip);

export default connect(mapStateToProps)(Form);
