import React, { useState } from "react";
import { Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import { Table } from "react-bootstrap";
import Delete from "../../../../assets/images/ux.svg";
import Spinner from "../../../../Components/loader";
import Edit from "../../../../assets/images/ui.svg";
import profile from "../../../../assets/images/we.svg";
import { displayCurrency, S3_root, newDeleteTempTable } from "../../../../actions";

const ViewCustomProducts = (props) => {

    let dispatch = useDispatch();

    const { data, handleValue, allData } = props;

    const generateAlphabeticPairs = (length) => {
        if (length < 1 || length > 26) {
            return [];
        }
        const alphabetElement = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const letterToShow = alphabetElement[length - 1];
        return [letterToShow];
    };

    const generateLetterSequence = (length) => {
        if (length < 1 || length > 26) {
            return '';
        }

        const alphabetOrder = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const sequence = [];

        for (let i = 0; i < length; i++) {
            sequence.push(alphabetOrder[i]);
        }

        return sequence.join(' + ');
    };

    const confirm = (e) => {
        dispatch(newDeleteTempTable(e.customProductId, e.quotationId, e.quotationGroupId))
    };

    return (
        <>
            <div className="table-back" style={{ width: "108%", marginLeft: "18px", padding: "20px" }}>
                <Table responsive className="table-blue"  >
                    <thead>
                        <tr>
                            <th style={{ maxWidth: "70px" }}>Serial No</th>
                            <th style={{ width: "800px" }}>Description</th>
                            <th>Qty</th>
                            <th>Rate/Unit</th>
                            <th>Total Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data !== undefined && data?.length > 0 ? (
                            data?.map((item, i) => (
                                <>
                                    <tr key={i}>
                                        <td rowSpan={3} className="txt-center">{i + 1}</td>
                                        <td>{item?.description1}</td>
                                        <td className="txt-center">{item?.quantity1}</td>
                                        <td className="txt-center">{item?.ratePerUnit1}</td>
                                        <td className="txt-right">{displayCurrency(item?.totalCost1)}</td>
                                        <td rowSpan={3}>
                                            <img src={Edit} alt="" onClick={() => handleValue(item)} className="Edit-staffs" />
                                            <Popconfirm
                                                title="Are you sure to delete this product?"
                                                onConfirm={() => confirm(item)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <img src={Delete} alt="" className="delete-staff" />
                                            </Popconfirm>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{item?.description2}</td>
                                        <td className="txt-center">{item?.quantity2}</td>
                                        <td className="txt-center">{item?.ratePerUnit2}</td>
                                        <td className="txt-right">{displayCurrency(item?.totalCost2)}</td>
                                    </tr>
                                    <tr className="txt-center">
                                        <td colSpan={3} className="txt-right">Total Amount ( {generateAlphabeticPairs(i + 1)} )</td>
                                        <td className="txt-right">{displayCurrency(item?.totalAmount)}</td>
                                    </tr>
                                    <tr className="txt-center">
                                        <td colSpan={6}><img width="80%" src={item?.customProductImageUrl ?? item?.customProductImageUrl != null ? S3_root + item?.customProductImageUrl : profile} /></td>
                                    </tr>
                                </>
                            ))
                        ) : !this.props.loading ?
                            <h1 style={{ margin: "50px", color: "black" }} className="empty_data"><br></br><br></br>No Records Found</h1> :
                            <Spinner />
                        }
                        <tr>
                            <td colSpan={5} className="txt-right">Total Amount ( {generateLetterSequence(data?.length)} )</td>
                            <td className="txt-right">{displayCurrency(allData?.totalAmount)}</td>

                        </tr>
                        {/* <tr>
                            <td colSpan={5} className="txt-right">Transport Charges</td>
                            <td className="txt-right">{displayCurrency(0)}</td>

                        </tr>
                        <tr>
                            <td colSpan={5} className="txt-right">Total Taxable Amount</td>
                            <td className="txt-right">{displayCurrency(0)}</td>

                        </tr>
                        <tr>
                            <td colSpan={5} className="txt-right">Gst 18%</td>
                            <td className="txt-right">{displayCurrency(allData?.gst)}</td>

                        </tr>
                        <tr>
                            <td colSpan={5} className="txt-right">Total Amount</td>
                            <td className="txt-right">{displayCurrency(allData?.totalAmount)}</td>
                        </tr> */}
                    </tbody>
                </Table >
            </div >
        </>
    )
}

export default ViewCustomProducts;
