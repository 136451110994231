import React from 'react'
import { Field, reduxForm } from 'redux-form';
import {
    getProductList,
    createStock,
    addReturnInwardProduct,
    getVerifiedProject,
    getProductById,
    getVendorList,
    getCustomersList
} from '../../actions';
import { Row, Col, Modal, Form } from "react-bootstrap";
import { SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import validate from './Validate';
import plus from "../../assets/images/pl.svg";
import { toast } from 'react-toastify';

const renderField = ({ input, placeholder, maxLength, disabled, type, readOnly, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input {...input} placeholder={placeholder} disabled={disabled} type={type} className="form-control" maxLength={maxLength} readOnly={readOnly} />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

const changeClassName = (str) => {
    if (str === "inward") {
        return "product-add-staffs"
    } else {
        return "add-stock"
    }
}

class AddStock extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
            allData: [],
            checked: false,
            load: false,
            projects: [],
            inStock: 0,
            totalStock: 0,
            customerId: null
        }
        this.productNameChange = this.productNameChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.addStock = this.addStock.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.customerChange = this.customerChange.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.getQuantity = this.getQuantity.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.bulkProducts !== this.props.bulkProducts) {
            this.setState({
                items: this.props.bulkProducts.content,
            });
        } if (prevProps.allData !== this.props.allData) {
            this.setState({
                allData: this.props.allData.content,
            });
        } if (prevProps.returnInwardSuccess !== this.props.returnInwardSuccess) {
            const { returnInwardSuccess, onHide, listData } = this.props;
            if (returnInwardSuccess.status === 200) {
                onHide();
                listData();
                this.setState({
                    load: false,
                    checked: false,
                    inStock: 0,
                    totalStock: 0
                })
                toast(returnInwardSuccess.data);
            } else {
                toast(returnInwardSuccess.data);
            }
        } if (prevProps.data !== this.props.data) {
            this.setState({
                projects: this.props.data
            })
        } if (prevProps.loading !== this.props.loading) {
            this.setState({
                load: this.props.loading
            })
        }
    }
    productNameChange(e) {
        this.props.products_list?.map((item) => {
            if (e[0] === (`${item.productName} - ${item.articleId}`)) {
                this.props.dispatch(getProductById(item?.productId));
                this.props.dispatch(getVerifiedProject(item?.productId));
            }
        });
    }
    getQuantity(e) {
        const { initialValues } = this.props;
        this.setState({
            inStock: initialValues?.inStock + Number(e.target.value),
            totalStock: initialValues?.totalStock + Number(e.target.value)
        })
    }
    customerChange(e) {
        this.props.customerList?.map((item) => {
            if (item.customerName === e[0]) {
                this.setState({
                    customerId: item.customerId
                })
            }
        });
    }
    inputChanged(text, e) {
        this.props.dispatch(getCustomersList(0, text));
    }
    changeHandler(e) {
        this.setState({
            checked: e.target.checked
        })
    }
    inputChange(text) {
        this.props.dispatch(getProductList({
            searchKey: text,
            pageNo: 0,
            sortType: "ASC",
            sortField: "p.product_id",
            fromDate: null,
            toDate: null,
            sizeOfPage: 50
        }));
    }
    showModal() {
        this.props.handleShow();
        this.setState({
            checked: false,
            inStock: 0,
            totalStock: 0
        })
    }
    addStock(values) {
        const { checked } = this.state;
        if (values.productId === undefined) {
            toast.error("Please Select a Product");
        }
        if (!values.stockQty) {
            throw new SubmissionError({
                stockQty: 'Please fill the field *',
            })
        } else if (values.stockQty?.trim().length === 0) {
            throw new SubmissionError({
                stockQty: 'Please fill the field *',
            })
        }
        // if (values.stockQty > values.totalStock) {
        //     throw new SubmissionError({
        //         stockQty: "Quantity can't be greater than total stock *",
        //     })
        // }
        if (checked) {
            if (this.state.customerId === null) {
                toast.error("Please Select a Customer");
            } else {
                let formData = {
                    "productId": values.productId,
                    "remark": values.remark === undefined ? "" : values.remark,
                    "returnQty": values.stockQty,
                    "customerId": this.state.customerId,
                }
                this.setState({
                    load: true
                })
                this.props.dispatch(addReturnInwardProduct(formData));
            }
        } else {
            let formData = {
                "productId": values.productId,
                "remark": values.remark === undefined ? "" : values.remark,
                "stockQty": Number(values.stockQty)
            }
            this.setState({
                load: true
            })
            this.props.dispatch(createStock([formData]));
        }
    }

    render() {
        const { error, show, onHide, reset, str, handleSubmit, products_list, customerList, initialValues, stockLoad } = this.props;
        const { checked, inStock, totalStock, load } = this.state;

        return (
            <>
                {str === "dashboard" ? <button className="dashboard-btn" onClick={this.showModal}>
                    Inward</button> :
                    <button className={changeClassName(str)} onClick={this.showModal}>
                        <img src={plus} alt="" /> Add Stock
                    </button>
                }
                <Modal
                    show={show}
                    size="lg"
                    onHide={() => {
                        this.props.dispatch(getProductList({
                            searchKey: null,
                            pageNo: 0,
                            sortType: "ASC",
                            sortField: "p.product_id",
                            fromDate: null,
                            toDate: null,
                            sizeOfPage: 50
                        }));
                        onHide();
                        reset();
                        this.setState({
                            checked: false,
                            inStock: 0,
                            totalStock: 0
                        })
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-staff">
                        <div className="head">
                            <div>
                                <i class="fas fa-arrow-left" onClick={() => {
                                    onHide();
                                    reset();
                                    this.setState({
                                        checked: true
                                    })
                                }}></i>
                                <h4>INWARD PRODUCT</h4>
                            </div>
                            <div>
                                <div className="customer-check">
                                    <input type="checkbox" onChange={this.changeHandler} style={{ margin: "-5px 0px 0px -5px" }} />
                                    <label className="stock_label">Return</label>
                                </div>
                            </div>
                        </div>
                        <Row>
                            <form onSubmit={handleSubmit(this.addStock)} className="customers">
                                <Row>
                                    {checked && <Col md={12} style={{ marginBottom: "30px" }}>
                                        <label class="form-control-placeholder type-form" for="name" >{checked ? "Customer Name" : "Vendor Name"} <span>*</span></label>
                                        {checked &&
                                            customerList?.length >= 0 ? <Typeahead
                                            onChange={this.customerChange}
                                            id="async-example"
                                            labelKey={customerList?.map(item => item.customerName)}
                                            onInputChange={this.inputChanged}
                                            options={customerList?.map(item => item.customerName)}
                                        /> : null
                                            //  :
                                            // allData?.content?.length >= 0 ? <Typeahead
                                            //     onChange={this.customerChange}
                                            //     id="async-example"
                                            //     labelKey={allData?.content?.map(item => item.name)}
                                            //     onInputChange={this.inputChanged}
                                            //     options={allData?.content?.map(item => item.name)}
                                            // /> : null
                                        }
                                        <div className="triangle-bottom" style={{ marginTop: "-22px" }}></div>
                                    </Col>}
                                    <Col md={12}>
                                        <label class="form-control-placeholder type-form" for="name" >Product Name <span>*</span></label>
                                        {products_list?.length >= 0 ? <Typeahead
                                            onChange={this.productNameChange}
                                            id="async-example"
                                            labelKey={products_list?.map(item => item.productName)}
                                            onInputChange={this.inputChange}
                                            options={products_list?.map(item => `${item.productName} - ${item.articleId}`)}
                                        /> : null}
                                        <div className="triangle-bottom" style={{ marginTop: "-24px" }}></div>
                                    </Col>
                                    <>
                                        <Col md={9} style={{ marginBottom: "20px" }}>
                                            <div>
                                                <label class="form-control-placeholder" for="name">Remark </label>
                                                <Field name="remark" type="text" component={renderField} />
                                            </div>
                                        </Col>
                                        <Col md={3} tyle={{ marginBottom: "20px" }}>
                                            <label class="form-control-placeholder" for="name">Quantity <span>*</span></label>
                                            <Field name="stockQty" type="text" maxLength="8" component={renderField} onChange={this.getQuantity} />
                                        </Col>
                                    </>
                                    <Col md={3}>
                                        <label class="form-control-placeholder" for="name">Article ID</label>
                                        <Field name="articleId" type="text" component={renderField} maxLength="10" disabled={true} />
                                    </Col>
                                    <Col md={3}>
                                        <label class="form-control-placeholder" for="name">Nos</label>
                                        <Field name="unitName" type="text" component={renderField} disabled={true} />
                                    </Col>
                                    <Col md={3}>
                                        <label class="form-control-placeholder" for="name">In Stock</label>
                                        <Form.Label></Form.Label>
                                        <Form.Control
                                            type="text"
                                            disabled
                                            name="inStock"
                                            value={inStock === 0 ? isNaN(initialValues?.inStock) ? "" : initialValues?.inStock : inStock}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <label class="form-control-placeholder" for="name">Total Stock</label>
                                        <Form.Label></Form.Label>
                                        <Form.Control
                                            type="text"
                                            disabled
                                            name="totalStock"
                                            value={totalStock === 0 ? isNaN(initialValues?.totalStock) ? "" : initialValues?.totalStock : totalStock}
                                        />
                                    </Col>
                                    <Col md={3}></Col>
                                    <Col md={6}><br></br>
                                        {load ? <button type="button" className="customer-btn">
                                            Loading....
                                        </button> :
                                            <button type="submit" className="customer-btn">
                                                Add To Stock
                                            </button>}

                                    </Col>
                                    <Col md={3}></Col>
                                    {error && <strong>{error}</strong>}
                                </Row>
                            </form>
                        </Row>
                    </div>
                </Modal >
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        initialValues: state.product.singleProduct,
        products_list: state.product.products_list,
        bulkProducts: state.product.bulkProducts,
        returnInwardSuccess: state.inward.returnInwardSuccess,
        allData: state.projects.allData,
        data: state.inward.data,
        load: state.inward.load,
        loading: state.product.loading,
        allData: state.vendor.allData,
        customerList: state.customer.customers_list,

    }
}

const StockForm = reduxForm({
    form: "AddStock",
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate
})(AddStock);

export default connect(mapStateToProps)(StockForm);
