import React from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import Filter from "../../../assets/images/filter.svg";
import Plus from "../../../assets/images/pl.svg";
import cust from "../../../assets/images/green1.svg";
import Search from "../../../assets/images/search.svg";
import { connect } from "react-redux";
import { getGroupList, createGroup, getGroup, updateGroup, changePage, changeDateFormat } from "../../../actions";
import Spinner from "../../../Components/loader";
import GroupForm from "./groupForm";
import Pagination from "../../../Components/Pagination";
import { toast } from "react-toastify";
import { reset, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import Re from "../../../assets/images/cat.svg";

const role = localStorage.getItem('userRole');
class Group extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allGroups: [],
            show: false,
            success_msg: '',
            allData: [],
            sortType: "asc",
            searchKey: "",
            sortField: "",
            pageNo: 0,
            sort: {},
            group: [],
            str: ""
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
        this.sortHandler = this.sortHandler.bind(this);
    }
    handleShow() {
        this.props.dispatch(changePage());
        this.setState({ show: true, str: "create", group: {} });
    }
    handleClose() {
        this.setState(
            {
                show: false,
            }
        );
    }
    componentDidUpdate(prevProps) {
        if (prevProps.group_list !== this.props.group_list) {
            this.setState({
                allGroups: this.props.group_list,
            });
        }
        if (prevProps.allData !== this.props.allData) {
            this.setState({
                allData: this.props.allData,
            });
        }
        // if (prevProps.group !== this.props.group) {
        //     this.setState({
        //     });
        // }
        if (prevProps.success_msg !== this.props.success_msg) {
            this.setState({
                success_msg: this.props.success_msg,
            });
            const { pageNo, searchKey, sort, sortField } = this.state;
            if (this.props.success_msg.status === 200) {
                this.setState({
                    show: false
                });
                toast(this.props.success_msg.data);
                if (sortField === "") {
                    this.props.dispatch(getGroupList(pageNo, searchKey));
                } else {
                    this.props.dispatch(getGroupList(pageNo, searchKey, sort));
                }
            } else {
                toast(this.props.success_msg.data);
            }
        }
    }
    componentDidMount() {
        this.props.dispatch(getGroupList());
    }
    handleSubmit(formData, dispatch) {
        console.log(formData);
        if (!formData.groupName) {
            throw new SubmissionError({
                groupName: 'Please fill the field *',
            })
        } else if (formData?.groupName?.trim().length === 0) {
            throw new SubmissionError({
                groupName: 'Please fill the field *',
            })
        }
        if (formData) {
            if (this.state.str === "edit") {
                this.props.dispatch(updateGroup(formData));
            }
            else {
                this.props.dispatch(createGroup(formData));
                dispatch(reset("groupForm"));
            }
        } else {
            toast("Please fill all the required fields");
        }
    }

    handleEdit(row) {
        this.setState({
            group: row,
            show: true,
            str: "edit"
        });
    }

    onChange(pageNo) {
        this.setState({
            pageNo: pageNo
        });
        const { searchKey, sortType, sortField } = this.state;
        let sortKey = sortType === 'asc' ? 'desc' : 'asc';
        let sort = {
            sortType: sortKey,
            sortField: sortField
        }

        if (sortField === "") {
            this.props.dispatch(getGroupList(searchKey));
        } else {
            this.props.dispatch(getGroupList(searchKey, sort));
        }
    }


    searchHandler(e) {
        const { sort, sortField } = this.state;
        this.setState({
            searchKey: e.target.value
        });
        if (sortField === "") {
            this.props.dispatch(getGroupList(e.target.value));
        } else {
            this.props.dispatch(getGroupList(e.target.value, sort));

        }
    }


    sortHandler(sortField) {
        const { pageNo, searchKey } = this.state;
        this.setState({
            sortField: sortField
        });

        if (this.state.sortType === 'desc') {
            this.setState({
                sortType: 'asc'
            })
        } else {
            this.setState({
                sortType: 'desc'
            })
        }

        let sort = {
            sortType: this.state.sortType,
            sortField: sortField
        }
        this.setState({
            sort: sort
        })
        this.props.dispatch(getGroupList(pageNo, searchKey, sort));
    }


    render() {
        const {
            allGroups,
            show,
            allData,
            str,
            group
        } = this.state;

        let data = {
            totalElements: allData.totalElements,
            totalPages: allData.totalPages,
            offset: allData?.pageable?.offset,
            numberOfElements: allData.numberOfElements,
            num: (allData?.number) + 1
        }
        return (

            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <div className="page-head-split">
                                <div className="head-colums">
                                    <div className="head-part-tab">
                                        <img src={Re} alt="..." className="head-imgs" />
                                        <h5>Settings</h5>
                                    </div>
                                    <div className="tab-links">
                                        <Link to={"/" + role + "/gst"} >GST</Link>
                                        <Link to={"/" + role + "/reference-id"} >Reference Id</Link>
                                        <Link to={"/" + role + "/unit"}>Unit </Link>
                                        <Link to={"/" + role + "/group"} className="active">Quotation Group </Link>
                                        <Link to={"/" + role + "/content-of-letter"}>Content Of the Letter</Link>
                                        <Link to={"/" + role + "/terms-conditions"}>Terms & Condition</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="unit-page">
                                <div className="page-head-split">
                                    <div className="head-colums">
                                        <button className="add-product create-staff" onClick={this.handleShow}>
                                            <img src={Plus} alt="" /> Add Group
                                        </button>
                                        <GroupForm
                                            handleShow={this.handleShow}
                                            show={show}
                                            onHide={this.handleClose}
                                            onSubmit={this.handleSubmit}
                                            str={str}
                                            initialValues={group}
                                        />
                                    </div>
                                </div>
                                <div className="table-class">
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    ID <img onClick={(e) => this.sortHandler("c.customer_first_name")} src={Filter} alt="..." />
                                                </th>
                                                <th scope="col">
                                                    Group Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("c.customer_city")} />
                                                </th>
                                                <th scope="col">
                                                    Created <img src={Filter} alt="..." onClick={(e) => this.sortHandler("c.customer_phone")} />
                                                </th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {this.props.allData && this.props.allData?.length > 0 ? this.props.allData?.map((item, i) => (
                                                    <tr key={i} onClick={() => this.handleEdit(item)} style={{ cursor: "pointer" }}>
                                                        <td>{item.quotationMaterialGroupId}</td>
                                                        <td>{item.groupName}</td>
                                                        <td>{changeDateFormat(item.createdAt)}</td>
                                                        <td>
                                                            <button className="Edit-staff" onClick={() => this.handleEdit(item)}>
                                                                Edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )) : !this.props.loading ?
                                                    <h1 className="empty-data">No Records Found</h1> :
                                                    <Spinner />
                                                }
                                            </>
                                        </tbody>
                                    </table>
                                </div>
                                {/* <Pagination data={data} onChange={this.onChange} /> */}
                            </div>

                        </Card>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.group.loading,
        group_list: state.group.group_list,
        page: state.group.page,
        success_msg: state.group.success_msg,
        allData: state.group.allData,
        group: state.group.group,
    }
}

export default connect(mapStateToProps)(Group);




