import { outwardConstants } from "../constants";

const initialState = {
    loading: false,
    data: [],
    error: [],
    load: false,
    coProductSucces: [],
    vendorProductSucces: [],
    vendorProductList: [],
    coProductList: [],
    coProjectList: [],
    vendorProjectList: [],
    outwardSuccess: [],
    remarkSuccess: [],
    outwardProductSuccess: [],
    csvData: []
};

export default function outwardReducer(state = initialState, { type, response }) {
    switch (type) {
        case outwardConstants.GET_OUTWARDS_LOGS:
        case outwardConstants.GET_CO_PRODUCT_LIST:
        case outwardConstants.GET_CO_PROJECTS:
        case outwardConstants.GET_VENDOR_PROJECTS:
        case outwardConstants.GET_VENDOR_PRODUCTS:
            return {
                ...state,
                loading: true,
            };
        case outwardConstants.GET_CO_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                loading: true,
                coProductList: response.data
            };
        case outwardConstants.GET_VENDOR_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: true,
                vendorProductList: response.data
            };
        case outwardConstants.GET_CO_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: true,
                coProjectList: response.data
            };
        case outwardConstants.GET_VENDOR_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: true,
                vendorProjectList: response.data
            };
        case outwardConstants.CREATE_CO_PRODUCT:
        case outwardConstants.EDIT_CO_PRODUCT:
        case outwardConstants.DELETE_CO_PRODUCT:
        case outwardConstants.UPDATE_CO_OUTWARD:
        case outwardConstants.CREATE_VENDOR_PRODUCT:
        case outwardConstants.EDIT_VENDOR_PRODUCT:
        case outwardConstants.DELETE_VENDOR_PRODUCT:
        case outwardConstants.EDIT_RETURN_REVIEW:
        case outwardConstants.UPDATE_RETURN_OUTWARD_PRODUCT:
        case outwardConstants.DOWNLOAD_OUTWARD_CSV:
            return {
                ...state,
                load: true,
            };
        case outwardConstants.CREATE_CO_PRODUCT_SUCCESS:
        case outwardConstants.EDIT_CO_PRODUCT_SUCCESS:
        case outwardConstants.DELETE_CO_PRODUCT_SUCCESS:
            return {
                ...state,
                load: false,
                coProductSucces: response,
            };
        case outwardConstants.CREATE_VENDOR_PRODUCT_SUCCESS:
        case outwardConstants.EDIT_VENDOR_PRODUCT_SUCCESS:
        case outwardConstants.DELETE_VENDOR_PRODUCT_SUCCESS:
            return {
                ...state,
                load: false,
                vendorProductSucces: response,
            };
        case outwardConstants.EDIT_RETURN_REVIEW_SUCCESS:
            return {
                ...state,
                load: false,
                remarkSuccess: response,
            };
        case outwardConstants.UPDATE_RETURN_OUTWARD_PRODUCT_SUCCESS:
            return {
                ...state,
                load: false,
                outwardProductSuccess: response,
            };
        case outwardConstants.UPDATE_CO_OUTWARD_SUCCESS:
            return {
                ...state,
                load: false,
                outwardSuccess: response,
            };
        case outwardConstants.DOWNLOAD_OUTWARD_CSV_SUCCESS:
            return {
                ...state,
                load: false,
                csvData: response,
            };
        case outwardConstants.CREATE_CO_PRODUCT_FAILURE:
        case outwardConstants.EDIT_CO_PRODUCT_FAILURE:
        case outwardConstants.DELETE_CO_PRODUCT_FAILURE:
        case outwardConstants.UPDATE_CO_OUTWARD_FAILURE:
        case outwardConstants.CREATE_VENDOR_PRODUCT_FAILURE:
        case outwardConstants.EDIT_VENDOR_PRODUCT_FAILURE:
        case outwardConstants.DELETE_VENDOR_PRODUCT_FAILURE:
        case outwardConstants.EDIT_RETURN_REVIEW_FAILURE:
        case outwardConstants.UPDATE_RETURN_OUTWARD_PRODUCT_FAILURE:
        case outwardConstants.DOWNLOAD_OUTWARD_CSV_FAILURE:
            return {
                ...state,
                load: false,
            };
        case outwardConstants.GET_OUTWARDS_LOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                outward_list: response.data,
            };
        case outwardConstants.CHANGE_ADD_PAGE:
            return {
                ...state,
                page: "add"
            };
        case outwardConstants.CHANGE_PAGE:
            return {
                ...state,
                page: "edit"
            };
        case outwardConstants.GET_OUTWARDS_LOGS_FAILURE:
        case outwardConstants.GET_CO_PRODUCT_LIST_FAILURE:
        case outwardConstants.GET_CO_PROJECTS_FAILURE:
        case outwardConstants.GET_VENDOR_PRODUCTS_FAILURE:
        case outwardConstants.GET_VENDOR_PROJECTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
}
