import { adminConstants } from "../constants";

export const getStaffProfile = () => {
    return {
        type: adminConstants.GET_STAFF_PROFILE,
    };
};

export const updateAdmin = (formData) => {
    return {
        type: adminConstants.UPDATE_ADMIN_REQUEST,
        formData,
    }
}

export const updatePassword = (formData) => {
    return {
        type: adminConstants.UPDATE_PASSWORD_REQUEST,
        formData
    }
}