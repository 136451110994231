import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import { groupConstants } from "../constants";
import { getGroupApi, createGroupApi, getOneGroupApi, updateGroupApi } from "../services";

export function* grouplistSaga(payload) {
    try {
        const response = yield call(getGroupApi, payload.pageNo, payload.searchKey, payload.sort);
        yield put({ type: groupConstants.GET_GROUP_SUCCESS, response });
    } catch (error) {
        yield put({ type: groupConstants.GET_GROUP_FAILURE, error });
    }
}
export function* createGroupSaga(payload) {
    try {
        const response = yield call(createGroupApi, payload);
        yield put({ type: groupConstants.CREATE_GROUP_SUCCESS, response });
    } catch (error) {
        yield put({ type: groupConstants.CREATE_GROUP_FAILURE, error });
    }
}
export function* getGroupSaga(payload) {
    try {
        const response = yield call(getOneGroupApi, payload.quotationMaterialGroupId);
        yield put({ type: groupConstants.GET_GROUP_SUCCESS, response });
    } catch (error) {
        yield put({ type: groupConstants.GET_GROUP_FAILURE, error });
    }
}
export function* updateGroupSaga(payload) {
    try {
        const response = yield call(updateGroupApi, payload);
        yield put({ type: groupConstants.UPDATE_GROUP_SUCCESS, response });
    } catch (error) {
        yield put({ type: groupConstants.UPDATE_GROUP_FAILURE, error });
    }
}
export default function* groupSaga() {
    yield takeEvery(groupConstants.GET_GROUP, grouplistSaga);
    yield takeLatest(groupConstants.CREATE_GROUP_INIT, createGroupSaga);
    yield takeLatest(groupConstants.GET_GROUP_INIT, getGroupSaga);
    yield takeLatest(groupConstants.UPDATE_GROUP_INIT, updateGroupSaga);
}