import { authToken,baseUrl } from "../actions";

export const getTemplateApi = () => {
    const TEMPLATE_LIST = `${baseUrl}staff/templates`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(TEMPLATE_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}


export const getOneTemplateApi = (templateId) => {
    const TEMPLATE_LIST = `${baseUrl}staff/templates${templateId}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(TEMPLATE_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}


export const getSettingsApi = () => {
    const SETTINGS = `${baseUrl}public/settings`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(SETTINGS, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}