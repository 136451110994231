export const vendorpoConstants = {
    GET_VENDORPO: "GET_VENDORPO_LIST",
    GET_VENDORPO_SUCCESS: "GET_VENDORPO_LIST_SUCCESS",
    GET_VENDORPO_FAILURE: "GET_VENDORPO_LIST_FAILURE",

    CREATE_VENDORPO_INIT: "CREATE_VENDORPO_INIT",
    CREATE_VENDORPO_SUCCESS: "CREATE_VENDORPO_SUCCESS",
    CREATE_VENDORPO_FAILURE: "CREATE_VENDORPO_FAILURE",

    GET_VENDORPO_INIT: "GET_VENDORPO_INIT",
    GET_VENDORPO_SUCCESSD: "GET_VENDORPO_SUCCESSD",
    GET_VENDORPO_FAILURED: "GET_VENDORPO_FAILURED",

    UPDATE_VENDORPO_INIT: "UPDATE_VENDORPO_INIT",
    UPDATE_VENDORPO_SUCCESS: "UPDATE_VENDORPO_SUCCESS",
    UPDATE_VENDORPO_FAILURE: "UPDATE_VENDORPO_FAILURE",

    CHANGE_PAGE: "CHANGE_PAGE",
}