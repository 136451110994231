import { put, call, takeEvery } from "redux-saga/effects";
import { adminConstants } from "../constants";
import {  updateStaffApi, updatePasswordApi, getStaffProfileApi } from "../services";

export function* staffProfileSaga(payload) {
    try {
        const response = yield call(getStaffProfileApi, payload);
        yield put({ type: adminConstants.GET_STAFF_PROFILE_SUCCESS, response });
    } catch (error) {
        yield put({ type: adminConstants.GET_STAFF_PROFILE_FAILURE, error });
    }
}
export function* updateAdminSaga(payload) {
    try {
        const response = yield call(updateStaffApi, payload.formData);
        yield put({ type: adminConstants.UPDATE_ADMIN_SUCCESS, response });
    } catch (error) {
        yield put({ type: adminConstants.UPDATE_ADMIN_FAILURE, error });
    }
}
export function* updatePasswordSaga(payload) {
    try {
        const response = yield call(updatePasswordApi, payload.formData);
        yield put({ type: adminConstants.UPDATE_PASSWORD_SUCCESS, response });
    } catch (error) {
        yield put({ type: adminConstants.UPDATE_PASSWORD_FAILURE, error });
    }
}

export default function* adminSaga() {
    yield takeEvery(adminConstants.UPDATE_ADMIN_REQUEST, updateAdminSaga);
    yield takeEvery(adminConstants.UPDATE_PASSWORD_REQUEST, updatePasswordSaga);
    yield takeEvery(adminConstants.GET_STAFF_PROFILE, staffProfileSaga);
}
