import React, { useRef, useState } from "react";
import { Overlay, Popover, Col, Row, Form } from "react-bootstrap";
import filter from "../../../assets/images/filt.svg";
import { qutationDateFormat } from "../../../actions";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";

function OutWardFilter(props) {
    const [show, setShow] = useState(false);
    const [cur, setCur] = useState("");
    const [eff, setEff] = useState("");

    const { onSubmit, reset } = props;

    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };


    const disabledDate = (current) => current && current > moment().endOf("day");
    const checkDisabledDate = (current) => (current && current > moment().endOf("day")) || moment(eff).startOf("day") > current;

    const effHandler = (val) => {
        let item = val === null ? "" : qutationDateFormat(val._d);
        setCur(item);
    }
    const currentHandler = (val) => {
        let item = val === null ? "" : qutationDateFormat(val._d);
        setEff(item);
    }


    const submitHandler = (e) => {
        e.preventDefault();
        let data = {
            "fromDate": eff,
            "toDate": cur
        }
        onSubmit(data);
        setTarget(null);
        setShow(false);
    }

    const resetHandler = () => {
        reset();
        setCur("");
        setEff("");
        setTarget(null);
        setShow(false);
    }


    return (
        <div ref={ref} className="filter-background">
            <button className="product-filter-button" onClick={handleClick}>
                <img src={filter} alt="..." width="18px" style={{ paddingRight: "5px" }} /> Filter
            </button>
            <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
            >
                <Popover id="popover-contained">
                    <Popover.Body>
                        <div className="filter-page">
                            <Form onSubmit={submitHandler}>
                                <p>Custom Date</p><br></br>
                                <Form.Group controlId="formBasicDate">
                                    <DatePicker
                                        format="DD-MM-YYYY"
                                        placeholder={eff !== "" ? eff : "From Date"}
                                        className="form-control"
                                        onChange={currentHandler}
                                        disabledDate={disabledDate}
                                    />
                                </Form.Group><br></br>
                                <Form.Group controlId="formBasicDate">
                                    <DatePicker
                                        format="DD-MM-YYYY"
                                        placeholder={cur !== "" ? cur : "To Date"}
                                        className="form-control"
                                        onChange={effHandler}
                                        disabledDate={checkDisabledDate}

                                    />
                                </Form.Group>

                                <div>
                                    <Row>
                                        <Col md={6}>
                                            <button className="modal-price reset" type="button" onClick={resetHandler}>
                                                Reset</button>
                                        </Col>
                                        <Col md={6}>
                                            <button type="submit" className="modal-btn modal-apply">
                                                Apply
                                            </button>
                                        </Col>
                                    </Row>

                                </div>
                            </Form>
                        </div>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    );
}


export default OutWardFilter;


