import React from 'react'
import { Modal, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { getPoConvertProductList, shortStr } from '../../../actions';
import plus from "../../../assets/images/ar-g.svg";
import Spinner from '../../../Components/loader';


class ConvertBoq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openedItem: [],
            data: []
        }
        this.selectHandler = this.selectHandler.bind(this);
        this.selectProHandler = this.selectProHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }
    selectHandler(e) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((item) => item = e.target.checked);
        this.setState({
            openedItem: newItems,
        })
    }
    selectProHandler(e, index) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((item, i) => index === i ? e.target.checked : item);
        this.setState({
            openedItem: newItems
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.confirmProducts !== this.props.confirmProducts) {
            let data = [];
            this.props.confirmProducts?.map((item, i) => data[i] = false);
            this.setState({
                openedItem: data,
                data: this.props.confirmProducts
            })
        }
    }
    submitHandler() {
        const { openedItem, data } = this.state;
        let pro = [];
        if (openedItem.includes(true)) {
            data?.map((item, i) => {
                if (openedItem[i]) {
                    pro.push(item.billOfQuantityId);
                }
            });
            let formData = {
                "billOfQuantityIds": pro
            }
            this.props.dispatch(getPoConvertProductList(formData));
        } else {
            toast("Select Atleast One Product");
        }
    }
    render() {
        const { show, onHide, handleShow, str } = this.props;
        const { openedItem, data } = this.state;
        return (
            <>
                <button className="convert-po" onClick={handleShow}>
                    <img src={plus} alt="" />Convert BOQ to PO</button>
                <Modal
                    show={show}
                    size="xl"
                    onHide={() => {
                        onHide();
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-staff">
                        <div className="head">
                            <div>
                                <i class="fas fa-arrow-left" onClick={() => {
                                    onHide();
                                }}></i>
                                <h4>CONVERT BOQ TO PO</h4>
                            </div>
                        </div>
                        <div className="table-class space-part" >
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            BOQ No.
                                        </th>
                                        <th scope="col">
                                            Customer Name
                                        </th>
                                        <th scope="col">
                                            Project Name
                                        </th>
                                        <th scope="col" className='txt-center'>
                                            Count
                                        </th>
                                        <th scope="col">
                                            Select
                                            <input type="checkbox" onChange={this.selectHandler} style={{ margin: "7px" }} />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data !== undefined && data?.length > 0 ?
                                        data?.map((item, i) => (
                                            <tr key={i} >
                                                <td>{shortStr(item?.billOfQuantityNumber)}</td>
                                                <td>{item?.customerName}</td>
                                                <td className="new_bulk_sizee">
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item?.projectName || "-"}</Tooltip>}>
                                                        <span className="d-inline-block">
                                                            {item?.projectName || "-"}
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                                <td className='txt-center'>{item?.quantity}</td>
                                                <td>
                                                    <Form.Check
                                                        checked={openedItem[i] ? true : false}
                                                        onChange={(e) => this.selectProHandler(e, i)}
                                                        name="group1"
                                                        type="checkbox"
                                                        className="form-check-box"
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                        : !this.props.loading ?
                                            <h1 className="empty-data">No Records Found</h1> :
                                            <Spinner />}
                                </tbody>
                            </table>
                        </div>
                        <center style={{ padding: "15px" }}>
                            <button onClick={this.submitHandler} className="customer-btn col-md-6">Convert PO</button>
                        </center>
                    </div>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        confirmProducts: state.po.confirmProducts,
        loading: state.po.loading,
        convertProducts: state.po.convertProducts
    }
}


export default connect(mapStateToProps)(ConvertBoq);
