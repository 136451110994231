import React from "react";
import reactDom from "react-dom";
import Logo from "../../assets/images/k-new.gif";
import { Link } from "react-router-dom";



class PageNotFound extends React.Component {
    appLogout(e) {
        localStorage.clear();
        window.location = '/';
    }
    render() {
        return (
            <div className="page-not-found">
                <img src={Logo} alt="..." className="" />
                <h1>OOPS!</h1>
                <p>The page you have requested could not be found<br></br> Please go back to the homepage.</p>
                <button className="login-button" onClick={this.appLogout}>Go to Login page</button>
            </div>
        )
    }
}



export default PageNotFound;
