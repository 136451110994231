import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import { projectsConstants } from "../constants";
import { getProjectsApi, createProjectsApi, getOneProjectsApi, updateProjectsApi } from "../services";

export function* projectslistSaga(payload) {
    try {
        const response = yield call(getProjectsApi, payload.pageNo, payload.searchKey, payload.sort);
        yield put({ type: projectsConstants.GET_PROJECTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: projectsConstants.GET_PROJECTS_FAILURE, error });
    }
}
export function* createProjectsSaga(payload) {
    try {
        const response = yield call(createProjectsApi, payload);
        yield put({ type: projectsConstants.CREATE_PROJECTS_SUCCESS, response });

    } catch (error) {
        yield put({ type: projectsConstants.CREATE_PROJECTS_FAILURE, error });
    }
}
export function* getProjectsSaga(payload) {
    try {
        const response = yield call(getOneProjectsApi, payload.purchaseOrderNumber);
        yield put({ type: projectsConstants.GET_SINGLE_PROJECTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: projectsConstants.GET_SINGLE_PROJECTS_FAILURE, error });
    }
}
export function* updateProjectsSaga(payload) {
    try {
        const response = yield call(updateProjectsApi, payload);
        yield put({ type: projectsConstants.UPDATE_PROJECTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: projectsConstants.UPDATE_PROJECTS_FAILURE, error });
    }
}
export default function* projectsSaga() {
    yield takeEvery(projectsConstants.GET_PROJECTS, projectslistSaga);
    yield takeLatest(projectsConstants.CREATE_PROJECTS_INIT, createProjectsSaga);
    yield takeLatest(projectsConstants.GET_SINGLE_PROJECTS_INIT, getProjectsSaga);
    yield takeLatest(projectsConstants.UPDATE_PROJECTS_INIT, updateProjectsSaga);
}