import React from "react";
import { Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Navbar from "../../../layouts/Navbar";
import { reset } from 'redux-form';
import Sidebar from "../../../layouts/Sidebar";
import { connect } from "react-redux";
import Filter from "../../../assets/images/filter.svg";
import Edit from "../../../assets/images/ui.svg";
import Delete from "../../../assets/images/ux.svg";
import Deliver from "../../../assets/images/deliver.svg";
import Clone from "../../../assets/images/clonee.svg";
import download from "../../../assets/images/download1.svg";
import mail from "../../../assets/images/mail.svg";
import Arrow from "../../../assets/images/pl.svg";
import Search from "../../../assets/images/search.svg";
import Boq from "../../../assets/images/boq-one.png";
import { toast } from "react-toastify";
import BillFilter from "./BillFilter";
import ConvertBoq from "./ConvertBoq";
import {
  getBillOfQuantityPoList,
  getConfirmQuotDropdown,
  changeDateFormat,
  clearBoq,
  clearConfirmDropdownList,
  clearBoqPreview,
  getExecutiveUrl,
  changePoClassName,
  previewBoq,
  generateBoq,
  sendBoq,
  cloneBoq,
  deleteBoq,
  reviseBoq,
  getProjectOutwardList,
  getInternalTransferProductList,
  clearBoqProjectData,
  cancelBoq,
  setTitle,
  shortStr,
  sendBoqMail,
  getBoqMailDetails,
  getProjectOutwardV2Action
} from "../../../actions";
import Spinner from "../../../Components/loader";
import BOQView from "./BOQView";
import Pagination from "../../../Components/Pagination";
import CloneStatus from "../../../Components/cloningStatus";
import ClonePopup from "../../../Components/clonePopup";
import DeletePopUp from "../vendorpo/deletePopup";
import Projects from "./Projects";
import Outward from "./Outward";
import EmailPopup from "../../../Components/emailPopup";
import BoqMailPopup from "../../../Components/BoqMailPopup";


const role = localStorage.getItem('userRole');

class BillOfQuantity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      boq_list: [],
      filterData: {},
      sortField: "",
      pageNo: 0,
      searchKey: "",
      sortType: "desc",
      editShow: false,
      status: "DRAFT",
      boqData: [],
      deletePopup: false,
      customPopup: false,
      id: "",
      projectPopup: false,
      outwardPopup: false,
      data: [],
      itStr: "",
      cloned: false,
      clonePopup: false,
      cloneStr: "",
      cloneShow: false,
      emailPopup: false
    }
    this.myRef = React.createRef();
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.onChange = this.onChange.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.editVendorShow = this.editVendorShow.bind(this);
    this.editVendorClose = this.editVendorClose.bind(this);
    this.goToPoPage = this.goToPoPage.bind(this);
    this.generatePoHandler = this.generatePoHandler.bind(this);
    this.sendPohandler = this.sendPohandler.bind(this);
    this.clonePoHandler = this.clonePoHandler.bind(this);
    this.revisePoHandler = this.revisePoHandler.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.deleteItemClose = this.deleteItemClose.bind(this);
    this.deletePoHandler = this.deletePoHandler.bind(this);
    this.listAllData = this.listAllData.bind(this);
    this.clickPage = this.clickPage.bind(this);
    this.projectOpenHandler = this.projectOpenHandler.bind(this);
    this.projectCloseHandler = this.projectCloseHandler.bind(this);
    this.outwardOpenHandler = this.outwardOpenHandler.bind(this);
    this.cancelBoqHandler = this.cancelBoqHandler.bind(this);
    this.outwardCloseHandler = this.outwardCloseHandler.bind(this);
    this.sendMailOpen = this.sendMailOpen.bind(this);
    this.sendMailClose = this.sendMailClose.bind(this);
    this.sendPoMailSubmit = this.sendPoMailSubmit.bind(this);
    this.getCCs = this.getCCs.bind(this);
  }
  async cancelBoqHandler() {
    const { boqData } = this.state;
    var footerData = await cancelBoq(boqData.billOfQuantityId);
    if (footerData?.data?.status === 200) {
      toast(footerData?.data?.data);
      this.setState({
        status: "CANCELLED"
      })
      this.listAllData();
    } else {
      toast(footerData?.data?.data);
    }
  }
  clickPage() {
    this.props.dispatch(clearBoq());
    this.props.dispatch(clearBoqProjectData());
    setTitle("Create BOQ");
    localStorage.setItem("title", "Create BOQ");
    window.location.hash = "/" + role + "/bill_of_quantity/customer_details/add";
  }
  handleShow() {
    this.props.dispatch(clearConfirmDropdownList());
    this.props.dispatch(getConfirmQuotDropdown());
    this.setState({ show: true });
  }
  handleClose() {
    this.props.dispatch(clearConfirmDropdownList());
    this.setState({ show: false });
  }
  editVendorShow(item, str) {
    if (str === "edit") {
      setTitle("Edit BOQ");
      localStorage.setItem("title", "Edit BOQ");
      window.location.hash = `/${role}/bill_of_quantity/product_details/${item.billOfQuantityId}`;
    } else if (str === "del") {
      this.setState({
        boqData: item
      })
      this.deleteItem(item);
    } else if (str === "clone") {
      this.setState({
        cloned: true,
        clonePopup: true,
        cloneStr: "Cloning BOQ...Please wait..."
      })
      this.props.dispatch(cloneBoq(item.billOfQuantityId));
    } else if (str === "mail") {
      this.setState({
        boqData: item
      })
      this.props.dispatch(sendBoq(item.billOfQuantityId));
    } else if (str === "deliver") {
      this.setState({
        boqData: item
      })
      this.props.dispatch(getProjectOutwardV2Action(item.billOfQuantityId));
      this.projectOpenHandler();
    } else {
      this.props.dispatch(clearBoqPreview());
      this.props.dispatch(clearBoqProjectData());
      this.props.dispatch(previewBoq(item.billOfQuantityId));
      this.setState({
        editShow: true,
        status: item.status,
        boqData: item
      })
    }
  }
  editVendorClose() {
    this.props.dispatch(clearBoqPreview());
    this.setState({
      editShow: false
    })
  }
  goToPoPage() {
    const { boqData, status } = this.state;
    const { history } = this.props;
    this.props.dispatch(clearBoq());
    if (status === "DRAFT") {
      setTitle("Edit BOQ");
      history.push(`/${role}/bill_of_quantity/product_details/${boqData.billOfQuantityId}`);
    } else {
      this.revisePoHandler();
    }
  }
  projectOpenHandler() {
    this.setState({
      projectPopup: true,
      editShow: false,
      itStr: "project"
    })
  }
  projectCloseHandler() {
    this.setState({
      projectPopup: false,
      itStr: ""
    })
  }
  outwardOpenHandler(item) {
    const { boqData, itStr } = this.state;
    this.props.dispatch(getInternalTransferProductList(boqData.billOfQuantityId));
    let dataValues = {
      billOfQuantityId: item.billOfQuantityId,
      billOfQuantityNumber: item.billOfQuantityNumber,
      customerName: item.customerName,
      outwardProductList: item?.outwardProductList,
      projectId: item.projectId,
      projectName: item.projectName,
      purchaseOrderId: item.purchaseOrderId,
      status: item.status
    };
    this.setState({
      outwardPopup: true,
      projectPopup: false,
      editShow: false,
      itStr: itStr === "project" ? "project" : "IT",
      data: dataValues
    })
  }
  outwardCloseHandler() {
    this.setState({
      outwardPopup: false,
      itStr: ""
    })
  }
  generatePoHandler() {
    const { boqData } = this.state;
    if (boqData.projectName === null) {
      toast("Please edit project to generate BOQ")
    } else {
      this.props.dispatch(generateBoq(boqData.billOfQuantityId));
    }
  }
  sendPohandler() {
    const { boqData } = this.state;
    this.props.dispatch(sendBoq(boqData.billOfQuantityId));
  }
  sendMailOpen() {
    const { boqData } = this.state;
    if (boqData.length !== 0) {
      this.props.dispatch(getBoqMailDetails(boqData.billOfQuantityId));
    }
    this.setState({
      emailPopup: true,
      editShow: false,
    })
  }
  sendMailClose() {
    this.setState({
      emailPopup: false
    })
  }
  sendPoMailSubmit(values, dispatch) {
    const { boqData, cc } = this.state;
    const newItems = cc.map((item) => item.value);
    let data = {
      "from": values.from,
      "to": values.to,
      "cc": newItems,
      "id": boqData.billOfQuantityId,
      "subject": values.subject
    }
    this.props.dispatch(sendBoqMail(data));
    dispatch(reset("SecondaryEmailForm"));

  }
  getCCs(val) {
    this.setState({
      cc: val
    })
  }
  clonePoHandler() {
    const { boqData } = this.state;
    this.setState({
      cloned: true,
      clonePopup: true,
      cloneStr: "Cloning BOQ...Please wait..."
    })
    this.props.dispatch(cloneBoq(boqData.billOfQuantityId));
  }
  revisePoHandler() {
    const { boqData } = this.state;
    this.setState({
      cloned: true,
      clonePopup: true,
      cloneStr: "Revising BOQ...Please wait..."
    })
    this.props.dispatch(reviseBoq(boqData.billOfQuantityId));
  }
  deleteItem(item) {
    this.setState({
      deletePopup: true,
    })
  }
  deleteItemClose() {
    this.setState({
      deletePopup: false
    })
  }
  deletePoHandler() {
    const { boqData } = this.state;
    this.props.dispatch(deleteBoq(boqData.billOfQuantityId));
  }
  onChange(pageNo) {
    const { filterData, searchKey, sortField, sortType } = this.state;
    this.setState({
      pageNo: pageNo
    })
    const url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
    this.props.dispatch(getBillOfQuantityPoList(url));
  }
  searchHandler(e) {
    const { filterData, pageNo, sortField, sortType } = this.state;
    this.setState({
      searchKey: e.target.value
    });
    const url = getExecutiveUrl(pageNo, e.target.value, filterData, sortField, sortType);
    this.props.dispatch(getBillOfQuantityPoList(url));
  }
  sortHandler(sortField) {
    const { pageNo, searchKey, filterData, sortType } = this.state;
    this.setState({
      sortField: sortField
    });
    let type = sortType;
    if (this.state.sortType === 'desc') {
      type = 'asc';
      this.setState({
        sortType: 'asc'
      })
    } else {
      type = 'desc';
      this.setState({
        sortType: 'desc'
      })
    };
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, type);
    this.props.dispatch(getBillOfQuantityPoList(url));
  }
  filterSubmit(filterData) {
    const { pageNo, searchKey, sortType, sortField } = this.state;
    this.setState({
      filterData: filterData
    })
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
    this.props.dispatch(getBillOfQuantityPoList(url));
  }
  resetHandler() {
    const { pageNo, sortField, sortType, searchKey } = this.state;
    this.setState({
      filterData: {}
    })
    let url = getExecutiveUrl(pageNo, searchKey, {}, sortField, sortType);
    this.props.dispatch(getBillOfQuantityPoList(url));
  }
  listAllData() {
    const { pageNo, searchKey, filterData, sortType, sortField } = this.state;
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
    this.props.dispatch(getBillOfQuantityPoList(url));
  }
  componentDidUpdate(prevProps) {
    if (prevProps.boqList !== this.props.boqList) {
      this.setState({
        boq_list: this.props.boqList,
      });
    } if (prevProps.generateData !== this.props.generateData) {
      if (this.props.generateData.status === 200) {
        this.setState({
          // editShow: false,
          status: "GENERATED"
        })
        this.listAllData();
        toast(this.props.generateData.data);
      } else {
        toast(this.props.generateData.data);
      }
    } if (prevProps.sendData !== this.props.sendData) {
      if (this.props.sendData.status === 200) {
        toast(this.props.sendData.data);
        this.listAllData();
        this.setState({
          editShow: false
        })
      } else {
        toast(this.props.sendData.data);
      }
    } if (prevProps.cloneData !== this.props.cloneData) {
      if (this.props.cloneData.status === 200) {
        toast(this.props.cloneData.message);
        this.listAllData();
        this.setState({
          editShow: false,
          clonePopup: false,
          cloneShow: true,
          cloneStr: "BOQ Cloned Successfully"
        })
        setTimeout(() => this.setState({ cloneShow: false, cloneStr: "", cloned: null }), 1200);
      } else {
        this.setState({
          clonePopup: false,
        })
        toast(this.props.cloneData.data);
      }
    } if (prevProps.reviseData !== this.props.reviseData) {
      if (this.props.reviseData.status === 200) {
        toast(this.props.reviseData.message);
        this.listAllData();
        this.setState({
          editShow: false,
          clonePopup: false,
          cloneShow: true,
          cloneStr: "Boq Revised Successfully"
        })
        setTimeout(() => this.setState({ cloneShow: false, cloneStr: "", cloned: null }), 1200);
      } else {
        this.setState({
          clonePopup: false,
        })
        toast(this.props.reviseData.data);
      }
    } if (prevProps.deleteBoq !== this.props.deleteBoq) {
      if (this.props.deleteBoq.status === 200) {
        toast(this.props.deleteBoq.data);
        this.listAllData();
        this.setState({
          deletePopup: false,
          editShow: false
        })
      } else {
        toast(this.props.deleteBoq.data);
      }
    } if (prevProps.boqMailSuccess !== this.props.boqMailSuccess) {
      if (this.props.boqMailSuccess.status === 200) {
        toast(this.props.boqMailSuccess.data);
        this.listAllData();
        this.setState({
          emailPopup: false
        })
      } else {
        toast(this.props.boqMailSuccess.data);
      }
    }
  }
  componentDidMount() {
    this.props.dispatch(getBillOfQuantityPoList('page=0'));
  }
  render() {
    const { boq_list, editShow, boqData, deletePopup, projectPopup, outwardPopup, itStr, status } = this.state;
    let data = {
      totalElements: boq_list.totalElements,
      totalPages: boq_list.totalPages,
      offset: boq_list?.pageable?.offset,
      numberOfElements: boq_list.numberOfElements,
      num: (boq_list?.number) + 1
    }
    return (
      <div>
        <Navbar />
        <div className="dashboard-page">
          <div className="dashboard-sidebar">
            <Sidebar />
          </div>
          <div className="dashboard-main">
            <Card className="staff-page">
              <div className="page-head-split">
                <div className="head-colums">
                  <div className="head-part-tab">
                    <img src={Boq} alt="..." className="head-imgs" />
                    <h5>Bill Of Quantity</h5>
                  </div>
                  <div className="tab-linkk">
                    {/* <Link to={"/" + role + "/bill_of_quantity"} className="active">Bill Of Quantity</Link>
                    <Link to={"/" + role + "/vendor_po"} >Vendor Po</Link> */}
                  </div>
                  <div className="customer-search">
                    <span><img src={Search} alt=".." className="search-img" /></span>
                    <Form.Control type="search" placeholder="Search" onChange={this.searchHandler} className="main-search" />
                  </div>
                  <BillFilter
                    onSubmit={this.filterSubmit}
                    reset={this.resetHandler}
                  />
                  <ConvertBoq
                    handleShow={this.handleShow}
                    show={this.state.show}
                    onHide={this.handleClose}
                    allData={this.listAllData}
                    str="BOQ"
                  />
                  <button className="create-po" onClick={this.clickPage}><img src={Arrow} alt=".." />Create BOQ</button>
                </div>
              </div>
              <div className="table-class">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      {/* <th scope="col">
                        ID <img src={Filter} alt="..." onClick={(e) => this.sortHandler("boq.bill_of_quantity_id")} />
                      </th> */}
                      <th scope="col">BOQ Number <img src={Filter} alt="..." onClick={(e) => this.sortHandler("boq.bill_of_quantity_number")} /></th>
                      <th scope="col">
                        Customer Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("c.customer_first_name")} />
                      </th>
                      <th scope="col">
                        Project Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("bqp.project_name")} />
                      </th>
                      <th scope="col">
                        Generated By <img src={Filter} alt="..." onClick={(e) => this.sortHandler("staffName")} />
                      </th>
                      <th scope="col" >
                        Created At <img src={Filter} alt="..." onClick={(e) => this.sortHandler("boq.created_at")} />
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Status <img src={Filter} alt="..." onClick={(e) => this.sortHandler("boq.status")} />
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {boq_list && boq_list?.content?.length > 0 ? (
                      boq_list?.content?.map((item, i) => {
                        return <tr key={i} style={{ cursor: "pointer" }}>
                          {/* <td onClick={() => this.editVendorShow(item, "")}>{item.billOfQuantityId}</td> */}
                          <td onClick={() => this.editVendorShow(item, "")}>{item.billOfQuantityNumber ?? "-"}</td>
                          <td onClick={() => this.editVendorShow(item, "")} className="customer_name_size">{item.customerName}</td>
                          <td onClick={() => this.editVendorShow(item, "")} className="customer_name_size">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item.projectName || "-"}</Tooltip>}>
                              <span className="d-inline-block">
                                {item.projectName || "-"}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td onClick={() => this.editVendorShow(item, "")}>{item?.generatedBy}</td>
                          <td onClick={() => this.editVendorShow(item, "")}>{changeDateFormat(item.createdAt)}</td>
                          <td style={{ textAlign: "center" }}>
                            <button style={{ width: "145px" }} className={changePoClassName(item.status)} onClick={() => this.editVendorShow(item, "")} >
                              {item.status}
                            </button>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {
                              (() => {
                                if (item.status === "DRAFT") {
                                  return (
                                    <>
                                      <img src={Edit} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "edit")} />
                                      <img src={Delete} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "del")} />
                                    </>
                                  )
                                } else if (item.status === "GENERATED") {
                                  return (
                                    <>
                                      <img src={download} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "")} />
                                      <img src={mail} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "mail")} />
                                    </>
                                  )
                                } else if (item.status === "SENT") {
                                  return (
                                    <>
                                      <img src={Clone} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "clone")} />
                                      <img src={download} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "")} />
                                    </>
                                  )
                                } else if (item.status === "CONVERTED") {
                                  return (
                                    <>
                                      <img src={download} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "")} />
                                      <img src={Clone} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "clone")} />
                                    </>
                                  )
                                } else if (item.status === "REVISED") {
                                  return (
                                    <>
                                      <img src={Clone} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "clone")} />
                                      <img src={download} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "")} />
                                    </>
                                  )
                                } else if (item.status === "CANCELLED") {
                                  return (
                                    <>
                                      <img src={Clone} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "clone")} />
                                      <img src={download} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "")} />
                                    </>
                                  )
                                } else if (item.status === "PARTIALLY_RECEIVED") {
                                  return (
                                    <>
                                      <img src={download} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "")} />
                                      <img src={Deliver} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "deliver")} />
                                    </>
                                  )
                                } else if (item.status === "PARTIALLY_DELIVERED") {
                                  return (
                                    <>
                                      <img src={download} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "")} />
                                      <img src={Deliver} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "deliver")} />
                                    </>
                                  )
                                } else if(item.status === "RECEIVED") {
                                  return (
                                    <>
                                      <img src={download} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "")} />
                                      <img src={Deliver} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "deliver")} />
                                    </>
                                  )
                                } {
                                  return (
                                    <>
                                      <img src={Clone} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "clone")} />
                                      <img src={download} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "")} />
                                    </>
                                  )
                                }
                              })()
                            }
                          </td>
                        </tr>
                      })) : !this.props.loading ?
                      <h1 className="empty-data">No Records Found</h1> :
                      <Spinner />
                    }
                  </tbody>
                </table>
              </div>
            </Card>
            <BOQView
              show={editShow}
              data={status}
              onHide={this.editVendorClose}
              goTo={this.goToPoPage}
              generate={this.generatePoHandler}
              send={this.sendPohandler}
              sendMail={this.sendMailOpen}
              item={boqData}
              clone={this.clonePoHandler}
              delete={this.deleteItem}
              revise={this.revisePoHandler}
              deliver={this.projectOpenHandler}
              internal={this.outwardOpenHandler}
              cancel={this.cancelBoqHandler}
            />
            <DeletePopUp
              show={deletePopup}
              onHide={this.deleteItemClose}
              deleteItem={this.deletePoHandler}
            />
            <Projects
              show={projectPopup}
              boqId={boqData.billOfQuantityId}
              onHide={this.projectCloseHandler}
              handleShow={this.outwardOpenHandler}
            />
            <Outward
              show={outwardPopup}
              id={boqData?.billOfQuantityId}
              onHide={this.outwardCloseHandler}
              data={this.state.data}
              str={itStr}
            />
            <CloneStatus
              show={this.state.clonePopup}
              str={this.state.cloneStr}
              onHide={false}
            />
            <ClonePopup
              show={this.state.cloneShow}
              str={this.state.cloneStr}
              onHide={false}
              cloned={this.state.cloned}
            />
            <BoqMailPopup
              show={this.state.emailPopup}
              onHide={this.sendMailClose}
              onSubmit={this.sendPoMailSubmit}
              getCC={this.getCCs}
            />
            <Pagination data={data} onChange={this.onChange} />
          </div>
        </div >
        <div className="template-two-design" style={{ display: "none" }} ref={this.myRef} dangerouslySetInnerHTML={{ __html: `${this.props.previewBoqText}` }} />
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.boq.loading,
    boqList: state.boq.boqList,
    convertBoqList: state.boq.convertBoqList,
    previewBoqText: state.boq.previewBoqText,
    generateData: state.boq.generateData,
    sendData: state.boq.sendData,
    cloneData: state.boq.cloneData,
    reviseData: state.boq.reviseData,
    deleteBoq: state.boq.deleteBoq,
    boqMailSuccess: state.boq.boqMailSuccess,
  };
};

export default connect(mapStateToProps)(BillOfQuantity);

