import React from 'react'
import parse from 'html-react-parser';
import { Row, Col } from 'react-bootstrap';
import { Modal, Form, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import Select from 'react-select';
import { connect } from "react-redux";
import plus from "../../../assets/images/ar-g.svg";
import { clearConfirmDropdownList, createBoq, role, setTitle } from '../../../actions';
import { toast } from 'react-toastify';


class ConvertBoq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            openedItem: [],
            quotationId: "",
            referenceId: "",
            dropDownList: [],
            projects: [],
            confirmPopup: false,
        }
        this.productHandler = this.productHandler.bind(this);
        this.selectHandler = this.selectHandler.bind(this);
        this.selectProHandler = this.selectProHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.confirmPopupClose = this.confirmPopupClose.bind(this);
        this.confirmPopupOpen = this.confirmPopupOpen.bind(this);
    }
    selectHandler(e) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((i) => i = e.target.checked);
        this.setState({
            openedItem: newItems,
        })
    }
    selectProHandler(e, index) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((item, i) => index === i ? e.target.checked : item);
        this.setState({
            openedItem: newItems
        })
    }
    confirmPopupOpen() {
        const { openedItem } = this.state;
        if (openedItem.includes(true)) {
            this.setState({
                confirmPopup: true
            })
        } else {
            toast("Select Atleast One Product");
        }
    }
    confirmPopupClose() {
        this.setState({
            confirmPopup: false
        })
    }
    changeHandler = (e, index) => {
        const { data } = this.state;
        let a = Number(data[index].quantity);
        let b = e.target.value;
        if (b && !/^([0-9]{1,5})+(\.[0-9]{0,2})?$/i.test(b)) {
            toast("Enter valid digits Ex(19.00,23456.45,200.34)");
            return false;
        }
        data[index]["boqQuantity"] = b;
        if (+b <= a) {
            this.setState({
                data: data
            })
        }
        else {
            toast("Pls enter the number less than or equal to " + a);
        }
    }
    productHandler(e) {
        this.state.dropDownList?.map((item) => {
            if (item?.quotationId == e.value) {
                let data = [];
                this.setState({
                    data: item?.quotationGroupProductResDto,
                    quotationId: item?.quotationId,
                    referenceId: item?.referenceId
                })
                item?.quotationGroupProductResDto?.map((item, i) => data[i] = false);
                this.setState({
                    openedItem: data
                })
            }
        });
    }
    clickHandler() {
        this.setState({
            data: [],
        })
        this.props.handleShow();
    }
    submitHandler() {
        const { openedItem, data, quotationId } = this.state;
        let pro = [];
        data?.map((item, i) => {
            let items = {};
            if (openedItem[i]) {
                if (Number(item.boqQuantity) !== 0) {
                    items["productId"] = item.productId;
                    // items["productGroupId"] = item.productGroupId;
                    items["boqQuantity"] = Number(item.boqQuantity);
                    pro.push(items);
                }
            }
        });
        let formData = {
            "quotationId": quotationId,
            "productReqDtoList": pro
        }
        this.props.dispatch(createBoq(formData));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.boqData !== this.props.boqData) {
            const { boqData } = this.props;
            const { history } = this.props;
            if (this.props.boqData.status === 200) {
                this.setState({
                    data: [],
                    dropDownList: [],
                    confirmPopup: false
                })
                setTitle("Edit BOQ");
                window.location.hash = `/${role}/bill_of_quantity/product_details/${boqData.data.data.billOfQuantityId}`;
                this.props.onHide();
                this.props.dispatch(clearConfirmDropdownList());
                toast(this.props.boqData.message);
                this.props.allData();
            } else {
                toast(this.props.boqData.message);
            }
        } if (prevProps.confirmQuotDropdown !== this.props.confirmQuotDropdown) {
            const { confirmQuotDropdown } = this.props;
            let items = [];
            confirmQuotDropdown !== undefined && confirmQuotDropdown?.length > 0 && confirmQuotDropdown?.map((item) => {
                let data = {};
                let pro = item.projectName !== null ? " -" + item?.projectName : "";
                data["value"] = item.quotationId;
                data["label"] = `${item.customerName} - ${item.referenceId}  ${pro}`
                items.push(data);
            })
            this.setState({
                projects: items
            })
            this.setState({
                dropDownList: this.props.confirmQuotDropdown
            })
            this.props.confirmQuotDropdown?.filter((item) => {
                if (item?.quotationId === this.props.quotationId) {
                    let data = [];
                    item?.quotationGroupProductResDto?.map((item, i) => data[i] = false);
                    this.setState({
                        openedItem: data,
                        data: item?.quotationGroupProductResDto,
                        quotationId: item?.quotationId
                    })
                }
            });
        }
    }


    render() {
        const { show, onHide, load, quotData, str, loading } = this.props;
        const { data, openedItem, dropDownList, confirmPopup, referenceId, projects } = this.state;
        return (
            <div>
                <>
                    <button className="convert-po" onClick={this.clickHandler}>
                        <img src={plus} alt="" />Convert  to BOQ</button>
                    <Modal
                        show={show}
                        size="xl"
                        onHide={() => {
                            onHide();
                            this.setState({
                                data: []
                            })
                        }}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <div className="modal-staff">
                            <div className="head">
                                <div>
                                    <i class="fas fa-arrow-left" onClick={() => {
                                        onHide();
                                    }}></i>
                                    <p >CONVERT <span><h4>{`${referenceId || quotData?.referenceId || "QUOTE"}`} </h4></span> TO BOQ </p>
                                </div>
                            </div>
                            {str === "BOQ" &&
                                <div className='space-parts' >
                                    <Row>
                                        <Col md={3}></Col>
                                        <Col md={6}>
                                            <Select
                                                placeholder="Select One Quotation"
                                                onChange={(e) => this.productHandler(e)}
                                                options={projects}
                                                isSearchable={true}
                                            />
                                        </Col>
                                        <Col md={3}></Col>
                                    </Row>

                                    {/* <select name="referenceId" className='form-control form-select mt-4' onChange={(e) => this.productHandler(e)} style={{ margin: "0 30px", width: "95%" }}>
                                        <option value="">Select One</option>
                                        {dropDownList ?? dropDownList?.length > 0 ? dropDownList?.map((unit, i) => (
                                            <option value={unit?.quotationId} key={i}>{unit?.referenceId}</option>)) : ""}
                                    </select>
                                    <div className="triangle-bottom" style={{ margin: "60px 20px 0 0" }}></div> */}
                                </div>
                            }
                            <div className="table-class space-part">
                                <table class="table table-borderless">
                                    <thead >
                                        <tr>
                                            <th scope="col">
                                                Art ID
                                            </th>
                                            <th scope="col">
                                                Product Name
                                            </th>
                                            <th scope="col">
                                                Qty
                                            </th>
                                            <th scope="col">
                                                BOQ Qty
                                            </th>
                                            <th scope="col" className='check-item'>
                                                Select
                                                <input type="checkbox" onChange={this.selectHandler} style={{ margin: "7px" }} />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <div className="empty-data">
                                                <Spinner />
                                            </div>
                                        ) : data === null || data === undefined || data?.length === 0 ? (
                                            <div>
                                                <h1 className="empty-data">No Records Found</h1> :
                                            </div>
                                        ) : data?.map((item, i) => (
                                            <tr key={i} >
                                                <td>{item?.articleId}</td>
                                                <td>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.productName || "-")}</Tooltip>}>
                                                        <span className="d-inline-block new-line">
                                                            {parse(item.productName || "-")}
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                                <td>{item?.quantity}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        maxLength="8"
                                                        className='convert_po_input'
                                                        value={item?.boqQuantity}
                                                        onChange={(e) => this.changeHandler(e, i)} />
                                                </td>
                                                <td >
                                                    <Form.Check
                                                        checked={openedItem[i] ? true : false}
                                                        onChange={(e) => this.selectProHandler(e, i)}
                                                        name="group1"
                                                        type="checkbox"
                                                        className="form-check-box"
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <center style={{ padding: "10px" }}>
                                <button className="customer-btn col-md-5" onClick={() => this.confirmPopupOpen()}>Convert to BOQ</button>
                            </center>
                        </div>
                    </Modal>
                </>
                <Modal
                    show={confirmPopup}
                    onHide={() => {
                        this.setState({
                            confirmPopup: false
                        });
                    }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className="modal-success">
                        <h5>Are you sure, you want to convert this Quote to BOQ</h5>
                    </div>
                    <div className="txt-center">
                        <button onClick={() => {
                            this.setState({
                                confirmPopup: false
                            });
                        }}
                            className="no_btn"
                        >
                            Cancel
                        </button>
                        {!load ?
                            <button onClick={this.submitHandler} className="okay"> Okay
                            </button>
                            : <button className="okay">Converting...</button>}
                    </div>
                </Modal>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        convertBoqList: state.boq.convertBoqList,
        load: state.boq.load,
        loading: state.boq.loading,
        confirmQuotDropdown: state.boq.confirmQuotDropdown,
        confirmQuotProduct: state.boq.confirmQuotProduct,
        boqData: state.boq.boqData
    }
}

export default connect(mapStateToProps)(ConvertBoq);
