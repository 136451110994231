import { authToken, baseUrl } from "../actions";

export const createInitialPackageApi = (request) => {
    let CREATE_INITIAL_PACKAGE = `${baseUrl}staff/addPackage`;
    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_INITIAL_PACKAGE, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}

export const updatePackageApi = (request) => {
    let UPDATE_PACKAGE = `${baseUrl}staff/editPackage`;
    const parameters = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(UPDATE_PACKAGE, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}

export const getPackageListApi = (request) => {
    let GET_PACKAGE = `${baseUrl}staff/getPackagesList`;
    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(GET_PACKAGE, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}

export const addPackageProductApi = (request) => {
    let ADD_PACKAGE = `${baseUrl}staff/package/addProduct`;
    const parameters = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(ADD_PACKAGE, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}

export const editPackageProductApi = (request) => {
    let EDIT_PACKAGE = `${baseUrl}admin/package/editProduct`;
    const parameters = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(EDIT_PACKAGE, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}

export const getProductListByPackageIdApi = (request) => {
    let GET_PRODUCTS_FOR_PACKAGE = `${baseUrl}staff/getPackageProducts?packageId=${request.id}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(GET_PRODUCTS_FOR_PACKAGE, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}

export const getPackageProductsDropdownApi = (request) => {
    let GET_PRODUCTS_FOR_PACKAGE_DROPDOWN = `${baseUrl}staff/package/product-dropdown?searchKey=${request.searchKey}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(GET_PRODUCTS_FOR_PACKAGE_DROPDOWN, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}

export const getProductsDropdownApi = (request) => {
    let GET_PRODUCTS_DROPDOWN = `${baseUrl}staff/products/v1?searchKey=${request.searchKey}&sizeOfPage=50`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(GET_PRODUCTS_DROPDOWN, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
