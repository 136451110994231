import { dashboardConstants } from "../constants";

const initialState = {
    loading: false,
    error: [],
    token: "",
    quotation_list: [],
    count: [],
    out_stock: []
};

export default function dashboardReducer(state = initialState, { type, response }) {
    switch (type) {
        case dashboardConstants.GET_DASHBOARD:
            return {
                ...state,
                loading: true,
            };
        case dashboardConstants.GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                quotation_list: response.data.quotationList,
            };
        case dashboardConstants.GET_DASHBOARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case dashboardConstants.GET_DASHBOARD_COUNT:
            return {
                ...state,
                loading: true,
            };
        case dashboardConstants.GET_DASHBOARD_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                count: response.data
            };
        case dashboardConstants.GET_DASHBOARD_COUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case dashboardConstants.GET_DASHBOARD_OUTOFSTOCK:
            return {
                ...state,
                loading: true,
            };
        case dashboardConstants.GET_DASHBOARD_OUTOFSTOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                out_stock: response.data
            };
        case dashboardConstants.GET_DASHBOARD_OUTOFSTOCK_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
}
