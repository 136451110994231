import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import { vendorpoConstants } from "../constants";
import { getVendorPoApi, createVendorPoApi, getOneVendorPoApi, updateVendorPoApi } from "../services";

export function* vendorpolistSaga(payload) {
    try {
        const response = yield call(getVendorPoApi, payload.item);
        yield put({ type: vendorpoConstants.GET_VENDORPO_SUCCESS, response });
    } catch (error) {
        yield put({ type: vendorpoConstants.GET_VENDORPO_FAILURE, error });
    }
}
export function* createvendorpoSaga(payload) {
    try {
        const response = yield call(createVendorPoApi, payload.formData);
        yield put({ type: vendorpoConstants.CREATE_VENDORPO_SUCCESS, response });
    } catch (error) {
        yield put({ type: vendorpoConstants.CREATE_VENDORPO_FAILURE, error });
    }
}
export function* getvendorpoSaga(payload) {
    try {
        const response = yield call(getOneVendorPoApi, payload.purchaseOrderId);
        yield put({ type: vendorpoConstants.GET_VENDORPO_SUCCESSD, response });
    } catch (error) {
        yield put({ type: vendorpoConstants.GET_VENDORPO_FAILURED, error });
    }
}
export function* updatevendorpoSaga(payload) {
    try {
        const response = yield call(updateVendorPoApi, payload);
        yield put({ type: vendorpoConstants.UPDATE_VENDORPO_SUCCESS, response });
    } catch (error) {
        yield put({ type: vendorpoConstants.UPDATE_VENDORPO_FAILURE, error });
    }
}

export default function* vendorpoSaga() {
    yield takeEvery(vendorpoConstants.GET_VENDORPO, vendorpolistSaga);
    yield takeLatest(vendorpoConstants.CREATE_VENDORPO_INIT, createvendorpoSaga);
    yield takeLatest(vendorpoConstants.GET_VENDORPO_INIT, getvendorpoSaga);
    yield takeLatest(vendorpoConstants.UPDATE_VENDORPO_INIT, updatevendorpoSaga);
}