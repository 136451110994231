import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Modal } from "react-bootstrap";
import validate from '../../../purchaseOrder/createpurchaseorder/footer/validate';

const textField = ({ input, placeholder, maxLength, type, meta: { touched, error } }) => (
    <div>
        <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength} />
        {touched && error && <p className="require" >{error}</p>}
    </div>
);

const EditFooter = props => {
    const { handleSubmit, show, onHide, load } = props;

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            // size="lg"
            centered >
            <div className="modal-staff">
                <div className="head">
                    <div>
                        <i class="fas fa-arrow-left" onClick={onHide}></i>
                        <h4> EDIT FOOTER</h4>
                    </div>
                </div>
                <Row className='modal-unit'>
                    <Col md={12} className="edit-product new-pro">
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={12} className="mt-4" style={{ marginBottom: "12px" }}>
                                    <label className="form-control-placeholders" for="name">Heading <span>*</span></label>
                                    <Field
                                        name="chargeName"
                                        type="text"
                                        component={textField}
                                        // placeholder="Enter Area *"
                                        required={true}
                                    />
                                </Col>
                                <Col md={12} className="mt-3">
                                    <label className="form-control-placeholders" for="name">Amount  <span>*</span></label>
                                    <Field
                                        name="chargeValue"
                                        type="text"
                                        component={textField}
                                        required={true}
                                    />
                                </Col>
                                <Col md={12} className="mt-3">
                                    <Field
                                        type="hidden"
                                        name="footerId"
                                        component={textField}
                                    />
                                </Col>
                            </Row>
                            <button type="submit" className="modal-btn">
                                {load ? "Loading.." : "Update Footer"}
                            </button>
                        </form>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default reduxForm({
    form: 'EditFooterForm',
    enableReinitialize: true,
    validate
})(EditFooter)

