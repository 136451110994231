import React, { Component } from "react";
import plus from "../../../../assets/images/bx-plus (2).svg";
import Orange from "../../../../assets/images/green2.svg";
import { Link } from "react-router-dom";
import { Form, Row, Col, Card } from "react-bootstrap";
import { connect } from 'react-redux';
import validate from "./validate";
import { Field, reduxForm, FieldArray, reset } from 'redux-form'
import {
    displayCurrency,
    getPoById,
    role,
    getPoFooter,
    createPoFooter,
    deletePoFooter,
    deletePo,
    generatePo,
    previewPo,
    clonePo,
    revisePo,
    sendPo,
    cancelPo,
    clearPoVerifyProducts,
    getPoVerifyProduts,
    getPoInwardList,
    getTitleStr,
    getPoMailDetails,
    sendPoMail
} from "../../../../actions";
import { toast } from "react-toastify";
import Navbar from "../../../../layouts/Navbar";
import Sidebar from "../../../../layouts/Sidebar";
import DeletePopUp from "../../vendorpo/deletePopup";
import VenderPoView from "../../vendorpo/VenderPoView";
import VerifyProducts from '../../vendorpo/verifyProducts';
import InwardProducts from '../../vendorpo/Inward';
import CloneStatus from "../../../../Components/cloningStatus";
import ClonePopup from "../../../../Components/clonePopup";
import EmailPopup from '../../../../Components/emailPopup';


const textField = ({ input, name, label, span, placeholder, readOnly, maxLength, required, type, meta: { touched, error, warning } }) => (
    <Form.Group className="mb-3" controlId={label}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
            {...input}
            name={name}
            type={type}
            readOnly={readOnly}
            placeholder={placeholder}
            maxLength={maxLength}
        />
        {touched && ((error && <span className="require">{error}</span>) || (warning && <span>{warning}</span>))}
    </Form.Group>
)

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            footerList: [],
            footerObject: [],
            deletePopup: false,
            addFooterSuccess: [],
            ignoreFieldList: [],
            total: "",
            footerId: null,
            num: 0,
            footerLength: 0,
            footerCharges: 0,
            editShow: false,
            total: 0,
            heading: "",
            amount: "",
            deleteStr: '',
            status: "DRAFT",
            verifyPopup: false,
            deletePopup: false,
            inwardPopup: false,
            cloned: false,
            clonePopup: false,
            cloneStr: "",
            cloneShow: false,
            emailPopup: false,
            cc: [],
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.renderCharges = this.renderCharges.bind(this);
        this.addFooterHandler = this.addFooterHandler.bind(this);
        this.removeFooterHandler = this.removeFooterHandler.bind(this);
        this.changeHeadingHandler = this.changeHeadingHandler.bind(this);
        this.changeAmountHandler = this.changeAmountHandler.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.goToPoPage = this.goToPoPage.bind(this);
        this.generatePoHandler = this.generatePoHandler.bind(this);
        this.editVendorShow = this.editVendorShow.bind(this);
        this.editVendorClose = this.editVendorClose.bind(this);
        this.sendPohandler = this.sendPohandler.bind(this);
        this.clonePoHandler = this.clonePoHandler.bind(this);
        this.revisePoHandler = this.revisePoHandler.bind(this);
        this.cancelPoHandler = this.cancelPoHandler.bind(this);
        this.deletePoHandler = this.deletePoHandler.bind(this);
        this.verifyShowPopup = this.verifyShowPopup.bind(this);
        this.verifyClosePopup = this.verifyClosePopup.bind(this);
        this.inwardOpen = this.inwardOpen.bind(this);
        this.inwardClose = this.inwardClose.bind(this);
        this.sendMailOpen = this.sendMailOpen.bind(this);
        this.sendMailClose = this.sendMailClose.bind(this);
        this.sendPoMailSubmit = this.sendPoMailSubmit.bind(this);
        this.getCCs = this.getCCs.bind(this);
    }
    goToPoPage() {
        const { status } = this.state
        if (status === "DRAFT") {
            this.setState({
                editShow: false
            })
        } else {
            this.revisePoHandler();
        }
    }
    generatePoHandler() {
        this.setState({
            editShow: false
        })
        this.props.dispatch(generatePo(this.props.match.params.id));
    }
    sendPohandler() {
        this.props.dispatch(sendPo(this.props.match.params.id));
    }
    clonePoHandler() {
        this.setState({
            cloned: true,
            clonePopup: true,
            cloneStr: "Cloning Purchase Order...Please wait..."
        })
        this.props.dispatch(clonePo(this.props.match.params.id));
    }
    revisePoHandler() {
        this.setState({
            cloned: false,
            clonePopup: true,
            cloneStr: "Revising Purchase Order...Please wait..."
        })
        this.props.dispatch(revisePo(this.props.match.params.id));
    }
    cancelPoHandler() {
        this.props.dispatch(cancelPo(this.props.match.params.id));
    }
    verifyShowPopup() {
        this.props.dispatch(getPoVerifyProduts(this.props.match.params.id));
        this.setState({
            verifyPopup: true
        })
    }
    verifyClosePopup() {
        this.props.dispatch(clearPoVerifyProducts());
        this.setState({
            verifyPopup: false
        })
    }
    inwardOpen() {
        this.props.dispatch(getPoInwardList());
        this.setState({
            inwardPopup: true,
        })
    }
    inwardClose() {
        this.setState({
            inwardPopup: false
        })
    }
    editVendorShow() {
        this.setState({
            editShow: true,
        })
    }
    editVendorClose() {
        const { status } = this.state;
        if (status === "DRAFT") {
            this.setState({
                editShow: false
            })
        } else {
            window.location.hash = `/${role}/vendor_po`;
        }
    }
    deletePoHandler() {
        this.props.dispatch(deletePo(this.props.match.params.id));
    }
    prevPage() {
        window.location.hash = `/${role}/vendor_po/product-detail/${this.props.match.params.id}`
    }
    handleClose() {
        this.setState({
            deletePopup: false,
        })
    }
    handleShow() {
        this.setState({
            deletePopup: true,
        })
    }
    changeHeadingHandler(e) {
        const { value } = e.target;
        this.setState({
            heading: value
        })
    }
    changeAmountHandler(e) {
        const { value } = e.target;
        this.setState({
            amount: value
        })
    }
    deleteHandler(id) {
        this.setState({
            footerId: id,
            deletePopup: true
        })
    }
    async removeFooterHandler() {
        const { footerId } = this.state;
        this.props.dispatch(deletePoFooter(footerId));
    }
    async addFooterHandler(formData) {
        let item = formData.miscellaneousCharges[0];
        if (item.heading !== "" && item.amount !== "") {
            let data = {
                "heading": item.heading,
                "amount": item.amount,
                'purchaseOrderId': Number(this.props.match.params.id),
            }
            this.props.dispatch(createPoFooter(data));
        } else {
            // window.location.hash = "/" + role + "/purchase_order/vendor-po/termsAndConditions/" + this.props.match.params.id;
        }
    }
    sendMailOpen() {
        this.setState({
            emailPopup: true,
            editShow: false,
        })
        this.props.dispatch(getPoMailDetails(Number(this.props.match.params.id)));

    }
    sendMailClose() {
        this.setState({
            emailPopup: false
        })
    }
    getCCs(val) {
        this.setState({
            cc: val
        })
    }
    sendPoMailSubmit(values, dispatch) {
        const { cc } = this.state;
        const newItems = cc?.map((item) => item.value);
        let data = {
            "from": values.from,
            "to": values.to,
            "cc": newItems,
            "id": Number(this.props.match.params.id),
            "subject": values.subject
        }
        this.props.dispatch(sendPoMail(data));
        dispatch(reset("SecondaryEmailForm"));
    }
    nextPage() {
        const { ignoredFields } = this.props;
        if (ignoredFields?.includes("TERMS_CONDITION")) {
            this.setState({
                editShow: true,
                deleteStr: "PO"
            })
            this.props.dispatch(previewPo(this.props.match.params.id));
        } else {
            setTimeout(() => window.location.hash = "/" + role + "/vendor_po/termsAndConditions/" + this.props.match.params.id, 100)
        }
    }
    componentDidMount() {
        if (this.props.match.params.id !== "add") {
            this.props.dispatch(this.props.dispatch(getPoFooter(this.props.match.params.id)));
            this.props.dispatch(getPoById(this.props.match.params.id));
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.footerList !== this.props.footerList) {
            this.setState({
                footerList: this.props.footerList,
            });
        } if (prevProps.footerSucess !== this.props.footerSucess) {
            if (this.props.footerSucess.status === 200) {
                this.props.reset();
                toast(this.props.footerSucess.data);
                this.props.dispatch(this.props.dispatch(getPoFooter(this.props.match.params.id)));
                this.props.dispatch(this.props.dispatch(getPoById(this.props.match.params.id)));
            } else {
                toast(this.props.footerSucess.data);
            }
        } if (prevProps.deleteFooter !== this.props.deleteFooter) {
            if (this.props.deleteFooter.status === 200) {
                this.setState({
                    deletePopup: false
                })
                toast(this.props.deleteFooter.data);
                this.props.dispatch(this.props.dispatch(getPoById(this.props.match.params.id)));
                this.props.dispatch(this.props.dispatch(getPoFooter(this.props.match.params.id)));
            } else {
                toast(this.props.deleteFooter.data);
            }
        } if (prevProps.poData !== this.props.poData) {
            const { poData } = this.props;
            this.setState({
                poData: this.props.poData,
                total: poData?.grandTotal
            });
        } if (prevProps.generateData !== this.props.generateData) {
            this.props.dispatch(previewPo(this.props.match.params.id));
            if (this.props.generateData.status === 200) {
                this.setState({
                    status: "GENERATED",
                    editShow: true
                })
                toast(this.props.generateData.data);
            } else {
                toast(this.props.generateData.data);
            }
        } if (prevProps.deletePo !== this.props.deletePo) {
            if (this.props.deletePo.status === 200) {
                toast(this.props.deletePo.data);
                setTimeout(() => window.location.hash = `/${role}/vendor_po`, 500);
                this.setState({
                    deletePopup: false,
                    editShow: false
                })
            } else {
                toast(this.props.deletePo.data);
            }
        } if (prevProps.sendData !== this.props.sendData) {
            if (this.props.sendData.status === 200) {
                toast(this.props.sendData.data);
                this.setState({
                    // editShow: false,
                    status: "SENT"
                })
            } else {
                toast(this.props.sendData.data);
            }
        } if (prevProps.cloneData !== this.props.cloneData) {
            if (this.props.cloneData.status === 200) {
                this.setState({
                    editShow: false,
                    clonePopup: false,
                    cloneShow: true,
                    cloneStr: "Purchase Order Cloned Successfully"
                })
                setTimeout(() => this.setState({ cloneShow: false, cloneStr: "", cloned: null }), 1500);
                setTimeout(() => window.location.hash = `/${role}/vendor_po`, 1800);
            } else {
                this.setState({
                    clonePopup: false,
                })
                toast(this.props.cloneData.data);
            }
        } if (prevProps.reviseData !== this.props.reviseData) {
            if (this.props.reviseData.status === 200) {
                toast(this.props.reviseData.message);
                this.setState({
                    editShow: false,
                    clonePopup: false,
                    cloneShow: true,
                    cloneStr: "Purchase Order Revised Successfully"
                })
                setTimeout(() => this.setState({ cloneShow: false, cloneStr: "", cloned: null }), 1500);
                setTimeout(() => window.location.hash = `/${role}/vendor_po`, 1800);
            } else {
                this.setState({
                    clonePopup: false,
                })
                toast(this.props.reviseData.data);
            }
        } if (prevProps.cancelData !== this.props.cancelData) {
            if (this.props.cancelData.status === 200) {
                toast(this.props.cancelData.data);
                this.listAllData();
                this.setState({
                    editShow: false
                })
            } else {
                toast(this.props.cancelData.data);
            }
        } if (prevProps.verifyData !== this.props.verifyData) {
            if (this.props.verifyData.status === 200) {
                this.props.dispatch(clearPoVerifyProducts());
                toast(this.props.verifyData.data);
                this.setState({
                    verifyPopup: false,
                    status: "VERIFIED"
                })
            } else {
                toast(this.props.verifyData.data);
            }
        } if (prevProps.poMailSuccess !== this.props.poMailSuccess) {
            const { poMailSuccess } = this.props;
            if (poMailSuccess.status === 200) {
                this.setState({
                    emailPopup: false,
                    cc: []
                });
                toast(poMailSuccess.data);
            } else {
                toast(poMailSuccess.data);
            }
        }
    }
    renderCharges = ({ fields, meta: { error, submitFailed } }) => (
        <>
            {
                this.props.footerList !== undefined ?? this.props.footerList.length !== 0 ? this.props.footerList.map((item, index) => {
                    return (
                        index < 6 ?
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={6}>
                                            <label class="form-control-label" for="name">Heading  <span>*</span></label>
                                            <Form.Group className="mb-3" controlId="enterarea">
                                                <Form.Control
                                                    type="text"
                                                    value={item.chargeName}
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <label class="form-control-label" for="name">Amount  <span>*</span></label>
                                            <Form.Group className="mb-3" controlId="enterarea">
                                                <Form.Control type="text"
                                                    value={item.chargeValue}
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <label class="form-control-label" for="name">Gst </label>
                                            <Form.Group className="mb-3" controlId="enterarea">
                                                <Form.Control type="text" defaultValue={this.props.poData?.gst} disabled label="Tax" />
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={1}> */}
                                        <Form.Group className="mb-3" controlId="enterarea">
                                            <Form.Control
                                                type="hidden"
                                                name="id"
                                                value={item.footerId}
                                            />
                                        </Form.Group>
                                        {/* </Col> */}
                                        <div onClick={(e) => this.deleteHandler(item.footerId)} className="del_trash">
                                            <i className="fas fa-trash"></i>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                            : <></>
                    )
                }) : <></>
            }
            {fields.map((footer, index) => (
                <Row key={"F" + index}>
                    <Col md={12} style={{ marginTop: "-20px" }}>
                        <Row>
                            <Col md={6}>
                                <label class="form-control-placeholder" for="name">Heading  <span>*</span></label>
                                <Field
                                    name={`${footer}.heading`}
                                    type="text"
                                    component={textField}
                                    required={true}
                                    onChange={this.changeHeadingHandler}
                                    maxLength="100"
                                />
                            </Col>
                            <Col md={3}>
                                <label class="form-control-placeholder" for="name">Amount  <span>*</span></label>
                                <Field
                                    name={`${footer}.amount`}
                                    type="text"
                                    component={textField}
                                    onChange={this.changeAmountHandler}
                                    required={true}
                                />
                            </Col>
                            <Col md={2}>
                                <label class="form-control-placeholder" for="name">Gst </label>
                                <Field
                                    name="gst"
                                    type="number"
                                    component={textField}
                                    placeholder={this.props.poData?.gst ?? "Amount Inclusive of 18% Tax"}
                                    readOnly
                                />
                            </Col>
                            {<Col md={1}>
                                {index !== 0 && <div className="del_trash foo_trash" onClick={() => {
                                    fields.remove(index);
                                }}>
                                    <i className="fas fa-trash"></i>
                                </div>}
                                {index === 0 ?
                                    <button type="submit" className="modal-btn foos" >
                                        <img src={plus} alt="" style={{ width: "15px", padding: "0 0 2px 2px" }} />
                                    </button>
                                    : <></>}
                            </Col>}
                        </Row>
                    </Col>
                </Row>
            ))}
        </>
    )

    render() {
        const { handleSubmit, poData, ignoredFields } = this.props;
        const { deletePopup, deleteStr, editShow, verifyPopup, inwardPopup, status } = this.state;
        let grandTotal = poData?.grandTotal;

        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page " >
                            <img src={Orange} alt="..." className="head-img" />
                            <h5 className='po_link' onClick={() => window.location.hash = "/" + role + "/vendor_po"}>Purchase Order</h5>
                            <i className="fas fa-arrow-right" style={{ marginTop: "10px" }}></i>
                            <h6>{getTitleStr()}</h6>
                            <hr className="line-new"></hr>
                            <div className="order-page-title">
                                <Link to={"/" + role + "/vendor_po/vendor-detail/" + this.props.match.params.id} >
                                    Vendor Details
                                </Link>
                                <Link to={"/" + role + "/vendor_po/product-detail/" + this.props.match.params.id}>
                                    Product Details
                                </Link>
                                <Link to={"/" + role + "/vendor_po/footer/" + this.props.match.params.id} className="active">
                                    Footer
                                </Link>
                                {ignoredFields?.includes("TERMS_CONDITION") ? <></> : <Link to={"/" + role + "/vendor_po/termsAndConditions/" + this.props.match.params.id} >
                                    Terms & Conditions
                                </Link>}
                            </div>
                            <form onSubmit={handleSubmit(this.addFooterHandler)} className="purchase_footer">
                                <FieldArray name="miscellaneousCharges" component={this.renderCharges} />
                                <br />
                                <br />
                                <br />
                                <div className="new-oneee" style={{ margin: "50px 40px 20px 0px" }}>
                                    <p>Grand Total &nbsp; &nbsp; :   &nbsp; &nbsp; &nbsp;   {displayCurrency(grandTotal)} </p>
                                    <p className="tax">(Total Amt. of all groups incl. of taxes)  </p>
                                </div>
                                <br />
                                {/* <div className="customer-sub" style={{ marginTop: "340px" }}>
                                    <button type="button" style={{ marginLeft: "17px" }} className="cancel-btn qutation-btn" onClick={() => this.prevPage()}>Cancel</button>
                                    <button type="submit" onClick={() => this.nextPage()} className="modal-btn save">
                                        Save & Proceed
                                    </button>
                                </div> */}
                                <Row >
                                    <Col md={3} >
                                        <button className="cancel-btn" onClick={() => this.prevPage()} style={{ marginTop: "110px" }}>Cancel</button>
                                    </Col>
                                    <Col md={6} >
                                    </Col>
                                    <Col md={3} >
                                        <button type="submit" onClick={() => this.nextPage()} className="modal-btn save" style={{ marginTop: "110px" }}>Save & Proceed</button>
                                    </Col>
                                </Row>

                            </form >
                        </Card>
                    </div>
                    <DeletePopUp
                        show={deletePopup}
                        onHide={this.handleClose}
                        deleteItem={deleteStr === "PO" ? this.deletePoHandler : this.removeFooterHandler}
                    />
                    <VenderPoView
                        show={editShow}
                        onHide={this.editVendorClose}
                        goTo={this.goToPoPage}
                        data={status}
                        item={poData}
                        generate={this.generatePoHandler}
                        delete={this.handleShow}
                        send={this.sendPohandler}
                        clone={this.clonePoHandler}
                        revise={this.revisePoHandler}
                        verify={this.verifyShowPopup}
                        cancel={this.cancelPoHandler}
                        inwardOpen={this.inwardOpen}
                        sendMail={this.sendMailOpen}

                    />
                    <VerifyProducts
                        show={verifyPopup}
                        onHide={this.verifyClosePopup}
                        data={poData}
                    />
                    <InwardProducts
                        show={inwardPopup}
                        id={Number(this.props.match.params.id)}
                        onHide={this.inwardClose}
                        listAllData={() => console.log("")}
                    />
                    <CloneStatus
                        show={this.state.clonePopup}
                        str={this.state.cloneStr}
                        onHide={false}
                    />
                    <ClonePopup
                        show={this.state.cloneShow}
                        str={this.state.cloneStr}
                        onHide={false}
                        cloned={this.state.cloned}
                    />
                    <EmailPopup
                        show={this.state.emailPopup}
                        onHide={this.sendMailClose}
                        onSubmit={this.sendPoMailSubmit}
                        getCC={this.getCCs}
                    />
                </div >
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        initialValues: {
            miscellaneousCharges: [{ heading: '', amount: '' }]
        },
        footerList: state.po.footerList,
        poData: state.po.poData,
        footerSucess: state.po.footerSucess,
        ignoredFields: state.po.poData?.ignoredFields,
        deleteFooter: state.po.deleteFooter,
        generateData: state.po.generateData,
        sendData: state.po.sendData,
        deletePo: state.po.deletePo,
        cloneData: state.po.cloneData,
        reviseData: state.po.reviseData,
        cancelData: state.po.cancelData,
        verifyData: state.po.verifyData,
        confirmQuotDropdown: state.boq.confirmQuotDropdown,
        convertProducts: state.po.convertProducts,
        poMailSuccess: state.po.poMailSuccess,
    }
}

const QuotationFooterForm = reduxForm({
    form: 'quotationFooterFormRedux',
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate
})(Footer)

export default connect(mapStateToProps)(QuotationFooterForm);
