import { projectsConstants } from "../constants";

const initialState = {
    loading: false,
    success_msg: "",
    data: [],
    error: [],
    status: 0,
    token: "",
    projects: [],
    page: "create",
    sortType: ""
};

export default function projectsReducer(state = initialState, { type, response }) {
    switch (type) {
        case projectsConstants.GET_PROJECTS:
            return {
                ...state,
                loading: true,
            };
        case projectsConstants.GET_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                projects_list: response.data.content,
                allData: response.data,

            };
        case projectsConstants.GET_PROJECTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
                success_msg: "",
            };
        case projectsConstants.CREATE_PROJECTS_INIT:
        case projectsConstants.UPDATE_PROJECTS_INIT:
            return {
                ...state,
                loading: true,
                success_msg: "",
            }
        case projectsConstants.CREATE_PROJECTS_SUCCESS:
        case projectsConstants.UPDATE_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success_msg: response,
                status: response.status
            }
        case projectsConstants.CREATE_PROJECTS_FAILURE:
        case projectsConstants.UPDATE_PROJECTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            }
        case projectsConstants.GET_SINGLE_PROJECTS_INIT:
            return {
                ...state,
                loading: true,
                page: ""
            }
        case projectsConstants.GET_SINGLE_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                projects: response.data,
                status: response.status,
                page: "edit"
            }
        case projectsConstants.GET_SINGLE_PROJECTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            }
        case projectsConstants.CHANGE_PAGE:
            return {
                ...state,
                loading: false,
                page: "create"
            }

        default:
            return state;
    }
}
