import { groupConstants } from "../constants";

const initialState = {
    loading: false,
    success_msg: "",
    data: [],
    error: [],
    status: 0,
    token: "",
    group: [],
    page: "create",
    sortType: ""
};

export default function groupReducer(state = initialState, { type, response }) {
    switch (type) {
        case groupConstants.GET_GROUP:
            return {
                ...state,
                loading: true,
            };
        case groupConstants.GET_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                group_list: response.data.content,
                allData: response.data,

            };
        case groupConstants.GET_GROUP_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
                success_msg: "",
            };

        case groupConstants.CREATE_GROUP_INIT:
        case groupConstants.UPDATE_GROUP_INIT:
            return {
                ...state,
                loading: true,
                success_msg: "",
            }
        case groupConstants.CREATE_GROUP_SUCCESS:
        case groupConstants.UPDATE_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                success_msg: response,
                status: response.status
            }
        case groupConstants.CREATE_GROUP_FAILURE:
        case groupConstants.UPDATE_GROUP_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            }

        case groupConstants.GET_GROUP_INIT:
            return {
                ...state,
                loading: true,
                page: ""
            }
        case groupConstants.GET_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                group: response.data,
                status: response.status,
                page: "edit"
            }
        case groupConstants.GET_GROUP_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            }

        case groupConstants.CHANGE_PAGE:
            return {
                ...state,
                loading: false,
                page: "create"
            }
        case groupConstants.CLEAR_GROUP_VALUES: {
            return {
                group: [],
                group_list: []
            }
        }

        default:
            return state;
    }
}
