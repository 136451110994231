import { authToken} from "../actions";

export const creategstapi = (request) => {
    const GST_CREATE = "https://estimationapi.corpfield.com/staff/customers";
    const parameters = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
        body: request,
    }

    return fetch(GST_CREATE, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        }
        );
}



export const createdelieveryapi = (request) => {
    const DELIEVERY_CREATE = "https://estimationapi.corpfield.com/staff/customers";
    const parameters = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
        body: request,
    }

    return fetch(DELIEVERY_CREATE, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        }
        );
}




export const createwarrentyapi = (request) => {
    const WARRENTY_CREATE = "https://estimationapi.corpfield.com/staff/customers";
    const parameters = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
        body: request,
    }

    return fetch(WARRENTY_CREATE, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        }
        );
}



export const createreferenceapi = (request) => {
    const BANK_CREATE = "https://estimationapi.corpfield.com/staff/customers";
    const parameters = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
        body: request,
    }

    return fetch(BANK_CREATE, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        }
        );
}



export const createbankapi = (request) => {
    const BANK_CREATE = "https://estimationapi.corpfield.com/staff/customers";
    const parameters = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
        body: request,
    }

    return fetch(BANK_CREATE, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        }
        );
}


export const createcompanyapi = (request) => {
    const COMPANY_CREATE = "https://estimationapi.corpfield.com/staff/customers";
    const parameters = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
        body: request,
    }

    return fetch(COMPANY_CREATE, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        }
        );
}