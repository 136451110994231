import { put, call, takeEvery } from "redux-saga/effects";
import { getTemplateApi, getOneTemplateApi, getSettingsApi } from "../services";
import { templateConstants } from "../constants";

export function* templatelistSaga(payload) {
    try {
        const response = yield call(getTemplateApi, payload.sort);
        yield put({ type: templateConstants.GET_TEMPLATE_SUCCESS, response });
    } catch (error) {
        yield put({ type: templateConstants.GET_TEMPLATE_FAILURE, error });
    }
}
export function* onetemplateSaga(payload) {
    try {
        const response = yield call(getOneTemplateApi, payload.templateId);
        yield put({ type: templateConstants.GET_TEMPLATE_ONE_SUCCESS, response });
    } catch (error) {
        yield put({ type: templateConstants.GET_TEMPLATE_ONE_FAILURE, error });
    }
}
export function* getSettingsSaga(payload) {
    try {
        const response = yield call(getSettingsApi, payload);
        yield put({ type: templateConstants.GET_SETTINGS_SUCCESS, response });
    } catch (error) {
        yield put({ type: templateConstants.GET_SETTINGS_FAILURE, error });
    }
}

export default function* templateSaga() {
    yield takeEvery(templateConstants.GET_TEMPLATE, templatelistSaga);
    yield takeEvery(templateConstants.GET_TEMPLATE_ONE, onetemplateSaga);
    yield takeEvery(templateConstants.GET_SETTINGS, getSettingsSaga);
}