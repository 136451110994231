const validate = values => {
  const errors = {}
  const requiredFields = [
    'firstName',
    'email',
    "phone",
    'lastName',
    'role'
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Please fill the field *'
    }
  })
  if ((values.firstName && /[^a-zA-Z- ./]/i.test(values.firstName))) {
    errors.firstName = "Only albhabetic characters allowed";
  }
  if (values?.firstName?.trim().length === 0) {
    errors.firstName = "Please fill the field * ";
  }

  if ((values.lastName && /[^a-zA-Z- ./]/i.test(values.lastName))) {
    errors.lastName = "Only albhabetic characters allowed";
  }
  if (values?.lastName?.trim().length === 0) {
    errors.lastName = "Please fill the field * ";
  }

  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid Email Address *'
  } if (values.phone && !/^(0|[1-9][0-9]{9})$/i.test(values.phone)) {
    errors.phone = 'Invalid Phone Number'
  }

  return errors
}

export default validate