import React from "react";
import { Card, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Filter from "../../../assets/images/filter.svg";
import Spinner from "../../../Components/loader";
import Orange from "../../../assets/images/blue1.svg";
import Search from "../../../assets/images/search.svg";
import Pagination from "../../../Components/Pagination";
import Art from "../../../assets/images/art.svg";
import parse from 'html-react-parser';
import BulkUpload from "../inward/bulkUpload";
import AddStock from "../../product/AddStock";
import {
    changeProjectClassName,
    getPoInwardList,
    getReceivedInward,
    clearStockProduct,
    getProductList,
    getExecutiveUrl,
    exportToJson,
    getCustomersList,
    getVendorList,
    downloadInwardCsv,
    camelize,
    shortStr,
    downloadYetToReceiveCsv
} from "../../../actions";
import { toast } from "react-toastify";
import ReceiveFilter from "./ReceiveFilter";
const role = localStorage.getItem('userRole');

class YetToReceive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            inwardShow: false,
            showStock: false,
            inwardRemark: "",
            all_inward: [],
            sortType: "asc",
            filterData: {},
            searchKey: "",
            sortField: "",
            pageNo: 0,
            sort: {},
            csvShow: false,
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.stockhandleClose = this.stockhandleClose.bind(this);
        this.stockhandleShow = this.stockhandleShow.bind(this);
        this.onChange = this.onChange.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
        this.sortHandler = this.sortHandler.bind(this);
        this.filterSubmit = this.filterSubmit.bind(this);
        this.resetHandler = this.resetHandler.bind(this);
        this.inwardPopupOpen = this.inwardPopupOpen.bind(this);
        this.inwardPopupClose = this.inwardPopupClose.bind(this);
        this.listAllData = this.listAllData.bind(this);
        this.downloadCsv = this.downloadCsv.bind(this);
        this.csvShow = this.csvShow.bind(this);
        this.csvClose = this.csvClose.bind(this);
    }
    handleShow() {
        this.props.dispatch(getPoInwardList());
        this.setState({ show: true, showStock: false });
    }
    handleClose() {
        this.setState({ show: false });
    }
    csvShow() {
        this.setState({ csvShow: true });
    }
    csvClose() {
        this.setState({ csvShow: false });
    }
    inwardPopupOpen(item) {
        if (item?.inwardType !== "INWARD") {
            this.setState({
                inwardShow: true,
                inwardRemark: item.inwardRemarks
            });
        }
    }
    inwardPopupClose() {
        this.setState({
            inwardShow: false
        });
    }
    stockhandleShow() {
        this.props.dispatch(clearStockProduct());
        this.props.dispatch(getProductList(0));
        this.props.dispatch(getCustomersList(0));
        this.props.dispatch(getVendorList());
        this.setState({
            showStock: true,
        });
    }
    stockhandleClose() {
        this.setState({
            showStock: false,
        });
    }
    searchHandler(e) {
        const { sortType, sortField, filterData } = this.state;
        this.setState({
            searchKey: e.target.value
        });
        let url = getExecutiveUrl(0, e.target.value, filterData, sortField, sortType);
        this.props.dispatch(getReceivedInward(url));
    }
    sortHandler(sortField) {
        const { pageNo, searchKey, filterData, sortType } = this.state;
        this.setState({
            sortField: sortField
        });
        let type = sortType;
        if (this.state.sortType === 'desc') {
            type = 'asc';
            this.setState({
                sortType: 'asc'
            })
        } else {
            type = 'desc';
            this.setState({
                sortType: 'desc'
            })
        };
        let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, type);
        this.props.dispatch(getReceivedInward(url));
    }
    filterSubmit(filterData) {
        const { pageNo, searchKey, sortType, sortField } = this.state;
        this.setState({
            filterData: filterData
        })
        let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
        this.props.dispatch(getReceivedInward(url));
    }
    resetHandler() {
        const { pageNo, sortField, sortType, searchKey } = this.state;
        this.setState({
            filterData: {}
        })
        let url = getExecutiveUrl(pageNo, searchKey, {}, sortField, sortType);
        this.props.dispatch(getReceivedInward(url));
    }
    onChange(pageNo) {
        const { searchKey, sortType, sortField, filterData } = this.state;
        this.setState({
            pageNo: pageNo
        });
        let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
        this.props.dispatch(getReceivedInward(url));
    }
    listAllData() {
        const { pageNo, searchKey, filterData, sortType, sortField } = this.state;
        let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
        this.props.dispatch(getReceivedInward(url));
    }
    downloadCsv() {
        const { pageNo, searchKey, filterData, sortType, sortField } = this.state;
        let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
        this.props.dispatch(downloadYetToReceiveCsv(url));
        this.csvClose();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.received_inward !== this.props.received_inward) {
            this.setState({
                all_inward: this.props.received_inward,

            });
        } if (prevProps.success_msg !== this.props.success_msg) {
            if (this.props.success_msg?.status === 200) {
                this.setState({
                    showStock: false,
                })
                this.listAllData();
                toast(this.props.success_msg?.data);
            } else {
                toast(this.props.success_msg?.data);
            }
        } if (prevProps.receiveCsv !== this.props.receiveCsv) {
            exportToJson(this.props.receiveCsv, "YetToReceiveLogs");
        }
    }
    componentDidMount() {
        this.props.dispatch(getReceivedInward("page=0"));
    }
    render() {
        const { show, showStock, all_inward } = this.state;
        let data = {
            totalElements: all_inward?.totalElements,
            totalPages: all_inward?.totalPages,
            offset: all_inward?.pageable?.offset,
            numberOfElements: all_inward?.numberOfElements,
            num: (all_inward?.number) + 1
        }


        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <div className="page-head-split">
                                <div className="head-colums">
                                    <div className="head-part-tab">
                                        <img src={Orange} alt="..." className="head-imgs" />
                                        <h5>Inward</h5>
                                    </div>
                                    <div className="tab-linke">
                                        <Link to={"/" + role + "/inward"} >Inward Logs</Link>
                                        <Link to={"/" + role + "/inward/yetToReceive"} className="active">Yet To Receive</Link>
                                    </div>
                                    <div style={{ visibility: "hidden" }}>
                                        <BulkUpload
                                            handleShow={this.handleShow}
                                            show={show}
                                            onHide={this.handleClose}
                                            listData={this.listAllData}
                                        />
                                        <AddStock
                                            show={showStock}
                                            handleShow={this.stockhandleShow}
                                            onHide={this.stockhandleClose}
                                            bulkPopup={this.handleShow}
                                            bulkShow={show}
                                            bulkPopupHide={this.handleClose}
                                            listData={this.listAllData}
                                            str="inward"
                                        />
                                    </div>
                                    <div className="download_btn">
                                        <img src={Art} alt=".." onClick={this.csvShow} />
                                    </div>
                                    <ReceiveFilter onSubmit={this.filterSubmit} reset={this.resetHandler} />
                                    <div className="customer-search">
                                        <span><img src={Search} alt=".." className="search-img" /></span>
                                        <Form.Control type="search" placeholder="Search" onChange={this.searchHandler} className="main-search" />
                                    </div>
                                </div>
                            </div>
                            <div className="table-class">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col" >
                                                PO Number <img src={Filter} alt="..." onClick={(e) => this.sortHandler("po.purchase_order_reference_id")} />
                                            </th>
                                            <th scope="col">Article ID <img src={Filter} alt="..." onClick={(e) => this.sortHandler("p.article_id")} /></th>
                                            <th scope="col">
                                                Product Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("p.product_name")} />
                                            </th>
                                            <th scope="col">
                                                Project Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("pop.project_name")} />
                                            </th>
                                            <th scope="col">Unit <img src={Filter} alt="..." onClick={(e) => this.sortHandler("u.unit_name")} />
                                            </th>
                                            <th scope="col" className='txt-center'>Ordered Qty <img src={Filter} alt="..." onClick={(e) => this.sortHandler("order_qty")} />
                                            </th>
                                            <th scope="col" className='txt-center'>Total Qty <img src={Filter} alt="..." onClick={(e) => this.sortHandler("ps.stock_qty")} />
                                            </th>
                                            <th scope="col" className='txt-center'>Status <img src={Filter} alt="..." onClick={(e) => this.sortHandler("pop.status")} />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {all_inward?.content && all_inward?.content.length > 0 ? (
                                            all_inward?.content.map((item, i) => {
                                                return <tr key={i} style={{ cursor: "pointer" }}>

                                                    <td className="txt-center">{shortStr(item?.purchaseOrderReferenceNumber) || "-"}</td>
                                                    <td>{item.articleId}</td>
                                                    <td className="inward_new_size">
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.productName || "-")}</Tooltip>}>
                                                            <span className="d-inline-block">
                                                                {parse(item.productName || "-")}
                                                            </span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td className="inward_new_size">
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item.projectName || "-"}</Tooltip>}>
                                                            <span className="d-inline-block">
                                                                {item.projectName || "-"}
                                                            </span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>{item.unitName}</td>
                                                    <td className='txt-center'>{item.yetToReceivedQuantity}</td>
                                                    <td className='txt-center'>{item.totalQty}</td>
                                                    <td style={{ textAlign: "center" }} >
                                                        <button style={{ width: "140px" }} className={changeProjectClassName(item.status)} >
                                                            {item.status}
                                                        </button>
                                                    </td>
                                                </tr>
                                            })) : !this.props.loading ?
                                            <h1 className="empty-data">No Records Found</h1> :
                                            <Spinner />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                        <Modal
                            show={this.state.inwardShow}
                            onHide={() => {
                                this.setState({
                                    inwardShow: false
                                });
                            }}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <div className="modal-staff" >
                                <div className="head">
                                    <div>
                                        <i class="fas fa-arrow-left" onClick={() => {
                                            this.setState({
                                                inwardShow: false
                                            });
                                        }}></i>
                                        <h4>REMARK</h4>
                                    </div>
                                </div>
                                <div className="" style={{ margin: "20px 10px 20px 40px" }}>
                                    <h6 style={{ fontWeight: "400", color: "#121212" }}>
                                        {this.state.inwardRemark}
                                    </h6>
                                </div>
                            </div>
                        </Modal >
                        <Modal
                            show={this.state.csvShow}
                            onHide={() => {
                                this.setState({
                                    csvShow: false
                                });
                            }}
                            size="sm"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <div className="modal-success">
                                <h5>Are You Sure! You want to download this csv file ?</h5>
                            </div>
                            <div className="txt-center">
                                <button className="no" onClick={this.csvClose}>cancel</button>
                                <button className="okay" onClick={this.downloadCsv}>okay</button>
                            </div>
                        </Modal>
                        <Pagination data={data} onChange={this.onChange} />
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.inward.loading,
        inward_list: state.inward.inward_list,
        success_msg: state.product.success_msg,
        received_inward: state.inward.received_inward,
        receiveCsv: state.inward.receiveCsv
    };
};

export default connect(mapStateToProps)(YetToReceive);

