import React from "react";
import { Card, Form, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import back from "../../../assets/images/back (3).png";
import plus from "../../../assets/images/bx-plus (2).svg";
import { Field, reduxForm, FieldArray, reset, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import validate from "./validate";
import Orange from "../../../assets/images/green2.svg";
import Edit from "../../../assets/images/ui.svg";
import Delete from "../../../assets/images/ux.svg";
import { toast } from "react-toastify";
import { addFooterCharges, deleteFooterCharges } from "./services/servics";
import {
    changeTitleStr,
    displayCurrency,
    editFooterToQuotation,
    getFooterListOfQuotations,
    getQutation
} from "../../../actions";
import DeletePopUp from "../../../Components/deletePopup";
import EditFooter from "./Location/EditFooter";

const role = localStorage.getItem('userRole');

const textField = ({ input, name, label, span, placeholder, readOnly, maxLength, required, type, meta: { touched, error, warning } }) => (
    <Form.Group className="mb-3" controlId={label}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
            {...input}
            name={name}
            type={type}
            readOnly={readOnly}
            placeholder={placeholder}
            maxLength={maxLength}
        />
        {touched && ((error && <span className="require">{error}</span>) || (warning && <span>{warning}</span>))}
    </Form.Group>
)



class QuotFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            editShow: false,
            empty: "",
            total: 0,
            footerId: null,
            footerItem: {}
        }
        this.handleChange = this.handleChange.bind(this);
        this.addFooterHandler = this.addFooterHandler.bind(this);
        this.removeFooterHandler = this.removeFooterHandler.bind(this);
        this.changeHeadingHandler = this.changeHeadingHandler.bind(this);
        this.changeAmountHandler = this.changeAmountHandler.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);
        this.deleteItemClose = this.deleteItemClose.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.editFooterPopup = this.editFooterPopup.bind(this);
        this.editFooterPopupClose = this.editFooterPopupClose.bind(this);
        this.editHandler = this.editHandler.bind(this);
    }
    prevPage() {
        const { history } = this.props;
        history.push(`/${role}/quotations/${this.props.match.params.add}/quotationBody/${this.props.match.params.id}/${this.props.match.params.quotId}`)
    }
    nextPage() {
        const { history } = this.props;
        setTimeout(() => history.push(`/${role}/quotations/${this.props.match.params.add}/termsAndConditions/${this.props.match.params.id}/${this.props.match.params.quotId}`), 500);
    }
    editFooterPopup(val) {
        this.setState({
            footerItem: val,
            editShow: true
        })
    }
    editFooterPopupClose() {
        this.setState({
            editShow: false
        })
    }
    editHandler(val) {
        console.log(val);
        if (!val.chargeName) {
            throw new SubmissionError({
                chargeName: 'Please fill the field *',
            })
        } else if (!val.chargeValue) {
            throw new SubmissionError({
                chargeValue: 'Please fill the field *'
            })
        } else if (val.chargeValue && /[^0-9 ]/i.test(val.chargeValue)) {
            throw new SubmissionError({
                chargeValue: 'Enter Valid digits'
            })
        } else if (val.chargeValue == 0) {
            throw new SubmissionError({
                chargeValue: 'Please enter a value greater than zero'
            })
        }
        let data = {
            "footerId": val.footerId,
            "heading": val.chargeName,
            "amount": val.chargeValue
        }
        this.props.dispatch(editFooterToQuotation(data))
    }
    handleChange(content) {
        this.setState({ content });
    }
    changeHeadingHandler(e) {
        const { value } = e.target;
        this.setState({
            heading: value
        })
    }
    changeAmountHandler(e) {
        const { value } = e.target;
        this.setState({
            amount: value
        })
    }
    async removeFooterHandler() {
        const { footerId } = this.state;
        var footerData = await deleteFooterCharges(footerId);
        if (footerData?.data?.status === 200) {
            toast(footerData.data.data);
            this.setState({
                show: false
            })
            this.props.dispatch(getFooterListOfQuotations(this.props.match.params.quotId));
            this.props.dispatch(getQutation(this.props.match.params.quotId));
        } else {
            toast(footerData.data.data);
        }
    }
    async addFooterHandler(formData, dispatch) {
        console.log(formData);
        let item = formData.miscellaneousCharges[0];
        if (item.heading !== "" && item.amount !== "") {
            let data = {
                "heading": item.heading,
                "amount": item.amount,
                'quotationId': Number(this.props.match.params.quotId),
            }
            var footerData = await addFooterCharges(data);
            if (footerData?.data?.status === 200) {
                toast(footerData.data.data);
                this.props.dispatch(getFooterListOfQuotations(this.props.match.params.quotId));
                this.props.dispatch(getQutation(this.props.match.params.quotId));
                dispatch(reset("quotationFooterFormRedux"));
            } else {
                toast(footerData.data.data);
            }
        } else {
            this.nextPage();
            // this.props.nextPage();
        }
    }
    deleteHandler(id) {
        this.setState({
            footerId: id,
            show: true
        })
    }
    deleteItemClose() {
        this.setState({
            show: false
        })
    }
    renderCharges = ({ fields }) => (
        <>
            {this.props.footerList !== undefined ?? this.props.footerList.length !== 0 ? this.props.footerList.map((item, index) => {
                return (
                    index < 6 ?
                        <Row className="footer-round">
                            <Col md={12}>
                                <Row>
                                    <Col md={6}>
                                        <label class="form-control-label" for="name">Heading  <span>*</span></label>
                                        <Form.Group className="mb-3" controlId="enterarea">
                                            <Form.Control
                                                type="text"
                                                value={item.chargeName}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <label class="form-control-label" for="name">Amount  <span>*</span></label>
                                        <Form.Group className="mb-3" controlId="enterarea">
                                            <Form.Control type="text"
                                                value={item.chargeValue}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <label class="form-control-label" for="name">Gst </label>
                                        <Form.Group className="mb-3" controlId="enterarea">
                                            <Form.Control type="text" defaultValue={this.props.quotationData?.gstPercent} disabled label="Tax" />
                                        </Form.Group>
                                    </Col>
                                    <Col md={1}>
                                        <Form.Group className="mb-3" controlId="enterarea">
                                            <Form.Control
                                                type="hidden"
                                                name="id"
                                                value={item.footerId}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <img src={Edit} onClick={(e) => this.editFooterPopup(item)} alt=".." className="footer-edit-img" />
                                    <img src={Delete} alt=".." className="footer-del-img" onClick={(e) => this.deleteHandler(item.footerId)} />

                                    {/* <div onClick={(e) => this.deleteHandler(item.footerId)} className="del_trash">
                                        <i className="fas fa-trash"></i>
                                    </div> */}
                                </Row>
                            </Col>
                        </Row>
                        : <></>
                )
            }) : <></>
            }
            {fields.map((footer, index) => (
                <Row key={"F" + index} className="footer-rounds">
                    <Col md={12} style={{ marginTop: "-20px" }}>
                        <Row>
                            <Col md={6}>
                                <label class="form-control-placeholder" for="name">Heading  <span>*</span></label>
                                <Field
                                    name={`${footer}.heading`}
                                    type="text"
                                    component={textField}
                                    required={true}
                                    onChange={this.changeHeadingHandler}
                                    maxLength="100"
                                />
                            </Col>
                            <Col md={3}>
                                <label class="form-control-placeholder" for="name">Amount  <span>*</span></label>
                                <Field
                                    name={`${footer}.amount`}
                                    type="text"
                                    component={textField}
                                    onChange={this.changeAmountHandler}
                                    required={true}
                                />
                            </Col>
                            <Col md={2}>
                                <label class="form-control-placeholder" for="name">Gst </label>
                                <Field
                                    name="gst"
                                    type="number"
                                    component={textField}
                                    placeholder={this.props.quotationData?.gstPercent ?? "Amount Inclusive of 18% Tax"}
                                    readOnly
                                />
                            </Col>
                            {<Col md={1}>
                                {index !== 0 && <div className="del_trash foo_trash" onClick={() => {
                                    fields.remove(index);
                                }}>
                                    <i className="fas fa-trash"></i>
                                </div>}
                                {index === 0 ?
                                    <button type="submit" className="modal-btn foos" >
                                        <img src={plus} alt="" style={{ width: "15px", padding: "0 0 2px 2px" }} />
                                    </button>
                                    : <></>}
                            </Col>}
                        </Row>
                    </Col>
                </Row>
            ))}
        </>
    )
    componentDidUpdate(prevProps) {
        if (prevProps.quotationData !== this.props.quotationData) {
            const { quotationData } = this.props;
            let footerCharges = quotationData?.footerCharges;
            let gst = quotationData?.gstPercent;
            let footerTotal = footerCharges + (footerCharges * gst / 100);
            let quotTotal = (quotationData?.grandTotal) + (quotationData?.grandTotal * gst / 100);
            let grandTotal = quotTotal + footerTotal;
            this.setState({
                quotationData: this.props.quotationData,
                footerCharges: grandTotal,
                total: grandTotal
            });
        } if (prevProps.updateFooterData !== this.props.updateFooterData) {
            if (this.props.updateFooterData?.status === 200) {
                this.setState({
                    editShow: false
                });
                toast(this.props?.updateFooterData?.data);
                this.props.dispatch(getFooterListOfQuotations(this.props.match.params.quotId));
            } else {
                toast(this.props?.updateFooterData?.data);
            }
        }
    }
    componentDidMount() {
        if (this.props.match.params.quotId !== "null") {
            this.props.dispatch(getFooterListOfQuotations(this.props.match.params.quotId));
            this.props.dispatch(getQutation(this.props.match.params.quotId));
        }
    }

    render() {
        const { handleSubmit, footerLoad } = this.props;
        const { show, total, editShow, footerItem } = this.state;
        const { history } = this.props;

        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <img src={Orange} alt="..." className="head-img" />
                            <h5 className='po_link' onClick={() => history.push("/" + role + "/quotations")}>Quotations</h5>
                            <i className="fas fa-arrow-right" style={{ margin: "10px 0 0 -10px" }}></i>
                            <h6 >{changeTitleStr(this.props.match.params.add)}</h6>
                            <hr className="line-new"></hr>
                            <div className="order-page-title">
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/customerDetails/${this.props.match.params.id}/${this.props.match.params.quotId}`}>
                                    Customer Details
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/contentBody/${this.props.match.params.id}/${this.props.match.params.quotId}`} >
                                    Content of the Letter
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/location/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Location
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/quotationBody/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Quotation Body
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/footer/${this.props.match.params.id}/${this.props.match.params.quotId}`} className="active"  >
                                    Footer
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/termsAndConditions/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Terms & Conditions
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/prepared/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Prepared by
                                </Link>
                            </div>
                            <form className="po-header" onSubmit={handleSubmit(this.addFooterHandler)}>
                                <FieldArray name="miscellaneousCharges" component={this.renderCharges} />
                                <div className="new-oneee new_footer" >
                                    <p>Grand Total &nbsp; &nbsp; :   &nbsp; &nbsp; &nbsp;   {displayCurrency(total)} </p>
                                    <p className="tax">(Total Amt. of all groups incl. of taxes)  </p>
                                </div>
                                <div className="customer-sub" style={{ marginTop: "340px" }}>
                                    <button type="button" className="cancel-btn qutation-btn col-md-2" onClick={this.prevPage}>
                                        <img style={{ marginRight: "10px" }} src={back} />
                                        Back</button>
                                    <button type="submit" onClick={this.nextPage} className="modal-btn save col-md-3" style={{ marginLeft: "-20px" }}>
                                        Save & Proceed
                                    </button>
                                </div>
                            </form >
                            <br></br>
                        </Card>
                    </div>
                </div>
                <EditFooter
                    show={editShow}
                    onHide={this.editFooterPopupClose}
                    initialValues={footerItem}
                    load={footerLoad}
                    onSubmit={this.editHandler}
                />
                <DeletePopUp
                    show={show}
                    onHide={this.deleteItemClose}
                    deleteItem={this.removeFooterHandler}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        initialValues: {
            miscellaneousCharges: [{ heading: '', amount: '' }]
        },
        footerList: state.qutation.footerList,
        quotationData: state.qutation.quotationData,
        settings: state.template.settings,
        footerLoad: state.qutation.footerLoad,
        updateFooterData: state.qutation.updateFooterData
    }
}
const VendorDetailsForm = reduxForm({
    form: 'quotationFooterFormRedux',
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate
})(QuotFooter)

export default connect(mapStateToProps)(VendorDetailsForm);