import { authToken, baseUrl } from "../actions";

export const getGroupApi = (pageNo, searchKey, sort = {}) => {
    var GROUP_LIST;
    if (Object.keys(sort).length === 2) {
        GROUP_LIST = `${baseUrl}public/quotationMaterialGroup?sortField=${sort.sortField}&sortMethod=${sort.sortType}&page=${pageNo}&searchKey=${searchKey}`;
    } else {
        GROUP_LIST = searchKey !== undefined ? `${baseUrl}public/quotationMaterialGroup?searchKey=${searchKey}&page=${pageNo}` : `${baseUrl}public/quotationMaterialGroup?page=${pageNo}`;
    }

    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(GROUP_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}


export const createGroupApi = (request) => {
    const CREATE_GROUP = `${baseUrl}admin/quotationMaterialGroup`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_GROUP, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}


export const getOneGroupApi = (quotationMaterialGroupId) => {
    const GROUP_LIST = `${baseUrl}admin/quotationMaterialGroup/${quotationMaterialGroupId}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(GROUP_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const updateGroupApi = (request) => {
    const UPDATE_GROUP = `${baseUrl}admin/quotationMaterialGroup`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(UPDATE_GROUP, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
