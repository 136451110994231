export const templateConstants = {
    GET_TEMPLATE: "GET_TEMPLATE_LIST",
    GET_TEMPLATE_SUCCESS: "GET_TEMPLATE_LIST_SUCCESS",
    GET_TEMPLATE_FAILURE: "GET_TEMPLATE_LIST_FAILURE",

    GET_TEMPLATE_ONE: "GET_TEMPLATE_ONE",
    GET_TEMPLATE_ONE_SUCCESS: "GET_TEMPLATE_ONE_LIST_SUCCESS",
    GET_TEMPLATE_ONE_FAILURE: "GET_TEMPLATE_ONE_LIST_FAILURE",

    GET_SETTINGS: "GET_SETTINGS",
    GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",
    GET_SETTINGS_FAILURE: "GET_SETTINGS_FAILURE",
}
