import React, { useState } from 'react'
import moment from "moment";
import { Field, reduxForm } from 'redux-form';
import { changeDateFormat, dateFormat } from '../../actions';
import { Row, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
// import profile from "../../assets/images/ava.png";
import profile from "../../assets/images/we.svg";
import { S3_root } from '../../actions';
import validate from './Validate';
import AddNewPrice from "./AddNewPrice";
import ViewHistory from "./ViewHistory";
import plus from "../../assets/images/pl.svg";



const renderField = ({ input, placeholder, maxLength, type, readOnly, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength} readOnly={readOnly} />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

const renderSelectField = ({ input, meta: { touched, error }, children }) => (
    <div>
        <div>
            <select {...input} className='form-control form-select mt-4'>
                {children}
            </select>
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

const file_upload = ({ input, type, imgHandler, label, meta: { touched, error, warning } }) => {
    delete input.value
    return (
        <div className=''>
            <label htmlFor={input.name}>
                <input {...input} type={type} onChange={imgHandler} accept="image/*" />
                {touched && error && <p className="require">{error}</p>}
            </label>
        </div>
    )
}


const ProductForm = props => {
    const { error, handleSubmit, imgHandler, empty, show, onHide, selectedFile, filePick, page, img, units, initialValues, history, getPriceData, productId, product_history, getHistory, reset, handleShow, str } = props;

    const date = changeDateFormat(initialValues?.effectiveOnDate);

    let imgUrl = img && img !== null ? S3_root + img : profile;
    const status = [
        {
            status: "Active",
            value: "ACTIVE",
        },
        {
            status: "Inactive",
            value: "INACTIVE",
        }
    ];


    let date1 = new Date();
    let oldDate = dateFormat(initialValues?.effectiveOnDate)
    let currentDate = dateFormat(date1);
    let checkDate = currentDate < oldDate;


    const showModal = () => {
        if (str === "add") {
            reset();
            handleShow();
        }
    }

    return (
        <>
            <button className="product-add-staffs" onClick={showModal}>
                <img src={plus} alt="" /> Add Product
            </button>
            <Modal
                show={show}
                size="lg"
                onHide={() => {
                    reset();
                    onHide();
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-staff main">
                    <form onSubmit={handleSubmit}>
                        <div className="head">
                            <div>
                                <i class="fas fa-arrow-left" onClick={() => {
                                    reset();
                                    onHide();
                                }}></i>
                                <h4>{empty === "edit" ? "PRODUCT DETAILS" : "Add New Product"}</h4>
                            </div>
                            {empty === "edit" ?
                                <div>
                                    <Field name="status" component="select" className="form-control select-control" >
                                        <option value="" disabled={true}>Select One</option>
                                        {status.map((item, i) =>
                                            <option value={item.status} key={i}>{item.value}</option>)}
                                    </Field>
                                    <div className="triangle-status"></div>
                                </div>
                                : null}
                        </div>
                        <Row className="modal-staff-body">
                            <Col md={6} xs={6}>
                                <Row className="modal-form-row">

                                    {empty === "edit" ? <Col md={12}>
                                        <label class="form-control-placeholder" for="name">Product ID </label>
                                        <Field name="productId" type="text" component={renderField} maxLength="6" readOnly />
                                    </Col> : null}
                                    <Col md={12}>
                                        <label class="form-control-placeholder" for="name">Article ID </label>
                                        <Field name="articleId" type="text" component={renderField} />
                                    </Col>
                                    <Col md={12}>
                                        <label class="form-control-placeholder" for="name">Product Name <span>*</span></label>
                                        <Field name="productName" type="text" component={renderField} />
                                    </Col>
                                    <Col md={12} className="mt-1">
                                        <div className='initial'>
                                            <label class="form-control-placeholder" for="name">Select Unit <span>*</span></label>
                                            <Field name="unitId" component={renderSelectField} className="form-control form-select" defaultValue={initialValues?.unitName}>
                                                <option value="" disabled={true} hidden={true}></option>
                                                {units?.map(unit =>
                                                    <option value={unit.unitId} key={unit.unitId}>{unit.unitName}</option>)}
                                            </Field>
                                            <div className="triangle-bottom"></div>
                                        </div>
                                    </Col>
                                    {page === "create" ? <Col md={12}>
                                        <label class="form-control-placeholder" for="name">Enter Price <span>*</span></label>
                                        <Field name="newPrice" type="text" component={renderField} />
                                    </Col> : null}
                                    <Col md={12}>
                                        <label class="form-control-placeholder" for="name">Threshold Value <span>*</span></label>
                                        <Field name="thresholdValue" type="text" component={renderField} />
                                    </Col>
                                    {empty === "edit" ? <Col md={12}>
                                        <label class="form-control-placeholder" for="name">New Price</label>
                                        <Field name="currentPrice" type="text" component={renderField} readOnly />
                                    </Col> : null}
                                    {empty === "edit" ? <Col md={12} className="mt-4">
                                        <AddNewPrice getData={getPriceData} productId={productId} />
                                    </Col> : null}
                                    {empty === "edit" && checkDate ? <Col md={12} >
                                        <label class="form-control-placeholder" for="name">Current Price</label>
                                        <Field name="effectivePrice" type="text" component={renderField} placeholder="Enter Current Price" readOnly />
                                    </Col> : null}
                                    {empty === "edit" ? <Col md={12} >
                                        <label class="form-control-placeholder" for="name">Price Effective Date/Time</label>
                                        <Field name="date" type="text" component={renderField} placeholder={date} readOnly />
                                    </Col> : null}
                                    {empty === "edit" ? <Col md={12} style={{ marginTop: "20px" }}>
                                        <ViewHistory history={product_history} get={getHistory} />
                                    </Col> : null}
                                    <Col md={12}>
                                        <button type="submit" className="modal-btn sub-modal-btn">
                                            {empty === "edit" ? "Update Product" : "Create Product"}
                                        </button>
                                    </Col>

                                </Row>
                            </Col>
                            <Col md={6} xs={6} className="profile">
                                <h4 >Product Image</h4>
                                <div className='product-svg'>
                                    <img
                                        src={filePick ? URL.createObjectURL(selectedFile) : imgUrl}
                                        alt=".."
                                        className="profile-img"
                                    />
                                </div>
                                <label className="upload">  {page === "edit" ? "Change photo" : "Upload photo"}
                                    <Field name="imageUrl" type="file" label="Upload Photo" component={file_upload} imgHandler={imgHandler} />
                                </label>
                            </Col>
                        </Row>
                    </form>
                </div>
            </Modal>
        </>
    )
}

function mapStateToProps(state) {
    let page = state.product.page;

    let initValues = page === "edit" ? state.product.singleProduct : {};


    return {
        initialValues: initValues,
        singleProduct: state.product.singleProduct,
        page: state.product.page,
        img: state.product.img,
        product_history: state.product.product_history,
    }
}

const Form = reduxForm({
    form: "ProductForm",
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate
})(ProductForm);

export default connect(mapStateToProps)(Form);
