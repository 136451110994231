import { put, call, takeEvery } from "redux-saga/effects";
import { creategstapi, createdelieveryapi, createwarrentyapi, createreferenceapi, createbankapi, createcompanyapi } from "../services/settingServices";
import { settingconstants, delieveryconstants, companyconstants, warrentyconstants, referenceconstants, bankconstants } from "../constants";

export function* settingSaga(payload) {
    try {
        const response = yield call(creategstapi, payload);
        yield put({ type: settingconstants.POST_GST_SUCCESS, response });
    } catch (error) {
        yield put({ type: settingconstants.POST_GST_FAILURE, error });
    }
}
export function* delieverySaga(payload) {
    try {
        const response = yield call(createdelieveryapi, payload);
        yield put({ type: delieveryconstants.POST_DELIEVERY_TIMELINE_SUCCESS, response });
    } catch (error) {
        yield put({ type: delieveryconstants.POST_DELIEVERY_TIMELINE_FAILURE, error });
    }
}
export function* warrentySaga(payload) {
    try {
        const response = yield call(createwarrentyapi, payload);
        yield put({ type: warrentyconstants.POST_WARRENTY_SUCCESS, response });
    } catch (error) {
        yield put({ type: warrentyconstants.POST_WARRENTY_FAILURE, error });
    }
}
export function* referenceSaga(payload) {
    try {
        const response = yield call(createreferenceapi, payload);
        yield put({ type: referenceconstants.POST_REFERENCE_SUCCESS, response });
    } catch (error) {
        yield put({ type: referenceconstants.POST_REFERENCE_FAILURE, error });
    }
}
export function* bankSaga(payload) {
    try {
        const response = yield call(createbankapi, payload);
        yield put({ type: bankconstants.POST_BANK_ACCOUNT_SUCCESS, response });
    } catch (error) {
        yield put({ type: bankconstants.POST_BANK_ACCOUNT_FAILURE, error });
    }
}
export function* companySaga(payload) {
    try {
        const response = yield call(createcompanyapi, payload);
        yield put({ type: companyconstants.POST_COMPANY_DETAILS_SUCCESS, response });
    } catch (error) {
        yield put({ type: companyconstants.POST_COMPANY_DETAILS_FAILURE, error });
    }
}

export default function* setting() {
    yield takeEvery(settingconstants.POST_GST, settingSaga);
    yield takeEvery(delieveryconstants.POST_DELIEVERY_TIMELINE, delieverySaga);
    yield takeEvery(warrentyconstants.POST_WARRENTY, warrentySaga);
    yield takeEvery(referenceconstants.POST_REFERENCE, referenceSaga);
    yield takeEvery(bankconstants.POST_BANK_ACCOUNT, bankSaga);
    yield takeEvery(companyconstants.POST_COMPANY_DETAILS, companySaga);
}