import { baseUrl, authToken } from "../../../../actions";
import axios from "axios";
import { toast } from "react-toastify";


export const editGroupProduct = async (formData) => {
    const config = {
        headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
        const response = await axios.put(`${baseUrl}staff/quotationGroups/products/`, formData, config);
        return response;
    } catch (error) {
        if (error.response) {
            toast(error.response);
            return error.response;
        } else if (error.request) { // no response
            return error.request;
        } else {
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
};

export const editGroupCustomProduct = async (formData) => {
    const config = {
        headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
        const response = await axios.put(`${baseUrl}staff/quotationGroups/editCustomProduct`, formData, config);
        return response;
    } catch (error) {
        if (error.response) {
            toast(error.response);
            return error.response;
        } else if (error.request) { // no response
            return error.request;
        } else {
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
};


export const addFooterCharges = async (formData) => {
    const config = {
        headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
        const response = await axios.post(`${baseUrl}staff/quotations/footer/v2`, formData, config);
        return response;
    } catch (error) {
        if (error.response) {
            toast(error.response);
            return error.response;
        } else if (error.request) { // no response
            return error.request;
        } else {
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
};

export const getUnitItems = async () => {
    try {
        const response = await axios.get(`${baseUrl}public/dropdown/units`);
        return response;
    } catch (error) {
        if (error.response) {
            toast(error.response);
            return error.response;
        } else if (error.request) { // no response
            return error.request;
        } else {
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
};


export const getBoqProjects = async () => {
    const config = {
        headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
        const response = await axios.get(`${baseUrl}staff/boqProjects`, config);
        return response;
    } catch (error) {
        if (error.response) {
            toast(error.response);
            return error.response;
        } else if (error.request) { // no response
            return error.request;
        } else {
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
};

// const UNITS_LIST = `${baseUrl}public/dropdown/units`
export const getLocAmounts = async (id) => {
    const config = {
        headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
        const response = await axios.get(`${baseUrl}staff/quotations/${id}/quotationLocations`, config);
        return response;
    } catch (error) {
        if (error.response) {
            toast(error.response);
            return error.response;
        } else if (error.request) { // no response
            return error.request;
        } else {
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
};

export const deleteFooterCharges = async (id) => {
    const config = {
        headers: { Authorization: `Bearer ${authToken}` }
    };
    try {
        const response = await axios.delete(`${baseUrl}staff/quotations/footer/${id}`, config);
        return response;
    } catch (error) {
        if (error.response) {
            toast(error.response);
            return error.response;
        } else if (error.request) { // no response
            return error.request;
        } else {
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
};
