import { put, call, takeEvery } from "redux-saga/effects";
import {
    getQutationApi,
    getQuotationByIdApi,
    getQuotationTemplatesApi,
    createQuotationApi,
    addLocationToQuotationApi,
    getQutationGroupsApi,
    createQuotationGroupsApi,
    createFooterGroupsApi,
    addTermsGroupsApi,
    getExecutivesApi,
    addSalesExecutiveApi,
    previewQuotationApi,
    generateQuotationApi,
    getLocationsOfQuotationsApi,
    getFooterOfQuotationsApi,
    addIgnoreFieldsApi,
    deleteLocationToQuotationApi,
    deleteQutationGroupsApi,
    getQuotationProductsApi,
    addQuotationProductApi,
    updateQuotationGroupsApi,
    deleteQuotationProductApi,
    getQuotationStatusApi,
    updateQuotationStatusApi,
    cloneQuotationApi,
    updateQuotationApi,
    deleteQuotationApi,
    downloadQuotationApi,
    editLocationToQuotationApi,
    getIgnoreFieldsApi,
    cloneLocationApi,
    cloneGroupApi,
    editFooterToQuotationApi,
    quotationCsvDownloadApi,
    newTempFormQuote,
    newTempUpdateFormQuote,
    newTempDeleteTeable
} from "../services";
import { qutationConstants } from "../constants";


export function* qutationlistSaga(payload) {
    try {
        const response = yield call(getQutationApi, payload);
        yield put({ type: qutationConstants.GET_QUTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.GET_QUTATION_FAILURE, error });
    }
}
export function* qutationByIdSaga(payload) {
    try {
        const response = yield call(getQuotationByIdApi, payload.quotationId);
        yield put({ type: qutationConstants.GET_QUTATION_BY_ID_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.GET_QUTATION_BY_ID_FAILURE, error });
    }
}
export function* getIgnoredFieldsSaga(payload) {
    try {
        const response = yield call(getIgnoreFieldsApi, payload.quotationId);
        yield put({ type: qutationConstants.GET_IGNORE_FILEDS_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.GET_IGNORE_FILEDS_FAILURE, error });
    }
}
export function* cloneQutationSaga(payload) {
    try {
        const response = yield call(cloneQuotationApi, payload.quotationId, payload.status);
        yield put({ type: qutationConstants.CLONE_QUTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.CLONE_QUTATION_FAILURE, error });
    }
}
export function* cloneLocationSaga(payload) {
    try {
        const response = yield call(cloneLocationApi, payload.id);
        yield put({ type: qutationConstants.CLONE_LOCATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.CLONE_LOCATION_FAILURE, error });
    }
}
export function* cloneGroupSaga(payload) {
    try {
        const response = yield call(cloneGroupApi, payload);
        yield put({ type: qutationConstants.CLONE_GROUP_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.CLONE_GROUP_FAILURE, error });
    }
}
export function* deleteQutationSaga(payload) {
    try {
        const response = yield call(deleteQuotationApi, payload.quotationId);
        yield put({ type: qutationConstants.DELETE_QUTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.DELETE_QUTATION_FAILURE, error });
    }
}
export function* qutationTemplates(payload) {
    try {
        const response = yield call(getQuotationTemplatesApi, payload);
        yield put({ type: qutationConstants.GET_QUTATION_TEMPLATES_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.GET_QUTATION_TEMPLATES_FAILURE, error });
    }
}
export function* createQuotationSaga(payload) {
    try {
        const response = yield call(createQuotationApi, payload);
        yield put({ type: qutationConstants.CREATE_QUOTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.CREATE_QUOTATION_FAILURE, error });
    }
}
export function* updateQuotationSaga(payload) {
    try {
        const response = yield call(updateQuotationApi, payload);
        yield put({ type: qutationConstants.UPDATE_QUOTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.UPDATE_QUOTATION_FAILURE, error });
    }
}
export function* getGroupQuotationSaga(payload) {
    try {
        const response = yield call(getQutationGroupsApi, payload.quotationId);
        yield put({ type: qutationConstants.GET_GROUPS_QUOTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.GET_GROUPS_QUOTATION_FAILURE, error });
    }
}
export function* createQuotationGroupsSaga(payload) {
    try {
        const response = yield call(createQuotationGroupsApi, payload);
        yield put({ type: qutationConstants.ADD_GROUP_QUOTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.ADD_GROUP_QUOTATION_FAILURE, error });
    }
}
export function* updateQuotationGroupsSaga(payload) {
    try {
        const response = yield call(updateQuotationGroupsApi, payload);
        yield put({ type: qutationConstants.UPDATE_GROUP_QUOTATION_SUCCESS, response });

        // yield takeEvery(qutationConstants.GET_GROUPS_QUOTATION_INIT, getGroupQuotationSaga);
    } catch (error) {
        yield put({ type: qutationConstants.UPDATE_GROUP_QUOTATION_FAILURE, error });
    }
}
export function* deleteQuotationGroupsSaga(payload) {
    try {
        const response = yield call(deleteQutationGroupsApi, payload.groupId);
        yield put({ type: qutationConstants.DELETE_GROUP_QUOTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.DELETE_GROUP_QUOTATION_FAILURE, error });
    }
}
export function* createQuotationFooterSaga(payload) {
    try {
        const response = yield call(createFooterGroupsApi, payload);
        yield put({ type: qutationConstants.CREATE_FOOTER_QUOTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.CREATE_FOOTER_QUOTATION_FAILURE, error });
    }
}
export function* createQuotationTermsSaga(payload) {
    try {
        const response = yield call(addTermsGroupsApi, payload);
        yield put({ type: qutationConstants.ADD_TERMS_QUOTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.ADD_TERMS_QUOTATION_FAILURE, error });
    }
}
export function* getExecutivesSaga(payload) {
    try {
        const response = yield call(getExecutivesApi, payload);
        yield put({ type: qutationConstants.GET_EXECUTIVES_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.AGET_EXECUTIVES_FAILURE, error });
    }
}
export function* addSalesExecutivesSaga(payload) {
    try {
        const response = yield call(addSalesExecutiveApi, payload);
        yield put({ type: qutationConstants.ADD_SALESEXE_QUOTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.ADD_SALESEXE_QUOTATION_FAILURE, error });
    }
}
export function* previewQuotaionSaga(payload) {
    try {
        const response = yield call(previewQuotationApi, payload);
        yield put({ type: qutationConstants.PREVIEW_QUOTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.PREVIEW_QUOTATION_FAILURE, error });
    }
}
export function* downloadQuotaionSaga(payload) {
    try {
        const response = yield call(downloadQuotationApi, payload);
        yield put({ type: qutationConstants.DOWNLOAD_QUTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.DOWNLOAD_QUTATION_FAILURE, error });
    }
}
export function* generateQuotationSaga(payload) {
    try {
        const response = yield call(generateQuotationApi, payload);
        yield put({ type: qutationConstants.GENERATE_QUOTATION_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.GENERATE_QUOTATION_FAILURE, error });
    }
}
export function* getFooterOfQuotations(payload) {
    try {
        const response = yield call(getFooterOfQuotationsApi, payload.quotationId);
        yield put({ type: qutationConstants.GET_FOOTERLIST_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.GET_FOOTERLIST_FAILURE, error });
    }
}

export function* editFooterToQuotationSaga(payload) {
    try {
        const response = yield call(editFooterToQuotationApi, payload);
        yield put({ type: qutationConstants.UPDATE_FOOTER_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.UPDATE_FOOTER_FAILURE, error });
    }
}

export function* addIgnoreFieldsSaga(payload) {
    try {
        const response = yield call(addIgnoreFieldsApi, payload);
        yield put({ type: qutationConstants.ADD_IGNORE_FIELDS_SUCCESS, response });

    } catch (error) {
        yield put({ type: qutationConstants.ADD_IGNORE_FIELDS_FAILURE, error });
    }
}
export function* getLocationsOfQuotations(payload) {
    try {
        const response = yield call(getLocationsOfQuotationsApi, payload.quotationId);
        yield put({ type: qutationConstants.GET_LOCATIONLIST_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.GET_LOCATIONLIST_FAILURE, error });
    }
}
export function* addLoactionToQuotationSaga(payload) {
    try {
        const response = yield call(addLocationToQuotationApi, payload);
        yield put({ type: qutationConstants.ADD_LOCATION_QUOTATION_SUCCESS, response });

    } catch (error) {
        yield put({ type: qutationConstants.ADD_LOCATION_QUOTATION_FAILURE, error });
    }
}
export function* editLoactionToQuotationSaga(payload) {
    try {
        const response = yield call(editLocationToQuotationApi, payload);
        yield put({ type: qutationConstants.EDIT_LOCATION_QUOTATION_SUCCESS, response });

    } catch (error) {
        yield put({ type: qutationConstants.EDIT_LOCATION_QUOTATION_FAILURE, error });
    }
}


export function* deleteLoactionToQuotationSaga(payload) {
    try {
        const response = yield call(deleteLocationToQuotationApi, payload);
        yield put({ type: qutationConstants.DELETE_LOCATION_QUOTATION_SUCCESS, response });

        yield takeEvery(qutationConstants.GET_LOCATIONLIST_INIT, getLocationsOfQuotations);
    } catch (error) {
        yield put({ type: qutationConstants.DELETE_LOCATION_QUOTATION_FAILURE, error });
    }
}
export function* getGroupProductsSaga(payload) {
    try {
        const response = yield call(getQuotationProductsApi, payload.groupId);
        yield put({ type: qutationConstants.GET_GROUP_PRODUCTS_SUCCESS, response });

    } catch (error) {
        yield put({ type: qutationConstants.GET_GROUP_PRODUCTS_FAILURE, error });
    }
}
export function* addQuotationProductsSaga(payload) {
    try {
        const response = yield call(addQuotationProductApi, payload);
        yield put({ type: qutationConstants.ADD_GROUP_PRODUCTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.ADD_GROUP_PRODUCTS_FAILURE, error });
    }
}
export function* deleteQuotationProductsSaga(payload) {
    try {
        const response = yield call(deleteQuotationProductApi, payload.groupId);
        yield put({ type: qutationConstants.DELETE_GROUP_PRODUCTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.DELETE_GROUP_PRODUCTS_FAILURE, error });
    }
}
export function* getQutationStatusSaga(payload) {
    try {
        const response = yield call(getQuotationStatusApi, payload);
        yield put({ type: qutationConstants.GET_QUOTATION_STATUS_SUCCESS, response });

    } catch (error) {
        yield put({ type: qutationConstants.GET_QUOTATION_STATUS_FAILURE, error });
    }
}
export function* updateQutationStatusSaga(payload) {
    try {
        const response = yield call(updateQuotationStatusApi, payload.quotationId, payload.statusId);
        yield put({ type: qutationConstants.UPDATE_QUOTATION_STATUS_SUCCESS, response });

        yield takeEvery(qutationConstants.GET_QUTATION, qutationlistSaga);
    } catch (error) {
        yield put({ type: qutationConstants.UPDATE_QUOTATION_STATUS_FAILURE, error });
    }
}

// new temp form cretae
export function* newTempFormSaa(payload) {
    try {
        const response = yield call(newTempFormQuote, payload);
        yield put({ type: qutationConstants.NEW_QUTATION_TEMPLATE_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.NEW_QUTATION_TEMPLATE_FAILURE, error });
    }
}

// new temp form update
export function* newUpdateTempFormSaa(payload) {
    try {
        const response = yield call(newTempUpdateFormQuote, payload);
        yield put({ type: qutationConstants.UPDATE_QUTATION_TEMPLATE_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.UPDATE_QUTATION_TEMPLATE_FAILURE, error });
    }
}


//  delete table
export function* newDeleteTempTableSaa(payload) {
    try {
        const response = yield call(newTempDeleteTeable, payload.id1, payload.id2, payload.id3);
        yield put({ type: qutationConstants.DELETE_QUTATION_TEMPLATE_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.DELETE_QUTATION_TEMPLATE_FAILURE, error });
    }
}

export function* quotationCsvDownloadSaga(formData) {
    try {
        const response = yield call(quotationCsvDownloadApi, formData);
        yield put({ type: qutationConstants.QUOTATION_CSV_DOWNLOAD_SUCCESS, response });
    } catch (error) {
        yield put({ type: qutationConstants.QUOTATION_CSV_DOWNLOAD_FAILURE, error });
    }
}

export default function* qutationSaga() {
    yield takeEvery(qutationConstants.GET_QUTATION, qutationlistSaga);
    yield takeEvery(qutationConstants.GET_QUTATION_BY_ID, qutationByIdSaga);
    yield takeEvery(qutationConstants.GET_IGNORE_FILEDS, getIgnoredFieldsSaga);
    yield takeEvery(qutationConstants.GET_QUTATION_TEMPLATES, qutationTemplates);
    yield takeEvery(qutationConstants.CREATE_QUOTATION_INIT, createQuotationSaga);
    yield takeEvery(qutationConstants.UPDATE_QUOTATION_INIT, updateQuotationSaga);
    yield takeEvery(qutationConstants.ADD_LOCATION_QUOTATION_INIT, addLoactionToQuotationSaga);
    yield takeEvery(qutationConstants.EDIT_LOCATION_QUOTATION_INIT, editLoactionToQuotationSaga);
    yield takeEvery(qutationConstants.GET_GROUPS_QUOTATION_INIT, getGroupQuotationSaga);
    yield takeEvery(qutationConstants.ADD_GROUP_QUOTATION_INIT, createQuotationGroupsSaga);
    yield takeEvery(qutationConstants.UPDATE_GROUP_QUOTATION_INIT, updateQuotationGroupsSaga);
    yield takeEvery(qutationConstants.CREATE_FOOTER_QUOTATION_INIT, createQuotationFooterSaga);
    yield takeEvery(qutationConstants.ADD_TERMS_QUOTATION_INIT, createQuotationTermsSaga);
    yield takeEvery(qutationConstants.GET_EXECUTIVES_INIT, getExecutivesSaga);
    yield takeEvery(qutationConstants.ADD_SALESEXE_QUOTATION_INIT, addSalesExecutivesSaga);
    yield takeEvery(qutationConstants.PREVIEW_QUOTATION_INIT, previewQuotaionSaga);
    yield takeEvery(qutationConstants.GENERATE_QUOTATION_INIT, generateQuotationSaga);
    yield takeEvery(qutationConstants.GET_LOCATIONLIST_INIT, getLocationsOfQuotations);
    yield takeEvery(qutationConstants.GET_FOOTERLIST_INIT, getFooterOfQuotations);
    yield takeEvery(qutationConstants.UPDATE_FOOTER_INIT, editFooterToQuotationSaga);
    yield takeEvery(qutationConstants.ADD_IGNORE_FIELDS_INIT, addIgnoreFieldsSaga);
    yield takeEvery(qutationConstants.DELETE_LOCATION_QUOTATION_INIT, deleteLoactionToQuotationSaga);
    yield takeEvery(qutationConstants.DELETE_GROUP_QUOTATION_INIT, deleteQuotationGroupsSaga);
    yield takeEvery(qutationConstants.GET_GROUP_PRODUCTS, getGroupProductsSaga);
    yield takeEvery(qutationConstants.ADD_GROUP_PRODUCTS, addQuotationProductsSaga);
    yield takeEvery(qutationConstants.DELETE_GROUP_PRODUCTS, deleteQuotationProductsSaga);
    yield takeEvery(qutationConstants.GET_QUOTATION_STATUS, getQutationStatusSaga);
    yield takeEvery(qutationConstants.UPDATE_QUOTATION_STATUS, updateQutationStatusSaga);
    yield takeEvery(qutationConstants.CLONE_QUTATION, cloneQutationSaga);
    yield takeEvery(qutationConstants.DELETE_QUTATION, deleteQutationSaga);
    yield takeEvery(qutationConstants.DOWNLOAD_QUTATION, downloadQuotaionSaga);
    yield takeEvery(qutationConstants.CLONE_LOCATION, cloneLocationSaga);
    yield takeEvery(qutationConstants.CLONE_GROUP, cloneGroupSaga);
    yield takeEvery(qutationConstants.NEW_QUTATION_TEMPLATE, newTempFormSaa);
    yield takeEvery(qutationConstants.UPDATE_QUTATION_TEMPLATE, newUpdateTempFormSaa);
    yield takeEvery(qutationConstants.DELETE_QUTATION_TEMPLATE, newDeleteTempTableSaa);
    yield takeEvery(qutationConstants.QUOTATION_CSV_DOWNLOAD, quotationCsvDownloadSaga)
}
