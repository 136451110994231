import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { S3_root, role, camelize } from '../actions';
import SetPassword from "../pages/staff/setPassword";
import validate from '../pages/staff/validate';
import profile from "../assets/images/avathar1.jpg";

const renderField = ({ input, placeholder, maxLength, type, readOnly, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength} readOnly={readOnly} />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

const selectGender = ({ input, label, type, defaultChecked }) => (

    <div className="form-radio" >
        <div>
            <input {...input} placeholder={label} type={type} defaultChecked={defaultChecked} />
        </div>
        <label>{label}</label>
    </div>
)


const file_upload = ({ input, type, imgHandler, meta: { touched, error, warning } }) => {
    delete input.value

    return (
        <div className=''>
            <label htmlFor={input.name}>
                <input {...input} type={type} onChange={imgHandler} />
                {touched && error && <p className="require">{error}</p>}
            </label>
        </div>
    )
}


const ProfileForm = props => {
    const { error, handleSubmit, imgHandler, selectedFile, filePick, show, onHide, initialValues, getPassword, changePass } = props;

    const maleCheck = (initialValues?.gender === "male" || initialValues?.gender === "Male") ? true : false;
    const femaleCheck = initialValues?.gender === "female" ? true : false;

    let img = initialValues?.profileImageUrl;
    let page = "profile";

    let imgUrl = img ?? img !== null ? S3_root + img : profile;


    return (
        <>
            <Modal
                show={show}
                size="lg"
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-staff main">
                    <div className="head">
                        <div>
                            <i class="fas fa-arrow-left" onClick={onHide}></i>
                            <h4>MY PROFILE</h4>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <Row className="modal-staff-body">
                            <Col md={6} xs={6}>
                                <h4 style={{ margin: "0 5px" }}> Personal Details</h4>
                                <Row className="modal-form-row">
                                    <Col md={6}>
                                        <label class="form-control-placeholder" for="name">First Name  <span>*</span></label>
                                        <Field name="firstName" type="text" component={renderField} placeholder="Enter First Name" />
                                    </Col>
                                    <Col md={6}>
                                        <label class="form-control-placeholder" for="name">Last Name <span>*</span></label>
                                        <Field name="lastName" type="text" component={renderField} placeholder="Enter Last Name" />
                                    </Col>
                                    <Col md={12}>
                                        <label class="form-control-placeholder" for="name">Email Address <span>*</span></label>
                                        <Field name="email" type="email" component={renderField} placeholder="Enter Email Address" readOnly />
                                    </Col>
                                    <Col md={12} className="call-field ">
                                        <label class="form-control-placeholder" for="name">Phone Number <span>*</span></label>
                                        <span className="call-now fields" > + 91 </span>
                                        <Field name="phone" type="tel" component={renderField} placeholder="Enter Phone Number" maxLength="10" />
                                    </Col>
                                    <Col md={12} className="mt-3">
                                        <h4 style={{ margin: "0px 0 5px 5px" }}>Gender</h4>
                                        <div className='form-radio'>
                                            <Field name="gender" component={selectGender} type="radio" value="male" label="Male" defaultChecked={maleCheck} />
                                            <Field name="gender" component={selectGender} type="radio" value="female" label="Female" defaultChecked={femaleCheck} />
                                        </div>
                                    </Col>
                                    <Field name="userId" type="hidden" component={renderField} placeholder="Enter Staff Id" />
                                </Row>

                                <SetPassword getPassword={getPassword} page={page} changePassword={changePass} />
                                {error && <strong>{error}</strong>}
                                <div>
                                    <button type="submit" className="modal-btn">
                                        Update
                                    </button>
                                </div>
                            </Col>
                            <Col md={6} xs={6} className="profile">
                                <h4>{page === "profile" ? camelize(initialValues?.role) : "Admin"} Photo </h4>
                                <img
                                    src={filePick ? URL.createObjectURL(selectedFile) : imgUrl}
                                    alt=".."
                                    className="profile-imgs"
                                />
                                <label className="upload">Change Photo
                                    <Field name="profileImageUrl" type="file" label="Upload Photo" className="new-btn" component={file_upload} imgHandler={imgHandler} />
                                </label>
                            </Col>
                        </Row>
                    </form>
                </div>

            </Modal>
        </>

    )
}


function mapStateToProps(state) {
    return {
        initialValues: state.admin.staffProfile,
    }
}

const Form = reduxForm({
    form: "ProfileForm",
    destroyOnUnmount: false,
    enableReinitialize: false,
    validate
})(ProfileForm);

export default connect(mapStateToProps)(Form);


