import { adminConstants } from "../constants";

const initialState = {
    loading: false,
    admin: [],
    error: [],
    img: "",
    data: [],
    passwordData: []
};

export default function adminReducer(state = initialState, { type, response }) {
    switch (type) {
        case adminConstants.UPDATE_PASSWORD_REQUEST:
        case adminConstants.GET_STAFF_PROFILE:
        case adminConstants.UPDATE_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case adminConstants.GET_STAFF_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                staffProfile: response.data,
            };

        case adminConstants.UPDATE_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: response,
            };

        case adminConstants.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                passwordData: response,
            }
       
        case adminConstants.UPDATE_ADMIN_FAILURE:
        case adminConstants.GET_STAFF_PROFILE_FAILURE:
        case adminConstants.UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };



        default:
            return state;
    }
}
