import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../assets/css/privacy.css";

function Privacy() {
  return (
    <Row>
      <div className="c29">
        <p className="c39">
          <Link to="/"> <span className="arg"> <i class="fas fa-arrow-left"></i></span></Link>
          <span className="c38">IDS Group</span
          ><span className="c9 c38 c32 c47">&nbsp;Privacy Policy</span>
        </p>
        <p className="c35">
          <span className="c1">Last Changes to Privacy Policy: March 20, 2022</span>
        </p>
        <p className="c10">
          <span className="c5 c3"
          >This Privacy Policy (&ldquo;Policy&rdquo;) describes how IDS Group
            (&ldquo;we,&rdquo; &ldquo;us&rdquo; or &ldquo;our&rdquo;) collects,
            uses, and discloses personal data, and the choices you have related to
            this.</span
          >
        </p>
        <p className="c10">
          <span className="c5 c3"
          >Please review this policy carefully to understand our policies and
            practices regarding your personal data. If you do not agree with the
            practices described in this policy, please do not access or use our
            Websites or Services (defined below).</span
          >
        </p>
        <p className="c10"><span className="c1">1. Privacy Policy Applicability</span></p>
        <p className="c10">
          <span className="c3 c27"
          >This Policy is applicable to data collected through </span
          ><span className="c40 c3"
          ><a
            className="c2"
            href="https://www.google.com/url?q=http://www.idsgroup.in&amp;sa=D&amp;source=editors&amp;ust=1648288906067004&amp;usg=AOvVaw0rQHYy-QtQele0fjlSbCsH"
          >www.idsgroup.in</a
            ></span
          ><span className="c5 c3"
          >&nbsp;and other IDS Group websites ( collectively, &ldquo;the
            Websites&rdquo;), through our applications (&ldquo;the
            Services&rdquo;)&nbsp; and through any other interactions you might have
            with IDS Group, such as through email newsletters, our Community boards,
            and other interactive features and communications.</span
          >
        </p>
        <p className="c10">
          <span className="c5 c3"
          >This Policy does not apply to any third-party websites, applications or
            businesses to which we link or who may link to us. You should review the
            privacy policies of those third parties to better understand how they
            may collect and use your personal data.</span
          >
        </p>
        <p className="c10">
          <span className="c5 c3"
          >Additionally, IDS Group&rsquo;s Master Subscription Agreement governs
            delivery and use of the Services, including any data submitted through
            customer accounts via the Services.</span
          >
        </p>
        <p className="c10"><span className="c1">2. Privacy Policy Updates</span></p>
        <p className="c10">
          <span className="c5 c3"
          >We may update the terms of this Policy at our discretion. We encourage
            visitors to frequently check this page for any changes.</span
          >
        </p>
        <p className="c10"><span className="c1">3. How We Collect</span></p>
        <p className="c10">
          <span className="c5 c3"
          >We may collect your personal data through various ways, when you:</span
          >
        </p>
        <ul className="c24 lst-kix_list_5-0 start">
          <li className="c18 li-bullet-0">
            <span className="c5 c3">Visit our Websites, through automated cookies</span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c5 c3">Use our Services</span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c5 c3"
            >Interact with us by providing your contact information through the
              Websites, by email, by social media, or by telephone</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c5 c3"
            >Participate in our online communities, blogs or other forums</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c5 c3"
            >Register for or attend an online or in person event sponsored by
              us</span
            >
          </li>
          <li className="c15 li-bullet-0">
            <span className="c5 c3">Submit an application to work for us</span>
          </li>
        </ul>
        <p className="c10"><span className="c1">4. What We Collect</span></p>
        <p className="c10">
          <span className="c5 c3"
          >The types of information we collect from you may differ depending on
            how you interact with us.</span
          >
        </p>
        <p className="c10">
          <span className="c5 c3">Personal data you may provide to us:</span>
        </p>
        <ul className="c24 lst-kix_list_6-0 start">
          <li className="c18 li-bullet-0">
            <span className="c5 c3"
            >Your name, username, email address, postal address, phone number, and
              other contact information</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c5 c3">Your account details and billing information</span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c5 c3"
            >Your employer, job title and other job application information</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c5 c3"
            >Records of your correspondence with us or on our forums</span
            >
          </li>
          <li className="c15 li-bullet-0">
            <span className="c5 c3"
            >Information you provide through other types of interactions, such as
              surveys, events, social media, or other types of communication with
              us</span
            >
          </li>
        </ul>
        <p className="c10">
          <span className="c5 c3"
          >Personal data we may automatically collect from you:</span
          >
        </p>
        <ul className="c24 lst-kix_list_7-0 start">
          <li className="c18 li-bullet-0">
            <span className="c5 c3"
            >Device and connection information, including IP address, unique
              device identifiers, browser type, browser language, and other
              transactional information</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c5 c3"
            >Geolocation information, if enabled on our Careers webpage</span
            >
          </li>
          <li className="c15 li-bullet-0">
            <span className="c5 c3"
            >Cookies and tracking technologies that analyze your use of the
              Websites, show traffic data, and log files such as time of access,
              date of access, software crash reports, session identification number,
              access times, and referring website addresses (see our Cookies Policy
              for more information)</span
            >
          </li>
        </ul>
        <p className="c10">
          <span className="c5 c3"
          >Personal data we may receive from third parties:</span
          >
        </p>
        <ul className="c24 lst-kix_list_1-0 start">
          <li className="c18 li-bullet-0">
            <span className="c5 c3"
            >Business contact information from third party providers or your
              employer, if our Services are provided to you via your employer</span
            >
          </li>
          <li className="c15 li-bullet-0">
            <span className="c3 c5"
            >Publicly available data from third party sources, like social
              media</span
            >
          </li>
        </ul>
        <p className="c10"><span className="c1">5. How We Use Your Data</span></p>
        <p className="c10">
          <span className="c5 c3"
          >How we use your personal data depends in part on how you interact with
            IDS Group. The specific purposes for which we use the data we collect
            about you are listed below. Where required by law, we obtain your
            consent to use and process your personal data for these purposes.
            Otherwise, we rely on either our legitimate interests or contractual
            necessity as our lawful basis for processing your data.</span
          >
        </p>
        <ul className="c24 lst-kix_list_2-0 start">
          <li className="c18 li-bullet-0">
            <span className="c1">To provide the Services.</span
            ><span className="c5 c3"
            >&nbsp;We use your personal data to provide, support, understand and
              improve the Services. As part of providing the Services we may send
              you service announcements, technical notices, updates, security
              alerts, billing and support-related messages related to your account
              or transactions with us, through the Services or by email. You may not
              opt out of these messages, as they are considered part of the
              Services.</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c1"
            >To personalize the Services, Websites and business.</span
            ><span className="c5 c3"
            >&nbsp;We use the information we collect to personalize content and
              experiences on our Websites and Services, to better understand your
              interests, make personalized recommendations based on your interests
              or information and otherwise personalize your experience with IDS
              Group.</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c1">To communicate with you.</span
            ><span className="c5 c3"
            >&nbsp;We will send you emails or otherwise communicate with you in
              response to your questions, feedback or comments. For example, we will
              respond to comments on our Community boards, and answer questions sent
              to us through the Websites. Additionally, we sometimes send emails
              about new product features, promotional communications or other IDS
              Group news. You can always opt-out of these communications by
              following the opt-out instructions in the message or by contacting us
              through the contact information listed below.</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c1">To create anonymous data.</span
            ><span className="c5 c3"
            >&nbsp;We may create aggregated data from our users&rsquo; personal
              data.&nbsp; We make personal data into aggregated data by removing
              information that makes the data personally identifiable to you. We may
              use this aggregated data and share it with third parties to operate,
              understand and improve our Services, to develop new services, and for
              other lawful business purposes.</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c1"
            >For security, compliance, fraud prevention and safety.</span
            ><span className="c5 c3"
            >&nbsp;We may use your personal data as we believe appropriate to
              investigate or prevent violation of the law or our Terms of Service;
              to secure the Services; to protect our, your or others&rsquo; rights,
              privacy, safety or property; and to protect, investigate and deter
              against fraudulent, harmful, unauthorized, unethical or illegal
              activity. For example, we may use tools to scan your content in the
              Services or on our Websites for content that violates our Terms of
              Service or the law. We may also use the information we collect about
              your device to detect users violating our Terms of Service and prevent
              further violations.</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c1"
            >For compliance with law or to investigate legal claims.</span
            ><span className="c5 c3"
            >&nbsp;We may use your personal data to comply with applicable laws,
              lawful requests and legal process, such as to respond to subpoenas or
              requests from government authorities. We may also use your personal
              data where permitted by law in connection with any legal investigation
              and to prosecute or defend legal claims.</span
            >
          </li>
          <li className="c15 li-bullet-0">
            <span className="c1">With your consent.</span
            ><span className="c5 c3"
            >&nbsp;In some cases, we may ask for your consent to collect, use or
              share your personal data. We may use the information gathered from one
              location on the Websites and use it in another location, either on the
              Websites or&nbsp; elsewhere in our network of Services and features,
              or in reports and analysis, all of which are owned and operated by IDS
              Group.&nbsp; We may also combine information gathered from multiple
              locations on the Website into a single customer record or analysis or
              report. We also use and/or combine information that we collect
              off-line or we collect or receive from third party sources to enhance,
              expand, and check the accuracy of your customer records.</span
            >
          </li>
        </ul>
        <p className="c10"><span className="c1">6. How We Share Your Data</span></p>
        <p className="c10">
          <span className="c5 c3"
          >Aside from the instances listed below, we do not disclose information
            about your personal data to any companies not part of IDS Group or its
            parent, subsidiaries or related entities. We will not sell or rent your
            personal data as part of a customer list or similar transaction.</span
          >
        </p>
        <p className="c10">
          <span className="c5 c3">We may share your personal data as follows:</span>
        </p>
        <ul className="c24 lst-kix_list_3-0 start">
          <li className="c18 li-bullet-0">
            <span className="c1">With Affiliates.</span
            ><span className="c5 c3"
            >&nbsp;We may share your personal data with our subsidiaries, joint
              ventures, or other companies under common control, in which case we
              will require those entities to honor this Privacy Policy.</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c1">With Business Partners.</span
            ><span className="c5 c3"
            >&nbsp;We may share your personal data with our business partners from
              time to time, for example, businesses who help us host our
              events.&nbsp; You may opt out of sharing your personal data with
              business partners at the time of collection, or afterwards by
              contacting us as provided in the Contact Us section.</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c1">With Third-Party Agents and Service Providers.</span
            ><span className="c5 c3"
            >&nbsp;We have third party agents, service providers, subsidiaries and
              partners that perform functions on our behalf, such as hosting,
              billing, push notifications, storage, bandwidth, content management
              tools, analytics, customer service, fraud protection, etc.&nbsp; These
              entities have access to the personal data needed to perform their
              functions and are contractually obligated to maintain the
              confidentiality and security of that data. They are restricted from
              using, selling, distributing or altering this data in any way other
              than to provide the requested services to the Services and
              Website.</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c1">With Social Media Widgets.</span
            ><span className="c5 c3"
            >&nbsp;Our Websites may include social media features, such as the
              Facebook, LinkedIn and Twitter widgets. These features may collect
              your personal data and track your use of the Websites. These social
              media features are either hosted by a third party or hosted directly
              in the Service. Your interactions with these features are governed by
              the privacy policy of the company providing such functionality.</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c1">By Linking to Third Party Sites.</span
            ><span className="c5 c3"
            >&nbsp;Our Websites may link to other websites or services operated by
              third parties, whose privacy practices may differ from ours. We do not
              control or endorse any of these third-party websites or services, and
              we encourage you to carefully review the privacy policy of any website
              you visit.</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c1"
            >For Compliance with Law; Legal Claims; Security, Compliance, Fraud
              Prevention and Safety.</span
            ><span className="c5 c3"
            >&nbsp;We may also use or disclose personal data if required to do so
              by law or in the good-faith belief that such action is necessary to
              conform to applicable law or comply with legal process served on us;
              protect and defend our rights or property, the Website and Services or
              our users, and act under emergency circumstances to protect the
              personal safety of us, our affiliates, agents, or the users of the
              Website, Services or the public. This includes exchanging information
              with other companies and organizations for fraud protection.</span
            >
          </li>
          <li className="c15 li-bullet-0">
            <span className="c1">Through Business Transfers.</span
            ><span className="c5 c3"
            >&nbsp;We may sell, transfer or otherwise share some or all of our
              business or assets, including your personal data, in connection with a
              business deal (or potential business deal) such as a merger,
              consolidation, acquisition, reorganization, sale of assets or in the
              event of bankruptcy.</span
            >
          </li>
        </ul>
        <p className="c10"><span className="c1">7. Data Retention</span></p>
        <p className="c10">
          <span className="c5 c3"
          >IDS Group may retain your information for as long as your account is
            active, or as needed to provide you services, comply with our legal
            obligations, resolve disputes and enforce our agreements.</span
          >
        </p>
        <p className="c10">
          <span className="c5 c3"
          >Following termination or deactivation of your account, IDS Group may
            retain information (including your profile information) and user content
            for a commercially reasonable time for backup, archival, and/or audit
            purposes.</span
          >
        </p>
        <p className="c10">
          <span className="c5 c3"
          >After the applicable retention period has expired, IDS Group will
            securely delete or anonymize your personal data.</span
          >
        </p>
        <p className="c10"><span className="c1">8. Data Security</span></p>
        <p className="c10">
          <span className="c5 c3"
          >IDS Group takes security very seriously. We take appropriate measures,
            including organizational, technical, and physical precautions to help
            protect against unauthorized access to, alteration of,&nbsp; or
            destruction of your personal data.</span
          >
        </p>
        <p className="c10">
          <span className="c5 c3"
          >While we follow industry standards and best practices to protect your
            data, no transmission of data over the Internet or any public network
            can be guaranteed to be 100% secure.</span
          >
        </p>
        <p className="c10"><span className="c1">9. Payment Information</span></p>
        <p className="c10">
          <span className="c3 c27"
          >Any payment card information you use to make a purchase with IDS Group
            is collected and processed directly by our </span
          ><span className="c3 c27"
          >payment processor. IDS Group will never receive or store your full
            payment card information. You should review the payment
            processor&rsquo;s privacy policy prior to making a purchase. </span
          ><span className="c5 c3"
          >If you have questions about our current payment processor, please
            contact us.</span
          >
        </p>
        <p className="c10"><span className="c1">10. Children&rsquo;s Data</span></p>
        <p className="c10">
          <span className="c5 c3"
          >The Services and Websites are not directed to anyone under the age of
            16. A parent or guardian who becomes aware that his or her child under
            the age of 16 has provided us with personal data may contact us at
            privacy@idsgroup.in and we will attempt to delete the child&rsquo;s data
            as soon as possible.</span
          >
        </p>
        <p className="c10"><span className="c1">11. Your Data Rights</span></p>
        <p className="c10">
          <span className="c5 c3"
          >IDS Group respects your control over your personal data and, upon
            request, we will confirm whether we hold or are processing data that we
            have collected from you. You also have the right to amend or update
            inaccurate or incomplete personal data, request deletion of your
            personal data, or request that we no longer use it. Under certain
            circumstances we will not be able to fulfill your request, such as if it
            interferes with our regulatory obligations, affects legal matters, we
            cannot verify your identity, or fulfillment involves disproportionate
            cost or effort, but in any event we will respond to your request within
            a reasonable timeframe and provide you an explanation.</span
          >
        </p>
        <p className="c10">
          <span className="c5 c3"
          >You can always contact us as provided in the Contact Us section to
            exercise these rights. You may also be able to take action yourself
            through the methods listed below.</span
          >
        </p>
        <ul className="c24 lst-kix_list_4-0 start">
          <li className="c18 li-bullet-0">
            <span className="c1"
            >View, correct and delete your account information.</span
            ><span className="c5 c3"
            >&nbsp;If you use the Services or have an account through our
              Websites, you can view, update and delete certain information directly
              through your account.</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c1">Opt-out of communications.</span
            ><span className="c5 c3"
            >&nbsp;You can opt out of receiving future promotional communications
              from us by clicking the unsubscribe link within an email, or by
              updating your profile settings. Please note that you generally
              can&rsquo;t opt out of service-related communications.</span
            >
          </li>
          <li className="c11 li-bullet-0">
            <span className="c1">Deactivate your account.</span
            ><span className="c5 c3"
            >&nbsp;If you would like to stop using our Services, you or your
              administrator may be able to deactivate your account. Please be aware
              that depending on how you use our Services and Websites, this may not
              delete all of your information.</span
            >
          </li>
          <li className="c15 li-bullet-0">
            <span className="c1">Turn off cookies.</span
            ><span className="c5 c3"
            >&nbsp;See our Cookie Policy to learn how to control browser-based
              cookie controls.</span
            >
          </li>
        </ul>
        <p className="c10"><span className="c1">12. Contact Us</span></p>
        <p className="c10">
          <span className="c5 c3"
          >If you have any questions about this Policy, or to exercise any of your
            statutory rights, please email us at privacy@idsgroup.in. You can also
            contact us at our mailing address below:</span
          >
        </p>
        <p className="c26"><span className="c1 c9">Innovative Door solution</span></p>
        <p className="c26">
          <span className="c5 c3">282,13th Street, Porur garden phase -1,</span>
        </p>
        <p className="c26">
          <span className="c5 c3">Vanagaram, Chennai - 600095 Tamil Nadu</span>
        </p>
        <p className="c26"><span className="c5 c3">Attention: Privacy Counsel</span></p>
        <p className="c31 c36"><span className="c9 c28 c8"></span></p>
        <p className="c31"><span className="c8 c28">IDS Group Cookie Policy</span></p>
        <p className="c10"><span className="c1">Last Updated: March 20, 2022</span></p>
        <p className="c31">
          <span className="c3 c8">We, as the</span
          ><span className="c6">&nbsp;IDS Group</span
          ><span className="c3 c8"
          >&nbsp;company operating this site, use cookies and other similar
            technologies to collect certain information about visitors. In this
            cookie policy we explain to you how we and third-parties use cookies
            while you browse on our website, mobile app, or similar device,
            including any sub-pages, online shop, or other functionalities
            (generally referred to as &ldquo;</span
          ><span className="c25">site</span
          ><span className="c0">&rdquo; in this policy).</span>
        </p>
        <p className="c31">
          <span className="c0"
          >We also have a presence in the form of branded social media pages on
            social media networks, like Facebook, LinkedIn, and Twitter. Please
            visit their websites to learn their privacy policies and the privacy and
            marketing controls available for their community members since we have
            no control over how these social media platforms operate.</span
          >
        </p>
        <p className="c31">
          <span className="c0"
          >Where we use on our site third party cookies from social media
            platforms or any other third parties for our business purposes, we
            explain such use in this cookie policy.</span
          >
        </p>
        <p className="c31">
          <span className="c3 c8"
          >You can control the cookies used on this site either via our Cookie
            Consent Portal or by adjusting your browser settings. Click the heading
            &ldquo;</span
          ><span className="c6">How can you control cookies?</span
          ><span className="c0">&rdquo; below for more information.</span>
        </p>
        <p className="c31"><span className="c9 c12 c8">Sections</span></p>
        <p className="c4">
          <span className="c12 c27">1.</span><span className="c17 c27">&nbsp;</span
          ><span className="c17 c46">&nbsp; </span
          ><span className="c9 c12 c42">What are cookies? </span>
        </p>
        <p className="c4">
          <span className="c12">2.</span><span className="c17">&nbsp; &nbsp;</span
          ><span className="c9 c12 c42">How do we use cookies?</span>
        </p>
        <p className="c4">
          <span className="c12">3.</span><span className="c17">&nbsp; &nbsp;</span
          ><span className="c9 c12 c42">How can you control cookies?</span>
        </p>
        <p className="c4">
          <span className="c12">4.</span><span className="c17">&nbsp; &nbsp;</span
          ><span className="c9 c12 c42">How long do cookies last? </span>
        </p>
        <p className="c4">
          <span className="c12">5.</span><span className="c17">&nbsp; &nbsp;</span
          ><span className="c9 c12 c42">Changes to our use of cookies</span>
        </p>
        <p className="c4">
          <span className="c12">6.</span><span className="c17">&nbsp; &nbsp;</span
          ><span className="c9 c12 c42">How to contact us?</span>
        </p>
        <p className="c31"><span className="c6 c7">&nbsp;</span></p>
        <p className="c4">
          <span className="c12 c8">1.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c9 c12 c8">What are cookies?</span>
        </p>
        <p className="c13">
          <span className="c3 c8"
          >Like most websites you visit, our site uses cookies and other similar
            technologies described below (generally referred to as &ldquo;</span
          ><span className="c6 c22">cookies</span
          ><span className="c0"
          >&rdquo; in this policy) for the purposes described in the section
            headed &ldquo;How we use cookies&rdquo;.</span
          >
        </p>
        <p className="c14 c19">
          <span className="c6">A.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c6 c9">Cookies</span>
        </p>
        <p className="c13">
          <span className="c0"
          >Cookies are text files embedded in a web page that contain small
            amounts of information which are downloaded to your device when you
            visit a site or embedded in a web page. Cookies may then be sent back to
            the originating site on each subsequent visit, or to another site that
            recognizes that cookie. Cookies are useful because they allow a site to
            recognize a user&rsquo;s device and to remember your actions and
            preferences (such as login details, language, font size, and other
            display preferences) over a while.</span
          >
        </p>
        <p className="c13">
          <span className="c0"
          >Where possible, security measures are put in place to prevent
            unauthorized access to our cookies and similar technologies. A unique
            identifier ensures that only we and/or our authorized service providers
            have access to cookie data.</span
          >
        </p>
        <p className="c14 c19">
          <span className="c6">B.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c6 c9">Web beacons</span>
        </p>
        <p className="c13">
          <span className="c0"
          >Web beacons (also known as internet tags, pixel tags, and clear GIFs)
            are typically transparent graphic images placed on a site or email. Web
            beacons are used in combination with cookies to learn about our
            visitors` interactions, capture visitor&rsquo;s journey on our site, to
            create segment categories and behavior patterns, or to serve targeted
            advertisements. We (or the third parties we use) use beacons to obtain
            information such as the IP address of the computer that downloaded the
            page on which the beacon appears, the URL of the page on which the
            beacon appears, the time the page containing the beacon was viewed, and
            the type of browser used to view the page.</span
          >
        </p>
        <p className="c14 c19">
          <span className="c6">C.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c6 c9">IP addresses and URLs</span>
        </p>
        <p className="c13">
          <span className="c0"
          >An IP address is a unique identifier that certain electronic devices
            used to identify and communicate with each other on the internet. When
            you visit our site, we (or the third parties we use) may view the IP
            address of the device that you use to connect to the internet using a
            web beacon.</span
          >
        </p>
        <p className="c13">
          <span className="c3 c8"
          >We (or the third parties we use) use only the redacted part of the IP
            address to determine the broader physical location of the device so that
            we can`t identify your precise location. This helps us to understand
            from what geographic regions (e.g. city, province, country, like
            Massachusetts, United States) our site visitors come to change the way
            we present our site to you, to enhance your visit, or to customize our
            marketing content. If we </span
          ><span className="c0 c30"
          >want to collect your precise location data for our advertising
            purposes, we obtain your consent to this.</span
          >
        </p>
        <p className="c13">
          <span className="c0"
          >A URL (uniform resource locator) is a unique identifier or address for
            each resource on the internet, in effect it is the address for the web
            page that you are visiting. We use this information to see which sites
            and pages are visited and the way that you navigate through our
            site.</span
          >
        </p>
        <p className="c14 c19">
          <span className="c6">D.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c6 c9">Device IDs</span>
        </p>
        <p className="c13">
          <span className="c0"
          >Device IDs are distinctive numbers associated with a smartphone or
            similar handheld device from which the internet can be accessed. We use
            device IDs for the same purposes for which we use cookies, as described
            in this policy.</span
          >
        </p>
        <p className="c14 c19">
          <span className="c6">E.</span
          ><span className="c17 c8"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
          ><span className="c6 c9">Device fingerprinting</span>
        </p>
        <p className="c13">
          <span className="c0"
          >Device fingerprinting is a way to combine certain attributes of a
            device e.g. its operating system, the type, and version of browser being
            used, language settings and IP addresses, etc., which are used together
            to identify unique devices. To the extent that fingerprinting is used,
            we use it for the same purposes for which we use cookies.</span
          >
        </p>
        <p className="c4">
          <span className="c12 c8">2.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c12">How do we</span
          ><span className="c9 c12 c8">&nbsp;use cookies?</span>
        </p>
        <p className="c31 c34">
          <span className="c0"
          >We use different types of cookies on our site for various purposes set
            out below.</span
          >
        </p>
        <p className="c31 c45">
          <span className="c0"
          >Some of these cookies are placed by us and others by third parties
            (e.g. social media platform, advertising, and analytics providers,
            etc.).</span
          >
        </p>
        <p className="c31 c45">
          <span className="c0"
          >You can find more information about the individual cookies used and the
            third parties which set the cookies on this site in your browser, as
            well as in the Cookie Consent Portal.</span
          >
        </p>
        <p className="c14 c19">
          <span className="c6">A.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c6">Required cookies - </span
          ><span className="c0"
          >These cookies are required for the operation of our site, to enable
            core site functions, to ensure the site is secure and to enable us to
            manage the network. They are sometimes referred to as &ldquo;strictly
            necessary&rdquo; or &ldquo;essential&rdquo; cookies. Without these
            cookies, the site cannot function properly. They include, for example,
            cookies that allow us to remember your cookie consent preferences and
            balance network load, enable you to log into secure areas of our site
            and to access user accounts or online forms, use a shopping cart, make
            use of e-billing services, and download voucher coupons.</span
          >
        </p>
        <p className="c14 c19">
          <span className="c6">B.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c6">Functional and analytical cookies</span
          ><span className="c3 c8"
          >&nbsp;- These cookies are either functional or analytical. They are
            used to </span
          ><span className="c3"
          >improve functionality, improve and personalize browsing experience, and
            to analyze site traffic and usage.</span
          ><span className="c0">&nbsp;</span>
        </p>
        <p className="c16">
          <span className="c0"
          >Analytics cookies allow us to recognize and count the number of
            visitors and to see how visitors move around our site. They help us
            understand and measure how you interact with our site, allow us to
            gather statistics about our site visitors to better understand them, and
            to improve the way our site works, for example, by ensuring that users
            are finding what they are looking for easily. Analytics cookies also
            allow us to test new site content, features, or interactions so we can
            improve the site experience and appearance for all.
          </span>
        </p>
        <p className="c16">
          <span className="c0"
          >Functional cookies are used to enhance what you can do on our site.
            &nbsp;Examples include to help us to give you better and customized user
            experience to test and provide the best site design, layout, and
            content, and to remember choices you have made between sessions This
            enables us to enhance and personalize our content for you, greet you by
            name and remember your preferences (for example, your choice of language
            or region).</span
          >
        </p>
        <p className="c16">
          <span className="c3 c8"
          >Besides, certain third-party cookies are necessary to allow some
            site-embedded functionalities to work. These cookies are dropped by
            third parties only if you interact with certain functionality on the
            site. Provided that consent is required in your country, a</span
          ><span className="c3"
          >t each place on our site where this applies, you will be told that
            cookies will be placed on your device by the relevant third-party and
            that by clicking on the given functionality, you are indicating that you
            consent to the setting of cookies by that third party.</span
          ><span className="c0"
          >&nbsp;If you do not allow these cookies, you may not be able to use the
            functionality. These cookies cannot be managed through our Cookie
            Consent Portal but can be disabled and deleted via your browser
            settings, as explained below.</span
          >
        </p>
        <p className="c16">
          <span className="c3 c8"
          >Examples of how cookies are used in this way include our use of YouTube
            on the site for showing videos, where YouTube sets cookies on your
            device once you click on the YouTube video to play. Please note that in
            terms of playing YouTube videos on our site, we promote using the
            &ldquo;privacy-enhanced mode&rdquo; to prevent the setting of cookies
            that track viewing behavior to the storage of </span
          ><span className="c3"
          >personally-identifiable cookie information by YouTube</span
          ><span className="c0">.</span>
        </p>
        <p className="c16">
          <span className="c0"
          >Similarly, our site may include some social media sharing
            functionalities, such as the Facebook &ldquo;Like&rdquo; button, the
            Twitter &ldquo;Tweet&rdquo; button, or the LinkedIn &ldquo;Share&rdquo;
            button to allow you to share pages with social networks and to allow you
            to post comments and so on. These features may collect your IP address
            and which page you are visiting on our site, and set cookies or employ
            similar technologies to enable the feature to function properly. These
            third parties can place cookies only if you interact with the relevant
            functionality on the site. We have no control over how these social
            networks collect and use your personal information and their use is
            subject to the social network&rsquo;s privacy policy (you can find the
            relevant privacy policy on the social network&rsquo;s website or via the
            links in our Cookie Consent Portal).</span
          >
        </p>
        <p className="c14 c19">
          <span className="c6">C.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c6">Advertising cookies - </span
          ><span className="c3 c8"
          >These cookies are used for advertising our products, brands, or
            services, for example, to show you advertisements that are more relevant
            to your interests based on your online activity (also known as
            personalized or interest-based advertising), or to prevent </span
          ><span className="c9 c3 c43"
          >the same ads from continuously re-appearing.</span
          >
        </p>
        <p className="c16">
          <span className="c3 c8">Cookies in this category enable us to </span
          ><span className="c3 c8 c30"
          >collect information across the sites you visit, </span
          ><span className="c0"
          >to record your visit to our site, the pages you have visited and the
            links you have followed, our brand emails you view and links you click
            in the emails, to track your online activities across your computer,
            mobile phone or other devices you use, including the mobile apps you
            use.</span
          >
        </p>
        <p className="c16">
          <span className="c3 c8">We use this information to </span
          ><span className="c3 c8 c30"
          >try to predict what advertisements for our products or brands might be
            the most interesting to </span
          ><span className="c3 c22 c8">you</span
          ><span className="c3 c8 c30">&nbsp;and to </span
          ><span className="c0"
          >make our site and the advertising displayed on it or other sites more
            relevant to you.</span
          >
        </p>
        <p className="c16">
          <span className="c3 c8"
          >We also use the cookies in this category to place you into a group of
            consumers </span
          ><span className="c3">who show the same interests </span
          ><span className="c3 c8 c30">(e.g. age, gender, interest category)</span
          ><span className="c3 c8"
          >&nbsp;(also known as an &ldquo;audience&rdquo;). </span
          ><span className="c3 c8 c30"
          >Generally, this process does not require us to know you as an
            identifiable individual, only that someone using your browser showed
            interest in a product. We may add </span
          ><span className="c9 c3 c32"
          >other information to this, such as information about the products you
            buy in an online shop or information that you provide directly to us
            when creating an account on our sites, or information about your
            browsing history.</span
          >
        </p>
        <p className="c20">
          <span className="c9 c3 c32">By using advertising cookies, we can:</span>
        </p>
        <p className="c31 c41 c19">
          <span className="c33">&middot;</span
          ><span className="c17"
          >&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
          ><span className="c9 c3 c32"
          >learn about trends, habits, or characteristics from a group of
            consumers who all act similarly;</span
          >
        </p>
        <p className="c31 c41 c19">
          <span className="c33">&middot;</span
          ><span className="c17"
          >&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
          ><span className="c0"
          >show you personalized advertisements on our site or other sites you
            visit;</span
          >
        </p>
        <p className="c31 c41 c19">
          <span className="c33">&middot;</span
          ><span className="c17"
          >&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
          ><span className="c0"
          >find others who appear to be similar in interests, characteristics, or
            behaviors to those already in the interest group, so we can reach more
            potential customers by showing relevant advertising to them.</span
          >
        </p>
        <p className="c16">
          <span className="c0"
          >We partner with third-party service providers (e.g. Amazon, Facebook,
            Google, Microsoft, etc.) which may use cookies, web beacons, and other
            storage technologies to collect or receive information from our site.
            These third parties use this information to help us with the advertising
            described above including to target ads and to provide us with
            measurement services and reports of the success of our advertising
            campaigns that are run through our sites or other sites across the
            internet.</span
          >
        </p>
        <p className="c16">
          <span className="c3 c8"
          >If you turn off these cookies you will see less targeted,
            interest-based advertising; however, it does not mean you will not see
            any advertisements. Even if you opt-out from receiving interest-based
            advertisements, you will still be shown advertisements about our
            products or brands based on the context of the sites you visit (these
            are called &ldquo;</span
          ><span className="c3 c22 c8">contextual ads</span
          ><span className="c0">&rdquo;).</span>
        </p>
        <p className="c16">
          <span className="c3 c8"
          >If you want to learn more about interest-based advertising and
            cross-device linking, you may find it useful to visit </span
          ><span className="c3">the </span
          ><span className="c23 c3"
          ><a
            className="c2"
            href="https://www.google.com/url?q=http://www.networkadvertising.org/understanding-digital-advertising&amp;sa=D&amp;source=editors&amp;ust=1648288906080643&amp;usg=AOvVaw0qMiXKDax7NJyHhPs7YfGx"
          >Network Advertising Initiative website</a
            ></span
          ><span className="c9 c3 c32">.</span>
        </p>
        <p className="c16">
          <span className="c9 c3 c32"
          >In general, when we share information about your use of our site with
            our third parties, we do not pass to them any personally identifiable
            information obtained through cookies used on our site.
          </span>
        </p>
        <p className="c16">
          <span className="c3 c8"
          >You can find information about how we process personal data in
            our</span
          ><span className="c3 c40"
          ><a
            className="c2"
            href="https://www.google.com/url?q=https://www.idsgroup.in/privacy-policy/&amp;sa=D&amp;source=editors&amp;ust=1648288906081177&amp;usg=AOvVaw0_TkK9CabYMY34TJIuUX1Y"
          >&nbsp;Privacy Notice</a
            ></span
          ><span className="c0">.</span>
        </p>
        <p className="c4">
          <span className="c12 c8">3.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c9 c12 c8">How can you control cookies?</span>
        </p>
        <p className="c31 c19 c41">
          <span className="c33 c8">v</span><span className="c17 c8">&nbsp;</span
          ><span className="c6 c22">Opt-in model</span
          ><span className="c3 c22 c8">: In the </span
          ><span className="c3 c22">European Economic Area and the United Kingdom</span
          ><span className="c9 c3 c32"
          >: except where allowed by applicable law, we place cookies after having
            received your consent through the cookie banner which you will see when
            you first use our site, or via our Cookie Consent Portal which is
            continually available on the site, or through your browser settings (see
            below for more details). Please note that certain functionalities
            embedded in the site require the setting of cookies by third parties to
            work. These cookies are set only when you interact with the
            functionality. At each place on our site where this applies, you will be
            told that cookies will be placed on your device by the relevant
            third-party and that by clicking on the given functionality, you are
            indicating that you consent to the setting of cookies by that third
            party.</span
          >
        </p>
        <p className="c31 c41 c19">
          <span className="c33 c8">v</span><span className="c17 c8">&nbsp;</span
          ><span className="c22 c25">Opt-out model:</span
          ><span className="c3 c22"
          >&nbsp;In countries outside of the European Economic Area, or the United
            Kingdom</span
          ><span className="c3"
          >: except where prohibited by applicable law in your country, we place
            cookies when you </span
          ><span className="c0"
          >visit our site, however, you can opt-out from cookies any time via
            either the Cookie Consent Portal or your browser settings (see below for
            more details).</span
          >
        </p>
        <p className="c31 c41 c19">
          <span className="c6">A.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c6">Cookie Consent Portal</span
          ><span className="c3 c8"
          >: We provide a tool on our site that is referred to as the
            &ldquo;</span
          ><span className="c3 c8 c22">Cookie Consent Portal</span
          ><span className="c0"
          >&rdquo;). You can change your cookie preferences (except for strictly
            necessary cookies, including those that are needed for transmission of
            communications and the proper functioning of the site) at any time by
            clicking on the Cookie Consent Portal buttons. You can find the Cookie
            Consent Portal on the site whenever you visit it; however, there may be
            circumstances when it is unavailable (e.g. downtime, error, you
            don&rsquo;t allow JavaScript, etc.). In this case, you can change cookie
            preferences in your browser, as explained below.</span
          >
        </p>
        <p className="c31 c41 c19">
          <span className="c6">B.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c6">Your browser:</span
          ><span className="c3 c8"
          >&nbsp;You can change your cookie preferences by changing your browser
            settings. The majority of web browsers accept cookies, but you can
            usually change the web browser&rsquo;s settings to refuse new cookies,
            disable existing ones or simply let you know when new ones are sent to
            your device. To do this, follow the instructions provided by your
            browser (usually located within the &ldquo;Help&rdquo;,
            &ldquo;Tools&rdquo;, &ldquo;Settings&rdquo; or &ldquo;Edit&rdquo;
            facility). Also, certain third parties have additional opt-out browser
            add-ons that you can enable on your browser, for example, see the
            browser add-on by Google regarding</span
          ><span className="c3 c8"
          ><a
            className="c2"
            href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout/&amp;sa=D&amp;source=editors&amp;ust=1648288906082516&amp;usg=AOvVaw1BR0WJ3R0dNMwoQ1GFoPMl"
          >&nbsp;</a
            ></span
          ><span className="c23 c3"
          ><a
            className="c2"
            href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout/&amp;sa=D&amp;source=editors&amp;ust=1648288906082751&amp;usg=AOvVaw0aD4yo61AXC0Cv4GzKNqlQ"
          >Google Analytics</a
            ></span
          ><span className="c0">.</span>
        </p>
        <p className="c31 c41 c19">
          <span className="c6">C.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c25 c37">Your mobile phone: </span
          ><span className="c3 c8"
          >You can change settings to opt-in or opt-out from receiving
            personalized interest-based advertising within your mobile phone or
            similar device usually in the &ldquo;Setting&rdquo; menu. (For example,
            in iOS: visit Settings - Privacy - Advertising - Limit Ad Tracking.
            Similarly, in Android: visit Settings - Google - Ads - Opt-out of
            interest</span
          ><span className="c9 c3 c37">-based ads.)</span>
        </p>
        <p className="c14">
          <span className="c9 c3 c37"
          >Please note that the opt-outs described above will apply only to the
            specific browser or device from which you opt-out, and therefore you
            will need to opt-out separately on all of your browsers and
            devices.</span
          >
        </p>
        <p className="c14">
          <span className="c3 c8"
          >Also, disabling a cookie or category of cookie does not delete the
            cookie from your device; you will need to do this yourself within your
            browser. Please note if </span
          ><span className="c9 c3 c32"
          >you decide to delete all cookies, your &ldquo;opt-out cookie&rdquo;
            will be also deleted, and you will need to opt-out again if you still do
            not want to receive certain categories of cookies. &nbsp;</span
          >
        </p>
        <p className="c14">
          <span className="c3 c8">You can visit </span
          ><span className="c3 c23"
          ><a
            className="c2"
            href="https://www.google.com/url?q=http://www.attacat.co.uk/resources/cookies/how-to-ban&amp;sa=D&amp;source=editors&amp;ust=1648288906083502&amp;usg=AOvVaw2yEpCaxv9uSbuURZBwSQCG"
          >http://www.attacat.co.uk/resources/cookies/how-to-ban</a
            ></span
          ><span className="c3 c8">&nbsp;or the</span
          ><span className="c3 c8"
          ><a
            className="c2"
            href="https://www.google.com/url?q=https://www.networkadvertising.org/understanding-online-advertising/what-are-my-options&amp;sa=D&amp;source=editors&amp;ust=1648288906083863&amp;usg=AOvVaw064m5gWrHKSdieOy1Ph7xk"
          >&nbsp;</a
            ></span
          ><span className="c23 c25"
          ><a
            className="c2"
            href="https://www.google.com/url?q=https://www.networkadvertising.org/understanding-online-advertising/what-are-my-options&amp;sa=D&amp;source=editors&amp;ust=1648288906084105&amp;usg=AOvVaw29jVvCu-BFOSKiOvqAzXhZ"
          >NAI&rsquo;s</a
            ></span
          ><span className="c23 c3"
          ><a
            className="c2"
            href="https://www.google.com/url?q=https://www.networkadvertising.org/understanding-online-advertising/what-are-my-options&amp;sa=D&amp;source=editors&amp;ust=1648288906084338&amp;usg=AOvVaw2thQuHxpjDqglKsiGRqH9j"
          >&nbsp;website
            </a></span
          ><span className="c0">for more information on how to manage cookies.</span>
        </p>
        <p className="c4">
          <span className="c12 c8">4.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c9 c8 c12">How long do cookies last? </span>
        </p>
        <p className="c13">
          <span className="c0"
          >The cookies used on our site last for a maximum of 13 months. Session
            cookies expire immediately after you finish your browsing session on our
            site.</span
          >
        </p>
        <p className="c4">
          <span className="c12 c8">5.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c9 c12 c8">Changes to our use of cookies</span>
        </p>
        <p className="c13">
          <span className="c0"
          >Any changes to our use of cookies for this site will be posted on the
            Cookie Consent Portal.</span
          >
        </p>
        <p className="c13">
          <span className="c0"
          >If necessary, we may also reflect these changes in our cookie policy
            and update it from time to time. Where those changes to our cookie
            policy are material, we will take steps to let you know. Please always
            verify whether you have consulted the latest version of the cookie
            policy which is available on the site.</span
          >
        </p>
        <p className="c4">
          <span className="c12 c8">6.</span><span className="c17 c8">&nbsp; &nbsp;</span
          ><span className="c9 c12 c8">How to contact us?</span>
        </p>
        <p className="c10 c21">
          <span className="c3 c8"
          >If you have any queries concerning this notice or our use of cookies,
            please contact us at </span
          ><span className="c3 c42">privacy@idsgroup.in</span
          ><span className="c0"
          >. You may also write to us at Innovative Door solution, 282,13th
            Street, Porur garden phase -1, Vanagaram, Chennai - 600095 Tamil
            Nadu.</span
          >
        </p>
        <p className="c10 c21 c36"><span className="c0"></span></p>
        <p className="c36 c39"><span className="c5 c3"></span></p>
      </div>
    </Row>
  )
}

export default Privacy;
