import React from 'react';
import { Field, reduxForm ,SubmissionError} from 'redux-form';
import { Row, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
    updateBoqProject,
} from "../../../../../actions";
import validate from '../../../createpurchaseorder/productdetails/validate';

const renderField = ({ input, placeholder, maxLength, defaultValue, type, readOnly, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input {...input} placeholder={placeholder} type={type} className="form-control" maxLength={maxLength}
                defaultValue={defaultValue}
                readOnly={readOnly} />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);

const renderSelectField = ({ input, label, type, meta: { touched, error }, children }) => (
    <div>
        <div>
            <select {...input} className='form-control form-select  mt-4'>
                {children}
            </select>
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
)

class EditPoProject extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            quantity: 0,
            discount: 0,
            productId: 0,
            items: [],
            total: 0,
            groupId: this.props.groupId,
            singleProduct: this.props.singleProduct,
            disabled: true
        }
        this.myRef = React.createRef();
        this.addGroupProduct = this.addGroupProduct.bind(this);
    }
    addGroupProduct(values) {
        if (!values.projectName) {
            throw new SubmissionError({
                projectName: 'Please fill the field *',
            })
        } else if (values?.projectName?.trim().length === 0) {
            throw new SubmissionError({
                projectName: 'Please fill the field *',
            })
        }
        let data = {
            "projectName": values.projectName,
            "projectId": this.props.projectId,
            "vertexId": values.vertexId,
            "salesExecutiveId": values.salesExecutiveId
        }
        this.props.dispatch(updateBoqProject(data));
    }

    render() {
        const { handleSubmit, show, onHide, reset, load } = this.props;

        return (
            <>
                <Modal
                    contentClassName='po_modal'
                    show={show}
                    onHide={onHide}
                    size="xl"
                >
                    <div className="modal-staff">
                        <form onSubmit={handleSubmit(this.addGroupProduct)}>
                            <div className="head">
                                <div>
                                    <i class="fas fa-arrow-left" onClick={() => {
                                        reset();
                                        onHide();
                                    }}></i>
                                    <h4>EDIT PROJECT</h4>
                                </div>
                            </div>
                            <Row className="modal-row-form">
                                <Col md={6}>
                                    <label class="form-control-placeholder" for="name">Project Name<span>*</span></label>
                                    <Field
                                        name='projectName'
                                        type="text"
                                        component={renderField}
                                    />
                                </Col>
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name">Select one Vertices <span>*</span></label>
                                    <Field name="vertexId" component={renderSelectField} className="form-control form-select">
                                        <option value="" disabled={true} hidden={true} >Select one Vertices *</option>
                                        {this.props.verticesDropdown?.map(unit =>
                                            <option value={unit.vertexId} key={unit.vertexId}>{unit.vertexName}</option>)}
                                    </Field>
                                    <div className="triangle-bottom"></div>
                                </Col>
                                <Col md={3}>
                                    <label class="form-control-placeholder" for="name">Select one Sales Executive <span>*</span></label>
                                    <Field name="salesExecutiveId" component={renderSelectField} className="form-control form-select" >
                                        <option value="" disabled={true} hidden={true} >Select one Sales Executive *</option>
                                        {this.props.salesExecutives?.map(unit =>
                                            <option value={unit.userId} key={unit.userId}>{unit.salesExecutiveName}</option>)}
                                    </Field>
                                    <div className="triangle-bottom"></div>
                                </Col>
                                <Col md={4}></Col>
                                <Col md={4}>
                                    {!load ? <button type="submit" className="modal-btn sub-modal-btn">
                                        Update Project
                                    </button> : <button type="button" className="modal-btn sub-modal-btn">
                                        Loading ....
                                    </button>}
                                </Col>
                                <Col md={4}></Col>
                            </Row>
                        </form>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {

    return {
        load: state.boq.load,
        verticesDropdown: state.po.verticesDropdown,
        salesExecutives: state.qutation.salesExecutives,
        projectSuccess: state.boq.projectSuccess
    }
}

const Form = reduxForm({
    form: "EditPoProject",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate
})(EditPoProject);

export default connect(mapStateToProps)(Form);