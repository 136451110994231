import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/popup.css";
import { changeSetPassword, changeSetPasswordBtn, changeSetPasswordTitle, } from "../../functions/Password";
import Lock from "../../assets/images/lock.svg";
import { baseUrl, authToken } from "../../actions";
import { connect } from "react-redux";


function SetPassword({ onGetPassword, page, changePassword, userId, passwordData }) {
  const [open, setOpen] = useState(false);
  const [showEye, setShowEye] = useState(false);
  const [curEye, setCurEye] = useState(false);
  const [newEye, setNewEye] = useState(false);
  const [dataObj, setDataObj] = useState({});
  const [error, setError] = useState(null);

  console.log(page);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setDataObj({ ...dataObj, [name]: value });
  }
  const showPassword = () => {
    setShowEye(!showEye);
  }
  const showCurPass = () => {
    setCurEye(!curEye);
  }
  const newPass = () => {
    setNewEye(!newEye);
  }
  const handleClose = () => setOpen(false);
  const handleShow = () => {
    setOpen(true);
  };

  const submitPassword = (e) => {
    e.preventDefault();
    let objlength = page === "profile" ? 3 : 2;
    if (Object.values(dataObj).includes("") || (Object.keys(dataObj).length < objlength)) {
      toast("Fill all required fields");
      return false
    }
    if (dataObj.newPassword.includes(""))
      if ((dataObj.newPassword) !== dataObj?.confirmPassword) {
        toast("passwords mismatch");
      } else if ((dataObj.newPassword.length < 8) || (dataObj.confirmPassword.length < 8) || dataObj?.currentPassword?.length < 8) {
        toast("password length should be 8 digits");
      } else if ((dataObj.newPassword) === (dataObj.confirmPassword)) {
        if (page === "edit") {
          const config = {
            headers: { Authorization: `Bearer ${authToken}` }
          };
          var formData = new FormData();
          formData.append('userId', userId);
          formData.append('password', dataObj.newPassword);
          axios.put(`${baseUrl}admin/staff`, formData, config)
            .then(res => {
              if (res.data.status === 200) {
                toast("Password changed Successfully");
                handleClose();
              } else {
                toast(res?.data?.data);
              }
            })
        } else if (page === "profile") {
          let formData = {
            "password": dataObj.confirmPassword,
            "oldPassword": dataObj.currentPassword
          };
          console.log(formData);
          changePassword(formData);
        } else {
          toast("password set successfully");
          onGetPassword(dataObj.newPassword);
          setTimeout(() => handleClose(), 2000);
        }
      } else {
        console.log("click");
      }
  };
  if (passwordData?.status === 200 && passwordData.status !== undefined) {
    handleClose();
    passwordData.status = undefined;
  }
  useEffect(() => {
    passwordData.status = undefined;
  }, []);

  return (
    <>
      <div className="modal-password" onClick={handleShow}>
        <img src={Lock} alt="..." />
        <p>{changeSetPassword(page)}</p>
        <i class="fas fa-arrow-right"></i>
      </div>
      <Modal
        show={open}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-staff main">
          <div className="head">
            <div>
              <i class="fas fa-arrow-left" onClick={handleClose}></i>
              <h4>{changeSetPasswordTitle(page)}</h4>
            </div>
          </div>
          <Row className="modal-unit">
            <Col md={12} className="edit-product">
              <Form className="modal-form">
                {page === "profile" ? (
                  <div>
                    <label class="form-control-placeholder  type-form" for="name" style={{ marginTop: page === "profile" && "2px" }}>Current Password  <span>*</span></label>
                    <Form.Group controlId="formBasicCurPw">
                      <Form.Control
                        type={!newEye ? "password" : "text"}
                        onChange={handleInputChange}
                        maxLength={40}
                        minLength={8}
                        name="currentPassword"
                      />
                      <span onClick={newPass} className="cur_password">
                        {newEye ? <i className="fas fa-eye-slash log-eyes" /> : <i className="fas fa-eye log-eye" />}
                      </span>
                    </Form.Group>
                  </div>
                ) : (
                  <div></div>
                )}
                <label class="form-control-placeholder" for="name" style={{ marginTop: page === "profile" && "65px" }}>New Password  <span>*</span></label>
                <Form.Group controlId="formBasicNewPw">
                  <Form.Control
                    type={!curEye ? "password" : "text"}
                    name="newPassword"
                    onChange={handleInputChange}
                    maxLength={40}
                    minLength={8}
                  />
                  <span onClick={showCurPass}
                    className={page === "profile" ? "new_password" : "new_pass"}>
                    {curEye ? <i className="fas fa-eye-slash log-eyes" /> : <i className="fas fa-eye log-eye" />}
                  </span>
                </Form.Group>
                <label class="form-control-placeholder" style={{ marginTop: page === "profile" ? "130px" : "65px" }} for="name">Confirm New Password  <span>*</span></label>
                <Form.Group controlId="formBasicConPw">
                  <Form.Control
                    type={!showEye ? "password" : "text"}
                    name="confirmPassword"
                    onChange={handleInputChange}
                    maxLength={40}
                    minLength={8}
                  />
                  <span onClick={showPassword}
                    className={page === "profile" ? "confirm_password" : "confirm_pass"}
                  >
                    {showEye ? <i className="fas fa-eye-slash log-eyes" /> : <i className="fas fa-eye log-eye" />}
                  </span>
                </Form.Group>
                <button onClick={submitPassword} className="modal-btn">
                  {changeSetPasswordBtn(page)}
                </button>
              </Form>
            </Col >
          </Row >
        </div >
      </Modal >
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    passwordData: state.admin.passwordData
  };
};

export default connect(mapStateToProps)(SetPassword);