import { authToken, baseUrl } from "../actions";


export const getReportOaApi = (request) => {
    const ADD_STOCK_API = `${baseUrl}staff/reports/orderAcceptance`;
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.data)
    };

    return fetch(ADD_STOCK_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    })
};

export const getOaConfirmApi = (product) => {
    const OA_CONFIRM = `${baseUrl}staff/reports/orderAcceptance/confirmedList?oaNumber=${product.payload.oaNo}&productId=${product.payload.productId}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(OA_CONFIRM, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}

export const getReportOaListApi = (request) => {
    const OA_LIST = `${baseUrl}staff/reports/orderAcceptance/v2`;
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.data)
    };

    return fetch(OA_LIST, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    })
};

export const addMsPoInwardProductsApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/products/inward`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };
    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}

export const addReportOaFormListApi = (request) => {
    const OA_LIST_FORM = `${baseUrl}staff/reports/orderAcceptance/updateOaConfirmation`;
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(OA_LIST_FORM, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    })
};

export const lisReportSubMenuApi = (request) => {
    const OA_LIST_FORM = `${baseUrl}staff/reports/orderAcceptanceConfirmation`;
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(OA_LIST_FORM, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    })
};

export const getOaReportCsvDownload = (request) => {
    const CSV_DOWNLOAD = `${baseUrl}staff/reports/orderAcceptance/v2/csv`;
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CSV_DOWNLOAD, parameters).then(response => {
        return response.text();
    }).then(json => {
        return json;
    })
};


export const getOaCsvDownload = (request) => {
    const CSV_DOWNLOAD = `${baseUrl}staff/reports/orderAcceptanceConfirmation/csv`;
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CSV_DOWNLOAD, parameters).then(response => {
        return response.text();
    }).then(json => {
        return json;
    })
};


export const getOaConfirmPdfView = (request) => {

    const PDF_VIEW = `${baseUrl}public/oAConfirmation/preview`;
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };
    return fetch(PDF_VIEW, parameters).then(response => {
        return response.text() // response.json()
    }).then((page) => {
        return page;
    }).catch((error) => {
        console.error(error)
    })
};


export const oaDownloadApi = (request) => {
    const OA_DOWNLOAD = `${baseUrl}staff/oAConfirmation/htmls`;
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request)
    };

    return fetch(OA_DOWNLOAD, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    })
}

export const oaMsApi = (request) => {
    const MS_DATA = `${baseUrl}staff/reports/orderAcceptanceConfirmation/inward`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    }
    return fetch(MS_DATA, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    })
}

export const onOaMsInwardApi = (request) => {
    const MS_DATA = `${baseUrl}staff/purchaseOrder/${request.formData.id}/verifiedProductListForInward`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
    }
    return fetch(MS_DATA, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    })
}


export const oaCsvDownloadApi = (request) => {
    const CSV_DOWNLOAD_POP = `${baseUrl}staff/reports/orderAcceptance/confirmedList`;
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CSV_DOWNLOAD_POP, parameters).then(response => {
        return response.text();
    }).then(json => {
        return json;
    })
};

export const oaMsCsvDownloadApi = (request) => {
    const CSV_DOWNLOAD_POP = `${baseUrl}staff/reports/orderAcceptanceConfirmation/inward/csv`;
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CSV_DOWNLOAD_POP, parameters).then(response => {
        return response.text();
    }).then(json => {
        return json;
    })
};