import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';
import authReducer from "./authReducer";
import customerReducer from "./customerReducer";
import productReducer from "./productReducer";
import qutationReducer from "./qutationReducer";
import staffReducer from "./staffReducer";
import templateReducer from "./templateReducer";
import dashboardReducer from "./dashboardReducer";
import staffexecutiveReducer from "./staffexecutiveReducer";
import reportcustomerReducer from "./reportcustomerReducer";
import unitReducer from "./unitReducer";
import settingReducer from "./settingReducer";
import adminReducer from "./adminReducer";
import vendorReducer from "./vendorReducer";
import projectsReducer from "./projectsReducer";
import vendorpoReducer from "./vendorpoReducer";
import reportPurchaseReducer from "./reportPurchaseReducer";
import groupReducer from "./groupReducer";
import inwardReducer from "./inwardReducer";
import boqReducer from "./boqReducer";
import poReducer from "./poReducer";
import outwardReducer from "./outwardReducer";
import deliverReducer from "./deliverReducer";
import reportOaReducer from "./reportOaReducer";
import packageReducer from "./packageReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  staff: staffReducer,
  product: productReducer,
  qutation: qutationReducer,
  template: templateReducer,
  customer: customerReducer,
  dashboard: dashboardReducer,
  staffexecutive: staffexecutiveReducer,
  reportcustomer: reportcustomerReducer,
  unit: unitReducer,
  setting: settingReducer,
  admin: adminReducer,
  vendor: vendorReducer,
  projects: projectsReducer,
  vendorpo: vendorpoReducer,
  purchase: reportPurchaseReducer,
  group: groupReducer,
  inward: inwardReducer,
  boq: boqReducer,
  po: poReducer,
  outward: outwardReducer,
  deliver: deliverReducer,
  oa: reportOaReducer,
  form: formReducer,
  packages: packageReducer
});

export default rootReducer;
