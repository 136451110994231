import React from "react";
import { Modal } from "react-bootstrap";
import Delete from "../assets/images/del_icon.svg";


function DeletePopUp(props) {
    const { show, onHide } = props;
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div className="modal-success">
                    <img src={Delete} alt=".." />
                    <h5>Do you want to delete the selected item?</h5>
                </div>
                <div className="txt-center">
                    <button className="okay" onClick={() => props.deleteItem()}>Yes
                    </button>
                    <button onClick={onHide} className="no">No </button>
                </div>
            </Modal>
        </>
    );
}

export default DeletePopUp;

