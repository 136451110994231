import { unitConstants } from "../constants";

export const getAllUnits = () => {
    return {
        type: unitConstants.GET_UNITS
    };
};

export const addUnit = (formData) => {
    return {
        type: unitConstants.UNIT_REQUEST,
        formData
    };
};





export const updateUnit = (formData) => {
    return {
        type: unitConstants.UPDATE_UNIT_REQUEST,
        formData
    };
};

export const changePageAdd = () => {
    return {
        type: unitConstants.CHANGE_PAGE_ADD,
    }
}

export const changePageEdit = () => {
    return {
        type: unitConstants.CHANGE_PAGE_EDIT,
    }
}