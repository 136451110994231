import { vendorConstants } from "../constants";

const initialState = {
    loading: false,
    success_msg: "",
    data: [],
    error: [],
    status: 0,
    token: "",
    vendor: [],
    vendor_emails: [],
    load: false,
    page: "create",
    sortType: "",
    mail_success_msg: ""
};

export default function vendorReducer(state = initialState, { type, response }) {
    switch (type) {
        case vendorConstants.GET_VENDOR:
        case vendorConstants.GET_VENDOR_SECONDARY_MAIL:
            return {
                ...state,
                loading: true,
            };
        case vendorConstants.GET_VENDOR_SUCCESS:
            return {
                ...state,
                loading: false,
                vendor_list: response.data.content,
                allData: response.data,
            };
        case vendorConstants.GET_VENDOR_SECONDARY_MAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                vendor_emails: response.data,
            };
        case vendorConstants.GET_VENDOR_FAILURE:
        case vendorConstants.GET_VENDOR_SECONDARY_MAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
                success_msg: "",
            };
        case vendorConstants.CREATE_VENDOR_INIT:
        case vendorConstants.UPDATE_VENDOR_INIT:
            return {
                ...state,
                loading: true,
                success_msg: "",
            }
        case vendorConstants.CREATE_VENDOR_SUCCESS:
        case vendorConstants.UPDATE_VENDOR_SUCCESS:
            return {
                ...state,
                loading: false,
                success_msg: response,
                status: response.status
            }
        case vendorConstants.CREATE_VENDOR_FAILURE:
        case vendorConstants.UPDATE_VENDOR_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            }
        case vendorConstants.CREATE_VENDOR_SECONDARY_MAIL_INIT:
        case vendorConstants.UPDATE_VENDOR_SECONDARY_MAIL_INIT:
            return {
                ...state,
                load: true,
                mail_success_msg: "",
            }
        case vendorConstants.CREATE_VENDOR_SECONDARY_MAIL_SUCCESS:
        case vendorConstants.UPDATE_VENDOR_SECONDARY_MAIL_SUCCESS:
            return {
                ...state,
                load: false,
                mail_success_msg: response,
            }
        case vendorConstants.CREATE_VENDOR_SECONDARY_MAIL_FAILURE:
        case vendorConstants.UPDATE_VENDOR_SECONDARY_MAIL_FAILURE:
            return {
                ...state,
                load: false,
            }
        case vendorConstants.GET_VENDOR_STAFF:
            return {
                ...state,
                loading: true,
                page: ""
            }
        case vendorConstants.GET_VENDOR_STAFF_SUCCESS:
            return {
                ...state,
                loading: false,
                vendor: response.data,
                status: response.status,
                page: "edit"
            }
        case vendorConstants.GET_VENDOR_STAFF_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            }

        case vendorConstants.CHANGE_PAGE:
            return {
                ...state,
                loading: false,
                page: "create",
                vendor: [],
            }

        default:
            return state;
    }
}
