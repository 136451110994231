import React, { useState } from "react";
import { Modal, Row } from "react-bootstrap";
import "../../assets/css/view.css";
import pdf from "../../assets/pdf/estimation.pdf";

function View(props) {
    const { templates, temp, show, onHide, handleShow } = props;
    return (
        <>
            <Modal size="xl" show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="modal-staff main">
                    <div className="head">
                        <div>
                            <i class="fas fa-arrow-left" onClick={onHide}></i>
                            <h4>{temp.templateName}</h4>
                        </div>
                    </div>
                </div>

                <Row className="preview-img" style={{ minHeight: "500px" }}>
                    <img src={temp.templateSampleUrl} style={{ width: "100%", marginRight: "40px" }} />
                    {/* <object data={pdf} width="100%" style={{ paddingRight: "40px", paddingBottom: "20px" }} ></object> */}
                </Row>
            </Modal>
        </>
    )
}


export default View;