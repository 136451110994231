import React from "react";
import { Card, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navbar from "../../layouts/Navbar";
import Sidebar from "../../layouts/Sidebar";
import Filter from "../../assets/images/filter.svg";
import Edit from "../../assets/images/ui.svg";
import Delete from "../../assets/images/ux.svg";
import del from "../../assets/images/trash_del.svg";
import plus from "../../assets/images/pl.svg";
import Search from "../../assets/images/search.svg";
import Orange from "../../assets/images/qu.svg";
import clone from "../../assets/images/clone1.svg";
import download from "../../assets/images/download1.svg";
import ConvertBoq from "../purchaseOrder/billofquantity/ConvertBoq";
import Logo from "../../assets/images/main.svg";
import View from "./View";
import {
  changeDateFormat,
  changeClassName,
  getQutationList,
  getConfirmQuotDropdown,
  clearConfirmDropdownList,
  previewQuotation,
  updateQutationStatus,
  cloneQuotation,
  generateQuotation,
  deleteQuotation,
  getTotal,
  downloadQutation,
  clearSingleCustomer,
  clearValuesOfCustomer,
  clearValues,
  clearQuotationBody
} from "../../actions";
import { connect } from "react-redux";
import Spinner from "../../Components/loader";
import Pagination from "../../Components/Pagination";
import { toast } from "react-toastify";
import QutationFilter from "./QutationFilter";
import ClonePopUp from "./clonePopup";

const authRole = localStorage.getItem('userRole');

class Quotations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      all_quotations: [],
      changeName: false,
      show: false,
      view: false,
      cloneEdit: false,
      reviseEdit: false,
      cancelEdit: false,
      pageable: [],
      allData: [],
      quotData: [],
      quotationData: [],
      previewQutationTxt: undefined,
      downloadQutation: "",
      qutationStatus: [],
      quotationId: "",
      updateQutationStatus: [],
      revised: false,
      cloneQutation: [],
      filterData: {
        "sortField": "",
        "sortMethod": "desc",
        "status": "",
        "fromDate": "",
        "toDate": "",
        "searchKey1": "",
        "pageNumber": 0,
        "pageSize": 10
      },
      generateQuotationSuccess: "",
      cloneShow: false,
      status: "DRAFT",
      cloned: null,
      pdf: "",
      key: "",
      errorPopup: false,
      clonePopup: false,
      deletePopup: false,
      boqPopup: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.statusHandler = this.statusHandler.bind(this);
    this.cloneQutationHandler = this.cloneQutationHandler.bind(this);
    this.editQutationHandler = this.editQutationHandler.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
    this.generateQuotationHandler = this.generateQuotationHandler.bind(this);
    this.deleteQutationHandler = this.deleteQutationHandler.bind(this);
    this.showClonePopup = this.showClonePopup.bind(this);
    this.closeClonePopup = this.closeClonePopup.bind(this);
    this.getAllQuotation = this.getAllQuotation.bind(this);
    this.confrimHandler = this.confrimHandler.bind(this);
    this.boqShow = this.boqShow.bind(this);
    this.boqClose = this.boqClose.bind(this);
    this.quotPage = this.quotPage.bind(this);
  }
  componentDidMount() {
    const { filterData } = this.state;
    this.props.dispatch(getQutationList(filterData));
    this.props.dispatch(clearSingleCustomer());
    this.props.dispatch(clearValuesOfCustomer());
    localStorage.removeItem("newQuotation");
    localStorage.removeItem("groupId");
  }
  quotPage() {
    const { quotData } = this.state;
    const { history } = this.props;
    history.push(`/${authRole}/quotations/edit-quotation/quotationBody/${quotData?.template_id}/${quotData.quotationId}`)
  }
  boqShow() {
    this.props.dispatch(clearConfirmDropdownList());
    this.props.dispatch(getConfirmQuotDropdown());
    this.setState({ boqPopup: true, show: false });
  }
  boqClose() {
    this.props.dispatch(clearConfirmDropdownList());
    this.setState({ boqPopup: false });
  }
  getAllQuotation() {
    const { filterData } = this.state;
    this.props.dispatch(getQutationList(filterData));
  }
  generateQuotationHandler() {
    this.props.dispatch(generateQuotation(this.state.quotationId));
  }
  handleClose() {
    this.setState({
      show: false,
      previewQutationTxt: undefined,
      confirmed: false
    })
  }
  handleShow(row, key) {
    let status;
    localStorage.setItem("templateId", row.template_id);
    localStorage.setItem("tab", "two");
    const { history } = this.props;
    // this.props.dispatch(clearQuotationBody());
    if (key === "edit" && row.status === "DRAFT") {
      status = false;
      this.props.dispatch(clearSingleCustomer());
      this.props.dispatch(clearValues());
      this.setState({
        show: false
      })
      history.push(`/${authRole}/quotations/edit-quotation/customerDetails/${row?.template_id}/${row.quotationId}`)
      // window.location.hash = `/${authRole}/quotations/edit-quotation/customerDetails/${row?.template_id}/${row.quotationId}`;
    } else {
      status = true;
    }
    this.setState({
      show: status,
      quotationId: row.quotationId,
      revised: status,
      quotData: row,
      key: key,
      changeName: false
    });

    if (row.status === "GENERATED" || row.status === "CANCELLED") {
      this.props.dispatch(downloadQutation(row.quotationId));
    }
    localStorage.setItem("newQuotation", row.quotationId);
    this.props.dispatch(previewQuotation(row.quotationId));
  }
  onChange(pageNo) {
    let data = { ...this.state.filterData };
    data.pageNumber = pageNo;
    this.setState({
      filterData: data
    })
    this.props.dispatch(getQutationList(data));
  }
  filterSubmit(filterData) {
    let data = { ...this.state.filterData };
    data.fromDate = filterData.fromDate;
    data.toDate = filterData.toDate;
    data.status = filterData?.status ? filterData?.status : ""
    this.setState({
      filterData: data
    })
    this.props.dispatch(getQutationList(data));
  }
  resetHandler() {
    let data = { ...this.state.filterData };
    data.fromDate = "";
    data.toDate = "";
    data.status = "";
    this.setState({
      filterData: data
    })
    this.props.dispatch(getQutationList(data));
  }
  searchHandler(e) {
    let data = { ...this.state.filterData };
    data.searchKey1 = e.target.value;
    data.pageNumber = 0;
    this.setState({
      filterData: data
    })
    this.props.dispatch(getQutationList(data));
  }
  sortHandler(sortField) {
    const { filterData } = this.state;
    let data = { ...filterData };
    data.sortField = sortField;
    data.sortMethod = filterData.sortMethod === "desc" ? "asc" : "desc";
    this.setState({
      filterData: data
    })
    this.props.dispatch(getQutationList(data));
  }
  cloneQutationHandler(id) {
    let quotationId = id ?? id !== null ? id.quotationId : this.state.quotationId;
    this.props.dispatch(cloneQuotation(quotationId, false));
    this.setState({
      cloned: false,
      cloneEdit: true,
      clonePopup: true,
      status: "CLONED"
    })
  }
  deleteQutationHandler(row) {
    this.setState({
      deletePopup: true,
      quotationId: row.quotationId,
    });
  }
  confrimHandler() {
    this.setState({
      confirmed: true,
      status: "CONFIRMED"

    })
    let quotationId = this.state.quotationId;
    this.props.dispatch(updateQutationStatus(quotationId, 7));
  }
  editQutationHandler() {
    let quotationId = this.state.quotationId;
    this.props.dispatch(cloneQuotation(quotationId, true));
    this.setState({
      cloned: true,
      reviseEdit: true
    })
  }
  statusHandler() {
    this.setState({
      cancelEdit: false,
      status: "CANCELLED"
    })
    let quotationId = this.state.quotationId;
    this.props.dispatch(updateQutationStatus(quotationId, 6));
  }
  showClonePopup() {
    this.setState({
      cloneShow: true
    })
  }
  closeClonePopup() {
    this.setState({
      cloneShow: false
    })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.qutation_list !== this.props.qutation_list) {
      this.setState({
        all_quotations: this.props.qutation_list,
      });
    } if (prevProps.cloneQutation !== this.props.cloneQutation) {
      this.setState({
        cloneQutation: this.props.cloneQutation,
        show: false,
        cloneEdit: false,
        reviseEdit: false
      });
      if (this.props.cloneQutation.status === 200) {
        this.setState({
          cloneShow: true,
          clonePopup: false
        })
        setTimeout(() => this.setState({ cloneShow: false }), 3500);
        setTimeout(() => this.setState({ cloned: null }), 3800);

      } else {
        toast(this.props.cloneQutation.data);
      }
      this.getAllQuotation();
    } if (prevProps.deleteQuotation !== this.props.deleteQuotation) {
      this.setState({
        deleteQuotation: this.props.deleteQuotation,
        show: false,
        deletePopup: false
      });
      toast(this.props.deleteQuotation.data);
      this.getAllQuotation();
    } if (prevProps.downloadQutation !== this.props.downloadQutation) {
      this.setState({
        pdf: this.props.downloadQutation,
      });
    } if (prevProps.quotationData !== this.props.quotationData) {
      this.setState({
        quotationData: this.props.quotationData,
      });
      const { quotationData } = this.props;
      const { key } = this.state;
    } if (prevProps.allData !== this.props.allData) {
      this.setState({ allData: this.props.allData });
    } if (prevProps.generateQuotationSuccess !== this.props.generateQuotationSuccess) {
      this.setState({
        generateQuotationSuccess: this.props.generateQuotationSuccess,
      });
      if (this.props.generateQuotationSuccess.status === 200) {
        this.setState({
          show: false,
          status: "GENERATED"
        })
        toast(this.props.generateQuotationSuccess.data);
      } else {
        this.setState({
          errorPopup: true,
        })
      }
      this.props.dispatch(previewQuotation(this.state.quotationId));
      this.getAllQuotation();
    } if (prevProps.qutationStatus !== this.props.qutationStatus) {
      this.setState({
        qutationStatus: this.props.qutationStatus
      });
    } if (prevProps.updateQutationStatus !== this.props.updateQutationStatus) {
      this.setState({ updateQutationStatus: this.props.updateQutationStatus });
      if (this.props.updateQutationStatus !== "") {
        if (this.state.status === "CANCELLED") {
          this.setState({
            show: false
          })
        }
        this.setState({
          changeName: true
        })
        toast(this.props.updateQutationStatus);
        this.props.dispatch(previewQuotation(this.state.quotationId));
        this.getAllQuotation();
      }
    } if (prevProps.previewQutationTxt !== this.props.previewQutationTxt) {
      this.setState({
        previewQutationTxt: this.props.previewQutationTxt,
      });
    }
  }

  render() {
    const {
      all_quotations,
      allData, quotData,
      cloneEdit,
      cloneShow,
      quotationId,
      previewQutationTxt,
      qutationStatus,
      reviseEdit,
      cancelEdit,
      changeName,
      generateQuotationSuccess,
      cloned, pdf, confirmed, clonePopup, deletePopup, boqPopup
    } = this.state;
    getTotal(0);

    let data = {
      totalElements: allData.totalElements,
      totalPages: allData.totalPages,
      offset: allData?.pageable?.offset,
      numberOfElements: allData.numberOfElements,
      num: (allData?.number) + 1
    }

    return (
      <div>
        <Navbar />
        <div className="dashboard-page">
          <div className="dashboard-sidebar">
            <Sidebar />
          </div>
          <div className="dashboard-main">
            <Card className="staff-page">
              <div className="page-head-split">
                <div className="head-colums">
                  <div className="head-part-tab">
                    <img src={Orange} alt="..." className="head-imgs" />
                    <h5>Quotations</h5>
                  </div>
                  <div className="tab-links-staff">
                  </div>
                  <div className="customer-search">
                    <span><img src={Search} alt=".." className="search-img" /></span>
                    <Form.Control type="search" placeholder="Search" onChange={this.searchHandler} className="main-search" />
                  </div>
                  <QutationFilter onSubmit={this.filterSubmit} reset={this.resetHandler} />
                  <Link to={"/" + authRole + "/quotations/select-template"} >
                    {/* onClick={() => this.props.dispatch(clearQuotationBody())} */}
                    <button className="product-add-staffs" >
                      <img src={plus} alt="" />
                      Create Quotation
                    </button>
                  </Link></div>
              </div>
              <div className="table-class">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      {/* <th scope="col">
                        ID <img src={Filter} alt="..." onClick={(e) => this.sortHandler("q.quotation_id")} />
                      </th> */}
                      <th scope="col">
                        Quotation No <img src={Filter} alt="..." onClick={(e) => this.sortHandler("q.quotation_reference_id")} />
                      </th>
                      <th scope="col">
                        Customer Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("c.customer_first_name")} />
                      </th>
                      <th scope="col">
                        Project Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("q.project_name")} />
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Discount
                      </th>
                      <th scope="col">
                        Created By <img src={Filter} alt="..." onClick={(e) => this.sortHandler("issuedStaff")} />
                      </th>
                      <th scope="col">
                        Sales Executive <img src={Filter} alt="..." onClick={(e) => this.sortHandler("sales")} />
                      </th>
                      <th scope="col">
                        Created At <img src={Filter} alt="..." onClick={(e) => this.sortHandler("q.created_at")} />
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Status <img src={Filter} alt="..." onClick={(e) => this.sortHandler("qs.quotation_status")} />
                      </th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all_quotations && all_quotations.length > 0 ? (
                      all_quotations.map((item, i) => (
                        <tr key={i} style={{ cursor: "pointer" }} >
                          <td onClick={(e) => this.handleShow(item, "")}>{item.referenceId}</td>
                          <td onClick={(e) => this.handleShow(item, "")} >{`${item.customerFirstName} ${item.customerLastName ?? ""}`}</td>
                          <td className="inward_new_size" onClick={(e) => this.handleShow(item, "")}>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item?.projectName || "-"}</Tooltip>}>
                              <span className="d-inline-block">
                                {item?.projectName || "-"}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td style={{ textAlign: "center" }} className="inward_project_size" onClick={(e) => this.handleShow(item, "")}>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item?.discount || "-"}</Tooltip>}>
                              <span className="d-inline-block">
                                {item?.discount || "-"}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td onClick={(e) => this.handleShow(item, "")}>{item.createdBy}</td>
                          <td onClick={(e) => this.handleShow(item, "")}>{item.salesExecutive || "-"}</td>
                          <td onClick={(e) => this.handleShow(item, "")}>{changeDateFormat(item.createdAt)}</td>
                          <td style={{ textAlign: "center" }}>
                            <button style={{ width: "120px" }} className={changeClassName(item.status)} onClick={(e) => this.handleShow(item, "")} >
                              {item.status}
                            </button>
                          </td>
                          <td>
                            {item.status === "REVISED" || item.status === "BOQ_GENERATED" ? <img src={item.status === "REVISED" || item.status === "BOQ_GENERATED" ? download : null} className="Edit-staffs" onClick={(e) => this.handleShow(item, "edit")} /> : null}
                            {item.status === "REVISED" || item.status === "BOQ_GENERATED" ? null : <img src={item.status === "GENERATED" || item.status === "CANCELLED" || item.status === "CONFIRMED" ? download : Edit} className="Edit-staffs" onClick={(e) => this.handleShow(item, "edit")} />}
                            {<img src={item.status === "GENERATED" || item.status === "CANCELLED" || item.status === "CONFIRMED" || item.status === "REVISED" || item.status === "BOQ_GENERATED" ? clone : Delete} className="delete-staff" onClick={item.status === "GENERATED" || item.status === "CANCELLED" || item.status === "CONFIRMED" || item.status === "REVISED" || item.status === "BOQ_GENERATED" ? (e) => this.cloneQutationHandler(item) : (e) => this.deleteQutationHandler(item)} />}
                            {item.status === "GENERATED" || item.status === "CANCELLED" || item.status === "CONFIRMED" || item.status === "REVISED" || item.status === "BOQ_GENERATED" ? null : <img src={clone} className="delete-staff" onClick={(e) => this.cloneQutationHandler(item)} />}
                          </td >
                        </tr >
                      ))
                    ) : !this.props.loading ?
                      <tr>
                        <td colspan={4}><h1 className="empty-data">No Records Found</h1></td>
                      </tr> :
                      <Spinner />
                    }
                  </tbody>
                </table >
              </div >
            </Card >
            <Pagination data={data} onChange={this.onChange} />
          </div >
        </div >
        <ClonePopUp show={cloneShow} onHide={this.closeClonePopup} cloned={cloned} />
        <div style={{ display: "none" }}>
          <ConvertBoq
            handleShow={this.boqShow}
            show={boqPopup}
            onHide={this.boqClose}
            allData={() => console.log("Clicked")}
            quotationId={quotationId}
            quotData={quotData}
            str="Quote"
          />
        </div>
        <View
          edited={cloneEdit}
          revised={reviseEdit}
          cancel={cancelEdit}
          show={this.state.show}
          onHide={this.handleClose}
          pdf={pdf}
          data={quotData}
          preview={previewQutationTxt}
          status={qutationStatus}
          changedStatus={this.state.status}
          statusHandler={this.statusHandler}
          cloneHandler={this.cloneQutationHandler}
          editHandler={this.editQutationHandler}
          generate={this.generateQuotationHandler}
          deleteHandler={this.deleteQutationHandler}
          confirmed={confirmed}
          confrimHandler={this.confrimHandler}
          changed={changeName}
          boqShow={this.boqShow}
        />
        <Modal
          show={this.state.errorPopup}
          onHide={() => {
            this.setState({
              errorPopup: false
            });
          }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="modal-success">
            <h5 >{generateQuotationSuccess?.data}</h5>
          </div>
          <button className="modal-btn sub-modal-btn error_popup" onClick={() => {
            this.quotPage();
            this.setState({
              errorPopup: false
            });
          }} >
            Ok
          </button>
        </Modal>
        <Modal
          show={clonePopup}
          onHide={() => {
            this.setState({
              clonePopup: false
            });
          }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="modal-success">
            <img src={Logo} alt=".." className="spin" width="200px" />
            <h5 >Cloning Quotation...Please wait...</h5>
          </div>
        </Modal>
        <Modal
          show={deletePopup}
          onHide={() => {
            this.setState({
              deletePopup: false
            });
          }}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="modal-success">
            <img src={del} alt=".." />
            <h5>Are you sure you want to delete this Quotation?</h5>
          </div>
          <div className="txt-center">
            <button onClick={() => {
              this.setState({
                deletePopup: false
              });
            }}
              className="no_btn"
            >
              Cancel
            </button>
            <button onClick={() => {
              this.setState({
                deletePopup: false
              });
              this.props.dispatch(deleteQuotation(quotationId));
            }
            } className="okay"> Okay
            </button>
          </div>
        </Modal>
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.qutation.loading,
    qutation_list: state.qutation.qutation_list,
    allData: state.qutation.allData,
    quotationData: state.qutation.quotationData,
    previewQutationTxt: state.qutation.previewQutation,
    qutationStatus: state.qutation.qutationStatus,
    updateQutationStatus: state.qutation.updateQutationStatus,
    cloneQutation: state.qutation.cloneQutation,
    deleteQuotation: state.qutation.deleteQuotation,
    generateQuotationSuccess: state.qutation.generateQuotationSuccess,
    downloadQutation: state.qutation.downloadQutation
  }
}

export default connect(mapStateToProps)(Quotations);