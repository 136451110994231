import { templateConstants } from "../constants";

const initialState = {
    loading: false,
    data: [],
    error: [],
    token: "",
    settings:[],
    templateData:[]
};

export default function templateReducer(state = initialState, { type, response }) {
    switch (type) {
        case templateConstants.GET_TEMPLATE:
        case templateConstants.GET_SETTINGS:
        case templateConstants.GET_TEMPLATE_ONE:
            return {
                ...state,
                loading: true,
            };

        case templateConstants.GET_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                templates_list: response.data,
            };

        case templateConstants.GET_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: response.data,
            };

        case templateConstants.GET_TEMPLATE_ONE_SUCCESS:
            return {
                ...state,
                loading: false,
                templateData: response.data,
            };

        case templateConstants.GET_TEMPLATE_FAILURE:
        case templateConstants.GET_SETTINGS_FAILURE:
        case templateConstants.GET_TEMPLATE_ONE_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
}
