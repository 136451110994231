import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../auth/Login';
import Forgot from '../auth/Forgot';
import Privacy from '../pages/privacy&condition/privacy';
import Terms_Condition from '../pages/privacy&condition/terms&condition';
import PageNotFound from '../pages/pagenotfound/PageNotFound';
import { Redirect } from 'react-router-dom';



class PublicRoutes extends Component {
	render() {
		return (
			<Switch>
				<Route exact path="/" component={Login} />
				<Route exact path="/login" component={Login} />
				<Route exact path="/forgot-password" component={Forgot} />
				<Route exact path="/privacy-policy" component={Privacy} />
				<Route exact path="/terms-and-conditions" component={Terms_Condition} />
				<Route exact path="/404" component={PageNotFound} />
				<Redirect to="/404" />
			</Switch>
		)
	}
}

export default PublicRoutes;