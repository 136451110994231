import { put, call, takeEvery } from "redux-saga/effects";
import { unitConstants } from "../constants";
import { addUnitApi, getAllUnitsApi, updateUnitApi } from "../services";

export function* getAllUnitsSaga(payload) {
    try {
        const response = yield call(getAllUnitsApi, payload);
        yield put({ type: unitConstants.GET_UNITS_LIST_SUCCESS, response });
    } catch (error) {
        yield put({ type: unitConstants.GET_UNITS_LIST_FAILURE, error });
    }
}

export function* addUnitSaga(payload) {
    try {
        const response = yield call(addUnitApi, payload.formData);
        yield put({ type: unitConstants.UNIT_SUCCESS, response });

        yield takeEvery(unitConstants.GET_UNITS, getAllUnitsSaga);
    } catch (error) {
        yield put({ type: unitConstants.UNIT_FAILURE, error });
    }
}

export function* editUnitSaga(payload) {
    try {
        const response = yield call(updateUnitApi, payload.formData);
        yield put({ type: unitConstants.UPDATE_UNIT_SUCCESS, response });

        yield takeEvery(unitConstants.GET_UNITS, getAllUnitsSaga);
    } catch (error) {
        yield put({ type: unitConstants.UNIT_FAILURE, error });
    }
}

export default function* unitSaga() {
    yield takeEvery(unitConstants.GET_UNITS, getAllUnitsSaga);
    yield takeEvery(unitConstants.UNIT_REQUEST, addUnitSaga);
    yield takeEvery(unitConstants.UPDATE_UNIT_REQUEST, editUnitSaga);

}
