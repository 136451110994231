import { billofquantityConstants } from "../constants";

export const getBillOfQuantityPoList = (item) => {
    return {
        type: billofquantityConstants.GET_BILL_OF_QUANTITY,
        item
    }
}
export const createBoq = (formData) => {
    return {
        type: billofquantityConstants.CREATE_BOQ,
        formData
    }
}
export const getConvertBoqToPo = () => {
    return {
        type: billofquantityConstants.GET_CONVERT_BOQ_TO_PO,
    }
}
export const getConfirmQuotDropdown = (id) => {
    return {
        type: billofquantityConstants.GET_CONFIRM_QUOTATION_DROPDOWN,
        id
    }
}

// TODO: Boq csv Download
export const onBoqDownloadCsv = (formData) => {
    return {
        type: billofquantityConstants.DOWNLOAD_BOQ_CSV,
        formData
    }
}

export const createBillOfQuantity = (formData) => {
    return {
        type: billofquantityConstants.CREATE_BILL_OF_QUANTITY_INIT,
        formData
    }
}
export const getBillOfQuantity = (billofquantityId) => {
    return {
        type: billofquantityConstants.SINGLE_BILL_OF_QUANTITY_INIT,
        billofquantityId
    }
}
export const updateBillOfQuantity = (formData) => {
    return {
        type: billofquantityConstants.UPDATE_BILL_OF_QUANTITY_INIT,
        formData
    }
}
export const changePage = () => {
    return {
        type: billofquantityConstants.CHANGE_PAGE,
    }
}
export const getBoqProjectDetail = (id) => {
    return {
        type: billofquantityConstants.GET_BOQ_PROJECTS,
        id
    }
}
export const createBoqProject = (formData) => {
    return {
        type: billofquantityConstants.CREATE_BOQ_PROJECT,
        formData
    }
}
export const updateBoqProject = (formData) => {
    return {
        type: billofquantityConstants.EDIT_BOQ_PROJECT,
        formData
    }
}
export const createBoqProduct = (formData) => {
    return {
        type: billofquantityConstants.CREATE_BOQ_PRODUCT,
        formData
    }
}
export const editBoqProduct = (formData) => {
    return {
        type: billofquantityConstants.EDIT_BOQ_PRODUCT,
        formData
    }
}
export const deleteBoqProduct = (id) => {
    return {
        type: billofquantityConstants.DELETE_BOQ_PRODUCT,
        id
    }
}
export const previewBoq = (id) => {
    return {
        type: billofquantityConstants.PREVIEW_BOQ,
        id
    }
}
export const getBoqById = (id) => {
    return {
        type: billofquantityConstants.GET_BOQ_BY_ID,
        id
    }
}
export const generateBoq = (id) => {
    return {
        type: billofquantityConstants.GENERATE_BOQ,
        id
    }
}
export const sendBoq = (id) => {
    return {
        type: billofquantityConstants.SENT_BOQ,
        id
    }
}
export const cloneBoq = (id) => {
    return {
        type: billofquantityConstants.CLONE_BOQ,
        id
    }
}
export const deleteBoq = (id) => {
    return {
        type: billofquantityConstants.DELETE_BOQ,
        id
    }
}
export const reviseBoq = (id) => {
    return {
        type: billofquantityConstants.REVISE_BOQ,
        id
    }
}
export const updateCustomBoqProduct = (formData) => {
    return {
        type: billofquantityConstants.UPDATE_CUSTOM_BOQ_PRODUCT,
        formData
    }
}
export const getProjectOutwardList = () => {
    return {
        type: billofquantityConstants.GET_PROJECT_OUTWARD,
    }
}
export const getInternalTransferProductList = (id) => {
    return {
        type: billofquantityConstants.GET_INTERNAL_TRANSFER_PRODUCT,
        id
    }
}
export const getBoqMailDetails = (id) => {
    return {
        type: billofquantityConstants.GET_BOQ_MAIL_DETAILS,
        id
    }
}
export const sendBoqMail = (formData) => {
    return {
        type: billofquantityConstants.SENT_BOQ_MAIL,
        formData
    }
}
export const deliverBoq = (formData) => {
    return {
        type: billofquantityConstants.DELIVER_BOQ,
        formData
    }
}
export const clearBoq = () => {
    return {
        type: billofquantityConstants.CLEAR_BOQ_ID_DATA,
    }
}
export const clearBoqPreview = () => {
    return {
        type: billofquantityConstants.CLEAR_BOQ_PREVIEW,
    }
}
export const changeBoqPage = () => {
    return {
        type: billofquantityConstants.CHANGE_PAGE
    }
}
export const changeBoqAddPage = () => {
    return {
        type: billofquantityConstants.CHANGE_ADD_PAGE
    }
}
export const updateInternalTransfer = (formData) => {
    return {
        type: billofquantityConstants.UPDATE_INTERNAL_TRANSFER,
        formData
    }
}
export const clearBoqProjectData = () => {
    return {
        type: billofquantityConstants.CLEAR_BOQ_PROJECT
    }
}
export const clearConfirmDropdownList = () => {
    return {
        type: billofquantityConstants.CLEAR_CONFIRM_QUOT_DROPDOWN
    }
}
export const getBoqProductDetails = (id) => {
    return {
        type: billofquantityConstants.GET_BOQ_PRODUCT_DETAILS,
        id
    }
}
export const previewPackingSlip = (id) => {
    return {
        type: billofquantityConstants.PREVIEW_PACKING_SLIP,
        id
    }
}
export const clearCSV = () => {
    return {
        type: billofquantityConstants.CLEAR_CSV,
    }
}

export const clearProductOutwardAction = () => {
    return {
        type: billofquantityConstants.CLEAR_PROJECT_OUTWARD
    }
}

export const getProjectOutwardV2Action = (boqId) => {
    return {
        type: billofquantityConstants.GET_PROJECT_OUTWARD_V2,
        boqId
    }
}
