import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Form, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import { Link } from "react-router-dom";
import { authToken, baseUrl } from "../../../actions";
import Re from "../../../assets/images/cat.svg";
const role = localStorage.getItem("userRole");



function GstForm() {
  const [dataObj, setDataObj] = useState({});
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setDataObj({ ...dataObj, [name]: value });
    }
    else setDataObj({ ...dataObj, ...e });
  }

  const loadData = async () => {
    axios.get(`${baseUrl}public/settings`)
      .then((res) => {
        setDataObj(res.data.data);

      }).catch((err) => {
        console.error(err);
      });
  }

  const submitHandler = (e) => {
    e.preventDefault();


    if (/[^0-9- .]/i.test(dataObj.GST)) {
      toast("Only digits allowed");
    }
    else if (dataObj.GST) {

      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ GST: Number(dataObj.GST) })

      };
      fetch(`${baseUrl}admin/settings`, requestOptions)
        .then(res => {
          if (res.status >= 400) {
            console.log(res);
            throw new Error("Server responds with error!")
          }
          return res.json()
        })
        .then(res => {
          setData(res)
        },
          err => {
            setError(err)
          })
    } else {
      toast("Enter a value to change the gst percentage");
    }
  }



  if (data.status === 200 && data.status !== undefined) {
    toast(data.data);
    data.status = undefined;
  } else if (data.status !== 200 && data.status !== undefined) {
    toast(data.data);
    data.status = undefined;
  } else {
    // console.log(data.status);
  }

  useEffect(() => {
    loadData();
    data.status = undefined
  }, []);

  if (error) {
    return <div className="require"> {error.message} </div>
  } else {
    return (

      <div>
        <Navbar />
        <div className="dashboard-page">
          <div className="dashboard-sidebar">
            <Sidebar />
          </div>
          <div className="dashboard-main">
            <Card className="staff-page">
              <div className="page-head-split">
                <div className="head-colums">
                  <div className="head-part-tab">
                    <img src={Re} alt="..." className="head-imgs" />
                    <h5>Settings</h5>
                  </div>
                  <div className="tab-links">
                    <Link to={"/" + role + "/gst"} className="active">GST</Link>
                    <Link to={"/" + role + "/reference-id"} >Reference Id</Link>
                    <Link to={"/" + role + "/unit"}>Unit </Link>
                    <Link to={"/" + role + "/group"}>Quotation Group </Link>
                    <Link to={"/" + role + "/content-of-letter"}>Content Of the Letter</Link>
                    <Link to={"/" + role + "/terms-conditions"}>Terms & Condition</Link>
                  </div>
                </div>
              </div>
              <Row style={{ padding: "50px" }}>
                <Col md={5}>
                  <Form onSubmit={submitHandler}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>GST Percentage</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="GST Percentage"
                        onChange={handleChange}
                        name="GST"
                        value={dataObj.GST}
                      />
                      <span className="setting-span">%</span>
                    </Form.Group>
                    <button className="setting" type="submit">Save Changes</button>
                  </Form>
                </Col>
              </Row >
            </Card>
          </div>
        </div>
      </div>
    );
  }
}


export default GstForm;
