import { authToken, baseUrl } from "../actions";

export const getVerifiedProjectsApi = (productId) => {
    const SINGLE_PRODUCT = `${baseUrl}staff/inward/verifiedProjectList/${productId}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(SINGLE_PRODUCT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const getInwardLogsApi = (item) => {
    let INWARD_LIST =  `${baseUrl}staff/inwardLogs?${item}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(INWARD_LIST, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const getReceivedInwardsApi = (item) => {
    let INWARD_LIST =  `${baseUrl}staff/purchaseOrderProjects/yetToReceiveProducts?${item}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(INWARD_LIST, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const addReturnInwardProductApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/products/inwardReturn`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getInwardCsvApi = (item) => {
    let GET_CATEGORY_CSV_LIST = `${baseUrl}staff/inwardLogs/csv?${item}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(GET_CATEGORY_CSV_LIST, parameters)
        .then((response) => {
            return response.text();
        })
        .then((json) => {
            return json;
        });
};
export const getYetToReceiveCsvApi = (item) => {
    let GET_CATEGORY_CSV_LIST = `${baseUrl}staff/purchaseOrderProjects/yetToReceiveProducts/csv?${item}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(GET_CATEGORY_CSV_LIST, parameters)
        .then((response) => {
            return response.text();
        })
        .then((json) => {
            return json;
        });
};

