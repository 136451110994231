import { authToken, baseUrl } from "../actions";

export const getstaffExecutiveApi = (pageNo, filterData = {}) => {
    let STAFF_EXECUTIVE;

    if (Object.keys(filterData).length > 0) {
        let fn = filterData.firstName ?? "";
        let userId = filterData.userId ?? ""
        let fd = filterData.fromDate ?? "";
        let td = filterData.toDate ?? "";
        STAFF_EXECUTIVE = `${baseUrl}staff/reports/salesExecutives?firstName=${fn}&userId=${userId}&fromDate=${fd}&toDate=${td}&page=${pageNo}`;
    } else {
        STAFF_EXECUTIVE = `${baseUrl}staff/reports/salesExecutives?page=${pageNo}`;
    }
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(STAFF_EXECUTIVE, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};


export const getSingleExecutiveApi = (id,item) => {
    let SINGLE_EXECUTIVE = `${baseUrl}staff/quotations?salesExecutiveId=${id}&${item}`;

    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(SINGLE_EXECUTIVE, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};