import { baseUrl } from "../actions";


export const loginApi = (request) => {
  console.log(request);
  const LOGIN_API_ENDPOINT = `${baseUrl}public/internalUserLogin`;
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request.formData),
  };



  return fetch(LOGIN_API_ENDPOINT, parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};




export const forgotPasswordApi = (request) => {
  const FORGOT_PASSWORD_API_ENDPOINT = `${baseUrl}public/forgotPassword`;
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request.formData),
  };

  return fetch(FORGOT_PASSWORD_API_ENDPOINT, parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};





