import { settingconstants } from "../constants";


const initialState = {
    loading: false,
    data: [],
    error: [],
    token: "",
    status: 0,
};

export default function settingReducer(state = initialState, { type, response }) {
    switch (type) {
        case settingconstants.POST_GST:
            return {
                ...state,
                loading: true,
            };
        case settingconstants.POST_GST_SUCCESS:
            return {
                ...state,
                loading: false,
                setting_gst: response.data.content,
            };
        case settingconstants.POST_GST_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        default:
            return state;
    }
}


