import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Row, Col, Card, Form } from "react-bootstrap";
import edit from "../../../../../assets/images/edit_btn.svg";
import { Link } from "react-router-dom";
import plus2 from "../../../../../assets/images/plus-circle2.svg";
import { connect } from "react-redux";
import Navbar from "../../../../../layouts/Navbar";
import check from "../../../../../assets/images/check2.svg";
import Sidebar from "../../../../../layouts/Sidebar";
import Orange from "../../../../../assets/images/green2.svg";
import plus from "../../../../../assets/images/plus_green.svg";
import ViewProducts from '../../../createpurchaseorder/productdetails/ViewProducts';
import BoqCustomProducts from "./AddCustomProducts";
import {
    getBoqProjectDetail,
    getVerticesDropdown,
    getExecutives,
    createBoqProject,
    previewBoq,
    getBoqById,
    generateBoq,
    setProduct,
    changeBoqPage,
    changeBoqAddPage,
    updateBoqProject,
    sendBoq,
    cloneBoq,
    reviseBoq,
    deleteBoq,
    clearBoq,
    cancelBoq,
    getTitleStr,
    clearSingleProduct,
    getProductById
} from '../../../../../actions';
import validate from '../../../createpurchaseorder/productdetails/validate';
import { toast } from 'react-toastify';
import AddProducts from './AddProducts';
import EditProject from './editProject';
import BOQView from '../../BOQView';
import CloneStatus from "../../../../../Components/cloningStatus";
import ClonePopup from "../../../../../Components/clonePopup";
import DeletePopUp from '../../../vendorpo/deletePopup';
const role = localStorage.getItem('userRole');

const renderField = ({ input, label, placeholder, type, meta: { touched, error } }) => (
    <div>
        <label>{label}</label>
        <div>
            <input {...input} type={type} placeholder={placeholder} className="form-control" />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);
const renderSelectField = ({ input, meta: { touched, error }, children }) => (
    <div>
        <div>
            <select {...input} className='form-control form-select mt-4'>
                {children}
            </select>
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
);
class BoqProductDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boqProjectData: this.props.boqProjectData,
            openedItem: [],
            productPopup: false,
            customPopup: false,
            projectId: "",
            projectPopup: false,
            status: "DRAFT",
            data: {},
            editShow: false,
            item: [],
            deletePopup: false,
            productItem: {},
            cloned: false,
            clonePopup: false,
            cloneStr: "",
            cloneShow: false,
        }
        this.createProject = this.createProject.bind(this);
        this.setContent = this.setContent.bind(this);
        this.productOpen = this.productOpen.bind(this);
        this.productClose = this.productClose.bind(this);
        this.projectOpen = this.projectOpen.bind(this);
        this.projectClose = this.projectClose.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.editVendorShow = this.editVendorShow.bind(this);
        this.editVendorClose = this.editVendorClose.bind(this);
        this.customProductOpen = this.customProductOpen.bind(this);
        this.customProductClose = this.customProductClose.bind(this);
        this.productHandler = this.productHandler.bind(this);
        this.generatePoHandler = this.generatePoHandler.bind(this);
        this.sendPohandler = this.sendPohandler.bind(this);
        this.clonePoHandler = this.clonePoHandler.bind(this);
        this.revisePoHandler = this.revisePoHandler.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.deleteItemClose = this.deleteItemClose.bind(this);
        this.deletePoHandler = this.deletePoHandler.bind(this);
        this.goToPoPage = this.goToPoPage.bind(this);
        this.cancelBoqHandler = this.cancelBoqHandler.bind(this);
    }
    async cancelBoqHandler() {
        var footerData = await cancelBoq(this.props.match.params.id);
        if (footerData?.data?.status === 200) {
            toast(footerData?.data?.data);
            this.setState({
                status: "CANCELLED"
            });
            const { history } = this.props;
            history.push(`/${role}/bill_of_quantity`);
        } else {
            toast(footerData?.data?.data);
        }
    }
    goToPoPage() {
        if (this.state.status === "DRAFT") {
            this.editVendorClose();
        } else {
            this.props.dispatch(clearBoq());
            this.revisePoHandler();
        }
    }
    generatePoHandler() {
        this.setState({
            editShow: false
        })
        this.props.dispatch(generateBoq(this.props.match.params.id));
    }
    sendPohandler() {
        this.props.dispatch(sendBoq(this.props.match.params.id));
    }
    clonePoHandler() {
        this.setState({
            cloned: true,
            clonePopup: true,
            cloneStr: "Cloning BOQ...Please wait..."
        })
        this.props.dispatch(cloneBoq(this.props.match.params.id));
    }
    revisePoHandler() {
        this.setState({
            cloned: true,
            clonePopup: true,
            cloneStr: "Revising BOQ...Please wait..."
        })
        this.props.dispatch(reviseBoq(this.props.match.params.id));
    }
    deleteItem() {
        this.setState({
            deletePopup: true,
        })
    }
    deleteItemClose() {
        this.setState({
            deletePopup: false
        })
    }
    deletePoHandler() {
        this.props.dispatch(deleteBoq(this.props.match.params.id));
    }
    productHandler(values) {
        this.props.dispatch(changeBoqPage());
        this.props.dispatch(clearSingleProduct());
        if (values?.productName?.startsWith("<")) {
            this.props.dispatch(getProductById(values.billOfQuantityProductId));
            let data = {
                'articleId': values?.articleId,
                'productName': values.productName,
                'Quantity': values.quantity,
                'unitId': values?.unit?.unitId,
                'unitName': values?.unit?.unitName,
                'productId': values?.billOfQuantityProductId,
                'unitCost': values?.currentPrice,
                'totalStock': values?.availableStock,
                'totalCost': values.quantity * values.currentPrice
            }
            this.setState(prevState => ({
                customPopup: !prevState.customPopup,
                productPopup: false,
                str: "edit",
                productItem: data
            }));
        } else {
            this.setState(prevState => ({
                productPopup: !prevState.productPopup,
                customPopup: false,
                str: "edit",
                productItem: values
            }));
            setProduct(values);
        }
    }
    createProject(values) {
        const { boqProjectData } = this.state;
        if (this.props.match.params.id === "add") {
            toast("You are not allow to create project...Fill the previous section first");
        } else {
            if (boqProjectData.length !== 0) {
                let data = {
                    "projectName": values.projectName,
                    "projectId": boqProjectData[0].projectId,
                    "vertexId": values.vertexId,
                    "salesExecutiveId": values.salesExecutiveId
                }
                this.props.dispatch(updateBoqProject(data));
            } else {
                let data = {
                    "projectName": values.projectName,
                    "vertexId": Number(values.vertexId),
                    "billOfQuantityId": Number(this.props.match.params.id),
                    "salesExecutiveId": Number(values.salesExecutiveId)
                }
                this.props.dispatch(createBoqProject(data));
            }
        }
    }
    customProductOpen(item) {
        this.props.dispatch(changeBoqAddPage());
        this.setState(prevState => ({
            str: "add",
            customPopup: !prevState.customPopup,
            projectId: item.projectId,
            productItem: {},
        }));
    }
    customProductClose() {
        this.setState(prevState => ({
            customPopup: !prevState.customPopup,
        }));
    }
    editVendorShow() {
        const { initialValues } = this.props;
        if (initialValues?.projectName === undefined && initialValues?.vertexId === undefined) {
            toast("Please create a project");
        } else {
            this.props.dispatch(previewBoq(this.props.match.params.id));
            this.setState({
                editShow: true,
            })
        }

    }
    editVendorClose() {
        const { status } = this.state;
        if (status === "DRAFT" || status === undefined) {
            this.setState({
                editShow: false
            })
        } else {
            window.location.hash = `/${role}/bill_of_quantity`;
        }
    }
    prevPage() {
        const { history } = this.props;
        history.push(`/${role}/bill_of_quantity/customer_details/${this.props.match.params.id}`);
    }
    projectOpen(item) {
        this.setState({
            projectPopup: true,
            productPopup: false,
            customPopup: false,
            data: item,
            projectId: item.projectId
        })
    }
    projectClose() {
        this.setState({
            projectPopup: false
        })
    }
    productOpen(item) {
        this.props.dispatch(changeBoqAddPage());
        setProduct({})
        this.setState(prevState => ({
            str: "add",
            productPopup: !prevState.productPopup,
            projectId: item.projectId,
            productItem: {},
        }));
    }
    productClose() {
        this.setState(prevState => ({
            productPopup: !prevState.productPopup,
        }));
    }
    setContent(val, index) {
        !val ? this.state.openedItem[index] = true : this.state.openedItem[index] = false;
        this.setState({
            productPopup: false,
            customPopup: false,
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.boqProjectData !== this.props.boqProjectData) {
            this.setState({
                boqProjectData: this.props.boqProjectData,
            });
            const { openedItem } = this.state;
            let data = [];
            this.props.boqProjectData?.map((item, i) => {
                if (openedItem[i] && item?.productList?.length > 0) {
                    data[i] = true;
                } else {
                    data[i] = false;
                }
            });
            this.setState({
                openedItem: data
            })
        } if (prevProps.projectSuccess !== this.props.projectSuccess) {
            if (this.props.projectSuccess.status === 200) {
                this.props.dispatch(getBoqById(this.props.match.params.id));
                this.setState({
                    projectPopup: false
                })
                toast(this.props.projectSuccess.message);
                this.props.reset();
                this.props.dispatch(getBoqProjectDetail(this.props.match.params.id));
            } else {
                toast(this.props.projectSuccess.data);
            }
        } if (prevProps.deleteBoqProduct !== this.props.deleteBoqProduct) {
            if (this.props.deleteBoqProduct.status === 200) {
                toast(this.props.deleteBoqProduct.data);
                this.props.dispatch(getBoqProjectDetail(this.props.match.params.id));
            } else {
                toast(this.props.deleteBoqProduct.data);
            }
        } if (prevProps.boqIdData !== this.props.boqIdData) {
            this.setState({
                item: this.props.boqIdData,
                status: this.props.boqIdData.status
            })
        } if (prevProps.generateData !== this.props.generateData) {
            this.props.dispatch(previewBoq(this.props.match.params.id));
            if (this.props.generateData.status === 200) {
                this.props.dispatch(getBoqById(this.props.match.params.id));
                this.setState({
                    status: "GENERATED",
                    editShow: true
                })
                toast(this.props.generateData.data);
            } else {
                toast(this.props.generateData.data);
            }
        } if (prevProps.sendData !== this.props.sendData) {
            if (this.props.sendData.status === 200) {
                toast(this.props.sendData.data);
                this.setState({
                    status: "SENT",
                    editShow: false
                })
                const { history } = this.props;
                history.push(`/${role}/bill_of_quantity`);
            } else {
                toast(this.props.sendData.data);
            }
        } if (prevProps.cloneData !== this.props.cloneData) {
            if (this.props.cloneData.status === 200) {
                this.setState({
                    editShow: false,
                    clonePopup: false,
                    cloneShow: true,
                    cloneStr: "BOQ Cloned Successfully"
                })
                setTimeout(() => this.setState({ cloneShow: false, cloneStr: "", cloned: null }), 1500);
                const { history } = this.props;
                setTimeout(() => history.push(`/${role}/bill_of_quantity`), 1800);
            } else {
                this.setState({
                    clonePopup: false,
                })
                toast(this.props.cloneData.data);
            }
        } if (prevProps.reviseData !== this.props.reviseData) {
            if (this.props.reviseData.status === 200) {
                this.setState({
                    editShow: false,
                    clonePopup: false,
                    cloneShow: true,
                    cloneStr: "Boq Revised Successfully"
                })
                setTimeout(() => this.setState({ cloneShow: false, cloneStr: "", cloned: null }), 1500);
                const { history } = this.props;
                setTimeout(() => history.push(`/${role}/bill_of_quantity`), 1800);
                // toast(this.props.reviseData.message);
            } else {
                this.setState({
                    clonePopup: false,
                })
                toast(this.props.reviseData.data);
            }
        } if (prevProps.deleteBoq !== this.props.deleteBoq) {
            if (this.props.deleteBoq.status === 200) {
                this.setState({
                    deletePopup: false,
                    editShow: false
                })
                const { history } = this.props;
                history.push(`/${role}/bill_of_quantity`);
                toast(this.props.deleteBoq.data);
            } else {
                toast(this.props.deleteBoq.data);
            }
        }
    }
    componentDidMount() {
        if (this.props.match.params.id !== "add") {
            this.props.dispatch(getBoqProjectDetail(this.props.match.params.id));
            this.props.dispatch(getBoqById(this.props.match.params.id));
        }
        this.props.dispatch(getVerticesDropdown());
        this.props.dispatch(getExecutives());
    }
    render() {
        const { boqProjectData, productPopup, projectPopup, data, editShow, customPopup, str, productItem, deletePopup, status, item } = this.state;
        const { load, handleSubmit } = this.props;
        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <img src={Orange} alt="..." className="head-img" />
                            <h5 className='po_link' onClick={() => window.location.hash = "/" + role + "/bill_of_quantity"}>Bill Of Quantity</h5>
                            <i className="fas fa-arrow-right" style={{ margin: "10px 0 0" }}></i>
                            <h6>{getTitleStr()}</h6>
                            <hr className="line-new"></hr>
                            <div className="order-page-title">
                                <Link to={"/" + role + "/bill_of_quantity/customer_details/" + this.props.match.params.id} >
                                    Customer Details
                                </Link>
                                <Link to={"/" + role + "/bill_of_quantity/product_details/" + this.props.match.params.id} className="active">
                                    Product Details
                                </Link>
                            </div>
                            <div className="purchase-order">
                                <section className="po-header">
                                    <Row className='ignore_field_body' >
                                        <Col md={3} style={{ padding: "20px 10px" }}>
                                            <h4 >Project Groups</h4>
                                        </Col>
                                        <Col md={9}> </Col>
                                    </Row>
                                </section>
                                <div className="qutation-page" style={{ marginRight: "30px" }} >
                                    {boqProjectData !== undefined && boqProjectData[0]?.projectName !== null && boqProjectData[0]?.vertexName !== null && boqProjectData?.length > 0 ? boqProjectData?.map((loc, index) => {
                                        return (
                                            <div >
                                                <Row key={index} >
                                                    <Col md={3} style={{ marginTop: "13px" }} >
                                                        <label class="form-control-label" for="name">Project Name <span>*</span></label>
                                                        <Form.Group className="mb-3" controlId="enterarea">
                                                            <Form.Control
                                                                type="text"
                                                                name="projectName"
                                                                value={loc.projectName}
                                                                readOnly
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3} style={{ marginTop: "13px" }} >
                                                        <label class="form-control-label" for="name">Vertex Name <span>*</span></label>
                                                        <Form.Group className="mb-3" controlId="enterdwg">
                                                            <Form.Control
                                                                type="text"
                                                                name="vertexName"
                                                                value={loc.vertexName}
                                                                readOnly
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3} style={{ marginTop: "13px" }} >
                                                        <label class="form-control-label" for="name">Sales Executive Name <span>*</span></label>
                                                        <Form.Group className="mb-3" controlId="enterdimention">
                                                            <Form.Control
                                                                type="text"
                                                                name="salesExecutive"
                                                                value={loc.salesExecutive}
                                                                readOnly
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3} className="project_body">
                                                        {loc?.productList?.length > 0 ? <button className='view_po_btn' onClick={() => this.setContent(this.state.openedItem[index], index)}>
                                                            {this.state.openedItem[index] ? "Hide" : "View"}
                                                        </button> : <></>}
                                                        <button className={loc?.productList?.length === 0 ? 'add_po_btn new' : 'add_po_btn'} onClick={() => this.productOpen(loc)}>
                                                            <img src={plus} alt=".../" />
                                                            Add Product
                                                        </button>
                                                        <div onClick={() => this.projectOpen(loc)} className="edit_trash">
                                                            <img src={edit} alt="" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {this.state.openedItem[index] ?
                                                    <>
                                                        <ViewProducts
                                                            data={loc.productList}
                                                            purchaseId={this.props.match.params.id}
                                                            str="boq"
                                                            handleShow={this.productHandler}
                                                        />
                                                        <div className='blue_body' id="table-bluee" style={{ width: "103%" }}>
                                                            <button onClick={() => {
                                                                this.productOpen(loc);
                                                            }} className="group_product_btn" style={{ marginRight: "10px" }}>
                                                                <img src={plus2} alt="" />
                                                                Add Product</button>
                                                            <button className="group_product_btns"
                                                                onClick={() => this.customProductOpen(loc)}
                                                            ><img src={plus2} alt="" />Add Custom Product</button>
                                                        </div>
                                                    </>
                                                    : <></>}
                                            </div>
                                        )
                                    }) : <></>}
                                    {boqProjectData.length === 0 || boqProjectData[0]["projectName"] === null || boqProjectData[0]["vertexName"] === null ? <form onSubmit={handleSubmit(this.createProject)} >
                                        <Row >
                                            <Col md={3} style={{ marginTop: "3px" }}>
                                                <label class="form-control-labelk" for="name">Enter Project Name  <span>*</span></label>
                                                <Field
                                                    name='projectName'
                                                    type="text"
                                                    component={renderField}
                                                // placeholder="Enter Project Name  *"
                                                />
                                            </Col>
                                            <Col md={3}>
                                                <label class="form-control-labelk" for="name">Select one Vertices   <span>*</span></label>
                                                <Field name="vertexId" component={renderSelectField} className="form-control form-select" >
                                                    <option value="" hidden={true}></option>
                                                    {this.props.verticesDropdown?.map(unit =>
                                                        <option value={unit.vertexId} key={unit.vertexId}>{unit.vertexName}</option>)}
                                                </Field>
                                                <div className="triangle-bottom"></div>
                                            </Col>
                                            <Col md={3}>
                                                <label class="form-control-labelk" for="name">Select one Sales Executive<span>*</span></label>
                                                <Field name="salesExecutiveId" component={renderSelectField} className="form-control form-select">
                                                    <option value="" hidden={true}></option>
                                                    {this.props.salesExecutives?.map(unit =>
                                                        <option value={unit.userId} key={unit.userId}>{unit.salesExecutiveName}</option>)}
                                                </Field>
                                                <div className="triangle-bottom"></div>
                                            </Col>
                                            <Col md={3}>
                                                {!load ? <button type="submit" className="po_btn"  >
                                                    <img src={check} alt="" /> Create Project
                                                </button> : <button type="button" className="po_btn"  >
                                                    Loading ...
                                                </button>}
                                            </Col>
                                        </Row>
                                    </form> : <></>}
                                    <div className="customer-sub" style={{ marginTop: "70px" }}>
                                        <Col md={3}>
                                            <button type="button" style={{ marginLeft: "-20px" }} className="cancel-btn" onClick={() => this.prevPage()}>Cancel</button>
                                        </Col>
                                        <Col md={6}></Col>
                                        <Col md={3}>
                                            <button style={{ marginLeft: "20px" }} disabled={boqProjectData.length === 0 || boqProjectData[0]["projectName"] === null || boqProjectData[0]["vertexName"] === null} className="modal-btn save" onClick={this.editVendorShow}>
                                                Save & Preview
                                            </button>
                                        </Col>
                                    </div>
                                    <AddProducts
                                        show={productPopup}
                                        purchaseId={this.props.match.params.id}
                                        onHide={this.productClose}
                                        openPopup={this.productOpen}
                                        projectId={this.state.projectId}
                                        str={str}
                                        data={productItem}
                                    />
                                    <EditProject
                                        str="boq"
                                        show={projectPopup}
                                        purchaseId={this.props.match.params.id}
                                        onHide={this.projectClose}
                                        initialValues={data}
                                        projectId={this.state.projectId}
                                    />
                                    <BoqCustomProducts
                                        show={customPopup}
                                        onHide={this.customProductClose}
                                        projectId={this.state.projectId}
                                        purchaseId={this.props.match.params.id}
                                        initialValues={productItem}
                                        data={productItem}
                                        str={str}
                                    />
                                    <BOQView
                                        show={editShow}
                                        data={status}
                                        item={item}
                                        onHide={this.editVendorClose}
                                        goTo={this.goToPoPage}
                                        generate={this.generatePoHandler}
                                        send={this.sendPohandler}
                                        clone={this.clonePoHandler}
                                        delete={this.deleteItem}
                                        revise={this.revisePoHandler}
                                        cancel={this.cancelBoqHandler}
                                    />
                                    <DeletePopUp
                                        show={deletePopup}
                                        onHide={this.deleteItemClose}
                                        deleteItem={this.deletePoHandler}
                                    />
                                    <CloneStatus
                                        show={this.state.clonePopup}
                                        str={this.state.cloneStr}
                                        onHide={false}
                                    />
                                    <ClonePopup
                                        show={this.state.cloneShow}
                                        str={this.state.cloneStr}
                                        onHide={false}
                                        cloned={this.state.cloned}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let projectData = state.boq.boqProjectData.length > 0 ? state.boq.boqProjectData[0] : {};
    let data = {
        salesExecutiveId: projectData?.salesExecutiveId,
        vertexId: projectData?.vertexId,
        projectName: projectData?.projectName
    }
    return {
        initialValues: data,
        boqProjectData: state.boq.boqProjectData,
        generateData: state.boq.generateData,
        load: state.boq.load,
        verticesDropdown: state.po.verticesDropdown,
        salesExecutives: state.qutation.salesExecutives,
        projectSuccess: state.boq.projectSuccess,
        deleteBoqProduct: state.boq.deleteBoqProduct,
        boqIdData: state.boq.boqIdData,
        sendData: state.boq.sendData,
        cloneData: state.boq.cloneData,
        reviseData: state.boq.reviseData,
        deleteBoq: state.boq.deleteBoq
    }
}
const VendorDetailsForm = reduxForm({
    form: 'BoqProductDetailsFormRedux',
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate
})(BoqProductDetailsForm)

export default connect(mapStateToProps)(VendorDetailsForm);

// export default reduxForm({
//     form: 'fieldArrays', // a unique identifier for this form
// })(BoqProductDetailsForm)