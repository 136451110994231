import { authToken, baseUrl } from "../actions";

// export const getProductApi = (pageNo, searchKey, sort = {}, filterData = {}, size) => {
//     let PRODUCT_LIST;
//     if (Object.keys(filterData).length === 2 || Object.keys(sort).length === 2) {
//         if (Object.keys(sort).length === 2 && Object.keys(filterData).length !== 2) {
//             PRODUCT_LIST = `${baseUrl}staff/products?sortField=${sort.sortField}&sortMethod=${sort.sortType}&page=${pageNo}&searchKey=${searchKey}`;
//         } else {
//             if (Object.keys(filterData).length === 2) {
//                 PRODUCT_LIST = `${baseUrl}staff/products?fromDate=${filterData.fromDate}&toDate=${filterData.toDate}&page=${pageNo}&searchKey=${searchKey}`;
//             } if (Object.keys(sort).length === 2 && Object.keys(filterData).length === 2) {
//                 PRODUCT_LIST = `${baseUrl}staff/products?fromDate=${filterData.fromDate}&toDate=${filterData.toDate}&sortField=${sort.sortField}&sortMethod=${sort.sortType}&page=${pageNo}&searchKey=${searchKey}`;
//             }
//         }
//     } else {
//         let s = size !== undefined ? size : 10;
//         PRODUCT_LIST = searchKey !== undefined ? `${baseUrl}staff/products?searchKey=${searchKey}&page=${pageNo}&sizeOfPage=${s}` : `${baseUrl}staff/products?page=${pageNo}&sizeOfPage=${s}`
//     }

//     const parameters = {
//         method: "GET",
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${authToken}`,
//         },
//     };

//     return fetch(PRODUCT_LIST, parameters)
//         .then((response) => {
//             return response.json();
//         })
//         .then((json) => {
//             return json;
//         });
// };

export const getProductApi = (request) => {
    const ADD_STOCK_API = `${baseUrl}staff/productsList`;
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.data)
    };

    return fetch(ADD_STOCK_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    })
};


export const createProductApi = (request) => {
    const CREATE_PRODUCT = `${baseUrl}staff/products`;
    const parameters = {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${authToken}`,
        },
        body: request
    };

    return fetch(CREATE_PRODUCT, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    })
};

export const getProductUnitApi = () => {
    const PRODUCT_UNIT = `${baseUrl}public/dropdown/units`;
    // "https://estimationapi.corpfield.com/public/dropdown/units";
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(PRODUCT_UNIT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const getProductByIdApi = (productId) => {
    const SINGLE_PRODUCT = `${baseUrl}staff/products/${productId}`;
    //  "https://estimationapi.corpfield.com/staff/products/" + productId;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(SINGLE_PRODUCT, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};



export const updateProductApi = (request) => {
    const UPDATE_PRODUCT = `${baseUrl}staff/products`;
    const parameters = {
        method: 'PUT',
        headers: {
            "Authorization": `Bearer ${authToken}`,
        },
        body: request
    };

    return fetch(UPDATE_PRODUCT, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    })
};

export const getProductHistoryApi = (productId) => {
    const PRODUCT_HISTORY = `${baseUrl}staff/products/${productId}/priceHistory`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(PRODUCT_HISTORY, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const getBulkProductsApi = (searchKey) => {
    const PRODUCT_HISTORY = `${baseUrl}staff/products?sizeOfPage=50&searchKey=${searchKey}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(PRODUCT_HISTORY, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const createStockApi = (request) => {
    const ADD_STOCK_API = `${baseUrl}staff/products/stock`;
    const parameters = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request)
    };

    return fetch(ADD_STOCK_API, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    })
};

export const getProductCsvDownloadApi = () => {
    let REPORT_CUSTOMER = `${baseUrl}staff/product/purchaseDetail/csv`;

    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(REPORT_CUSTOMER, parameters)
        .then((response) => {
            return response.text();

        })
        .then((json) => {
            return json;
        });
};

// TODO: Product Bulk upload download

export const getBulkCsvDownloadApi = () => {
    let BULK_CSV = `${baseUrl}staff/product/productList/csv`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BULK_CSV, parameters)
        .then((response) => {
            return response.text();
        })
        .then((json) => {
            return json;
        });
};



export const updateBulkTableApi = (request) => {
    const UPDATE_BULK_TABLE = `${baseUrl}staff/product/updatePrice`;
    const parameters = {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
        body: request
    };

    return fetch(UPDATE_BULK_TABLE, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    })
};