import React from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import Navbar from "../../layouts/Navbar";
import Sidebar from "../../layouts/Sidebar";
import Orange from "../../assets/images/green1.svg";
import Filter from "../../assets/images/filter.svg";
import "../../assets/css/button.css";
import plus from "../../assets/images/pl.svg";
import Search from "../../assets/images/search.svg";
import { connect } from "react-redux";
import {
  getStaffList,
  getStaffById,
  changePage,
  addStaff,
  updateStaff,
  updateStaffStatus,
  camelize
} from "../../actions";
import Spinner from "../../Components/loader";
import StaffForm from "./StaffForm";
import { toast } from "react-toastify";
import Pagination from "../../Components/Pagination";
import { reset, SubmissionError } from "redux-form";
import StaffFilter from "./staffFilter";
import { Link } from "react-router-dom";

const role = localStorage.getItem('userRole');
class Staff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allStaffs: [],
      show: false,
      status: 0,
      selectedFile: "",
      password: "",
      isFilePicked: false,
      singleStaff: [],
      success_msg: "",
      str: "",
      allData: [],
      searchKey: "",
      userId: 0,
      sortType: "asc",
      sortField: "",
      staffStatus: [],
      filterData: {},
      pageNo: 0,
      sort: {},
      empty: "",
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.getPasswordData = this.getPasswordData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.imageHandler = this.imageHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getStaffList(0));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.staffs_list !== this.props.staffs_list) {
      this.setState({
        allStaffs: this.props.staffs_list,
        success_msg: "",
      });
    } if (prevProps.allData !== this.props.allData) {
      this.setState({
        allData: this.props.allData,
      });
    } if (prevProps.statusData !== this.props.statusData) {
      const { pageNo, searchKey, sort, filterData, sortField } = this.state;
      if (this.props.statusData.status === 200) {
        toast("Status Changed");
        if (sortField === "") {
          this.props.dispatch(getStaffList(pageNo, searchKey, {}, filterData));
        } else {
          this.props.dispatch(getStaffList(pageNo, searchKey, sort, filterData));
        }
      } else {
        toast(this.props.statusData.data);
      }
    }
    if (prevProps.success_msg !== this.props.success_msg) {
      this.setState({
        success_msg: this.props.success_msg,
      });
      if (this.props.success_msg.status === 200) {
        toast(this.props.success_msg.message);
        this.setState({
          show: false,
          password: ""
        })
        const { pageNo, searchKey, sort, filterData, sortField } = this.state;
        if (sortField === "") {
          this.props.dispatch(getStaffList(pageNo, searchKey, {}, filterData));
        } else {
          this.props.dispatch(getStaffList(pageNo, searchKey, sort, filterData));
        }
      } else {
        toast(this.props.success_msg.data);
      }
    }
  }

  onChange(pageNo) {
    this.setState({
      pageNo: pageNo
    });
    const { searchKey, sortType, sortField, filterData } = this.state;
    let sortKey = sortType === 'asc' ? 'desc' : 'asc';
    let sort = {
      sortType: sortKey,
      sortField: sortField
    }

    if (sortField === "") {
      this.props.dispatch(getStaffList(pageNo, searchKey, {}, filterData));
    } else {
      this.props.dispatch(getStaffList(pageNo, searchKey, sort, filterData));
    }
  }

  resetHandler() {
    const { pageNo, sortField, sort, searchKey } = this.state;
    this.setState({
      filterData: []
    })
    if (sortField === "") {
      this.props.dispatch(getStaffList(pageNo, searchKey, {}, {}));
    } else {
      this.props.dispatch(getStaffList(pageNo, searchKey, sort, {}));
    }
  }

  getPasswordData(val) {
    if (val === undefined) {
      toast("Please set a password");
    } else if (val !== undefined) {
      this.setState({ password: val })
    }
  }

  searchHandler(e) {
    const { filterData, sort, sortField } = this.state;
    this.setState({
      searchKey: e.target.value
    });
    if (sortField === "") {
      this.props.dispatch(getStaffList(0, e.target.value, {}, filterData));
    } else {
      this.props.dispatch(getStaffList(0, e.target.value, sort, filterData,));
    }
  }

  imageHandler = (event) => {
    const imageFile = event.target.files[0];
    if (!imageFile?.name?.match(/\.(jpg|jpeg|png)$/)) {
      toast("Please select valid image");
      return false;
    } else {
      this.setState({
        selectedFile: imageFile,
        isFilePicked: true,
      })
    }
  }


  filterSubmit(filterData) {
    const { pageNo, searchKey, sort, sortField } = this.state;
    console.log(sort);
    this.setState({
      filterData: filterData
    })

    if (sortField === "") {
      this.props.dispatch(getStaffList(pageNo, searchKey, {}, filterData));
    } else {
      this.props.dispatch(getStaffList(pageNo, searchKey, sort, filterData));

    }
  }

  sortHandler(sortField) {
    const { pageNo, searchKey, filterData } = this.state;

    this.setState({
      sortField: sortField
    });
    if (this.state.sortType === 'desc') {
      this.setState({
        sortType: 'asc'
      })
    } else {
      this.setState({
        sortType: 'desc'
      })
    }
    let sort = {
      sortType: this.state.sortType,
      sortField: sortField
    }
    this.setState({
      sort: sort
    })

    this.props.dispatch(getStaffList(pageNo, searchKey, sort, filterData));
  }

  handleShow() {
    console.log("clicked");
    this.setState({ show: true, empty: "add" });
    this.props.dispatch(changePage());
  }

  handleClose() {
    this.setState({
      show: false,
      selectedFile: "",
      isFilePicked: false,
    });
    this.props.dispatch(changePage());
  }

  handleSubmit(values, dispatch) {
    if (!values.firstName) {
      throw new SubmissionError({
        firstName: 'Please fill the field *',
      })
    } else if (values.firstName?.trim().length === 0) {
      throw new SubmissionError({
        firstName: 'Please fill the field *',
      })
    }
    var formData = new FormData();
    formData.append('firstName', values.firstName);
    if (values.lastName !== undefined) {
      formData.append('lastName', values.lastName);
    } else {
      // formData.append('lastName', "");
    }
    formData.append('email', values.email);
    formData.append('phoneNumber', values.phone);

    if (this.state.selectedFile) {
      formData.append("profileImage", this.state.selectedFile, this.state.selectedFile.name);
    }
    if (this.props.page === "add") {
      if (!this.state.password) {
        toast("please set a password");
      } else if (!values.gender) {
        toast("Please select a gender");
      }
      else {
        formData.append('gender', values.gender);
        formData.append('role', values.role);
        formData.append('password', this.state.password);
        this.props.dispatch(addStaff(formData));
        // dispatch(reset("StaffForm"));
      }
    } else {
      formData.append('userId', values.userId);
      formData.append('gender', values.gender);
      formData.append('role', values.role);
      this.props.dispatch(updateStaff(formData));
    }
    this.setState({
      selectedFile: "",
      isFilePicked: false,
    })
  }

  handleClick = (event) => {
    if (!this.state.show) {
      this.setState({
        filterShow: true,
        target: event.target
      })
    } else {
      this.setState({
        filterShow: false,
      })
    }
  };


  handleEdit(row) {
    this.setState({
      show: true,
      userId: row.userId,
      empty: "edit"
    });
    this.props.dispatch(getStaffById(row.userId));
  }

  getStatus(val) {
    const status = val === "Inactive" ? false : true;
    let userId = this.state.userId;
    this.props.dispatch(updateStaffStatus(userId, status));
  }


  render() {

    const { show, allStaffs, selectedFile, empty, isFilePicked, allData } = this.state;

    let data = {
      totalElements: allData.totalElements,
      totalPages: allData.totalPages,
      offset: 0,
      numberOfElements: allData.numberOfElements,
      offset: allData?.pageable?.offset,
      numberOfElements: allData.numberOfElements,
      num: (allData?.number) + 1
    }

    return (
      <div>
        <Navbar />
        <div className="dashboard-page">
          <div className="dashboard-sidebar">
            <Sidebar />
          </div>
          <div className="dashboard-main">
            <Card className="staff-page">
              <div className="page-head-split">
                <div className="head-colums">
                  <div className="head-part-tab">
                    <img src={Orange} alt="..." className="head-imgs" />
                    <h5> IDS Directory Staff</h5>
                  </div>
                  <div className="tab-links-staff">
                    <Link to={"/" + role + "/customers"}>Customers</Link>
                    <Link to={"/" + role + "/customers/staff"} className="active">Staff</Link>
                    <Link to={"/" + role + "/customers/vendor"}>Vendors</Link>
                  </div>
                  <div className="customer-search">
                    <span><img src={Search} alt=".." className="search-img" /></span>
                    <Form.Control type="search" placeholder="Search" onChange={this.searchHandler} className="main-search" />
                  </div>
                  <StaffFilter onSubmit={this.filterSubmit} reset={this.resetHandler} />
                  <StaffForm show={show} onHide={this.handleClose} onSubmit={this.handleSubmit} getPassword={this.getPasswordData} handleShow={this.handleShow}
                    imgHandler={this.imageHandler} selectedFile={selectedFile} filePick={isFilePicked} getStatus={this.getStatus} changePassword={this.changePassword} empty={empty} />
                </div>
              </div>
              <div className="table-class">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      {/* <th scope="col">
                        ID <img src={Filter} alt="..." onClick={(e) => this.sortHandler("s.internal_user_id")} />
                      </th> */}
                      <th scope="col">
                        Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("s.first_name")} />
                      </th>
                      <th scope="col">
                        Role <img src={Filter} alt="..." onClick={(e) => this.sortHandler("iu.role")} />
                      </th>
                      <th scope="col">
                        Email <img src={Filter} alt="..." onClick={(e) => this.sortHandler("iu.email")} />
                      </th>
                      <th scope="col">
                        Contact <img src={Filter} alt="..." onClick={(e) => this.sortHandler("iu.phone")} />
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Quotations Created <img src={Filter} alt="..." onClick={(e) => this.sortHandler("totalQuotations")} />
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Status <img src={Filter} alt="..." onClick={(e) => this.sortHandler("iu.active")} />
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>Actions</th>
                    </tr >
                  </thead >
                  <tbody>
                    {allStaffs && allStaffs.length > 0 ? allStaffs.map((staff, index) => {
                      let role = staff.role !== "undefined" ? camelize(staff.role) : "-";
                      let ln = staff.lastName !== "undefined" ? staff.lastName : " ";

                      return (
                        <tr key={staff.userId} onClick={() => this.handleEdit(staff)} style={{ cursor: "pointer" }}>
                          {/* <td>{staff.userId}</td> */}
                          <td>{`${staff.firstName ?? ""} ${ln ?? ""}`}</td>
                          <td >{staff.role === "undefined" ? <div className="center"><p>-</p></div> : role}</td>
                          <td>{staff.email}</td>
                          <td>{`+91 ${staff.contact}`}</td>
                          <td style={{ textAlign: "center" }}>{staff.quotationsCreated || "-"}</td>
                          <td style={{ textAlign: "center" }}>
                            {staff.status === "Inactive" ? (
                              <button className="cancelled-btn">Inactive</button>
                            ) : (
                              <button className="generate_btn">Active</button>
                            )}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <button className="Edit-staff" onClick={() => this.handleEdit(staff)}>
                              Edit
                            </button>
                          </td>
                        </tr>
                      )
                    }) :
                      !this.props.loading ?
                        <h1 className="empty-data">No Records Found</h1> :
                        <Spinner />
                    }
                  </tbody>
                </table >
              </div >
            </Card >
            <Pagination data={data} onChange={this.onChange} />
          </div >
        </div >
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.staff.loading,
    staffs_list: state.staff.staffs_list,
    status: state.staff.status,
    success_msg: state.staff.success_msg,
    page: state.staff.page,
    allData: state.staff.allData,
    error: state.staff.error,
    statusData: state.staff.statusData
  };
};

export default connect(mapStateToProps)(Staff);




{/* <img src={Orange} alt="..." className="head-img" />
              <h5>Staffs</h5>
              <div className="search product-search">
                <span><img src={Search} alt=".." /></span>
                <Form.Control type="search" placeholder="Search" onChange={this.searchHandler} className="global col-md-2" />
              </div>
              <StaffFilter onSubmit={this.filterSubmit} reset={this.resetHandler} />

              <StaffForm show={show} onHide={this.handleClose} onSubmit={this.handleSubmit} getPassword={this.getPasswordData} handleShow={this.handleShow}
                imgHandler={this.imageHandler} selectedFile={selectedFile} filePick={isFilePicked} getStatus={this.getStatus} changePassword={this.changePassword} empty={empty} /> */}