const components = {
	dashboard: {
		component: 'Dashboard',
		url: '/dashboard',
		title: 'Dashboard'
	},
	customers: {
		component: 'Customers',
		url: '/customers',
		title: 'Customers'
	},
	staff: {
		component: 'Staff',
		url: '/customers/staff',
		title: 'Staff'
	},
	vendor: {
		component: 'Vendor',
		url: '/customers/vendor',
		title: 'Vendor'
	},
	templates: {
		component: 'Templates',
		url: '/templates',
		title: 'Templates'
	},
	product: {
		component: 'Product',
		url: '/product',
		title: 'Product'
	},
	projects: {
		component: 'Projects',
		url: '/projects',
		title: 'Projects'
	},
	inward: {
		component: 'InWard',
		url: '/inward',
		title: 'In Ward'
	},
	yetToReceive: {
		component: 'YetToReceive',
		url: '/inward/yetToReceive',
		title: 'Yet To Receive'
	},
	outward: {
		component: 'OutWard',
		url: '/outward',
		title: 'Out Ward'
	},
	quotations: {
		component: 'Quotations',
		url: '/quotations',
		title: 'Quotations'
	},
	quot_customerDetails: {
		component: 'QuotCustomerDetails',
		url: '/quotations/:add/customerDetails/:id/:quotId',
		title: 'QuotCustomerDetails'
	},
	contentBody: {
		component: 'ContentBody',
		url: '/quotations/:add/contentBody/:id/:quotId',
		title: 'ContentBody'
	},
	location: {
		component: 'Location',
		url: '/quotations/:add/location/:id/:quotId',
		title: 'Location'
	},
	quotBody: {
		component: 'QuotationBody',
		url: '/quotations/:add/quotationBody/:id/:quotId',
		title: 'QuotationBody'
	},
	quotFooter: {
		component: 'QuotFooter',
		url: '/quotations/:add/footer/:id/:quotId',
		title: 'QuotFooter'
	},
	quotTermsAndCondition: {
		component: 'QuotTermsAndConditions',
		url: '/quotations/:add/termsAndConditions/:id/:quotId',
		title: 'QuotTermsAndConditions'
	},
	preparedBy: {
		component: 'PreparedBy',
		url: '/quotations/:add/prepared/:id/:quotId',
		title: 'PreparedBy'
	},
	gst: {
		component: 'GstForm',
		url: '/gst',
		title: 'Gst'
	},
	reference: {
		component: 'ReferenceId',
		url: '/reference-id',
		title: 'ReferenceId'
	},
	unit: {
		component: 'Unit',
		url: '/unit',
		title: 'Unit'
	},
	group: {
		component: 'Group',
		url: '/group',
		title: 'Group'
	},
	content: {
		component: 'Content',
		url: '/content-of-letter',
		title: 'Content'
	},
	termsandconditions: {
		component: 'TermsAndConditions',
		url: '/terms-conditions',
		title: 'Terms And Conditions'
	},
	reports: {
		component: 'staffExecutive',
		url: '/sale_executive',
		title: 'staffExecutive'
	},
	customer_exe: {
		component: 'CustomerExecutive',
		url: '/sale_executive/customer_executive',
		title: 'Customer Executive'
	},
	purchase_exe: {
		component: 'PurchaseOrder',
		url: '/sale_executive/purchase_order',
		title: 'Purchase Order'
	},
	executivepage: {
		component: 'ExecutivePage',
		url: '/sale_executive/executivepage/:id/:name',
		title: 'Executive Page'
	},
	select_template: {
		component: 'SelectTemplate',
		url: '/quotations/select-template',
		title: 'SelectTemplate'
	},
	create_quotation: {
		component: 'CreateQuotation',
		url: '/create-quotation/:templateId',
		title: 'CreateQuotation'
	},
	view_quotation: {
		component: 'ViewQutation',
		url: '/view-quotation',
		title: 'ViewQutation'
	},
	new_quotation: {
		component: 'NewQuotation',
		url: '/new-quotation/:quotationId/:templateId',
		title: 'New Quotation'
	},
	vendorpo: {
		component: 'VendorPo',
		url: '/vendor_po',
		title: 'Vendor Po'
	},
	customerDetails: {
		component: 'CustomerDetails',
		url: '/bill_of_quantity/customer_details/:id',
		title: 'Customer Details'
	},
	boqProductDetails: {
		component: 'BoqProductDetailsForm',
		url: '/bill_of_quantity/product_details/:id',
		title: 'Boq Product Details'
	},
	boqFooter: {
		component: 'BoqFooter',
		url: '/purchase_order/boq/footer/:id',
		title: 'Footer'
	},
	boqTermsAndConditions: {
		component: 'BoqTermsAndConditions',
		url: '/purchase_order/boq/termsAndConditions/:id',
		title: 'Terms And Conditions'
	},
	billofquantity: {
		component: 'BillOfQuantity',
		url: '/bill_of_quantity',
		title: 'Bill Of Quantity'
	},
	vendordetails: {
		component: 'VendorDetails',
		url: '/vendor_po/vendor-detail/:id',
		title: 'Vendor Details'
	},
	productdetails: {
		component: 'ProductDetails',
		url: '/vendor_po/product-detail/:id',
		title: 'Product Details'
	},
	footer: {
		component: 'Footer',
		url: '/vendor_po/footer/:id',
		title: 'Footer'
	},
	termsAndConditions: {
		component: 'PoTermsAndConditions',
		url: '/vendor_po/termsAndConditions/:id',
		title: 'Terms And Conditions'
	},
	oA: {
		component: 'OrderAcceptence',
		url: '/oa',
		title: 'Order Acceptence'
	},
	OaReport: {
		component: 'OaReport',
		url: '/oa/oa_report',
		title: 'Oa Report'
	},
	OaConfirmPage: {
		component: 'OaConfirmPage',
		url: '/oa/oaConfirmPage',
		title: 'Oa Confirm Page'
	},
	OaDownload: {
		component: 'OaDownload',
		url: '/oa/oaDownload',
		title: 'Oa Download'
	},
	OrderAcceptenceMS: {
		component: 'OrderAcceptenceMS',
		url: '/oa/ms',
		title: 'MS'
	}


};

const modules = {
	0: {
		title: 'Dashboard',
		icon: 'home',
		isExpendable: true
	}
};

const rolesConfig = {
	admin: {
		routes: [
			components.dashboard,
			components.customers,
			components.staff,
			components.vendor,
			components.templates,
			components.product,
			components.quotations,
			components.gst,
			components.reference,
			components.unit,
			components.group,
			components.content,
			components.termsandconditions,
			components.customerDetails,
			components.boqProductDetails,
			components.quot_customerDetails,
			components.contentBody,
			components.location,
			components.quotFooter,
			components.quotTermsAndCondition,
			components.preparedBy,
			components.quotBody,
			components.reports,
			components.customer_exe,
			components.purchase_exe,
			components.select_template,
			components.create_quotation,
			components.view_quotation,
			components.new_quotation,
			components.projects,
			components.inward,
			components.yetToReceive,
			components.outward,
			components.vendorpo,
			components.billofquantity,
			components.vendordetails,
			components.productdetails,
			components.footer,
			components.termsAndConditions,
			components.executivepage,
			components.boqFooter,
			components.boqTermsAndConditions,
			components.oA,
			components.OaReport,
			components.OaConfirmPage,
			components.OaDownload,
			components.OrderAcceptenceMS
		]
	},
	staff: {
		routes: [
			components.dashboard,
			components.customers,
			components.vendor,
			components.templates,
			components.product,
			components.quotations,
			components.customerDetails,
			components.boqProductDetails,
			components.quot_customerDetails,
			components.contentBody,
			components.location,
			components.quotFooter,
			components.quotTermsAndCondition,
			components.preparedBy,
			components.quotBody,
			components.reports,
			components.customer_exe,
			components.purchase_exe,
			components.select_template,
			components.create_quotation,
			components.view_quotation,
			components.new_quotation,
			components.projects,
			components.inward,
			components.yetToReceive,
			components.outward,
			components.vendorpo,
			components.billofquantity,
			components.vendordetails,
			components.productdetails,
			components.footer,
			components.termsAndConditions,
			components.executivepage,
			components.boqFooter,
			components.boqTermsAndConditions,
			components.oA,
			components.OaReport,
			components.OaConfirmPage,
			components.OaDownload,
			components.OrderAcceptenceMS
		]
	},
	common: {
		routes: [
			components.dashboard
		]
	}
};

export { modules, rolesConfig };