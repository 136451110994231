import React, { Fragment } from 'react';
import { Row, Card, OverlayTrigger, Tooltip, Form, Spinner, Modal } from "react-bootstrap";
import Re from "../../../assets/images/green2.svg";
import Art from "../../../assets/images/art.svg";
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from "react-redux";
import { exportToJson, filterDateFormat, oaMs, oaMsCsv, oaClearMsCsv, shortStr } from '../../../actions';
import { Link } from "react-router-dom";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import Search from "../../../assets/images/search.svg";
import { useState } from 'react';
import { useEffect } from 'react';
import InwardMsProducts from './inward';


const role = localStorage.getItem('userRole');

const OrderAcceptenceMS = (props) => {
    const { oaData, loading, oaAll, oaMsDispatch, onMsCsvDispatch, csvData, onClearMsCsvDispatch } = props;

    const [pageNo, setPageNo] = useState(0);
    const [searchKey, setSearchKey] = useState('');
    const [inwardPopup, setInwardPopUp] = useState(false);
    const [projectProductId, setSelectedProjectProductId] = useState(null);

    const [initialValue, setInitialValue] = useState({
        pageNumber: pageNo,
        pageSize: 10,
        searchKey: searchKey
    })

    const onFetchListTickets = (values) => {
        oaMsDispatch(values);
    }

    const onChange = () => {
        initialValue.pageNumber = pageNo + 1;
        setInitialValue(initialValue);
        setPageNo(pageNo + 1);
    }

    const csv = () => {
        onMsCsvDispatch({ pageNumber: pageNo, pageSize: 10, searchKey: searchKey })
    }

    const handleInward = (item) => {
        setSelectedProjectProductId(item.projectProductId);
        setInwardPopUp(true);
    }

    useEffect(() => {
        onFetchListTickets(initialValue);
    }, [pageNo, searchKey])

    useEffect(() => {
        if (csvData) {
            exportToJson(csvData, 'MS Inward CSV')
        }
        setTimeout(() => {
            onClearMsCsvDispatch();
        }, 1000);
    }, [csvData])

    const searchHandler = (e) => {
        initialValue.searchKey = e.target.value;
        let data = { ...initialValue };
        data.searchKey = e.target.value;
        data.pageNumber = e.target.value !== "" ? 0 : initialValue.pageNumber;
        setInitialValue(data);
        setSearchKey(e.target.value);
        setPageNo(0);
    }

    return (
        <Fragment>
            <Navbar />
            <div className="dashboard-page">
                <div className="dashboard-sidebar">
                    <Sidebar />
                </div>
                <div className="dashboard-main">
                    <Card className="staff-page">
                        <div className="page-head-split">
                            <div className="head-colums">
                                <div className="head-part-tab">
                                    <img src={Re} alt="..." className="head-imgs" />
                                    <h5>MS</h5>
                                </div>
                                <div className="tab-links tab-down">
                                    <Link to={"/" + role + "/oa"}>OA Details</Link>
                                    <Link to={"/" + role + "/oa/oa_report"} >PSO</Link>
                                    <Link to={"/" + role + "/oa/oaConfirmPage"}>MC</Link>
                                    <Link to={"/" + role + "/oa/oaDownload"}>MC Report</Link>
                                    <Link to={"/" + role + "/oa/ms"} className="active">MS</Link>
                                </div>
                                <div className="customer-search oa-search">
                                    <span><img src={Search} alt=".." className="search-img" /></span>
                                    <Form.Control type="search" placeholder="Search"
                                        onChange={searchHandler}
                                        className="main-search" />
                                </div>
                                <button className="download_btn" onClick={csv}>
                                    <img src={Art} alt=".." /> CSV
                                </button>
                            </div>
                        </div>
                        <div className="infinite-table" id="scrollableDiv">
                            <InfiniteScroll
                                dataLength={oaAll?.content?.length}
                                next={onChange}
                                hasMore={oaAll?.content?.length < oaData?.totalElements}
                                loader={<p className='txt-center'><h4>Loading...</h4></p>}
                                endMessage={<p className='txt-center'>{oaAll?.content?.length === 0 ? <p className='no-data-hg'> <b >{loading ? <Spinner /> : "No Data Found"}</b></p> : <b>Yay! You have seen it all</b>}</p>}
                                refreshFunction={() => { }}
                                pullDownToRefresh
                                scrollableTarget="scrollableDiv"
                                pullDownToRefreshThreshold={60}
                                pullDownToRefreshContent={<h3 className='txt-center'>&#8595; Pull down to refresh</h3>}
                                releaseToRefreshContent={<h3 className='txt-center'>&#8593; Release to refresh</h3>}
                            >
                                <table class="table table-borderless uniq-table">
                                    <thead>
                                        <tr>
                                            <th>PO No</th>
                                            <th>PO Date</th>
                                            <th>OA No</th>
                                            <th>OA Date</th>
                                            <th>Customer</th>
                                            <th>Project</th>
                                            <th>Art No</th>
                                            <th>Product Name</th>
                                            <th>Con.Qty</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {oaAll?.content?.map((item, a) => (
                                            <tr onClick={() => handleInward(item)} key={a} style={{ cursor: "pointer" }}>
                                                <td>{shortStr(item?.poNumber)}</td>
                                                <td>{filterDateFormat(item?.poGeneratedDate)}</td>
                                                <td>
                                                    <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {item?.oaNumber}</Tooltip>}>
                                                        <span className="d-inline-block value-add">
                                                            {item?.oaNumber}
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                                <td>{filterDateFormat(item?.oaDate)}</td>
                                                <td>
                                                    <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {item?.customerName}</Tooltip>}>
                                                        <span className="d-inline-block value-add">
                                                            {item?.customerName}
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                                <td>
                                                    <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {item?.projectName}</Tooltip>}>
                                                        <span className="d-inline-block value-add">
                                                            {item?.projectName}
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                                <td>{item?.articleId || ""}</td>
                                                <td className='product_name'>
                                                    <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {item?.productName}</Tooltip>}>
                                                        <span className="d-inline-block">
                                                            {item?.productName}
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                                <td className='ms-cell-txt-center'>{item?.oaConfirmedQuantity}</td>
                                                <td>
                                                    <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {item?.remark}</Tooltip>}>
                                                        <span className="d-inline-block value-add">
                                                            {item?.remark}
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        </div>
                    </Card>
                </div>
                <InwardMsProducts
                    show={inwardPopup}
                    id={projectProductId}
                    onHide={() => {
                        oaMsDispatch(initialValue);
                        setInwardPopUp(false);
                    }}
                />
            </div>
        </Fragment>
    );

}

const mapStateToProps = (state) => {
    const { oa } = state;
    return {
        csvData: oa.csvDataDown,
        oaData: oa.oaAll,
        oaAll: { content: oa.newData },
        loading: oa.loading,
    }
}

const mapDispatchToProps = (dispatch) => ({
    oaMsDispatch: (formData) => dispatch(oaMs(formData)),
    onMsCsvDispatch: (formData) => dispatch(oaMsCsv(formData)),
    onClearMsCsvDispatch: () => dispatch(oaClearMsCsv())
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderAcceptenceMS)