import { getProduct } from "../actions";
import { outwardConstants, productConstants } from "../constants";

const initialState = {
    loading: false,
    load: false,
    data: [],
    error: [],
    token: "",
    success_msg: "",
    page: "create",
    singleProduct: null,
    bulkProducts: [],
    csvData: [],
    bulkData: null
};

export default function productReducer(state = initialState, { type, response }) {
    switch (type) {
        case productConstants.GET_PRODUCTS:
        case productConstants.GET_BLUK_PRODUCTS:
            return {
                ...state,
                loading: true,
            };
        case productConstants.GET_PRODUCT_DOWNLOAD:
            return {
                ...state,
                load: true,
            };
        case productConstants.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                products_list: response.data.content,
                allData: response.data,
                groupProduct: "add"

            };
        case productConstants.GET_PRODUCTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
                success_msg: "",
            };
        case productConstants.GET_PRODUCT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                load: false,
                csvData: response,
            };

        case productConstants.CREATE_PRODUCT_INIT:
        case productConstants.UPDATE_PRODUCT_INIT:
        case productConstants.UPDATE_BULK_INIT:
        case productConstants.ADD_STOCK:
            return {
                ...state,
                loading: true,
                success_msg: "",
            };
        case productConstants.ADD_STOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                success_msg: response,
            };
        case productConstants.CREATE_PRODUCT_SUCCESS:
        case productConstants.UPDATE_PRODUCT_SUCCESS:
        case productConstants.UPDATE_BULK_SUCCESS:
            return {
                ...state,
                loading: false,
                success_msg: response,
            };
        case productConstants.CREATE_PRODUCT_FAILURE:
        case productConstants.UPDATE_PRODUCT_FAILURE:
        case productConstants.ADD_STOCK_FAILURE:
        case productConstants.UPDATE_BULK_FAILURE:
        case productConstants.GET_PRODUCT_DOWNLOAD_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
                load: false
            };

        case productConstants.GET_PRODUCT_UNIT:
            return {
                ...state,
                loading: true,
            };
        case productConstants.GET_PRODUCT_UNIT_SUCCESS:
            return {
                ...state,
                loading: false,
                product_units: response.data,
            };
        case productConstants.GET_BLUK_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                bulkProducts: response.data,
            };
        case productConstants.GET_PRODUCT_UNIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case productConstants.GET_SINGLE_PRODUCT:
            return {
                ...state,
                loading: true,
                page: "",
                groupProduct: "add"
            };
        case productConstants.GET_SINGLE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                singleProduct: response.data,
                groupProduct: "get",
                page: "edit",
                img: response.data.imageUrl
            };
        case productConstants.GET_SINGLE_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case productConstants.GET_PRODUCTS_HISTORY:
            return {
                ...state,
                loading: true,
            };
        case productConstants.GET_PRODUCTS_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                product_history: response.data,
            };

        case productConstants.GET_PRODUCTS_HISTORY_FAILURE:
        case productConstants.GET_BLUK_PRODUCTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case productConstants.CLEAR_SINGLE_PRODUCT:
        case productConstants.CLEAR_STOCK_PRODUCT:
        case outwardConstants.CHANGE_ADD_PAGE:
            return {
                ...state,
                singleProduct: null,
            };
        case productConstants.CLEAR_CSV_DATA:
            return {
                ...state,
                csvData: [],
            };
        case productConstants.GET_STOCK:
            return {
                ...state,
                singleProduct: getProduct(),
            };
        case productConstants.CHANGE_PAGE:
            return {
                ...state,
                loading: false,
                page: "create",
                img: ""
            };

        // TODO: Product Bulk upload download

        case productConstants.GET_BULK_DOWNLOAD:
            return {
                ...state,
                loading: true,
            };
        case productConstants.GET_BULK_DOWNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                bulkData: response,
            };
        case productConstants.GET_BULK_DOWNLOAD_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case productConstants.CLEAR_SUCCESS_MSG:
            return {
                ...state,
                success_msg: ""
            }
        default:
            return state;
    }
}
