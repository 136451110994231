import { authToken, baseUrl } from "../actions";

export const getProjectsApi = (pageNo, searchKey, sort = {}) => {
    var PROJECTS_LIST;
    if (Object.keys(sort).length === 2) {
        PROJECTS_LIST = `${baseUrl}staff/purchaseOrder/purchaseOrderProjects?sortField=${sort.sortField}&sortMethod=${sort.sortType}&page=${pageNo}&searchKey=${searchKey}`;
    } else {
        PROJECTS_LIST = searchKey !== undefined ? `${baseUrl}staff/purchaseOrder/purchaseOrderProjects?searchKey=${searchKey}&page=${pageNo}` : `${baseUrl}staff/purchaseOrder/purchaseOrderProjects?page=${pageNo}`;
    }

    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(PROJECTS_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}


export const createProjectsApi = (request) => {
    const CREATE_PROJECTS = `${baseUrl}staff/projects`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_PROJECTS, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}


export const getOneProjectsApi = (purchaseOrderNumber) => {
    const PROJECTS_LIST = `${baseUrl}staff/projects/${purchaseOrderNumber}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(PROJECTS_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export const updateProjectsApi = (request) => {
    const UPDATE_PROJECTS = `${baseUrl}staff/projects`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(UPDATE_PROJECTS, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
