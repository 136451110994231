import { put, call, takeEvery } from "redux-saga/effects";
import { reportOaConstants } from "../constants";
import { getReportOaApi, getOaConfirmApi, getReportOaListApi, lisReportSubMenuApi, oaDownloadApi, addReportOaFormListApi, getOaCsvDownload,getOaReportCsvDownload, getOaConfirmPdfView, oaCsvDownloadApi, oaMsApi, oaMsCsvDownloadApi, onOaMsInwardApi, addMsPoInwardProductsApi } from "../services";

export function* reportPurchaseSaga(payload) {
    try {
        const response = yield call(getReportOaApi, payload);
        yield put({ type: reportOaConstants.GET_REPORT_OA_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportOaConstants.GET_REPORT_OA_FAILURE, error });
    }
}

export function* reportConfirmSaga(payload) {
    try {
        const response = yield call(getOaConfirmApi, payload);
        yield put({ type: reportOaConstants.GET_REPORT_CONFIRM_OA_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportOaConstants.GET_REPORT_CONFIRM_OA_FAILURE, error });
    }
}

export function* reportOaMsSaga(payload) {
    try {
        const response = yield call(oaMsApi, payload);
        yield put({ type: reportOaConstants.GET_REPORT_OA_MS_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportOaConstants.GET_REPORT_OA_MS_FAILURE, error});
    }
}

export function* reportListSaga(payload) {
    try {
        const response = yield call(getReportOaListApi, payload);
        yield put({ type: reportOaConstants.GET_REPORT_OA_PAGE_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportOaConstants.GET_REPORT_OA_PAGE_FAILURE, error });
    }
}

export function* addMsPoInwardProductsSaga(payload) {
    try {
        const response = yield call(addMsPoInwardProductsApi, payload);
        yield put({ type: reportOaConstants.CREATE_PO_MS_INWARD_PRODUCTS_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportOaConstants.CREATE_PO_MS_INWARD_PRODUCTS_FAILURE, error });
    }
}

export function* reportAddSaga(payload) {
    try {
        const response = yield call(addReportOaFormListApi, payload);
        yield put({ type: reportOaConstants.GET_REPORT_OA_PAGE_FORM_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportOaConstants.GET_REPORT_OA_PAGE_FORM_FAILURE, error });
    }
}

export function* reportSubMenuSaga(payload) {
    try {
        const response = yield call(lisReportSubMenuApi, payload);
        yield put({ type: reportOaConstants.GET_REPORT_OA_SUCCESS_REPORT, response });
    } catch (error) {
        yield put({ type: reportOaConstants.GET_REPORT_OA_FAILURE_REPORT, error });
    }
}

export function* oaConfCsv(payload) {
    try {
        const response = yield call(getOaCsvDownload, payload);
        yield put({ type: reportOaConstants.DOWNLOAD_CSV_REPORT_OA_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportOaConstants.DOWNLOAD_CSV_REPORT_OA_FAILURE, error });
    }
}

export function* oaReportConfCsv(payload) {
    try {
        const response = yield call(getOaReportCsvDownload, payload);
        yield put({ type: reportOaConstants.DOWNLOAD_CSV_REPORT_OA_REPORT_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportOaConstants.DOWNLOAD_CSV_REPORT_OA_REPORT_FAILURE, error });
    }
}

export function* oaPdfViewSaga(payload) {
    try {
        const response = yield call(getOaConfirmPdfView, payload);
        yield put({ type: reportOaConstants.OA_PDF_REPORT_OA_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportOaConstants.OA_PDF_REPORT_OA_FAILURE, error });
    }
}

export function* oaDownloadSaga(payload) {
    try {
        const response = yield call(oaDownloadApi, payload.formData);
        yield put({ type: reportOaConstants.OA_PDF_DOWNLOAD_OA_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportOaConstants.OA_PDF_DOWNLOAD_OA_FAILURE, error });
    }
}


export function* oaCsvDownloadSaga(payload) {
    try {
        const response = yield call(oaCsvDownloadApi, payload);
        yield put({ type: reportOaConstants.OA_CSV_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportOaConstants.OA_CSV_FAILURE, error });
    }
}

export function* onMsCsvDownloadSaga(payload) {
    try {
        const response = yield call(oaMsCsvDownloadApi, payload);
        yield put({ type: reportOaConstants.GET_REPORT_OA_MS_CSV_SUCCESS, response});
    }catch (error) {
        yield put({ type: reportOaConstants.GET_REPORT_OA_MS_CSV_FAILURE, error });
    }
}

export function* onOaMsInwardSaga(payload) {
    try {
        const response = yield call(onOaMsInwardApi, payload);
        yield put({ type: reportOaConstants.OA_MS_INWARD_SUCCESS, response});
    }catch (error) {
        yield put({ type: reportOaConstants.OA_MS_INWARD_FAILURE, error });
    }
}


export default function* reportOa() {
    yield takeEvery(reportOaConstants.GET_REPORT_OA, reportPurchaseSaga);
    yield takeEvery(reportOaConstants.GET_REPORT_CONFIRM_OA, reportConfirmSaga);
    yield takeEvery(reportOaConstants.GET_REPORT_OA_PAGE, reportListSaga);
    yield takeEvery(reportOaConstants.GET_REPORT_OA_PAGE_FORM, reportAddSaga);
    yield takeEvery(reportOaConstants.GET_REPORT_OA_REPORT, reportSubMenuSaga);
    yield takeEvery(reportOaConstants.DOWNLOAD_CSV_REPORT_OA, oaConfCsv);
    yield takeEvery(reportOaConstants.DOWNLOAD_CSV_REPORT_OA_REPORT, oaReportConfCsv);
    yield takeEvery(reportOaConstants.OA_PDF_REPORT_OA, oaPdfViewSaga);
    yield takeEvery(reportOaConstants.OA_PDF_DOWNLOAD_OA, oaDownloadSaga);
    yield takeEvery(reportOaConstants.OA_CSV, oaCsvDownloadSaga);
    yield takeEvery(reportOaConstants.GET_REPORT_OA_MS, reportOaMsSaga);
    yield takeEvery(reportOaConstants.GET_REPORT_OA_MS_CSV, onMsCsvDownloadSaga);
    yield takeEvery(reportOaConstants.OA_MS_INWARD, onOaMsInwardSaga);
    yield takeEvery(reportOaConstants.CREATE_PO_MS_INWARD_PRODUCTS, addMsPoInwardProductsSaga);
}