import { authConstants } from "../constants";

const initialState = {
  loading: false,
  data: [],
  error: [],
  token: "",
  status: 0,
  allData: []
};

export default function authReducer(state = initialState, { type, response }) {
  switch (type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        Password : ''
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: response.data,
        status: response.status,
        allData: response,
        Password : ''
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: response,
      };
    case authConstants.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        status: 0
      };
    case authConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: response,
        status: response.status,
      };
    case authConstants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: response,
      };

    default:
      return state;
  }
}
