import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import validate from "./validate";
import "antd/dist/antd.css";


const renderField = ({ input, placeholder, type, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input  {...input} placeholder={placeholder} type={type} className="form-control" />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
)


const SecondaryEmailForm = props => {
    const { error, show, onHide, page, reset, empty, handleSubmit, str,vendor_emails } = props;

    return (
        <>
            <Modal
                show={show}
                size="lg"
                onHide={() => {
                    reset();
                    onHide();
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <div className="modal-staff">
                    <div className="head">
                        <div>
                            <i class="fas fa-arrow-left" onClick={() => {
                                reset();
                                onHide();
                            }}></i>
                            <h4>{str ? "UPDATE" : "ADD NEW"} SECONDARY EMAIL</h4>
                        </div>
                    </div>
                    {/* <h4 className="customer-head">Vendor Details</h4> */}
                    <Row>
                        <form onSubmit={handleSubmit} className="customer">
                            <Row>
                                {["email0", "email1", "email2", "email3", "email4", "email5", "email6", "email7", "email8", "email9", "email10",
                                    "email11", "email12", "email13", "email14", "email15", "email16", "email17", "email18", "email19"].map((item, i) => (
                                        <Col md={4} key={i}>
                                            <label class="form-control-placeholder" for="name">Secondary Email {i + 1}</label>
                                            <Field
                                                type="text"
                                                name={item}
                                                component={renderField}
                                            />
                                        </Col>
                                    ))}
                                {error && <strong>{error}</strong>}
                                <Col md={3}></Col>
                                <Col md={6}> <br></br>
                                    {props.load ?
                                        <button type="submit" className="customer-btn">
                                            Loading...
                                        </button>
                                        : <button type="submit" className="customer-btn">
                                            {str ? "Update" : "Create"} Secondary Email
                                        </button>}
                                </Col>
                            </Row>
                        </form>
                    </Row>
                </div>
            </Modal>
        </>
    )
}

function mapStateToProps(state) {
    let page = state.vendor.page;
    let mails = state.vendor?.vendor_emails?.vendorEmails;
    let items = {
        "email0": "",
        "email1": "",
        "email2": "",
        "email3": "",
        "email4": "",
        "email5": "",
        "email6": "",
        "email7": "",
        "email8": "",
        "email9": "",
        "email10": "",
        "email11": "",
        "email12": "",
        "email13": "",
        "email14": "",
        "email15": "",
        "email16": "",
        "email17": "",
        "email18": "",
        "email19": "",
    };
    if (mails) {
        Object.keys(items).map(function (key, index) {
            items[key] = mails[index];
        });
    }
    let initValues = items;
    return {
        initialValues: initValues,
        vendor: state.vendor.vendor,
        status: state.vendor.status,
        page: state.vendor.page,
        load: state.vendor.load,
        vendor_emails: state.vendor.vendor_emails?.vendorEmails
    }
}

const SecondaryForm = reduxForm({
    form: "SecondaryEmailForm",
    enableReinitialize: true,
    validate
})(SecondaryEmailForm);

export default connect(mapStateToProps)(SecondaryForm);


