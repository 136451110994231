import { getCharges, getGst, getHeading, getTotal, setTotal, totalValue } from "../../../../actions";

const validate = values => {
    const errors = {}
    if (!values.company_address) {
        errors.company_address = 'Required'
    }
    if (!values.company_email) {
        errors.company_email = 'Required'
    }
    if (!values.first_name) {
        errors.first_name = 'Required'
    }
    if (!values.last_name) {
        errors.last_name = 'Required'
    }

    if (!values.groupName) {
        errors.groupName = 'Required'
    }
    if ((values.groupName && /[^a-zA-Z0-9 ]/i.test(values.groupName))) {
        errors.groupName = "Only alphanumeric characters allowed";
    }
    // /[^a-zA-Z\\s]/gi

    const requiredFields = [
        'unitId',
        'Quantity',
        // 'discount',
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Please fill the field *'
        }
    })

    // if (values.articleId && !/^(0|[1-9][0-9]{19})$/i.test(values.articleId)) {
    //     errors.articleId = 'Please Enter a 6 digit Article ID *'
    // }
    if (values.Quantity && /[^0-9 ]/i.test(values.Quantity)) {
        errors.Quantity = 'Enter valid digits';
    }
    if (values.discount && /[^0-9 .]/i.test(values.discount)) {
        errors.discount = 'Only digits allowed';
    }
    if (Number(values.discount) > 80) {
        errors.discount = 'discount must be less than 80';
    }



    if (!values.locations || !values.locations.length === 4) {
        errors.locations = { _error: 'At least one member must be entered' }
    } else {
        const membersArrayErrors = []
        values.locations.forEach((member, memberIndex) => {
            const memberErrors = {}
            if (!member || !member.area) {
                memberErrors.area = 'Required'
                membersArrayErrors[memberIndex] = memberErrors
            }
            // if (member.area && /[^a-zA-Z0-9- ]/i.test(member.area)) {
            //     memberErrors.area = 'Only albha numerics allowed'
            //     membersArrayErrors[memberIndex] = memberErrors
            // }
            // if (!member || !member.dimension) {
            //     memberErrors.dimension = 'Required';
            //     membersArrayErrors[memberIndex] = memberErrors;
            // } if (member.dimension && /[^a-zA-Z0-9- !@#$%*?]/i.test(member.dimension)) {
            //     memberErrors.dimension = 'Alphapets not allowed';
            //     membersArrayErrors[memberIndex] = memberErrors;
            // }
            // if (!member || !member.drawingReferenceNumber) {
            //     memberErrors.drawingReferenceNumber = 'Required'
            //     membersArrayErrors[memberIndex] = memberErrors
            // }
            // if (member.drawingReferenceNumber && /[^a-zA-Z0-9 ]/i.test(member.drawingReferenceNumber)) {
            //     memberErrors.drawingReferenceNumber = 'Only albha numerics allowed';
            //     membersArrayErrors[memberIndex] = memberErrors;
            // }


            // if (!member || !member.setCount) {
            //     memberErrors.setCount = 'Required'
            //     membersArrayErrors[memberIndex] = memberErrors
            // }
            if (member.setCount && /[^0-9 ]/i.test(member.setCount)) {
                memberErrors.setCount = 'Enter the value more than Zero..';
                membersArrayErrors[memberIndex] = memberErrors;
            } if (member.setCount == 0) {
                memberErrors.setCount = 'Please enter a value greater than zero';
                membersArrayErrors[memberIndex] = memberErrors;
            }
        })
        if (membersArrayErrors.length) {
            errors.locations = membersArrayErrors
        }
        return errors
    }


    if (!values.miscellaneousCharges || !values.miscellaneousCharges.length === 4) {
        errors.miscellaneousCharges = { _error: 'At least one member must be entered' }
    } else {
        const membersArrayErrors = []
        values.miscellaneousCharges.forEach((member, memberIndex) => {
            const memberErrors = {}
            if (!member || !member.heading) {
                memberErrors.heading = 'Required'
                membersArrayErrors[memberIndex] = memberErrors
            } 
            // if (member.heading && /[^a-zA-Z0-9- !@#$%*?]/i.test(member.heading)) {
            //     memberErrors.heading = 'Not valid'
            //     membersArrayErrors[memberIndex] = memberErrors
            // }
            if (!member || !member.amount) {
                memberErrors.amount = 'Required'
                membersArrayErrors[memberIndex] = memberErrors
            }
            if (member.amount && /[^0-9 ]/i.test(member.amount)) {
                memberErrors.amount = 'Only digits allowed';
                membersArrayErrors[memberIndex] = memberErrors;
            }
        })

        let arr = values.miscellaneousCharges.map((item) => Number(item.charges)).reduce((acc, cur) => {
            return acc + cur;
        }, 0);
        if (!isNaN(arr)) {
            let total = arr + (arr * (getGst() / 100));
            setTotal(total);
        }

        if (membersArrayErrors.length) {
            errors.miscellaneousCharges = membersArrayErrors
        }
        return errors
    }



    return errors
}

export default validate