import React, { useRef } from "react";
import { Modal, Row } from "react-bootstrap";
import copy from "../../assets/images/copy.svg";
import edit from "../../assets/images/edit.svg";
import back from "../../assets/images/out.svg";
import check from "../../assets/images/check.svg";
import download from "../../assets/images/download.svg";
import trash from "../../assets/images/trash1.svg";
import circle from "../../assets/images/x-circle.svg";
import file from "../../assets/images/file.svg";
import Rou from "../../assets/images/rou.gif";
import { reduxForm } from 'redux-form';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import "../../assets/css/view.css";
import { downloadQuotationCsv, exportToJson, clearQuotationCsv } from '../../actions'
import Logo from "../../assets/images/main.svg";
import { useEffect } from "react";
const authRole = localStorage.getItem('userRole');

function View(props) {
    const componentRef = useRef();

    const { show, onHide, data, preview, onQuotationCsvDownloadDispatch,clearCsvDispatch, pdf, edited, revised, quotationCsvData, confirmed, changed, load  } = props;

    const appLogout = () => {
        if (data.status === "GENERATED" || data.status === "CANCELLED") {
            props.editHandler();
        } else {
            window.location.hash = `/${authRole}/quotations/edit-quotation/customerDetails/${data?.template_id}/${data.quotationId}`;
            // window.location.hash = "/" + authRole + '/new-quotation/' + data.quotationId + '/' + data.template_id;
        }
    }

    const csv = () => {
        onQuotationCsvDownloadDispatch({
            id: data.quotationId
        })
    }

    const confirmHandler = () => {
        if (!changed) {
            props.confrimHandler();
        } else {
            props.boqShow();
        }
    }
    const openPDF = (resData, fileName) => {
        var ieEDGE = navigator.userAgent.match(/Edge/g);
        var ie = navigator.userAgent.match(/.NET/g); // IE 11+
        var oldIE = navigator.userAgent.match(/MSIE/g);
        // var bytes = new Uint8Array(resData); //use this if data is raw bytes else directly pass resData
        // var blob = new window.Blob([bytes], {type: 'application/pdf' });

        if (ie || oldIE || ieEDGE) {
            window.navigator.msSaveBlob(resData, fileName);
        }
        else {
            if (resData) {
                var fileURL = URL.createObjectURL(resData);
                return fileURL;
            }
        }
    }

    let url = "";

    if (pdf !== "") {
        url = openPDF(pdf, "hello");
    }

    useEffect(() => {
        if(quotationCsvData !== null) {
            exportToJson(quotationCsvData, "Quotation Csv")
        }
        setTimeout(() => {
            clearCsvDispatch();
        }, 1000);
    },[quotationCsvData])


    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-staff preview">
                    <div className="modal-staff head">
                        <div>
                            <div onClick={onHide}>
                                <i class="fas fa-arrow-left"></i>
                            </div>
                            <h4 className="mod_head">
                                {data?.referenceId || data?.quotationReferenceId || "Q-1421"}
                            </h4>
                        </div>
                        <div>
                            <div className={data?.status === "GENERATED" || data?.status === "CANCELLED" || data?.status === "CONFIRMED" ? "pre" : "prepare"}>
                                <p> Prepared By </p>
                                <p style={{ fontSize: "15px", color: "black" }}>  {data?.createdBy || data?.issuedStaff || "john doe"}</p>
                            </div>
                            {data?.status === "CONFIRMED" ? < button className="select-template convert_boq" onClick={() => props.boqShow()}>
                                Convert Quote to BOQ
                            </button> : null}
                            {data?.status === "GENERATED" ? < button className={!changed ? "select-templatess" : "select-template convert_boq"} onClick={() => confirmHandler()}>
                                {!changed ? "Confirm Quotation" : "Convert Quote to Boq"}
                            </button> : null}

                            {(data?.status === "GENERATED" && data?.status !== "CONFIRMED" && !confirmed) ? < button className="select-template delete_quot cancel" onClick={() => {
                                props.statusHandler();
                            }
                            }>
                                <img src={circle} alt="" /> Cancel Quotation
                            </button> : null}
                            {data?.status === "DRAFT" ? <button type="button" className="select-template generate" onClick={props.generate} >
                                <img src={check} alt="" />   Generate
                            </button> : null}
                            {/* {data?.status === "GENERATED" || data?.status === "CANCELLED" ? 
                            <a href={url} target="_self"  download>
                                <button type="button" className="select-template download" >
                                    <img src={download} alt="" />   Download
                                </button> </a> : null} */}
                            {data?.status === "DRAFT" ?
                                <ReactToPrint
                                    trigger={() => <button className="select-template print-view"> <i className="fas fa-eye" /> Print Preview</button>}
                                    content={() => componentRef.current}
                                /> : null}
                            {data?.status === "GENERATED" || data?.status === "CANCELLED" || data?.status === "CONFIRMED" || data?.status === "REVISED" || data?.status === "BOQ_GENERATED" ?
                                <ReactToPrint
                                    trigger={() => <button className="select-template download"> <img src={download} alt="" /> Download</button>}
                                    content={() => componentRef.current}
                                /> : null}
                            {data?.status === "DRAFT" ? <button className="select-template delete_quot" onClick={() => props.deleteHandler(data)}>
                                <img src={trash} alt="" />   Delete Quotation
                            </button> : null}
                            {data?.status === "GENERATED" || data?.status === "CANCELLED" || data?.status === "CONFIRMED" || data?.status === "REVISED" || data?.status === "BOQ_GENERATED" || data?.status === "DRAFT" ?
                                !edited ? <button type="button" className="select-template clone" onClick={() => props.cloneHandler(data)}>
                                    <img src={copy} alt="" />   Clone Quotation
                                </button> : <button className="select-template clone">Loading...</button>
                                : null
                            }
                            {data?.status !== "REVISED" && data?.status !== "CONFIRMED" && data?.status !== "BOQ_GENERATED" && !revised ? <button className="select-template edit_quot" style={{ marginTop: "15px" }} onClick={() => appLogout()}>
                                <img src={data?.status === "GENERATED" || data?.status === "CANCELLED" ? file : edit} alt="" />
                                {data?.status === "GENERATED" || data?.status === "CANCELLED" ? "Revise Quotation" : "Edit Quotation"}
                            </button> : data?.status !== "REVISED" && data?.status !== "BOQ_GENERATED" && data?.status !== "CONFIRMED" && <button className="select-template edit_quot" style={{ marginTop: "15px" }}>Loading....</button>}
                            {(data?.template_id == 1 || data?.template_id == 2 || data?.template_id == 3 )? load ? <button className="select-template csv-download"> <img src={Rou} alt="" /> CSV </button> :
                                <button className="select-template csv-download" onClick={csv}> <img src={download} alt="" /> CSV </button> : null}
                            <img src={back} alt="" onClick={onHide} className="back_img" style={{ marginTop: "5px" }} />
                        </div>
                    </div>
                    {/* <ReactToPrint
                        trigger={() => <button className="print">Print</button>}
                        content={() => componentRef.current}
                    />
                    <Pdf targetRef={componentRef} filename="code-example.pdf">
                        {({ toPdf }) => <button onClick={toPdf} className="print">Generate Pdf</button>}
                    </Pdf> */}
                    <Row className="preview-img " style={{ minHeight: "700px" }}>
                        <div className="go-hr"></div>
                        {preview !== undefined ? <div className="template-two-design" ref={componentRef} dangerouslySetInnerHTML={{ __html: `${preview}` }} /> :
                            <div>
                                <img src={Logo} alt=".." className="spin rt-imgss" width="100px" />
                                <h2 className="rt-img">Please Wait...</h2>
                            </div>}
                    </Row>
                </div>
            </Modal>
        </>
    )
}

function mapStateToProps(state) {
    return {
        initialValues: state.qutation.quotationData,
        previewQutationTxt: state.qutation.previewQutation,
        quotationCsvData: state.qutation.quotationCsvData
    }
}

const mapDispatchToProps = (dispatch) => ({
    onQuotationCsvDownloadDispatch: (formData) => dispatch(downloadQuotationCsv(formData)),
    clearCsvDispatch: (formData) => dispatch(clearQuotationCsv(formData)),
})

const Form = reduxForm({
    form: "ViewForm",
    enableReinitialize: true,
    destroyOnUnmount: false,
})(View);

export default connect(mapStateToProps,mapDispatchToProps)(Form);