import { call, put, takeEvery } from "redux-saga/effects";
import { packageConstants } from "../constants";
import { addPackageProductApi, createInitialPackageApi, editPackageProductApi, getPackageListApi, getPackageProductsDropdownApi, getProductListByPackageIdApi, getProductsDropdownApi, updatePackageApi } from "../services/packageService";

export function* createInitialPackageSaga(payload) {
    try {
        const response = yield call(createInitialPackageApi, payload);
        yield put({type: packageConstants.GET_INITIAL_PACKAGE_ID_SUCCESS, response});
    } catch(error) {
        yield put({type: packageConstants.GET_INITIAL_PACKAGE_ID_FAILURE, error});
    }
}

export function* editPackageSaga(payload) {
    try {
        const response = yield call(updatePackageApi, payload);
        yield put({type: packageConstants.EDIT_PACKAGE_SUCCESS, response});
    } catch(error) {
        yield put({type: packageConstants.EDIT_PACKAGE_FAILURE, error});
    }
}

export function* getPackageListSaga(payload) {
    try {
        const response = yield call(getPackageListApi, payload);
        yield put({type: packageConstants.GET_PACKAGE_LIST_SUCCESS, response});
    } catch(error) {
        yield put({type: packageConstants.GET_PACKAGE_LIST_FAILURE, error});
    }
}

export function* addProductForPackageSaga(payload) {
    try {
        const response = yield call(addPackageProductApi, payload);
        yield put({type: packageConstants.ADD_PACKAGE_PRODUCT_SUCCESS, response});
    } catch(error) {
        yield put({type: packageConstants.ADD_PACKAGE_PRODUCT_FAILURE, error});
    }
}

export function* editProductForPackageSaga(payload) {
    try {
        const response = yield call(editPackageProductApi, payload);
        yield put({type: packageConstants.EDIT_PACKAGE_PRODUCT_SUCCESS, response});
    } catch(error) {
        yield put({type: packageConstants.EDIT_PACKAGE_PRODUCT_FAILURE, error});
    }
}

export function* getProductsForPackageIdSaga(payload) {
    try {
        const response = yield call(getProductListByPackageIdApi, payload);
        yield put({type: packageConstants.GET_PRODUCTS_FOR_PACKAGE_SUCCESS, response});
    } catch(error) {
        yield put({type: packageConstants.GET_PRODUCTS_FOR_PACKAGE_FAILURE, error});
    }
}

export function* getPackageProductsDropdownSaga(payload) {
    try {
        const response = yield call(getPackageProductsDropdownApi, payload);
        yield put({type: packageConstants.GET_PACKAGE_PRODUCT_DROPDOWN_SUCCESS, response});
    } catch(error) {
        yield put({type: packageConstants.GET_PACKAGE_PRODUCT_DROPDOWN_FAILURE, error});
    }
}

export function* getProductsDropdownSaga(payload) {
    try {
        const response = yield call(getProductsDropdownApi, payload);
        yield put({type: packageConstants.GET_PRODUCT_DROPDOWN_SUCCESS, response});
    } catch(error) {
        yield put({type: packageConstants.GET_PRODUCT_DROPDOWN_FAILURE, error});
    }
}

export default function* packageSaga() {
    yield takeEvery(packageConstants.GET_INITIAL_PACKAGE_ID, createInitialPackageSaga);
    yield takeEvery(packageConstants.GET_PACKAGE_LIST, getPackageListSaga);
    yield takeEvery(packageConstants.ADD_PACKAGE_PRODUCT, addProductForPackageSaga);
    yield takeEvery(packageConstants.EDIT_PACKAGE_PRODUCT, editProductForPackageSaga);
    yield takeEvery(packageConstants.GET_PRODUCTS_FOR_PACKAGE, getProductsForPackageIdSaga);
    yield takeEvery(packageConstants.GET_PACKAGE_PRODUCT_DROPDOWN, getPackageProductsDropdownSaga);
    yield takeEvery(packageConstants.GET_PRODUCT_DROPDOWN, getProductsDropdownSaga);
    yield takeEvery(packageConstants.EDIT_PACKAGE, editPackageSaga);
}