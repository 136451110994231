import React from "react";
import { Modal, Col, Row, Form } from "react-bootstrap";
import { toast } from "react-toastify";


const UnitForm = props => {
  const { error, onSubmit, show, onHide, str, initialValues } = props;

  let status;
  let unitName;
  if (str === "add") initialValues.unitName = "";

  const statusHandler = e => {
    status = e.target.value;
    if (status === "true") {
      status = true;
    } else {
      status = false;
    }
  }

  const handleInputChange = e => {
    unitName = e.target.value;
  }
  const submitHandler = (e) => {
    e.preventDefault();
    if (str === "edit") {
      let data = {
        unitName: unitName,
        unitStatus: status
      }
      onSubmit(data);
    } else {
      if (unitName === undefined || unitName === "") {
        toast("Please fill the required fields");
        return false;
      }
      let data = {
        unitName: unitName,
      }
      onSubmit(data);
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={submitHandler}>
          <div className="modal-staff body">
            <div className="modal-staff head">
              <div>
                <i onClick={onHide} class="fas fa-arrow-left"></i>
                <h4>{str === "edit" ? "EDIT" : "ADD NEW"} UNIT</h4>
              </div>
              {str === "edit" ? <Form.Select aria-label="Default select example" className="form-control select-control"
                onChange={statusHandler} defaultValue={initialValues?.active.toString()}
                name="unitStatus"
              >
                <option value="" disabled={true}>Select one</option>
                <option value="true">ACTIVE</option>
                <option value="false">INACTIVE</option>
              </Form.Select> : null}
            </div>
            <Row className="modal-unit-popup">
              <Col md={12}>
                <Form.Group controlId="formBasicConPw">
                  <Form.Control
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Enter Unit Name"
                    name="unitName"
                    defaultValue={initialValues?.unitName}
                  />
                </Form.Group>

              </Col>
              <Col md={12}>
                <Form.Group controlId="formBasicConPw">
                  <Form.Control
                    onChange={handleInputChange}
                    type="hidden"
                    placeholder="Enter Unit Id"
                    name="unitId"
                    value={initialValues?.unitId}
                  />
                </Form.Group>
              </Col>

              <Col md={12}>
                {error && <strong>{error}</strong>}<br></br>
                <button type="submit" className="modal-btn">
                  {str === "edit" ? "Update" : "Add"} Unit
                </button>
              </Col>
            </Row>
          </div>

        </Form>

      </Modal>
    </>
  )
}


export default UnitForm;