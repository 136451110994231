import React from "react";
import { Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import Edit from "../../../assets/images/ui.svg";
import Delete from "../../../assets/images/ux.svg";
import Clone from "../../../assets/images/clonee.svg";
import { reset, SubmissionError } from 'redux-form';
import { connect } from "react-redux";
import Filter from "../../../assets/images/filter.svg";
import download from "../../../assets/images/download1.svg";
import mail from "../../../assets/images/mail.svg";
import verify from "../../../assets/images/verify.svg";
import verify2 from "../../../assets/images/verify2.svg";
import Spinner from "../../../Components/loader";
import Orange from "../../../assets/images/vendor-one.png";
import Arrow from "../../../assets/images/pl.svg";
import Search from "../../../assets/images/search.svg";
import Pagination from "../../../Components/Pagination";
import { toast } from "react-toastify";
import EmailPopUp from "../../../Components/emailPopup";
import {
  deletePo,
  getConfirmProduts,
  getVendorPoList,
  previewPo,
  getExecutiveUrl,
  clearPo, generatePo,
  sendPo,
  changePoClassName,
  clonePo,
  revisePo,
  changeDateFormat,
  getPoVerifyProduts,
  cancelPo,
  clearPreview,
  getPoInwardList,
  getVendorList,
  clearPoProjectData,
  clearPoVerifyProducts,
  setTitle,
  getPoMailDetails,
  sendPoMail,
  clearMailData
} from "../../../actions";
import VendorFilter from "./VendorFilter";
import InwardProducts from "./Inward";
import ConvertBoq from "./ConvertBoq";
import ConvertBoqToPo from "./ConvertBoqPo";
import VenderPoView from "./VenderPoView";
import DeletePopUp from "./deletePopup";
import VerifyProducts from "./verifyProducts";
import CloneStatus from "../../../Components/cloningStatus";
import ClonePopup from "../../../Components/clonePopup";

const role = localStorage.getItem('userRole');

class VendorPo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      allVendorPo: [],
      success_msg: '',
      allData: [],
      sortType: "asc",
      searchKey: "",
      sortField: "",
      pageNo: 0,
      filterData: {},
      id: '',
      sort: {},
      str: "",
      editShow: false,
      status: "",
      verifyPopup: false,
      deletePopup: false,
      inwardPopup: false,
      cloned: false,
      clonePopup: false,
      cloneStr: "",
      cloneShow: false,
      emailPopup: false,
      poPopup: false,
      vendorItem: [],
      convertProducts: [],
      cc: [],
      to: []
    }
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.onChange = this.onChange.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.editVendorShow = this.editVendorShow.bind(this);
    this.editVendorClose = this.editVendorClose.bind(this);
    this.deletePoHandler = this.deletePoHandler.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.deleteItemClose = this.deleteItemClose.bind(this);
    this.goToPoPage = this.goToPoPage.bind(this);
    this.generatePoHandler = this.generatePoHandler.bind(this);
    this.sendPohandler = this.sendPohandler.bind(this);
    this.clonePoHandler = this.clonePoHandler.bind(this);
    this.revisePoHandler = this.revisePoHandler.bind(this);
    this.cancelPoHandler = this.cancelPoHandler.bind(this);
    this.verifyShowPopup = this.verifyShowPopup.bind(this);
    this.verifyClosePopup = this.verifyClosePopup.bind(this);
    this.listAllData = this.listAllData.bind(this);
    this.inwardOpen = this.inwardOpen.bind(this);
    this.inwardClose = this.inwardClose.bind(this);
    this.covertPoOpen = this.covertPoOpen.bind(this);
    this.covertPoClose = this.covertPoClose.bind(this);
    this.sendMailOpen = this.sendMailOpen.bind(this);
    this.sendMailClose = this.sendMailClose.bind(this);
    this.sendPoMailSubmit = this.sendPoMailSubmit.bind(this);
    this.clickPage = this.clickPage.bind(this);
    this.getCCs = this.getCCs.bind(this);
    this.getToLists = this.getToLists.bind(this);
  }
  clickPage() {
    const { history } = this.props;
    this.props.dispatch(clearPo());
    this.props.dispatch(clearPoProjectData());
    localStorage.setItem("title", "Create Purchase Order");
    setTitle("Create Purchase Order");
    history.push(`/${role}/vendor_po/vendor-detail/add`);
  }
  handleShow() {
    this.props.dispatch(getConfirmProduts());
    this.setState({ show: true });
  }
  getCCs(val) {
    this.setState({
      cc: val
    })
  }
  getToLists(val) {
    this.setState({
      to: val
    })
  }
  listAllData() {
    const { pageNo, searchKey, filterData, sortType, sortField } = this.state;
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
    this.props.dispatch(getVendorPoList(url));
  }
  generatePoHandler() {
    const { vendorItem } = this.state;
    this.props.dispatch(generatePo(vendorItem.purchaseOrderId));
  }
  sendPohandler() {
    const { vendorItem } = this.state;
    this.props.dispatch(sendPo(vendorItem.purchaseOrderId));
  }
  clonePoHandler() {
    const { vendorItem } = this.state;
    this.setState({
      cloned: true,
      clonePopup: true,
      cloneStr: "Cloning Purchase Order...Please wait..."
    })
    this.props.dispatch(clonePo(vendorItem.purchaseOrderId));
  }
  revisePoHandler() {
    const { vendorItem } = this.state;
    this.setState({
      cloned: false,
      clonePopup: true,
      cloneStr: "Revising Purchase Order...Please wait..."
    })
    this.props.dispatch(revisePo(vendorItem.purchaseOrderId));
  }
  cancelPoHandler() {
    const { vendorItem } = this.state;
    this.props.dispatch(cancelPo(vendorItem.purchaseOrderId));
  }
  handleClose() {
    this.setState({ show: false });
  }
  editVendorShow(item, str) {
    const { history } = this.props;
    this.props.dispatch(clearPreview());
    this.props.dispatch(clearPoProjectData());
    this.props.dispatch(previewPo(item.purchaseOrderId));
    if (str === "edit") {
      localStorage.setItem("title", "Edit Purchase Order");
      setTitle("Edit Purchase Order");
      history.push(`/${role}/vendor_po/product-detail/${item.purchaseOrderId}`);
    } else if (str === "del") {
      this.setState({
        vendorItem: item
      })
      this.deleteItem(item);
    } else if (str === "mail") {
      this.sendMailOpen();
      this.setState({
        vendorItem: item
      })
      this.props.dispatch(getPoMailDetails(item.purchaseOrderId));
      // this.props.dispatch(sendPo(item.purchaseOrderId));
    } else if (str === "verify") {
      this.props.dispatch(getPoVerifyProduts(item.purchaseOrderId));
      this.setState({
        editShow: false,
        verifyPopup: true,
        status: item.status,
        vendorItem: item
      })
    } else if (str === "inward") {
      this.setState({
        vendorItem: item,
        status: item.status,
      })
      this.inwardOpen();
    } else if (str === "clone") {
      this.setState({
        cloned: true,
        clonePopup: true,
        cloneStr: "Cloning Purchase Order...Please wait..."
      })
      this.props.dispatch(clonePo(item.purchaseOrderId));
    } else {
      this.setState({
        editShow: true,
        vendorItem: item,
        status: item.status,
      })
    }
  }
  verifyShowPopup() {
    const { vendorItem } = this.state;
    this.props.dispatch(getPoVerifyProduts(vendorItem.purchaseOrderId));
    this.setState({
      editShow: false,
      verifyPopup: true
    })
  }
  verifyClosePopup() {
    this.props.dispatch(clearPoVerifyProducts());
    this.setState({
      verifyPopup: false
    })
  }
  sendMailOpen() {
    const { vendorItem } = this.state;
    if (vendorItem.length !== 0) {
      this.props.dispatch(getPoMailDetails(vendorItem.purchaseOrderId));
    }
    this.setState({
      emailPopup: true,
      editShow: false,
    })
  }
  sendMailClose() {
    this.setState({
      emailPopup: false
    })
  }
  editVendorClose() {
    this.setState({
      editShow: false
    })
  }
  deleteItem(item) {
    this.setState({
      deletePopup: true,
      id: item.purchaseOrderId
    })
  }
  deleteItemClose() {
    this.setState({
      deletePopup: false
    })
  }
  deletePoHandler() {
    const { vendorItem } = this.state;
    this.props.dispatch(deletePo(vendorItem.purchaseOrderId));
  }
  covertPoOpen() {
    this.setState({
      poPopup: true,
      show: false
    })
  }
  covertPoClose() {
    this.setState({
      poPopup: false
    })
  }
  inwardOpen() {
    this.props.dispatch(getPoInwardList());
    this.setState({
      inwardPopup: true,
      editShow: false
    })
  }
  inwardClose() {
    this.setState({
      inwardPopup: false
    })
  }
  goToPoPage() {
    const { history } = this.props;
    this.props.dispatch(clearPo());
    const { vendorItem } = this.state;
    if (vendorItem.status === "DRAFT") {
      setTitle("Edit Purchase Order");
      history.push(`/${role}/vendor_po/product-detail/${vendorItem.purchaseOrderId}`);
    } else {
      this.revisePoHandler();
    }
  }
  sortHandler(sortField) {
    const { pageNo, searchKey, filterData, sortType } = this.state;
    this.setState({
      sortField: sortField
    });
    let type = sortType;
    if (this.state.sortType === 'desc') {
      type = 'asc';
      this.setState({
        sortType: 'asc'
      })
    } else {
      type = 'desc';
      this.setState({
        sortType: 'desc'
      })
    };
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, type);
    this.props.dispatch(getVendorPoList(url));
  }
  searchHandler(e) {
    const { sortField, filterData, sortType } = this.state;
    this.setState({
      searchKey: e.target.value
    });
    let url = getExecutiveUrl(0, e.target.value, filterData, sortField, sortType);
    this.props.dispatch(getVendorPoList(url));
  }
  filterSubmit(filterData) {
    const { pageNo, searchKey, sortType, sortField } = this.state;
    this.setState({
      filterData: filterData
    })
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
    this.props.dispatch(getVendorPoList(url));
  }
  resetHandler() {
    const { pageNo, sortField, sortType, searchKey } = this.state;
    this.setState({
      filterData: {}
    })
    let url = getExecutiveUrl(pageNo, searchKey, {}, sortField, sortType);
    this.props.dispatch(getVendorPoList(url));
  }
  onChange(pageNo) {
    const { filterData, sortField, sortType, searchKey } = this.state;
    this.setState({
      pageNo: pageNo
    });
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
    this.props.dispatch(getVendorPoList(url));
  }
  sendPoMailSubmit(values, dispatch) {
    const { vendorItem, cc, to } = this.state;
    if (!values.subject) {
      throw new SubmissionError({
        subject: 'Please fill the field *',
      })
    }
    if (cc.length === 0 || to.length === 0) {
      toast("Please Select CC and To");
      return false;
    }
    const newItems = cc.map((item) => item.value);
    const newToItems = to.map((item) => item.value);

    let data = {
      "from": values.from,
      "to": newToItems,
      "cc": newItems,
      "id": vendorItem.purchaseOrderId,
      "subject": values.subject
    }
    this.props.dispatch(sendPoMail(data));
    dispatch(reset("SecondaryEmailForm"));
  }
  componentDidUpdate(prevProps) {
    if (prevProps.vendorpo_list !== this.props.vendorpo_list) {
      this.setState({
        allVendorPo: this.props.vendorpo_list,
      });
    } if (prevProps.generateData !== this.props.generateData) {
      if (this.props.generateData.status === 200) {
        this.setState({
          // editShow: false,
          status: "GENERATED"
        })
        toast(this.props.generateData.data);
        this.listAllData();
      } else {
        toast(this.props.generateData.data);
      }
    } if (prevProps.deletePo !== this.props.deletePo) {
      if (this.props.deletePo.status === 200) {
        toast(this.props.deletePo.data);
        this.listAllData();
        this.setState({
          deletePopup: false,
          editShow: false
        })
      } else {
        toast(this.props.deletePo.data);
      }
    } if (prevProps.sendData !== this.props.sendData) {
      if (this.props.sendData.status === 200) {
        toast(this.props.sendData.data);
        this.listAllData();
        this.setState({
          // editShow: false,
          status: "SENT"
        })
      } else {
        toast(this.props.sendData.data);
      }
    } if (prevProps.cloneData !== this.props.cloneData) {
      if (this.props.cloneData.status === 200) {
        toast("Cloned Successfully");
        this.listAllData();
        this.setState({
          editShow: false,
          clonePopup: false,
          cloneShow: true,
          cloneStr: "Purchase Order Cloned Successfully"
        })
        setTimeout(() => this.setState({ cloneShow: false, cloneStr: "", cloned: null }), 1200);
      } else {
        this.setState({
          clonePopup: false,
        })
        toast(this.props.cloneData.data);
      }
    } if (prevProps.reviseData !== this.props.reviseData) {
      if (this.props.reviseData.status === 200) {
        toast(this.props.reviseData.message);
        this.listAllData();
        this.setState({
          editShow: false,
          clonePopup: false,
          cloneShow: true,
          cloneStr: "Purchase Order Revised Successfully"
        })
        setTimeout(() => this.setState({ cloneShow: false, cloneStr: "", cloned: null }), 1200);
      } else {
        this.setState({
          clonePopup: false,
        })
        toast(this.props.reviseData.data);
      }
    } if (prevProps.cancelData !== this.props.cancelData) {
      if (this.props.cancelData.status === 200) {
        toast(this.props.cancelData.data);
        this.listAllData();
        this.setState({
          editShow: false
        })
      } else {
        toast(this.props.cancelData.data);
      }
    } if (prevProps.verifyData !== this.props.verifyData) {
      if (this.props.verifyData.status === 200) {
        this.props.dispatch(clearPoVerifyProducts());
        toast(this.props.verifyData.data);
        this.listAllData();
        this.setState({
          verifyPopup: false
        })
      } else {
        toast(this.props.verifyData.data);
      }
    } if (prevProps.success_msg !== this.props.success_msg) {
      this.setState({
        success_msg: this.props.success_msg,
      });
      const { pageNo, searchKey, sort, sortField } = this.state;
      if (this.props.success_msg.status === 200) {
        this.setState({
          show: false
        });
        toast(this.props.success_msg.data);
        if (sortField === "") {
          this.props.dispatch(getVendorPoList(pageNo, searchKey));
        } else {
          this.props.dispatch(getVendorPoList(pageNo, searchKey, sort));
        }
      } else {
        toast(this.props.success_msg.data);
      }
    } if (prevProps.convertProducts !== this.props.convertProducts) {
      const { convertProducts } = this.props;
      this.props.dispatch(getVendorList());
      if (convertProducts.status === 200) {
        this.handleClose();
        this.setState({
          poPopup: true,
          convertProducts: convertProducts.data
        })
      }
    } if (prevProps.convertPoSuccess !== this.props.convertPoSuccess) {
      const { convertPoSuccess, history } = this.props;

      if (convertPoSuccess.status === 200) {
        this.setState({
          poPopup: false
        });
        setTitle("Edit Purchase Order");
        history.push(`/${role}/vendor_po/product-detail/${convertPoSuccess.data.purchaseOrderId}`);
        toast(convertPoSuccess.message);
        this.listAllData();
      } else {
        toast(convertPoSuccess.data);
      }
    } if (prevProps.poMailSuccess !== this.props.poMailSuccess) {
      const { poMailSuccess } = this.props;
      if (poMailSuccess.status === 200) {
        this.setState({
          emailPopup: false,
          cc: []
        });
        toast(poMailSuccess.data);
        this.listAllData();
        this.props.dispatch(clearMailData());
      } else {
        toast(poMailSuccess.data);
      }
    }
  }
  componentDidMount() {
    this.props.dispatch(getVendorPoList("page=0"));
  }

  render() {
    const { allVendorPo,
      vendorItem,
      editShow,
      convertProducts,
      verifyPopup,
      inwardPopup,
      poPopup,
      show,
      deletePopup,
      status } = this.state;
    let data = {
      totalElements: allVendorPo.totalElements,
      totalPages: allVendorPo.totalPages,
      offset: allVendorPo?.pageable?.offset,
      numberOfElements: allVendorPo.numberOfElements,
      num: (allVendorPo?.number) + 1
    }

    return (
      <div>
        <Navbar />
        <div className="dashboard-page">
          <div className="dashboard-sidebar">
            <Sidebar />
          </div>
          <div className="dashboard-main">
            {/* <EmailPopUp /> */}
            <Card className="staff-page">
              <div className="page-head-split">
                <div className="head-colums">
                  <div className="head-part-tab">
                    <img src={Orange} alt="..." className="head-imgs pinky" />
                    <h5>Vendor PO</h5>
                  </div>
                  <div className="tab-linkk">
                    {/* <Link to={"/" + role + "/bill_of_quantity"} >Bill Of Quantity</Link>
                    <Link to={"/" + role + "/vendor_po"} className="active">Vendor Po</Link> */}
                  </div>
                  <div className="customer-search">
                    <span><img src={Search} alt=".." className="search-img" /></span>
                    <Form.Control type="search" placeholder="Search" onChange={this.searchHandler} className="main-search" />
                  </div>
                  <VendorFilter onSubmit={this.filterSubmit} reset={this.resetHandler} />
                  <ConvertBoq
                    handleShow={this.handleShow}
                    show={show}
                    onHide={this.handleClose}
                  />
                  <button className="create-po" onClick={this.clickPage}><img src={Arrow} alt=".." />Create Purchase Order</button>
                </div>
              </div>
              <div className="table-class">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      {/* <th scope="col">
                        ID<img src={Filter} alt="..." onClick={(e) => this.sortHandler("po.purchase_order_id")} />
                      </th> */}
                      <th scope="col">PO Number <img src={Filter} alt="..." onClick={(e) => this.sortHandler("po.purchase_order_reference_id")} /></th>
                      <th scope="col"
                      >Customer Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("c.customer_first_name")} /></th>

                      <th scope="col">
                        Project Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("vendor_firstName")} />
                      </th>
                      {/* <th scope="col">
                        Created By <img src={Filter} alt="..." onClick={(e) => this.sortHandler("s.first_name")} />
                      </th> */}
                      <th scope="col" >
                        Created At <img src={Filter} alt="..." onClick={(e) => this.sortHandler("po.created_at")} />
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Status <img src={Filter} alt="..." onClick={(e) => this.sortHandler("ps.purchase_order_status")} />
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allVendorPo.content && allVendorPo.content.length > 0 ? (
                      allVendorPo.content.map((item, i) => {
                        return <tr key={i} style={{ cursor: "pointer" }} >
                          {/* <td onClick={() => this.editVendorShow(item, "")}>{item.purchaseOrderId}</td> */}
                          <td onClick={() => this.editVendorShow(item, "")}>{item.purchaseOrderNumber}</td>
                          <td onClick={() => this.editVendorShow(item, "")} className="customer_name_size">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item.customerName || "-"}</Tooltip>}>
                              <span className="d-inline-block">
                                {item.customerName || "-"}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td onClick={() => this.editVendorShow(item, "")} className="customer_name_size">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item.projectName || "-"}</Tooltip>}>
                              <span className="d-inline-block">
                                {item.projectName || "-"}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td onClick={() => this.editVendorShow(item, "")}>{changeDateFormat(item.createdAt)}</td>
                          <td style={{ textAlign: "center" }}>
                            <button style={{ width: "145px" }} className={changePoClassName(item.status)} onClick={() => this.editVendorShow(item, "")} >
                              {item.status}
                            </button>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {
                              (() => {
                                if (item.status === "DRAFT") {
                                  return (
                                    <>
                                      <img src={Edit} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "edit")} />
                                      <img src={Delete} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "del")} />
                                    </>
                                  )
                                } else if (item.status === "GENERATED") {
                                  return (
                                    <>
                                      <img src={download} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "")} />
                                      <img src={mail} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "mail")} />
                                    </>
                                  )
                                } else if (item.status === "SENT") {
                                  return (
                                    <>
                                      <img src={download} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "")} />
                                      <img src={verify} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "verify")} />
                                    </>
                                  )
                                } else if (item.status === "VERIFIED") {
                                  return (
                                    <>
                                      <img src={download} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "")} />
                                      <img src={verify2} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "inward")} />
                                    </>
                                  )
                                } else if (item.status === "PARTIALLY RECEIVED") {
                                  return (
                                    <>
                                      <img src={verify} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "verify")} />
                                      <img src={verify2} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "inward")} />
                                    </>
                                  )
                                } else if (item.status === "RECEIVED") {
                                  return (
                                    <>
                                      <img src={Clone} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "clone")} />
                                      <img src={download} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "")} />
                                    </>
                                  )
                                }
                                else if (item.status === "PARTIALLY VERIFIED") {
                                  return (
                                    <>
                                      <img src={verify} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "verify")} />
                                      <img src={verify2} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "inward")} />
                                    </>
                                  )
                                } else if (item.status === "REVISED") {
                                  return (
                                    <>
                                      <img src={Clone} alt="" className="Edit-staffs" onClick={() => this.editVendorShow(item, "clone")} />
                                      <img src={download} alt="" className="delete-staff" onClick={() => this.editVendorShow(item, "")} />
                                    </>
                                  )
                                }
                              })()
                            }
                          </td>
                        </tr>
                      })) : !this.props.loading ?
                      <h1 className="empty-data">No Records Found</h1> :
                      <Spinner />
                    }
                  </tbody>
                </table>
              </div>
            </Card>
            <Pagination data={data} onChange={this.onChange} />
            <VenderPoView
              data={status}
              item={vendorItem}
              show={editShow}
              goTo={this.goToPoPage}
              onHide={this.editVendorClose}
              generate={this.generatePoHandler}
              delete={this.deleteItem}
              send={this.sendPohandler}
              sendMail={this.sendMailOpen}
              clone={this.clonePoHandler}
              revise={this.revisePoHandler}
              verify={this.verifyShowPopup}
              cancel={this.cancelPoHandler}
              inwardOpen={this.inwardOpen}
            />
            <VerifyProducts
              show={verifyPopup}
              onHide={this.verifyClosePopup}
              data={vendorItem}
            />
            <DeletePopUp
              show={deletePopup}
              onHide={this.deleteItemClose}
              deleteItem={this.deletePoHandler}
            />
            <InwardProducts
              show={inwardPopup}
              id={vendorItem.purchaseOrderId}
              onHide={this.inwardClose}
              listAllData={this.listAllData}
            />
            <ConvertBoqToPo
              show={poPopup}
              onHide={this.covertPoClose}
              data={convertProducts}
            />
            <CloneStatus
              show={this.state.clonePopup}
              str={this.state.cloneStr}
              onHide={false}
            />
            <ClonePopup
              show={this.state.cloneShow}
              str={this.state.cloneStr}
              onHide={false}
              cloned={this.state.cloned}
            />
            <EmailPopUp
              show={this.state.emailPopup}
              onHide={this.sendMailClose}
              onSubmit={this.sendPoMailSubmit}
              getCC={this.getCCs}
              getTo={this.getToLists}
            />
          </div>
        </div >
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.vendorpo.loading,
    vendorpo_list: state.vendorpo.vendorpo_list,
    page: state.vendorpo.page,
    success_msg: state.vendorpo.success_msg,
    generateData: state.po.generateData,
    vendor: state.vendor.vendor,
    sendData: state.po.sendData,
    deletePo: state.po.deletePo,
    cloneData: state.po.cloneData,
    reviseData: state.po.reviseData,
    cancelData: state.po.cancelData,
    verifyData: state.po.verifyData,
    confirmQuotDropdown: state.boq.confirmQuotDropdown,
    convertProducts: state.po.convertProducts,
    poMailSuccess: state.po.convertPoSuccess,
    poMailSuccess: state.po.poMailSuccess,
    convertPoSuccess: state.po.convertPoSuccess
  }
}

export default connect(mapStateToProps)(VendorPo);

