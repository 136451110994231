import { vendorConstants } from "../constants";

export const getVendorList = (item) => {
    return {
        type: vendorConstants.GET_VENDOR,
        item
    }
}
// particular vendor get
export const getVendorById = (vendorId) => {
    return {
        type: vendorConstants.GET_VENDOR_STAFF,
        vendorId
    };
};
export const createVendor = (formData) => {
    return {
        type: vendorConstants.CREATE_VENDOR_INIT,
        formData
    }
}
export const updateVendor = (formData) => {
    return {
        type: vendorConstants.UPDATE_VENDOR_INIT,
        formData
    }
}
export const getVendorSecondaryEmailList = (id) => {
    return {
        type: vendorConstants.GET_VENDOR_SECONDARY_MAIL,
        id
    }
}
export const createVendorSecondaryEmail = (formData) => {
    return {
        type: vendorConstants.CREATE_VENDOR_SECONDARY_MAIL_INIT,
        formData
    }
}
export const  updateVendorSecondaryEmail = (formData) => {
    return {
        type: vendorConstants.UPDATE_VENDOR_SECONDARY_MAIL_INIT,
        formData
    }
}
export const changePage = () => {
    return {
        type: vendorConstants.CHANGE_PAGE,
    }
}