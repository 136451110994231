import React from 'react'
import { reduxForm } from 'redux-form';
import { Modal, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { displayCurrency, verifyDateFormat, verifyPo } from '../../../actions';
import parse from 'html-react-parser';
import { DatePicker } from "antd";
import moment from "moment";
import { toast } from 'react-toastify';
import Spinner from '../../../Components/loader';


class VerifyProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openedItem: [],
            selectedPro: [],
            data: [],
            oaNumber: null,
            oaDate: null
        }
        this.selectHandler = this.selectHandler.bind(this);
        this.selectProHandler = this.selectProHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.oaHandler = this.oaHandler.bind(this);
        this.oaDateHandler = this.oaDateHandler.bind(this);
        this.unitHandler = this.unitHandler.bind(this);
        this.qaConfirmHandler = this.qaConfirmHandler.bind(this);
        this.remarkHandler = this.remarkHandler.bind(this);
    }
    selectHandler(e) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((item) => item = e.target.checked);
        this.setState({
            openedItem: newItems,
        })
    }
    oaHandler(e) {
        this.setState({
            oaNumber: e.target.value,
        })
    }
    oaDateHandler(e) {
        let item = e === null ? "" : verifyDateFormat(e._d);
        this.setState({
            oaDate: item,
        })
    }
    changeHandler = (e, i) => {
        const { data } = this.state;
        let a = data[i]?.quantity;
        let b = e.target.value;
        if ((b && !/^([0-9]{1,5})+(\.[0-9]{0,2})?$/i.test(b)) || parseFloat(b) === 0) {
            toast("Enter valid digits Ex(19.00,23456.45,200.34)");
            return false;
        }
        if (+b <= a) {
            data[i].oaQuantity = b;
            this.setState({
                data: data,
                index: i
            })
        }else {
            toast("Pls enter the number less than or equal to " + a);
        }
    }
    unitHandler = (e, i) => {
        const { data } = this.state;
        let b = e.target.value;
        data[i].unitPrice = b;
        if (b && !/^([0-9]{1,5})+(\.[0-9]{0,2})?$/i.test(b)) {
            toast("Enter valid digits Ex(19.00,23456.45,200.34)");
            return false;
        }
        this.setState({
            data: data,
            index: i
        })
    }
    qaConfirmHandler = (e, i) => {
        const { data } = this.state;
        let a = data[i]?.oaQuantity;
        let b = e.target.value;
        if (b && !/^([0-9]{1,5})+(\.[0-9]{0,2})?$/i.test(b)) {
            toast("Enter valid digits Ex(19.00,23456.45,200.34)");
            return false;
        }
        data[i].oaConfirmQuantity = b;
        this.setState({
            data: data,
            index: i
        })
    }
    remarkHandler = (e, i) => {
        const { data } = this.state;
        let b = e.target.value;
        data[i].remark = b;
        this.setState({
            data: data,
            index: i
        })
    }
    selectProHandler(e, index) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((item, i) => index === i ? e.target.checked : item);
        this.setState({
            openedItem: newItems
        })
    }
    submitHandler() {
        const { openedItem, oaNumber, oaDate } = this.state;
        let pro = [];
        if (openedItem.includes(true)) {
            if ((oaNumber === null) || (oaDate === null)) {
                toast("Please Fill Oa Number and date");
                return false;
            }
            this.props.verifyProducts?.data?.projectList?.map((item, i) => {
                let items = {};
                if (openedItem[i]) {
                    items.purchaseProjectProductId = item.purchaseProjectProductId;
                    items.oaQuantity = item.oaQuantity;
                    items.specialPrice = Number(item.unitPrice);
                    items.oaConfirmQuantity = Number(item.oaConfirmQuantity);
                    items.remark = item.remark;
                    pro.push(items);
                }
            });
            let data = {
                "purchaseOrderId": this.props.data.purchaseOrderId,
                "oaDate": oaDate,
                "oaNumber": oaNumber,
                "verifyProducts": pro,
                "unitPrice": this.props.data.unitPrice
            }
            this.props.dispatch(verifyPo(data));
        } else {
            toast("Select atleast one product");
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.verifyProducts !== this.props.verifyProducts) {
            const { verifyProducts } = this.props;
            let data = [];
            if (verifyProducts.status === 200) {
                this.props.verifyProducts?.data?.projectList?.map((item, i) => data[i] = false);
                this.setState({
                    openedItem: data,
                    oaNumber: null,
                    oaDate: null,
                    data: this.props.verifyProducts?.data?.projectList
                })
            } else {
                toast(verifyProducts.data);
            }
        }
    }
    render() {
        const { show, onHide, verifyProducts, load } = this.props;
        const { openedItem, data } = this.state;
        return (
            <>
                <Modal
                    show={show}
                    size="xl"
                    onHide={() => {
                        onHide();
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-staff">
                        <div className="head">
                            <div>
                                <i class="fas fa-arrow-left" onClick={() => {
                                    onHide();
                                }}></i>
                                <h4>VERIFY PRODUCTS</h4>
                            </div>
                            <div>
                                <input type="text" onChange={this.oaHandler} className='form-control' placeholder='OA Number' maxLength={15}/>
                                <DatePicker
                                    onChange={this.oaDateHandler}
                                    style={{ width: "100%" }}
                                    disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
                                />
                            </div>
                            <h4>{`PO. NO. : ${verifyProducts?.data?.purchaseOrderReferenceNumber ?? ""} (${verifyProducts?.data?.totalQuantity ?? 0} / ${verifyProducts?.data?.verifiedQuantity ?? 0} )`}</h4>
                            {/* <h4>{`DATE: ${changePoDateFormat(verifyProducts?.data?.createdAt)}`}</h4> */}
                        </div>
                        <div className="table-class space-part">
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Project
                                        </th>
                                        <th scope="col">
                                            Article ID
                                        </th>
                                        <th scope="col">
                                            Product
                                        </th>
                                        <th scope="col" className='txt-center' >
                                            Unit Price
                                        </th>
                                        <th scope="col" className='txt-center'>
                                            Qty.
                                        </th>
                                        <th scope="col" className='txt-center'>
                                            OA Qty.
                                        </th>
                                        <th>OA Confirm Qty</th>
                                        <th>Remark</th>
                                        <th scope="col" className='check-item'>
                                            Select
                                            <input type="checkbox" onChange={this.selectHandler} style={{ margin: "7px" }} />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.length > 0 ? data?.map((item, i) => (
                                        <tr key={i}>
                                            <td className="new_bulk_sizee">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item?.projectName || "-"}</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        {item?.projectName || "-"}
                                                    </span>
                                                </OverlayTrigger>
                                            </td>
                                            <td>{item?.articleId}</td>
                                            <td className="new_bulk_sizee">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.productName || "-")}</Tooltip>}>
                                                    <span>
                                                        {parse(item.productName || "-")}
                                                    </span>
                                                </OverlayTrigger>
                                            </td>
                                            <td className='txt-center'>₹ &nbsp;

                                                <input type="text"
                                                    maxLength={15}
                                                    value={item?.unitPrice}
                                                    className='convert_po_input'
                                                    onChange={(e) => this.unitHandler(e, i)}
                                                />
                                            </td>
                                            <td className='txt-center'>{item?.quantity}</td>
                                            <td className='txt-center'>
                                                <input type="text"
                                                    maxLength={8}
                                                    value={item?.oaQuantity}
                                                    className='convert_po_input'
                                                    onChange={(e) => this.changeHandler(e, i)} />
                                            </td>
                                            <td className='txt-center'>
                                                <input type="text"
                                                    maxLength={10}
                                                    value={item?.oaConfirmQuantity}
                                                    className='convert_po_input'
                                                    onChange={(e) => this.qaConfirmHandler(e, i)} />
                                            </td>
                                            <td className='txt-center'>
                                                <textarea type="text"
                                                    maxLength={200}
                                                    value={item?.remark || ""}
                                                    className='big-input'
                                                    onChange={(e) => this.remarkHandler(e, i)} />
                                            </td>
                                            <td >
                                                <Form.Check
                                                    checked={openedItem[i] ? true : false}
                                                    onChange={(e) => this.selectProHandler(e, i)}
                                                    name="group1"
                                                    type="checkbox"
                                                    className="form-check-box"
                                                />
                                            </td>
                                        </tr>
                                    )) : !this.props.loading ?
                                        <h1 className="empty-data">No Records Found</h1> :
                                        <Spinner />}
                                </tbody>
                            </table>
                        </div>
                        <center style={{ padding: "15px" }}>
                            {!load ? <button className="customer-btn col-md-6" onClick={() => this.submitHandler()}>Verify</button> :
                                <button className="customer-btn col-md-6">
                                    Loading ....
                                </button>}

                        </center>
                    </div>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        confirmQuotDropdown: state.boq.confirmQuotDropdown,
        poData: state.po.poData,
        verifyProducts: state.po.verifyProducts,
        loading: state.po.loading,
        load: state.po.load
    }
}


const VerifyProductsForm = reduxForm({
    form: 'verifyProductsFormRedux',
    destroyOnUnmount: false,
    enableReinitialize: true,
})(VerifyProducts)

export default connect(mapStateToProps)(VerifyProductsForm);
