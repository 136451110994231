import { put, call, takeEvery } from "redux-saga/effects";
import { getDeliverApi } from "../services";
import { deliverConstants } from "../constants";

export function* getDeliverListSaga(payload) {
    try {
        const response = yield call(getDeliverApi, payload.searchKey);
        yield put({ type: deliverConstants.GET_DELIVER_LOGS_SUCCESS, response });
    } catch (error) {
        yield put({ type: deliverConstants.GET_DELIVER_LOGS_FAILURE, error });
    }
}
export default function* deliverSaga() {
    yield takeEvery(deliverConstants.GET_DELIVER_LOGS, getDeliverListSaga);

}