import { reportOaConstants } from "../constants";

const initialState = {
    loading: false,
    data: [],
    error: [],
    status: 0,
    newData: [],
    pdfView: null,
    csvDataDown: null,
    inwardData: null,
    downloadAllList: [],
    downloadContent: [],
    inwardSuccessStatus: null
};

export default function reportOaReducer(state = initialState, { type, response }) {
    switch (type) {
        case reportOaConstants.GET_REPORT_OA:
        case reportOaConstants.GET_REPORT_OA_PAGE_FORM:
            return {
                ...state,
                loading: true,
            };
        case reportOaConstants.GET_REPORT_OA_SUCCESS:
        case reportOaConstants.GET_REPORT_OA_PAGE_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
                data: response.data,
                success: response
            };
        case reportOaConstants.GET_REPORT_OA_FAILURE:
        case reportOaConstants.GET_REPORT_OA_PAGE_FORM_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case reportOaConstants.GET_REPORT_CONFIRM_OA:
            return {
                ...state,
                loading: true,
            };
        case reportOaConstants.GET_REPORT_CONFIRM_OA_SUCCESS:
            return {
                ...state,
                loading: false,
                onConfirm: response.data,
            };
        case reportOaConstants.GET_REPORT_CONFIRM_OA_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case reportOaConstants.GET_REPORT_OA_PAGE:
            return {
                ...state,
                loading: true,
            };
        case reportOaConstants.GET_REPORT_OA_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                oaAll: response.data,
                newData: response.data.first ? [...response.data.content] : [...state.newData, ...response.data.content],
            };
        case reportOaConstants.GET_REPORT_OA_PAGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };


        case reportOaConstants.GET_REPORT_OA_REPORT:
            return {
                ...state,
                loading: true,
            };
        case reportOaConstants.GET_REPORT_OA_SUCCESS_REPORT:
            return {
                ...state,
                loading: false,
                oaAll: response.data,
                newData: response.data.first ? [...response.data.content] : [...state.newData, ...response.data.content],
            };
        case reportOaConstants.GET_REPORT_OA_FAILURE_REPORT:
            return {
                ...state,
                loading: false,
                error: response,
            };

            case reportOaConstants.DOWNLOAD_CSV_REPORT_OA_REPORT:
                return {
                    ...state,
                    loadingcsv: true,
                };
            case reportOaConstants.DOWNLOAD_CSV_REPORT_OA_REPORT_SUCCESS:
                return {
                    ...state,
                    loadingcsv: false,
                    csvData: response
                };
            case reportOaConstants.DOWNLOAD_CSV_REPORT_OA_REPORT_FAILURE:
                return {
                    ...state,
                    loadingcsv: false,
                    error: response,
                };

        case reportOaConstants.DOWNLOAD_CSV_REPORT_OA:
            return {
                ...state,
                loadingcsv: true,
            };
        case reportOaConstants.DOWNLOAD_CSV_REPORT_OA_SUCCESS:
            return {
                ...state,
                loadingcsv: false,
                csvData: response
            };
        case reportOaConstants.DOWNLOAD_CSV_REPORT_OA_FAILURE:
            return {
                ...state,
                loadingcsv: false,
                error: response,
            };
        case reportOaConstants.OA_PDF_REPORT_OA:
            return {
                ...state,
                loading: true,
            };
        case reportOaConstants.OA_PDF_REPORT_OA_SUCCESS:
            return {
                ...state,
                loading: false,
                pdfView: response
            };
        case reportOaConstants.OA_PDF_REPORT_OA_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case reportOaConstants.OA_PDF_DOWNLOAD_OA:
            return {
                ...state,
                loading: true,
            };
        case reportOaConstants.OA_PDF_DOWNLOAD_OA_SUCCESS:
            return {
                ...state,
                loading: false,
                downloadAllList: response.data,
                downloadContent: response.data.first ? [...response.data.content] : [...state.downloadContent, ...response.data.content],
            };
        case reportOaConstants.OA_PDF_DOWNLOAD_OA_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case reportOaConstants.OA_CSV:
            return {
                ...state,
                loadingcsv: true,
            };
        case reportOaConstants.OA_CSV_SUCCESS:
            return {
                ...state,
                loadingcsv: false,
                csvDataDown: response
            };
        case reportOaConstants.OA_CSV_FAILURE:
            return {
                ...state,
                loadingcsv: false,
                error: response,
            };
        case reportOaConstants.CLEAR_PDF_REPORT_OA:
            return {
                ...state,
                pdfView: null,
            };
        case reportOaConstants.CLEAR_CSV_REPORT_OA:
            return {
                ...state,
                csvDataDown: null
            };
        case reportOaConstants.GET_REPORT_OA_MS:
            return {
                ...state,
                loading: true
            }
        case reportOaConstants.GET_REPORT_OA_MS_SUCCESS:
            return {
                ...state,
                loading: false,
                oaAll: response.data,
                newData: response.data.first ? [...response.data.content] : [...state.newData, ...response.data.content],
            }
        case reportOaConstants.GET_REPORT_OA_MS_FAILURE:
            return {
                ...state,
                loading: false,
                error: response
            }
        case reportOaConstants.GET_REPORT_OA_MS_CSV:
            return{
                ...state,
                loading: true
            }
        case reportOaConstants.GET_REPORT_OA_MS_CSV_SUCCESS:
            return{
                ...state,
                csvDataDown: response,
                loading: false
            }   
        case reportOaConstants.GET_REPORT_OA_MS_CSV_FAILURE:
            return {
                ...state,
                error: response,
                loading:false
            } 
        case reportOaConstants.CLEAR_MS_CSV:
            return {
                ...state,
                csvDataDown: null,
            }
        case reportOaConstants.OA_MS_INWARD:
            return {
                ...state,
                loading: true
            }
        case reportOaConstants.OA_MS_INWARD_SUCCESS: 
            return {
                ...state,
                loading: false,
                inwardData: response
            }
        case reportOaConstants.OA_MS_INWARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: response
            }
        case reportOaConstants.CREATE_PO_MS_INWARD_PRODUCTS:
            return {
                ...state,
                load: true
            }
        case reportOaConstants.CREATE_PO_MS_INWARD_PRODUCTS_SUCCESS:
            return {
                ...state,
                load: false,
                inwardSuccessStatus: response.status
            }
        case reportOaConstants.CREATE_PO_MS_INWARD_PRODUCTS_FAILURE:
            return {
                ...state,
                load: false,
                error: response
            }
        case reportOaConstants.CLEAR_INWARD_STATUS:
            return {
                ...state,
                inwardSuccessStatus: null
            }
        default:
            return state;
    }
}
