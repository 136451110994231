import { outwardConstants } from "../constants";

export const getOutwardLogs = (item) => {
    return {
        type: outwardConstants.GET_OUTWARDS_LOGS,
        item
    };
};
export const createCoProduct = (formData) => {
    return {
        type: outwardConstants.CREATE_CO_PRODUCT,
        formData
    }
}
export const editCoProduct = (formData) => {
    return {
        type: outwardConstants.EDIT_CO_PRODUCT,
        formData
    }
}
export const deleteCoProduct = (id) => {
    return {
        type: outwardConstants.DELETE_CO_PRODUCT,
        id
    }
}
export const getCoProductList = (formData) => {
    return {
        type: outwardConstants.GET_CO_PRODUCT_LIST,
        formData
    };
};
export const getCoProjects = (id) => {
    return {
        type: outwardConstants.GET_CO_PROJECTS,
        id
    };
};
export const getVendorProjects = (id) => {
    return {
        type: outwardConstants.GET_VENDOR_PROJECTS,
        id
    };
};
export const changeCoPage = () => {
    return {
        type: outwardConstants.CHANGE_ADD_PAGE
    }
}
export const changeCoEditPage = () => {
    return {
        type: outwardConstants.CHANGE_PAGE
    }
}
export const updateCoOutward = (formData) => {
    return {
        type: outwardConstants.UPDATE_CO_OUTWARD,
        formData
    }
}
export const createVendorProduct = (formData) => {
    return {
        type: outwardConstants.CREATE_VENDOR_PRODUCT,
        formData
    }
}
export const editVendorProduct = (formData) => {
    return {
        type: outwardConstants.EDIT_VENDOR_PRODUCT,
        formData
    }
}
export const deleteVendorProduct = (id) => {
    return {
        type: outwardConstants.DELETE_VENDOR_PRODUCT,
        id
    }
}
export const getVendorProductList = (formData) => {
    return {
        type: outwardConstants.GET_VENDOR_PRODUCTS,
        formData
    };
};
export const editReview = (formData) => {
    return {
        type: outwardConstants.EDIT_RETURN_REVIEW,
        formData
    }
}
export const updateOutwardReturnproduct = (id) => {
    return {
        type: outwardConstants.UPDATE_RETURN_OUTWARD_PRODUCT,
        id
    }
}
export const downloadOutwardCsv = (item) => {
    return {
        type: outwardConstants.DOWNLOAD_OUTWARD_CSV,
        item
    }
}