import { authToken, baseUrl } from "../actions";

export const getOutwardLogsApi = (item) => {
    let OUTWARD_LIST = `${baseUrl}staff/outwardLogs?${item}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(OUTWARD_LIST, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const createCoProductApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/counterSale`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const updateCoProductApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/counterSale`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const deleteCoProductApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/counterSale/${id}`;
    const parameters = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const getCoProductListApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/counterSaleDetails`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getCoProjectListApi = (id) => {
    let OUTWARD_LIST = `${baseUrl}staff/counterSaleProjects/${id}`
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(OUTWARD_LIST, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const getVendorProjectListApi = (id) => {
    let OUTWARD_LIST = `${baseUrl}staff/outwardReturn/${id}/purchaseOrderProjectDetails`
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(OUTWARD_LIST, parameters).then((response) => {
        return response.json();
    }).then((json) => {
        return json;
    });
}
export const updateCoOutwardtApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/outward/counterSale`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const createVendorProductApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/outwardReturn`;

    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const updateVendorProductApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/outwardReturn/productAndQuantity`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const deleteVendorProductApi = (id) => {
    let BILL_OF_QUALITY_LIST = `${baseUrl}staff/outwardReturn/${id}`;
    const parameters = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
    return fetch(BILL_OF_QUALITY_LIST, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const getVendorProductListApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/outwardReturnDetail`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const updateReviewApi = (request) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/outwardReturn/vendorAndReturnRemarks`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const updateOutwardReturnProductApi = (id) => {
    const CREATE_CUSTOMER = `${baseUrl}staff/product/outwardReturn/${id}`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            "Authorization": `Bearer ${authToken}`,
        },
        // body: JSON.stringify(request.formData)
    };

    return fetch(CREATE_CUSTOMER, parameters).then(response => {
        return response.json();
    }).then(json => {
        return json;
    });
}
export const getOutwardCsvApi = (item) => {
    let GET_CATEGORY_CSV_LIST = `${baseUrl}staff/outwardLogs/csv?${item}`;
    const parameters = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };

    return fetch(GET_CATEGORY_CSV_LIST, parameters)
        .then((response) => {
            return response.text();
        })
        .then((json) => {
            return json;
        });
};