import React from "react";
import { Card, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Navbar from "../../../layouts/Navbar";
import plus from "../../../assets/images/pl.svg";
import Sidebar from "../../../layouts/Sidebar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Filter from "../../../assets/images/filter.svg";
import Art from "../../../assets/images/art.svg";
import Spinner from "../../../Components/loader";
import Orange from "../../../assets/images/out-one.png";
import Search from "../../../assets/images/search.svg";
import Pagination from "../../../Components/Pagination";
import parse from 'html-react-parser';
import OutWardFilter from "./OutWardFilter";
import CounterSale from "./CounterSale";
import {
  getOutwardLogs,
  getVendorList,
  getCustomersList,
  getDeliverLogs,
  getInternalTransferProductList,
  getProjectOutwardList,
  changeDateFormat,
  getExecutiveUrl,
  exportToJson,
  downloadOutwardCsv,
  camelize,
  shortStr
} from "../../../actions";
import Projects from "../../purchaseOrder/billofquantity/Projects";
import Outward from "../../purchaseOrder/billofquantity/Outward";


const role = localStorage.getItem('userRole');

class OutWard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      inwardShow: false,
      counterSaleShow: false,
      outwardPopup: false,
      outward: [],
      projectPopup: false,
      deliver: [],
      sortType: "asc",
      filterData: {},
      searchKey: "",
      sortField: "",
      pageNo: 0,
      sort: {},
      itStr: "",
      data: [],
      item: []
    }
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.counterSalehandleClose = this.counterSalehandleClose.bind(this);
    this.counterSalehandleShow = this.counterSalehandleShow.bind(this);
    this.onChange = this.onChange.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
    this.projectOpenHandler = this.projectOpenHandler.bind(this);
    this.projectCloseHandler = this.projectCloseHandler.bind(this);
    this.outwardOpenHandler = this.outwardOpenHandler.bind(this);
    this.outwardCloseHandler = this.outwardCloseHandler.bind(this);
    this.inwardPopupOpen = this.inwardPopupOpen.bind(this);
    this.inwardPopupClose = this.inwardPopupClose.bind(this);
    this.listAllData = this.listAllData.bind(this);
    this.downloadCsv = this.downloadCsv.bind(this);
    this.csvShow = this.csvShow.bind(this);
    this.csvClose = this.csvClose.bind(this);
  }
  projectOpenHandler() {
    this.props.dispatch(getProjectOutwardList());
    this.setState({
      projectPopup: true,
      itStr: "project"
    })
  }
  projectCloseHandler() {
    this.setState({
      projectPopup: false,
      itStr: ""
    })
  }
  csvShow() {
    this.setState({ csvShow: true });
  }
  csvClose() {
    this.setState({ csvShow: false });
  }
  outwardOpenHandler(item) {
    const { itStr } = this.state;
    this.props.dispatch(getInternalTransferProductList(item.billOfQuantityId));
    let dataValues = {
      billOfQuantityId: item.billOfQuantityId,
      billOfQuantityNumber: item.billOfQuantityNumber,
      customerName: item.customerName,
      outwardProductList: item?.outwardProductList,
      projectId: item.projectId,
      projectName: item.projectName,
      purchaseOrderId: item.purchaseOrderId,
      status: item.status
    };
    this.setState({
      outwardPopup: true,
      projectPopup: false,
      editShow: false,
      itStr: itStr === "project" ? "project" : "IT",
      data: dataValues,
      item: item
    })
  }
  outwardCloseHandler() {
    this.listAllData();
    this.setState({
      outwardPopup: false,
      itStr: ""
    })
  }
  inwardPopupOpen(item) {
    if (item?.inwardType !== "OUTWARD") {
      this.setState({
        inwardShow: true,
        inwardRemark: item.inwardRemarks
      });
    }
  }
  inwardPopupClose() {
    this.setState({
      inwardShow: false
    });
  }
  handleShow() {
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }
  counterSalehandleShow() {
    this.props.dispatch(getCustomersList(0));
    this.props.dispatch(getVendorList());
    this.setState({ counterSaleShow: true });
  }
  counterSalehandleClose() {
    this.setState({ counterSaleShow: false });
    this.listAllData();
  }
  searchHandler(e) {
    const { sortType, sortField, filterData } = this.state;
    this.setState({
      searchKey: e.target.value
    });
    let url = getExecutiveUrl(0, e.target.value, filterData, sortField, sortType);
    this.props.dispatch(getOutwardLogs(url));
  }
  sortHandler(sortField) {
    const { pageNo, searchKey, filterData, sortType } = this.state;
    this.setState({
      sortField: sortField
    });
    let type = sortType;
    if (this.state.sortType === 'desc') {
      type = 'asc';
      this.setState({
        sortType: 'asc'
      })
    } else {
      type = 'desc';
      this.setState({
        sortType: 'desc'
      })
    };
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, type);
    this.props.dispatch(getOutwardLogs(url));
  }
  filterSubmit(filterData) {
    const { pageNo, searchKey, sortType, sortField } = this.state;
    this.setState({
      filterData: filterData
    })
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
    this.props.dispatch(getOutwardLogs(url));
  }
  resetHandler() {
    const { pageNo, sortField, sortType, searchKey } = this.state;
    this.setState({
      filterData: {}
    })
    let url = getExecutiveUrl(pageNo, searchKey, {}, sortField, sortType);
    this.props.dispatch(getOutwardLogs(url));
  }
  onChange(pageNo) {
    this.setState({
      pageNo: pageNo
    });
    const { searchKey, sortType, sortField, filterData } = this.state;
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
    this.props.dispatch(getOutwardLogs(url));
  }
  listAllData() {
    const { pageNo, searchKey, filterData, sortType, sortField } = this.state;
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
    this.props.dispatch(getOutwardLogs(url));
  }
  downloadCsv() {
    const { pageNo, searchKey, filterData, sortType, sortField } = this.state;
    let url = getExecutiveUrl(pageNo, searchKey, filterData, sortField, sortType);
    this.props.dispatch(downloadOutwardCsv(url));
    this.csvClose();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.outward_list !== this.props.outward_list) {
      this.setState({
        outward: this.props.outward_list,
      });
    } if (prevProps.deliver_list !== this.props.deliver_list) {
      this.setState({
        deliver: this.props.deliver_list,
      });

    } if (prevProps.csvData !== this.props.csvData) {
      this.setState({
        csvData: this.props.csvData,
      });
      exportToJson(this.props.csvData, "OutwardLogs");
    }
  }
  componentDidMount() {
    this.props.dispatch(getOutwardLogs("page=0"));
    this.props.dispatch(getDeliverLogs(0));
  }

  render() {
    const { outward, counterSaleShow, deliver, projectPopup, outwardPopup, itStr, item } = this.state;
    let data = {
      totalElements: outward.totalElements,
      totalPages: outward.totalPages,
      offset: outward?.pageable?.offset,
      numberOfElements: outward.numberOfElements,
      num: (outward?.number) + 1
    }
    return (
      <div>
        <Navbar />
        <div className="dashboard-page">
          <div className="dashboard-sidebar">
            <Sidebar />
          </div>
          <div className="dashboard-main">
            <Card className="staff-page">
              <div className="page-head-split">
                <div className="head-colums">
                  <div className="head-part-tab">
                    <img src={Orange} alt="..." className="head-imgs" style={{ width: "35px", height: "35px" }} />
                    <h5>Outward</h5>
                  </div>
                  <div className="tab-linkd">
                    {/* <Link to={"/" + role + "/inward"} >Inward Logs</Link>
                    <Link to={"/" + role + "/inward/outward"} className="active">Outward Logs</Link> */}
                  </div>
                  <div className="customer-search">
                    <div className="download_btn">
                      <img src={Art} alt=".." onClick={this.csvShow} />
                    </div>
                    <span><img src={Search} alt=".." className="search-img" /></span>
                    <Form.Control type="search" placeholder="Search" onChange={this.searchHandler} className="main-search" />
                  </div>
                  <OutWardFilter onSubmit={this.filterSubmit} reset={this.resetHandler} />
                  <button className="create-del" onClick={this.projectOpenHandler}><img src={plus} alt=".." />Deliver</button>
                  <CounterSale
                    deliverList={deliver}
                    handleShow={this.counterSalehandleShow}
                    show={counterSaleShow}
                    onHide={this.counterSalehandleClose}
                  />
                  <Projects
                    show={projectPopup}
                    onHide={this.projectCloseHandler}
                    handleShow={this.outwardOpenHandler}
                  />
                  <Outward
                    show={outwardPopup}
                    onHide={this.outwardCloseHandler}
                    data={this.state.data}
                    str={itStr}
                    id={item.billOfQuantityId}
                  />
                </div>
              </div>
              <div className="table-class">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th>Type
                        <img src={Filter} alt="..." onClick={(e) => this.sortHandler("ol.outward_type")} />
                      </th>
                      <th scope="col">
                        BOQ No  <img src={Filter} alt="..." onClick={(e) => this.sortHandler("boq.bill_of_quantity_id")} />
                      </th>
                      <th scope="col">Customer Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("c.customer_first_name")} /></th>
                      <th scope="col">
                        Project Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("pop.project_name")} />
                      </th>
                      <th scope="col">
                        Product Name  <img src={Filter} alt="..." onClick={(e) => this.sortHandler("p.product_name")} />
                      </th>
                      <th scope="col" style={{ textAlign: "center" }} >
                        Qty <img src={Filter} alt="..." onClick={(e) => this.sortHandler("ol.outward_quantity")} />
                      </th>
                      <th scope="col" style={{ textAlign: "right" }}>
                        Outward Date/Time <img src={Filter} alt="..." onClick={(e) => this.sortHandler("ol.created_at")} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {outward?.content && outward?.content.length > 0 ? (
                      outward?.content.map((item, i) => {
                        return <tr key={i} style={{ cursor: "pointer" }}>
                          <td>{camelize(item?.outwardType)}</td>
                          <td className="txt-center">{shortStr(item?.billOfQuantityNumber) || "-"}</td>
                          <td>{item?.customerName || "-"}</td>
                          <td className="customer_name_size">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item.projectName || "-"}</Tooltip>}>
                              <span className="d-inline-block">
                                {item.projectName || "-"}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td className="inward_new_size">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.productName || "-")}</Tooltip>}>
                              <span className="d-inline-block">
                                {parse(item.productName || "-")}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td style={{ textAlign: "center" }}>{item?.quantity}</td>
                          <td style={{ textAlign: "right" }}>{changeDateFormat(item?.isOutwardAt)}</td>
                        </tr>
                      })) : !this.props.loading ?
                      <h1 className="empty-data">No Records Found</h1> :
                      <Spinner />
                    }
                  </tbody>
                </table>
              </div>
            </Card>
            <Pagination data={data} onChange={this.onChange} />
          </div>
          <Modal
            show={this.state.csvShow}
            onHide={() => {
              this.setState({
                csvShow: false
              });
            }}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <div className="modal-success">
              <h5>Are You Sure! You want to download this csv file ?</h5>
            </div>
            <div className="txt-center">
              <button className="no" onClick={this.csvClose}>cancel</button>
              <button className="okay" onClick={this.downloadCsv}>okay</button>
            </div>
          </Modal>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.outward.loading,
    outward_list: state.outward.outward_list,
    deliver_list: state.deliver.deliver_list,
    csvData: state.outward.csvData,
  };
};

export default connect(mapStateToProps)(OutWard);

