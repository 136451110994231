const validate = values => {
  const errors = {}
  const requiredFields = [
    'projectName',
    'vertexId',
    'salesExecutiveId',
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Please fill the field *'
    }
  })

  if (values.Quantity && !/^([0-9]{1,5})+(\.[0-9]{0,2})?$/i.test(values.Quantity)) {
    errors.Quantity = 'Enter valid digits Ex(19.00,23456.45,200.34)';
  }

  if (values.discount && /[^0-9 .]/i.test(values.discount)) {
    errors.discount = 'Enter valid digit';
  }
  if (values.unitCost && /[^0-9 .]/i.test(values.unitCost)) {
    errors.unitCost = 'Enter valid digit';
  }
  if (Number(values.discount) > 80) {
    errors.discount = 'discount must be less than 80';
  } if (Number(values.unitCost) < 0) {
    errors.unitCost = 'Unit cost  must be greater than 0';
  }
  return errors
}

export default validate