import { unitConstants } from "../constants";

const initialState = {
    loading: false,
    error: [],
    token: "",
    data: [],
    success_msg: "",
    page: "add",
};

export default function unitReducer(state = initialState, { type, response }) {
    switch (type) {
        case unitConstants.GET_UNITS:
            return {
                ...state,
                loading: true,
            };
        case unitConstants.GET_UNITS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                units_list: response.data,
            };
        case unitConstants.GET_UNITS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
                success_msg: ""
            };
        case unitConstants.UNIT_REQUEST:
        case unitConstants.UPDATE_UNIT_REQUEST:
            return {
                ...state,
                loading: true,
                success_msg: ""
            };
        case unitConstants.UNIT_SUCCESS:
        case unitConstants.UPDATE_UNIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success_msg: response.data
            };
        case unitConstants.UNIT_FAILURE:
        case unitConstants.UPDATE_UNIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };

        case unitConstants.CHANGE_PAGE_ADD:
            return {
                ...state,
                loading: false,
                page: "add",
            }

        case unitConstants.CHANGE_PAGE_EDIT:
            return {
                ...state,
                loading: false,
                page: "edit",
            }
        default:
            return state;
    }
}
