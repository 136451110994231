import { put, call, takeEvery } from "redux-saga/effects";
import { getstaffExecutiveApi, getSingleExecutiveApi } from "../services/staffexecutiveServices";
import { staffexecutiveConstants } from "../constants/staffexecutiveConstants";

export function* staffexecutiveSaga(payload) {
    try {
        const response = yield call(getstaffExecutiveApi, payload.pageNo, payload.filterData);
        yield put({ type: staffexecutiveConstants.GET_STAFF_EXECUTIVE_SUCCESS, response });
    } catch (error) {
        yield put({ type: staffexecutiveConstants.GET_STAFF_EXECUTIVE_FAILURE, error });
    }
}
export function* singleStaffSaga(payload) {
    try {
        const response = yield call(getSingleExecutiveApi, payload.id, payload.item);
        yield put({ type: staffexecutiveConstants.SINGLE_EXECUTIVE_SUCCESS, response });
    } catch (error) {
        yield put({ type: staffexecutiveConstants.SINGLE_EXECUTIVE_FAILURE, error });
    }
}
export default function* executiveSaga() {
    yield takeEvery(staffexecutiveConstants.GET_STAFF_EXECUTIVE, staffexecutiveSaga);
    yield takeEvery(staffexecutiveConstants.SINGLE_EXECUTIVE, singleStaffSaga);
}