import React from 'react'
import { Modal, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { convertToPo, getVendorList } from "../../../actions";
import parse from 'html-react-parser';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';

class ConvertBoqToPo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openedItem: [],
            vendorId: "",
            data: [],
            confirmPopup: false,
        }
        this.selectHandler = this.selectHandler.bind(this);
        this.selectProHandler = this.selectProHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.customerChange = this.customerChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.confirmPopupClose = this.confirmPopupClose.bind(this);
        this.confirmPopupOpen = this.confirmPopupOpen.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
    }
    confirmPopupOpen() {
        const { openedItem } = this.state;
        if (openedItem.includes(true)) {
            if (this.state.vendorId !== "") {
                this.setState({
                    confirmPopup: true
                })
            } else {
                toast("Select a vendor!!");
            }
        } else {
            toast("Select Atleast One Product");
        }
    }
    confirmPopupClose() {
        this.setState({
            confirmPopup: false
        })
    }
    selectHandler(e) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((item) => item = e.target.checked);
        this.setState({
            openedItem: newItems,
        })
    }
    selectProHandler(e, index) {
        const { openedItem } = this.state;
        let newItems = openedItem.map((item, i) => index === i ? e.target.checked : item);
        this.setState({
            openedItem: newItems
        })
    }
    changeHandler = (e, index) => {
        const { data } = this.props;
        let b = e.target.value;
        if (b && !/^([0-9]{1,5})+(\.[0-9]{0,2})?$/i.test(b)) {
            toast("Enter valid digits Ex(19.00,23456.45,200.34)");
            return false;
        }
        data[index]["poQty"] = b;
        this.setState({
            data: data
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.convertProducts !== this.props.convertProducts) {
            let data = [];
            this.props.data.map((item, i) => data[i] = false);
            this.setState({
                openedItem: data,
                data: this.props.convertProducts.data
            })
        }
    }
    customerChange(e) {
        this.props.allData?.content?.map((item) => {
            if (item.name === e[0]) {
                this.setState({
                    vendorId: item.vendorId
                })
            }
        });
    }
    inputChange(text, e) {
        this.props.dispatch(getVendorList(0, text));
    }
    submitHandler() {
        const { openedItem, data, vendorId } = this.state;
        let pro = [];
        data?.map((item, i) => {
            let items = {};
            if (openedItem[i]) {
                if (Number(item.poQty) !== 0) {
                    items["billOfQuantityProjectId"] = item.billOfQuantityProjectId;
                    items["billOfQuantityProductId"] = item.billOfQuantityProductId;
                    items["quantity"] = Number(item.poQty);
                    pro.push(items);
                }
            }
        });
        let formData = {
            "vendorId": vendorId,
            "billOfQuantityProductDetail": pro
        }
        this.props.dispatch(convertToPo(formData));
    }
    render() {
        const { show, onHide, data, allData, load } = this.props;
        const { openedItem, confirmPopup } = this.state;
        return (
            <>
                {/* <button className="convert-po" onClick={handleShow}>
                    <img src={plus} alt="" />Convert BOQ to PO</button> */}
                <Modal
                    show={show}
                    size="xl"
                    onHide={() => {
                        onHide();
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-staff">
                        <div className="head">
                            <div>
                                <i class="fas fa-arrow-left" onClick={() => {
                                    onHide();
                                }}></i>
                                <h4>CONVERT BOQ TO PO</h4>
                            </div>
                        </div>
                        {allData?.content?.length >= 0 ?
                            <div className='space-parts'>
                                <Typeahead
                                    onChange={this.customerChange}
                                    id="async-example"
                                    style={{ margin: "20px" }}
                                    labelKey={allData?.content?.map(item => item.name)}
                                    onInputChange={this.inputChange}
                                    options={allData?.content?.map(item => item.name)}
                                    placeholder="Choose a Vendor..."
                                />
                                <div className="triangle-bottom" style={{ margin: "57px 20px 0 0" }}></div>
                            </div>
                            : null}
                        <div className="table-class space-part">
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Art. ID
                                        </th>
                                        <th scope="col">
                                            Product Name
                                        </th>
                                        <th scope="col" className='txt-center'>
                                            BOQ
                                        </th>
                                        <th scope="col" className='txt-center'>
                                            Available Qty
                                        </th>
                                        <th scope="col" className='txt-center'>
                                            PO Qty
                                        </th>
                                        <th scope="col" className='check-item'>
                                            Select
                                            <input type="checkbox" onChange={this.selectHandler} style={{ margin: "7px" }} />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data !== undefined && data?.length > 0 ?
                                        data?.map((item, i) => (
                                            <tr key={i} >
                                                <td>{item?.articleId}</td>
                                                <td className="new_bulk_sizee">
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.productName || "-")}</Tooltip>}>
                                                        <span className="d-inline-block">
                                                            {parse(item.productName || "-")}
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                                <td className='txt-center'>{item?.quantity}</td>
                                                <td className='txt-center'>{item?.inStockQuantity}</td>
                                                <td className='txt-center'><input
                                                    type="text"
                                                    className='convert_po_input'
                                                    value={item?.poQty ?? 0}
                                                    maxLength="8"
                                                    onChange={(e) => this.changeHandler(e, i)} />
                                                </td>
                                                <td>
                                                    <Form.Check
                                                        checked={openedItem[i] ? true : false}
                                                        onChange={(e) => this.selectProHandler(e, i)}
                                                        name="group1"
                                                        type="checkbox"
                                                        className="form-check-box"
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                        : <h1 className="empty-data">No Records Found</h1>}
                                </tbody>
                            </table>
                        </div>
                        <center style={{ padding: "15px" }}>
                            <button className="customer-btn col-md-6" onClick={this.confirmPopupOpen}>Convert to PO</button>
                        </center>
                    </div>
                </Modal>
                <Modal
                    show={confirmPopup}
                    onHide={() => {
                        this.setState({
                            confirmPopup: false
                        });
                    }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className="modal-success">
                        <h5>Are you sure , you want to Generate PO ?</h5>
                    </div>
                    <div className="txt-center">
                        <button onClick={() => {
                            this.setState({
                                confirmPopup: false
                            });
                        }}
                            className="no_btn"
                        >
                            Cancel
                        </button>
                        {!load ?
                            <button onClick={this.submitHandler} className="okay"> Okay
                            </button>
                            : <button className="okay">Generating...</button>}
                    </div>
                </Modal>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        convertProducts: state.po.convertProducts,
        allData: state.vendor.allData,
        load: state.po.load
    }
}


export default connect(mapStateToProps)(ConvertBoqToPo);
