const validate = values => {
  const errors = {}

  const requiredFields = [
    'firstName',
    // 'contactNumber',
    'gstNumber',
    'email',
    'state',
    'city',
    'pinCode',
    'vendorInitial'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Please fill the field *';
    }
  })


  // if ((values.firstName && /[^a-zA-Z- ./]/i.test(values.firstName))) {
  //   errors.firstName = "Only albhabetic characters allowed";
  // }

  if (values?.firstName?.trim().length === 0) {
    errors.firstName = "Please fill the field * ";
  }
  if (values.firstName?.length < 4) {
    errors.firstName = "firstName must be contains 4  characters";
  }
  // if ((values.lastName && /[^a-zA-Z- ./]/i.test(values.lastName))) {
  //   errors.lastName = "Only albhabetic characters allowed";
  // }
  // if (values.lastName?.length < 4) {
  //   errors.lastName = "lastName must be contains 4  characters";
  // }
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid Email Address *'
  }

  if (values.email0 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email0)) {
    errors.email0 = 'Invalid Email Address 1*'
  } if (values.email1 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email1)) {
    errors.email1 = 'Invalid Email Address 2*'
  } if (values.email2 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email2)) {
    errors.email2 = 'Invalid Email Address 3*'
  } if (values.email3 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email3)) {
    errors.email3 = 'Invalid Email Address 4*'
  } if (values.email4 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email4)) {
    errors.email4 = 'Invalid Email Address 5*'
  } if (values.email5 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email5)) {
    errors.email5 = 'Invalid Email Address 6*'
  } if (values.email6 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email6)) {
    errors.email6 = 'Invalid Email Address 7*'
  } if (values.email7 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email7)) {
    errors.email7 = 'Invalid Email Address 8*'
  } if (values.email8 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email8)) {
    errors.email8 = 'Invalid Email Address 9*'
  } if (values.email9 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email9)) {
    errors.email9 = 'Invalid Email Address 10*'
  } if (values.email10 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email10)) {
    errors.email10 = 'Invalid Email Address 11*'
  } if (values.email11 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email11)) {
    errors.email11 = 'Invalid Email Address 12*'
  }


  if (values.contactNumber && !/^(0|[1-9][0-9]{9})$/i.test(values.contactNumber)) {
    errors.contactNumber = 'Invalid Phone Number'
  }
  if ((values.city && /[^a-zA-Z- .,/]/i.test(values.city))) {
    errors.city = "Only albhabetic characters allowed";
  }
  if ((values.gstNumber && /[^a-zA-Z0-9 ]/i.test(values.gstNumber))) {
    errors.gstNumber = "Only alphanumeric characters allowed";
  }
  // if (values.city?.length < 4) {
  //   errors.city = "city must be contains 4  characters";
  // }
  if ((values.state && /[^a-zA-Z .,/]/i.test(values.state))) {
    errors.state = "Not Valid";
  }

  // if ((values.address && /[^a-zA-Z0-9- !@#$%*?()/,.:]/.test(values.address))) {
  //   errors.address = "Not Valid";
  // }
  // if (values.state?.length < 4) {
  //   errors.state = "state must be contains 4  characters";
  // }

  if (values.pinCode && !/^(0|[1-9][0-9]{5})$/i.test(values.pinCode)) {
    errors.pinCode = 'Invalid Pincode *'
  }
  return errors
}

export default validate