import React from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Field, reduxForm, reset, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import Navbar from "../../../../../layouts/Navbar";
import Sidebar from "../../../../../layouts/Sidebar";
import Orange from "../../../../../assets/images/green2.svg";
import plus from "../../../../../assets/images/pl.svg";
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from "react-toastify";
import CustomerForm from "../../../../customers/CustomerForm";
import {
    changePage,
    getCustomersList,
    createBillOfQuantity,
    createCustomer,
    getCustomer,
    getBoqById,
    updateBillOfQuantity,
    getTitleStr,
} from "../../../../../actions";

const role = localStorage.getItem('userRole');
const textField = ({ input, name, label, placeholder, defaultValue, disabled, type, meta: { touched, error, warning } }) => (
    <Form.Group className="mb-3" controlId={label}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
            {...input}
            name={name}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            defaultValue={defaultValue}
        />
        {touched && error && <p className="require">{error}</p>}
    </Form.Group>
)
class CustomerDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            empty: "",
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.customerChange = this.customerChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.addBoqDetails = this.addBoqDetails.bind(this);
        this.addCustomerHandler = this.addCustomerHandler.bind(this);
        this.prevPage = this.prevPage.bind(this);
    }
    handleShow() {
        this.props.dispatch(changePage());
        this.setState({ show: true, empty: "create" });
    }
    handleClose() {
        this.setState({ show: false, empty: "" });
    }
    prevPage() {
        window.location.hash = `/${role}/bill_of_quantity`
    }
    addBoqDetails(values) {
        if (this.props.match.params.id !== "add") {
            const { boqIdData } = this.props;
            if (boqIdData.customerFirstName !== values.firstName) {
                let formData = {
                    "billOfQuantityId": this.props.match.params.id,
                    "customerId": values.customerId
                }
                this.props.dispatch(updateBillOfQuantity(formData));
            } else {
                window.location.hash = "/" + role + "/bill_of_quantity/product_details/" + this.props.match.params.id;
            }
        } else {
            if (values.customerId === undefined) {
                toast("Please select a customer");
                return false
            }
            let formData = {
                "customerId": values.customerId,
            }
            this.props.dispatch(createBillOfQuantity(formData));
        }
    }
    inputChange(text, e) {
        this.props.dispatch(getCustomersList(0, text));
    }
    customerChange(e) {
        this.props.customerList?.map((item) => {
            if (item.customerName === e[0]) {
                this.props.dispatch(getCustomer(item?.customerId));
            }
        });
    }
    addCustomerHandler(formData, dispatch) {
        if (!formData.customerInitials) {
            throw new SubmissionError({
                customerInitials: 'Please fill the field *',
            })
        } else if (!formData.firstName) {
            throw new SubmissionError({
                firstName: 'Please fill the field *',
            })
        }
        this.props.dispatch(createCustomer(formData));
        dispatch(reset("customerForm"));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.customer_msg !== this.props.customer_msg) {
            this.setState({
                show: false,
                empty: ''
            });
            if (this.props.customer_msg.status === 200) {
                toast(this.props.customer_msg.data);
                this.props.dispatch(getCustomersList(0));
            } else {
                toast(this.props.customer_msg.data);
            }
        } if (prevProps.data !== this.props.data) {
            const { data, history } = this.props;
            if (data.status === 200) {
                toast(data.message);
                history.push(`/${role}/bill_of_quantity/product_details/${data?.data?.billOfQuantityId}`);
            } else {
                toast(data.message);
            }
        }

    }
    componentDidMount() {
        this.props.dispatch(changePage());
        this.props.reset();
        this.props.dispatch(getCustomersList(0));
        if (this.props.match.params.id !== "add") {
            this.props.dispatch(getBoqById(this.props.match.params.id));
        }
    }

    render() {
        const { handleSubmit, customerList } = this.props;
        const { history } = this.props;
        const { show } = this.state;
        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <img src={Orange} alt="..." className="head-img" />
                            <h5 className='po_link' onClick={() => window.location.hash = "/" + role + "/bill_of_quantity"}>Bill Of Quantity</h5>
                            <i className="fas fa-arrow-right" style={{ margin: "10px 0 0" }}></i>
                            <h6 >{getTitleStr()}</h6>
                            <hr className="line-new"></hr>
                            <div className="order-page-title">
                                <Link to={"/" + role + "/bill_of_quantity/customer_details/" + this.props.match.params.id} className="active">
                                    Customer Details
                                </Link>
                                <Link to={"/" + role + "/bill_of_quantity/product_details/" + this.props.match.params.id} >
                                    Product Details
                                </Link>
                            </div>
                            <form onSubmit={handleSubmit(this.addBoqDetails)}>
                                <Row className="vendor-details">
                                    <Col md={9}>
                                        <label className="span-name">Select Customer</label>
                                        {customerList?.length >= 0 ? <Typeahead
                                            onChange={this.customerChange}
                                            id="async-example"
                                            labelKey={customerList.map(item => item.customerName)}
                                            onInputChange={this.inputChange}
                                            options={customerList.map(item => item.customerName)}
                                            placeholder="Select Customer by Name"
                                        /> : null}

                                    </Col>
                                    <span className="customer-span" style={{ marginTop: "40px" }}>Or</span>
                                    <Col md={2}>
                                    </Col>
                                    <Col md={3} className="top-space">
                                        <Form.Label>Initials</Form.Label>
                                        <Field name="customerInitials" component="select" className="form-control form-select" disabled={true} >
                                            <option value="" disabled={true}>Select One</option>
                                            <option value="Mr" >Mr</option>
                                            <option value="M/s" >M/s</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss" >Miss</option>
                                        </Field>
                                    </Col>
                                    <Col md={3} className="top-space">
                                        <Field
                                            name="firstName"
                                            type="text"
                                            component={textField}
                                            label="First Name"
                                            placeholder="Enter First Name"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3} className="top-space">
                                        <Field
                                            name="lastName"
                                            type="text"
                                            component={textField}
                                            label="Last Name"
                                            placeholder="Enter Last Name"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3} className="top-space">
                                        <Field
                                            name="phone"
                                            type="text"
                                            component={textField}
                                            label="Contact Number"
                                            placeholder="Enter Contact Number"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="email"
                                            type="text"
                                            component={textField}
                                            label="Email"
                                            placeholder="Enter Email"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="gstNumber"
                                            type="text"
                                            component={textField}
                                            label="Gst Number"
                                            placeholder="Enter Gst Number"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field
                                            name="address"
                                            type="text"
                                            component={textField}
                                            label="Address"
                                            placeholder="Enter Address"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="city"
                                            type="text"
                                            component={textField}
                                            label="City"
                                            placeholder="Enter City"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="state"
                                            type="text"
                                            component={textField}
                                            label="State"
                                            placeholder="Enter State"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="pincode"
                                            type="text"
                                            component={textField}
                                            label="Pincode"
                                            placeholder="Enter Pincode"
                                            required={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Field
                                        name="customerId"
                                        type="hidden"
                                        component={textField}

                                    />
                                    <Col md={3} ></Col>
                                    <Col md={3} >
                                        <button className="cancel-btn" onClick={() => history.push(`/${role}/bill_of_quantity`)} style={{ marginTop: "100px" }}>Cancel</button>
                                    </Col>
                                    <Col md={6} >
                                    </Col>
                                    <Col md={3} >
                                        <button type="submit" className="modal-btn save" style={{ marginTop: "100px", marginLeft: "100px" }}>Save & Proceed</button>
                                    </Col>
                                </Row>
                            </form>
                            <div className="modal-btn boq_btn" style={{ marginTop: "10px" }} onClick={(e) => this.handleShow()}>
                                <img src={plus} alt="" />
                                Add New Customer
                            </div>
                            <br></br>
                        </Card>
                        <CustomerForm show={show} onHide={this.handleClose} onSubmit={this.addCustomerHandler} />
                    </div>
                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    let initValues;
    let quote = state.boq.boqIdData;
    let customer = state.customer.customer;
    let newCustomer = quote.billOfQuantityId !== null ? {
        'customerInitials': quote ? quote.customerInitials : '',
        'firstName': quote ? quote.customerFirstName : '',
        'lastName': quote ? quote.customerLastName : '',
        'phone': quote ? quote.customerNumber : '',
        'email': quote ? quote.customerEmail : '',
        'address': quote ? quote.customerAddress : '',
        'city': quote ? quote.customerCity : '',
        'state': quote ? quote.customerState : '',
        'pincode': quote ? quote.customerPincode : '',
    } : {};

    initValues = Object.keys(customer).length !== 0 ? customer : newCustomer;

    return {
        initialValues: initValues,
        customer_msg: state.customer.success_msg,
        customerList: state.customer.customers_list,
        data: state.boq.data,
        boqIdData: state.boq.boqIdData
    }
}
const VendorDetailsForm = reduxForm({
    form: 'purchaseOrderFormRedux',
    destroyOnUnmount: false,
    enableReinitialize: true,
    // validate
})(CustomerDetails)

export default connect(mapStateToProps)(VendorDetailsForm);