const validate = values => {
  const errors = {}

  const requiredFields = [
    'firstName',
    'customerInitials'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Please fill the field *';
    }
  })

  // if ((values.firstName && /[^a-zA-Z- ./]/i.test(values.firstName))) {
  //   errors.firstName = "Only albhabetic characters allowed";
  // }

  if (values?.firstName?.trim().length === 0) {
    errors.firstName = "Please fill the field * ";
  }
  if (values.firstName?.length < 4) {
    errors.firstName = "firstName must be contains 4  characters";
  }
  // if ((values.lastName && /[^a-zA-Z- ./]/i.test(values.lastName))) {
  //   errors.lastName = "Only albhabetic characters allowed";
  // }
  // if (values.lastName?.length < 4) {
  //   errors.lastName = "lastName must be contains 4  characters";
  // }
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid Email Address *'
  }
  if (values.phone && !/^(0|[1-9][0-9]{9})$/i.test(values.phone)) {
    errors.phone = 'Invalid Phone Number'
  }
  if ((values.city && /[^a-zA-Z- .,/]/i.test(values.city))) {
    errors.city = "Only albhabetic characters allowed";
  }
  // if (values.city?.length < 4) {
  //   errors.city = "city must be contains 4  characters";
  // }
  if ((values.state && /[^a-zA-Z .,/]/i.test(values.state))) {
    errors.state = "Not Valid";
  }

  // if ((values.address && /[^a-zA-Z0-9- !@#$%*?()/,.:]/.test(values.address))) {
  //   errors.address = "Not Valid";
  // }
  // if (values.state?.length < 4) {
  //   errors.state = "state must be contains 4  characters";
  // }

  if (values.pincode && !/^(0|[1-9][0-9]{5})$/i.test(values.pincode)) {
    errors.pincode = 'Invalid Pincode *'
  }
  return errors
}

export default validate