import { reportPurchaseConstants } from "../constants";

const initialState = {
    loading: false,
    data: [],
    error: [],
    token: "",
    status: 0,
    csvData: [],
    load: false,
};

export default function reportPurchaseReducer(state = initialState, { type, response }) {
    switch (type) {
        case reportPurchaseConstants.GET_REPORT_PURCHASE:
            return {
                ...state,
                loading: true,
            };
        case reportPurchaseConstants.GET_REPORT_PURCHASE_DOWNLOAD:
            return {
                ...state,
                load: true,
            };
        case reportPurchaseConstants.GET_REPORT_PURCHASE_SUCCESS:
            return {
                ...state,
                loading: false,
                report_Purchase: response.data,
                allData: response.data,
            };
        case reportPurchaseConstants.GET_REPORT_PURCHASE_DOWNLOAD_SUCCESS:
            return {
                ...state,
                load: false,
                csvData: response,
            };
        case reportPurchaseConstants.CLEAR_REPORT_CSV_DATA:
            return {
                ...state,
                csvData: [],
            };
        case reportPurchaseConstants.GET_REPORT_PURCHASE_FAILURE:
        case reportPurchaseConstants.GET_REPORT_PURCHASE_DOWNLOAD_FAILURE:
            return {
                ...state,
                loading: false,
                load: false,
                error: response,
            };
        default:
            return state;
    }
}
