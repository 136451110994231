export const unitConstants = {
    GET_UNITS: "GET_UNITS_LIST",
    GET_UNITS_LIST_SUCCESS: "GET_UNITS_LIST_SUCCESS",
    GET_UNITS_LIST_FAILURE: "GET_UNITS_LIST_FAILURE",

    UNIT_REQUEST: "ADD_UNIT_REQUEST",
    UNIT_SUCCESS: "ADD_UNIT_SUCCESS",
    UNIT_FAILURE: "ADD_UNIT_FAILURE",

    UPDATE_UNIT_REQUEST: "UPDATE_UNIT_REQUEST",
    UPDATE_UNIT_SUCCESS: "UPDATE_UNIT_SUCCESS",
    UPDATE_UNIT_FAILURE: "UPDATE_UNIT_FAILURE",

    CHANGE_PAGE_ADD: "CHANGE_PAGE_ADD",
    CHANGE_PAGE_EDIT: "CHANGE_PAGE_EDIT",

}