import React from 'react'
import { Field, reduxForm } from 'redux-form';
import Plus from "../../../assets/images/pl.svg";
import { useParams } from "react-router-dom";
import { Row, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
// import validate from "./validate";
import "antd/dist/antd.css";


const renderField = ({ input, placeholder, maxLength, disabled, type, meta: { touched, error } }) => (
    <div>
        <label></label>
        <div>
            <input  {...input} placeholder={placeholder} disabled={disabled} type={type} className="form-control" maxLength={maxLength} />
            {touched && error && <p className="require">{error}</p>}
        </div>
    </div>
)

const GroupForm = props => {
    const { error, handleSubmit, show, onHide, page, str, reset, handleShow, initialValues } = props;

    const showModal = () => {
        reset();
        handleShow();
    }

    return (
        <>
            <Modal
                show={show}
                size="md"
                onHide={() => {
                    onHide();
                    reset();
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <div className="modal-staff">
                    <div className="head">
                        <div>
                            <i class="fas fa-arrow-left" onClick={() => {
                                reset();
                                onHide();
                            }}></i>
                            <h4>{str === "edit" ? "Update" : "Add New"} Group</h4>
                        </div>
                    </div>
                    <Row>
                        <form onSubmit={handleSubmit} className="customer">
                            <Row>
                                {str === "edit" ? <Col md={12}>
                                    <Field name="quotationMaterialGroupId" type="text" disabled={true} component={renderField} placeholder="group Id " maxLength="25" />
                                </Col> : null}
                                <Col md={12}>
                                    <Field name="groupName" type="text" component={renderField} placeholder="Enter Group Name " maxLength="55" />
                                </Col>
                                {error && <strong>{error}</strong>}
                                <Col md={12}><br></br>
                                    <button type="submit" className="customer-btn">
                                        {str === "edit" ? "Update" : "Create"} Group
                                    </button>
                                </Col>
                            </Row>
                        </form>
                    </Row>
                </div>
            </Modal>
        </>
    )
}

function mapStateToProps(state) {

    return {
        group: state.group.group,
        status: state.group.status,
        page: state.group.page
    }
}

const Form = reduxForm({
    form: "groupForm",
    enableReinitialize: true,
    destroyOnUnmount: false,
    // validate
})(GroupForm);

export default connect(mapStateToProps)(Form);


