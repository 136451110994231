import React from 'react'
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { showFixedDigitsValue, showStockValue,msInward, createPoMsInwardProducts, clearInwardStatus } from '../../../actions';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import Spinner from '../../../Components/loader';
import { useState } from 'react';
import { useEffect } from 'react';

const InwardMsProducts = (props) => {

    const {show, inwardData, load, onHide, id, onInwardDispatch, createPoInwardProducts, addedInwardStatus, onClearInwardStatus} = props;
    const [inwData, setInwData] = useState([]);

    const onHideModal = () => {
        onHide();
    }

    const changeHandler = (e, i) => {
        let a = Number(inwData[i]?.balance);
        let b = e.target.value;
        if ((b && !/^([0-9]{1,5})+(\.[0-9]{0,2})?$/i.test(b))) {
            toast("Enter valid digits Ex(19.00,23456.45,200.34)");
            return false;
        }
        if (+b <= a) {
           const updatedInwData = [...inwData];
           updatedInwData[i] = { ...updatedInwData[i], inwardQuantity: b };
           setInwData(updatedInwData);
        } else {
            toast("Pls enter the number less than or equal to " + a);
        }
    }

    const submitHandler = () => {
        let pro = [];
        inwData?.map((item, i) => {
            let items = {};
            if (Number(item?.quantity) !== 0) {
                items["projectProductId"] = [item?.purchaseProjectProductId];
                items["stockQty"] = item?.inwardQuantity;
                pro.push(items);
            }
        });
        createPoInwardProducts(pro);
    }

    useEffect(() => {
        if(inwardData) {
            setInwData(inwardData?.data?.projectDetail);
        }
    }, [inwardData])

    useEffect(() => {
      if(show) {
         onInwardDispatch({
            id: id
         })
        }
    },[show])

    useEffect(() => {
        if(addedInwardStatus && addedInwardStatus == 200) {
            onClearInwardStatus();
            onHideModal();
        }
    },[addedInwardStatus])

    return (
        <Modal show={show} size="xl" onHide={onHideModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <div className="modal-staff">
                <div className="head">
                    <div>
                        <i class="fas fa-arrow-left" onClick={onHideModal}></i>
                        <h4>INWARD PRODUCTS</h4>
                    </div>
                </div>
                <div className="table-class space-part">
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Project</th>
                                <th scope="col">Article ID</th>
                                <th scope="col">Product</th>
                                <th scope="col" style={{ textAlign: "center" }}>PO Qty</th>
                                <th scope="col" style={{ textAlign: "center" }}>Con.Qty</th>
                                <th scope="col" style={{ textAlign: "center" }}>Inward</th>
                                <th scope="col" style={{ textAlign: "center" }}>Balance</th>
                                <th scope="col" style={{ textAlign: "center" }}>Available Stock</th>
                            </tr>
                        </thead>
                        <tbody>
                            { inwData?.length !== 0 ? inwData?.map((item, i) => (
                                <tr key={i} >
                                    <td className="new_bulk_size">
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {item?.projectName || "-"}</Tooltip>}>
                                            <span className="d-inline-block">{item?.projectName || "-"}</span>
                                        </OverlayTrigger>
                                    </td>   
                                    <td>{item?.articleId || "-"}</td>
                                    <td className="new_bulk_size">
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item?.productName || "-")}</Tooltip>}>
                                            <span className="d-inline-block">{parse(item?.productName || "-")}</span>
                                        </OverlayTrigger>
                                    </td>  
                                    <td style={{ textAlign: "center" }}>{inwardData?.data?.projectDetail[i]?.quantity}</td>
                                    <td style={{ textAlign: "center" }}>{inwardData?.data?.projectDetail[i]?.confirmedQuantity}</td>  
                                    <td style={{ textAlign: "center" }}>
                                        <input type="text" className='convert_po_input' value={item?.inwardQuantity} onChange={(e) => changeHandler(e, i)} />
                                    </td>
                                    <td style={{ textAlign: "center" }}> {`${inwardData?.data?.projectDetail[i]?.inwardQuantity - item.inwardQuantity === 0 ? 0 : showFixedDigitsValue(inwardData?.data?.projectDetail[i]?.inwardQuantity, item.inwardQuantity)}`} </td>
                                    <td style={{ textAlign: "center" }}> {`${showStockValue(item?.inwardQuantity, item?.availableStock)}`}</td>
                                </tr >
                            )) : !load ? <h1 className="empty-data">No Records Found</h1> : <Spinner />
                            }
                        </tbody>
                    </table>
                </div>
                <center style={{ padding: "15px" }}>
                    {!load ?
                        <button className="customer-btn col-md-6" disabled={inwData?.length === 0} onClick={() => submitHandler()}>Inward Products</button>
                        : <button className="customer-btn col-md-6">Loading...</button>}
                </center >
             </div>
        </Modal >
    )
    
}

function mapStateToProps(state) {
    const {oa} = state;
    return {
        inwardData: oa.inwardData,
        load: oa.loading,
        addedInwardStatus: oa.inwardSuccessStatus
    }
}

const mapDispatchToProps = (dispatch) => ({
    onInwardDispatch: (formData) => dispatch(msInward(formData)),
    createPoInwardProducts: (formData) => dispatch(createPoMsInwardProducts(formData)),
    onClearInwardStatus: () => dispatch(clearInwardStatus())
})

export default connect(mapStateToProps,mapDispatchToProps)(InwardMsProducts);
