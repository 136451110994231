import { put, call, takeEvery } from "redux-saga/effects";
import { getDashboardApi, getDashboardCountApi, getDashboardOutofStockApi } from "../services";
import { dashboardConstants } from "../constants";

export function* dashboardlistSaga(payload) {
    try {
        const response = yield call(getDashboardApi, payload);
        yield put({ type: dashboardConstants.GET_DASHBOARD_SUCCESS, response });
    } catch (error) {
        yield put({ type: dashboardConstants.GET_DASHBOARD_FAILURE, error });
    }
}
export function* dashboardcountSaga(payload) {
    try {
        const response = yield call(getDashboardCountApi, payload);
        yield put({ type: dashboardConstants.GET_DASHBOARD_COUNT_SUCCESS, response });
    } catch (error) {
        yield put({ type: dashboardConstants.GET_DASHBOARD_COUNT_FAILURE, error });
    }
}
export function* dashboardOutodStckSaga(payload) {
    try {
        const response = yield call(getDashboardOutofStockApi, payload);
        yield put({ type: dashboardConstants.GET_DASHBOARD_OUTOFSTOCK_SUCCESS, response });
    } catch (error) {
        yield put({ type: dashboardConstants.GET_DASHBOARD_OUTOFSTOCK_FAILURE, error });
    }
}

export default function* dashboardSaga() {
    yield takeEvery(dashboardConstants.GET_DASHBOARD, dashboardlistSaga);
    yield takeEvery(dashboardConstants.GET_DASHBOARD_COUNT, dashboardcountSaga);
    yield takeEvery(dashboardConstants.GET_DASHBOARD_OUTOFSTOCK, dashboardOutodStckSaga);
}
