import React from "react";
import { connect } from "react-redux";
import { Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Search from "../../../assets/images/search.svg";
import { getreportcustomer, getReportOa, previewPo, shortStr, verifyDateFormat } from "../../../actions";
import "../../../assets/css/button.css";
import "../../../assets/css/popup.css";
import Re from "../../../assets/images/green2.svg";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import Spinner from "../../../Components/loader";
import Pagination from "../../../Components/Pagination";
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import OaFilter from "./oaFilter";
import OaView from "./oaView";
import OaConfirm from "./oaConfirm";


const role = localStorage.getItem('userRole');
class OrderAcceptence extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            all_report_customer: [],
            allData: [],
            show: false,
            modal: false,
            data: [],
            previewQutationTxt: "",
            target: null,
            pageNo: 0,
            filterData: {
                searchKey: null,
                pageNo: 0,
                fromDate: null,
                toDate: null,
                sizeOfPage: 20,
                size: 10
            },
            oaPopup: false
        };
        this.onChange = this.onChange.bind(this);
        this.getCustomerExe = this.getCustomerExe.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.resetHandler = this.resetHandler.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.oaShow = this.oaShow.bind(this);
        this.oaClose = this.oaClose.bind(this);
        this.onCountData = this.onCountData.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getReportOa(this.state.filterData));
    }

    handleClick = (event) => {
        if (!this.state.show) {
            this.setState({
                show: true,
                target: event.target
            })
        } else {
            this.setState({
                show: false,
            })
        }
    };
    handleSubmit(filterData) {
        let data = { ...this.state.filterData };
        data.fromDate = filterData.fromDate;
        data.toDate = filterData.toDate;
        this.setState({
            filterData: data
        })
        this.props.dispatch(getReportOa(data));
        this.setState({
            show: false,
        })
    }
    handleShow(row) {
        this.setState({
            modal: true,
            data: row
        })
        this.props.dispatch(previewPo(row.purchaseOrderId));
    }
    handleClose() {
        this.setState({
            modal: false,
            previewQutationTxt: undefined
        })
    }
    resetHandler() {
        let data = { ...this.state.filterData };
        data.fromDate = null;
        data.toDate = null;
        this.setState({
            filterData: data
        })
        this.props.dispatch(getReportOa(data));
        this.setState({
            show: false,
        })
    }
    getCustomerExe() {
        this.setState({
            show: false,
            filterData: []
        })
        this.props.dispatch(getreportcustomer(0));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.allData !== this.props.allData) {
            this.setState({ allData: this.props.allData });
        }
    }
    searchHandler(e) {
        const { filterData } = this.state;
        let data = { ...filterData };
        data.searchKey = e.target.value;
        data.pageNo = 0;
        this.setState({
            filterData: data
        })
        this.props.dispatch(getReportOa(data));
    }
    onChange(pageNo) {
        let data = { ...this.state.filterData };
        data.pageNo = pageNo;
        this.setState({
            filterData: data
        })
        this.props.dispatch(getReportOa(data));
    }

    oaShow(val) {
        this.setState({
            oaPopup: true,
            data: val
        })
    }

    oaClose() {
        this.setState({ oaPopup: false });
    }

    onCountData(e) {
        let data = { ...this.state.filterData };
        data.size = e.target.value;
        this.setState({
            filterData: data
        })
        this.props.dispatch(getReportOa(data));
    }

    render() {
        const { allData, show, target, modal, previewQutationTxt, oaPopup } = this.state;

        let data = {
            totalElements: allData?.totalElements,
            totalPages: allData?.totalPages,
            offset: allData?.pageable?.offset,
            numberOfElements: allData?.numberOfElements,
            num: (allData?.number) + 1
        }

        return (
            <>
                <div>
                    <Navbar />
                    <div className="dashboard-page">
                        <div className="dashboard-sidebar">
                            <Sidebar />
                        </div>
                        <div className="dashboard-main">
                            <Card className="staff-page">
                                <div className="page-head-split">
                                    <div className="head-colums">
                                        <div className="head-part-tab">
                                            <img src={Re} alt="..." className="head-imgs" />
                                            <h5>OA Details</h5>
                                        </div>
                                        <div className="tab-links">
                                            <Link to={"/" + role + "/oa"} className="active">OA Details</Link>
                                            <Link to={"/" + role + "/oa/oa_report"} >PSO</Link>
                                            <Link to={"/" + role + "/oa/oaConfirmPage"}>MC</Link>
                                            <Link to={"/" + role + "/oa/oaDownload"}>MC Report</Link>
                                            <Link to={"/" + role + "/oa/ms"}>MS</Link>
                                        </div>
                                        <div className="customer-search oa-search">
                                            <span><img src={Search} alt=".." className="search-img" /></span>
                                            <Form.Control type="search" placeholder="Search" onChange={this.searchHandler} className="main-search" />
                                        </div>
                                        <OaFilter
                                            onSubmit={this.handleSubmit}
                                            getCustomerExe={this.getCustomerExe}
                                            handleClick={this.handleClick}
                                            reset={this.resetHandler}
                                            show={show} target={target}
                                            str={this.props.str}
                                        />
                                    </div>
                                </div>
                                <div className="table-class">
                                    <table class="table table-borderless uniq-table">
                                        <thead>
                                            <tr>
                                                <th>PO No</th>
                                                <th>PO Date</th>
                                                <th scope="col" >OA No</th>
                                                <th scope="col">OA Date</th>
                                                <th scope="col">Customer</th>
                                                <th>Project</th>
                                                <th>Art No</th>
                                                <th>Product Name</th>
                                                <th className="txt-center">OA Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allData && allData?.content?.length > 0 ? (
                                                allData?.content?.map((one, i) => (
                                                    <tr key={i}>
                                                        <td onClick={() => this.handleShow(one)} className="under-line">{shortStr(one?.poNumber)}</td>
                                                        <td onClick={() => this.handleShow(one)}>{verifyDateFormat(one?.poGeneratedDate)}</td>
                                                        <td onClick={() => this.oaShow(one)}>
                                                            <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {one?.oaNumber || "-"}</Tooltip>}>
                                                                <span className="d-inline-block value-add">
                                                                    {one?.oaNumber || "-"}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td onClick={() => this.oaShow(one)}>{verifyDateFormat(one?.oaDate)}</td>
                                                        <td onClick={() => this.oaShow(one)}>{one?.customerName || "-"}</td>
                                                        <td onClick={() => this.oaShow(one)}>
                                                            <OverlayTrigger placement="top-start" overlay={<Tooltip id="tooltip-disabled"> {one?.projectName || "-"}</Tooltip>}>
                                                                <span className="d-inline-block value-add">
                                                                    {one?.projectName || "-"}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td onClick={() => this.handleShow(one)}>{one?.articleId}</td>
                                                        <td onClick={() => this.oaShow(one)} className="product_name">
                                                            <OverlayTrigger placement="bottom-start" overlay={<Tooltip id="tooltip-disabled"> {parse(one?.productName || "-")}</Tooltip>}>
                                                                <span className="d-inline-block">
                                                                    {parse(one?.productName || "-")}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td onClick={() => this.oaShow(one)} className="end-child">{one?.productQuantity}</td>
                                                    </tr>
                                                ))
                                            ) : !this.props.loading ?
                                                <h1 className="empty-data">No Records Found</h1> :
                                                <Spinner />
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Card>
                            <Pagination data={data} onChange={this.onChange} hide="hide" />
                            <div className="count-one">
                                <div className="page-page">Select your items size</div>
                            </div>
                            <select className="select-input" onChange={this.onCountData}>
                                <option vlaue="10">10</option>
                                <option vlaue="20">20</option>
                                <option vlaue="50">50</option>
                                <option vlaue="100">100</option>
                            </select>
                        </div>
                    </div>
                    <OaView
                        show={modal}
                        onHide={this.handleClose}
                        data={this.state.data}
                        preview={previewQutationTxt}
                    />
                    <OaConfirm
                        data={this.state.data}
                        show={oaPopup}
                        onHide={this.oaClose} />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.oa.loading,
        page: state.oa.page,
        allData: state.oa.data,
    };
};
export default connect(mapStateToProps)(OrderAcceptence);

