import React from "react";
import Loader from "react-loader-spinner";

class Spinner extends React.Component {
  render() {
    return (
      <Loader
        className="loader"
        type="TailSpin"
        color="#ff7f03"
        height={50}
        width={50}
      />
    );
  }
}

export default Spinner;
