import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Form, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Re from "../../../assets/images/cat.svg";
import { authToken, baseUrl } from "../../../actions";
import Sidebar from "../../../layouts/Sidebar";
import Navbar from "../../../layouts/Navbar";

const role = localStorage.getItem("userRole");
function ReferenceId() {
    const [dataObj, setDataObj] = useState({});
    const [data, setData] = useState({});
    const [error, setError] = useState(null);

    const handleInputChange = e => {
        if (e.target) {
            const { name, value } = e.target;
            setDataObj({ ...dataObj, [name]: value });
        }
        else setDataObj({ ...dataObj, ...e });
    }

    const loadData = async () => {
        axios.get(`${baseUrl}public/settings`)
            .then((res) => {
                setDataObj(res.data.data);
            }).catch((err) => {
                console.error(err);
            });
    }



    const submitHandler = (e) => {
        e.preventDefault();
        if (Object.keys(dataObj).length < 2 || Object.values(dataObj).includes("")) {
            toast("Enter Required Fields..");
        }
        //  else if (dataObj.QUOTATION_REFERENCE_PREFIX && /[^a-zA-Z0-9- !@#$%*?]/i.test(dataObj.QUOTATION_REFERENCE_PREFIX)) {
        //     toast("Enter a valid Reference Id");
        // } else if (dataObj.QUOTATION_REFERENCE_NUMBER && /[^a-zA-Z0-9- ]/i.test(dataObj.QUOTATION_REFERENCE_NUMBER)) {
        //     toast("Enter a valid Number");
        // }
        else {

            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    QUOTATION_REFERENCE_PREFIX: dataObj.QUOTATION_REFERENCE_PREFIX,
                    QUOTATION_REFERENCE_NUMBER: dataObj.QUOTATION_REFERENCE_NUMBER,
                })
            };
            fetch(`${baseUrl}admin/settings`, requestOptions)
                .then(res => {
                    if (res.status >= 400) {
                        throw new Error("Server responds with error!")
                    }
                    return res.json()
                })
                .then(res => {
                    setData(res)
                },
                    err => {
                        setError(err)
                    })
        }
    }

    if (data.status === 200 && data.status !== undefined) {
        toast(data.data);
        data.status = undefined;
    } else if (data.status >= 400 && data.status !== undefined) {
        toast(data.data);
        data.status = undefined;
    } else {
        // console.log(data.status);
    }

    useEffect(() => {
        loadData();
        data.status = undefined
    }, []);


    if (error) {
        return <div className="require"> {error.message} </div>
    } return (
        <div>
            <Navbar />
            <div className="dashboard-page">
                <div className="dashboard-sidebar">
                    <Sidebar />
                </div>
                <div className="dashboard-main">
                    <Card className="staff-page">
                        <div className="page-head-split">
                            <div className="head-colums">
                                <div className="head-part-tab">
                                    <img src={Re} alt="..." className="head-imgs" />
                                    <h5>Settings</h5>
                                </div>
                                <div className="tab-links">
                                    <Link to={"/" + role + "/gst"} >GST</Link>
                                    <Link to={"/" + role + "/reference-id"} className="active" >Reference Id</Link>
                                    <Link to={"/" + role + "/unit"}>Unit </Link>
                                    <Link to={"/" + role + "/group"} >Quotation Group </Link>
                                    <Link to={"/" + role + "/content-of-letter"}>Content Of the Letter</Link>
                                    <Link to={"/" + role + "/terms-conditions"}>Terms & Condition</Link>
                                </div>
                            </div>
                        </div>
                        <Row style={{ padding: "50px" }}>
                            <Col md={5}>
                                <Form onSubmit={submitHandler}>
                                    <Form.Label>  Reference ID</Form.Label>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>   Prefix</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Prefix"
                                            name="QUOTATION_REFERENCE_PREFIX"
                                            onChange={handleInputChange}
                                            value={dataObj.QUOTATION_REFERENCE_PREFIX}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label> Number</Form.Label>
                                        <Form.Control
                                            name="QUOTATION_REFERENCE_NUMBER"
                                            type="text"
                                            placeholder="Number"
                                            value={dataObj.QUOTATION_REFERENCE_NUMBER}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <button className="setting" type="submit">Save Changes</button>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </div>
        </div>
    );
}


export default ReferenceId;




