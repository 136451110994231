import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import Logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { login } from "../actions";
import { toast } from "react-toastify";
import history from "../helpers";


class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      userData: [],
      error: [],
      allData: [],
      showEye: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showPassword = this.showPassword.bind(this);

  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  showPassword() {
    this.setState({
      showEye: !this.state.showEye
    })
  }


  handleSubmit(e) {
    e.preventDefault();
    const formData = {
      userName: this.state.userName,
      password: this.state.password,
    };

    if (Object.keys(formData).length < 2 || Object.values(formData).includes("")) {
      toast("Fill all required fields");
      return false;
    } if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i?.test(formData.userName)) {
      toast("Enter a valid email address");
      return false;
    } else {
      this.props.dispatch(login(formData));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ userData: this.props.data });
    }
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    } if (prevProps.allData !== this.props.allData) {
      this.setState({ allData: this.props.allData });
      if (this.props.allData.status !== 200) {
        toast(this.props.allData.data);
      }
    }
  }

  render() {
    const { userData, error, showEye } = this.state;
    const token = userData?.authToken;

    if (token && token !== "") {
      let userRole = userData.userRoleList[0];
      userRole = userRole === "ADMIN" ? "admin" : "staff";

      localStorage.setItem("authToken", token);
      localStorage.setItem("userRole", userRole);

      if (userRole === "admin") {
        window.location = "/";
      } else {
        window.location = "/";

        // window.location.hash = "/" + userRole + '/dashboard';
        // window.location.reload();
      }
    }

    return (
      <div className="login">
        <div className="form-section">
          <Row>
            <Col md={5}>
              <div className="logo-part">
                <img src={Logo} alt="..." width="40%" className="center spin flow-part" />
                <h2>IDS Group</h2>
              </div>
            </Col>
            <Col md={7} className="form-part">
              <h3>Login</h3>
              <p>Sign in to your account</p>
              <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    name="userName"
                    onChange={this.handleChange}
                    placeholder="Email Address"
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Control
                    type={!showEye ? "password" : "text"}
                    name="password"
                    maxLength="40"
                    minLength="8"
                    onChange={this.handleChange}
                    placeholder="Password"
                  />
                  <span onClick={this.showPassword} className="pointer">
                    {showEye ? <i className="fas fa-eye-slash log-eyes" /> : <i className="fas fa-eye log-eye" />}
                  </span>
                </Form.Group>
                <Link to="/forgot-password">
                  <p>Forgot Password?</p>
                </Link>
                {/* <Link to="/dashboard"> */}
                <button type="submit">Login</button>
                {/* </Link> */}
              </Form>

              <Link to="/privacy-policy" className="privacy">Privacy Policy</Link><span className="cut">|</span>
              <Link to="/terms-and-conditions" className="terms-condition">Terms & Condition</Link>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    data: state.auth.data,
    error: state.auth.error,
    loading: state.staff.loading,
    status: state.auth.status,
    allData: state.auth.allData,
  };
};


export default connect(mapStateToProps)(Login);
