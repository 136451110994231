import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import Logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import PopUp from "./popup";
import { forgotPassword } from "../actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { set } from "lodash";

class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      show: false,
      userData: [],
      error: [],
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleChange(e) {
    this.state.email = e.target.value
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        userData: this.props.data,
      });
      if (this.props.data.status === 200) {
        toast(this.props.data?.data);
        this.setState({
          show: true
        })
      } else {
        toast(this.props.data?.data)
      }
    }
  }


  handleSubmit(e) {
    e.preventDefault();
    const formData = {
      email: this.state.email,
    };

    const pattern = {
      "email": /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    };

    if (this.state.email.match(pattern["email"])) {
      this.props.dispatch(forgotPassword(formData));
    } else {
      toast("Please enter a valid email address");
    }

  }

  handleShow() {
    this.setState({ show: true, });
  }

  handleClose() {
    this.setState({ show: false, });
  }


  render() {
    const { userData, show } = this.state;

    return (
      <div className="login">
        <div className="form-section">
          <Row>
            <Col md={5}>
              <div className="logo-part">
                <img src={Logo} alt="..." width="40%" className="center spin flow-part"  />
                <h2>IDS Group</h2>
                <Link to="/" className="mobile-back d-block d-sm-none">
                  Back to Login
                </Link>
              </div>
            </Col>
            <Col md={7} className="form-part">
              <Link to="/">
                <i class="fas fa-arrow-left"></i>
              </Link>
              <h3>FORGOT PASSWORD</h3>

              <p>Reset your password using your registered email address.</p>
              <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control name="email" type="email" placeholder="Email Address" onChange={this.handleChange} />
                </Form.Group>
                <button type="submit">Submit</button>
              </Form>
              <PopUp show={show} onHide={this.handleClose} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    data: state.auth.data,
    error: state.auth.error,
    success_msg: state.auth.success_msg,
    status: state.auth.status
  };
};

export default connect(mapStateToProps)(Forgot);
