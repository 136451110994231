import { toast } from "react-toastify";
import { packageConstants } from "../constants";

const initialState = {
    loading: null,
    editLoading: false,
    initialPacakageData: null,
    productsList: null,
    packageAll: null,
    packLoad: false,
    addPackageLoad: false,
    addPackage: null,
    editPackage: null,
    packageDropdown: null,
    newData: [],
    productDropdownList: []
}

export default function packageReducer(state = initialState, {type, response}) {
    switch(type) {
        case packageConstants.GET_INITIAL_PACKAGE_ID:
            return {
                ...state,
                loading: true
            }
        case packageConstants.GET_INITIAL_PACKAGE_ID_SUCCESS:
            if(response.status !== 200) {
                toast(response.data);
            }
            return {
                ...state,
                loading: false,
                initialPacakageData: response
            }
        case packageConstants.GET_INITIAL_PACKAGE_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: response
            }
        case packageConstants.EDIT_PACKAGE:
            return {
                ...state,
                editLoading: true,
            }
        case packageConstants.EDIT_PACKAGE_SUCCESS:
            return {
                ...state,
                editLoading: false,
                editPackage: response
            }
        case packageConstants.EDIT_PACKAGE_FAILURE:
            return {
                ...state,
                editLoading: false,
                error: response
            }
        case packageConstants.GET_PACKAGE_LIST:
            return {
                ...state,
                packLoad: true
            }
        case packageConstants.GET_PACKAGE_LIST_SUCCESS:
            return {
                ...state,
                packLoad: false,
                packageAll: response.data,
                newData: response.data.first ? [...response.data.content] : [...state.newData, ...response.data.content],
            }
        case packageConstants.GET_PACKAGE_LIST_FAILURE:
            return {
                ...state,
                packLoad: false,
                error: response
            }
        case packageConstants.ADD_PACKAGE_PRODUCT:
        case packageConstants.EDIT_PACKAGE_PRODUCT:
            return {
                ...state,
                addPackageLoad: true,
            }
        case packageConstants.ADD_PACKAGE_PRODUCT_SUCCESS:
        case packageConstants.EDIT_PACKAGE_PRODUCT_SUCCESS:
            return {
                ...state,
                addPackageLoad: false,
                addPackage: response
            }
        case packageConstants.ADD_PACKAGE_PRODUCT_FAILURE: 
        case packageConstants.EDIT_PACKAGE_PRODUCT_FAILURE:
            return {
                ...state,
                addPackageLoad: false,
                error: response
            }
        case packageConstants.GET_PRODUCTS_FOR_PACKAGE:
            return {
                ...state,
                loading: true
            }
        case packageConstants.GET_PRODUCTS_FOR_PACKAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                productsList: response
            }
        case packageConstants.GET_PRODUCTS_FOR_PACKAGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: response
            }
        case packageConstants.GET_PACKAGE_PRODUCT_DROPDOWN:
            return {
                ...state,
                loading: true
            }
        case packageConstants.GET_PACKAGE_PRODUCT_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                packageDropdown: response?.data
            }
        case packageConstants.GET_PACKAGE_PRODUCT_DROPDOWN_FAILURE:
            return {
                ...state,
                loading: false,
                error: response
            }
        case packageConstants.CLEAR_INITIAL_PACKAGE:
            return {
                ...state,
                initialPacakageData: null,
                //clearing products list also on reset
                productsList: null
            }
        case packageConstants.CLEAR_ADD_OR_EDIT_PRODUCT:
            return {
                ...state,
                addPackage: null,
                loading: null
            }
        case packageConstants.CLEAR_ADD_OR_EDIT_PACKAGE:
            return {
                ...state,
                editPackage: null,
                loading: null,
                initialPacakageData: null,
            }
        case packageConstants.CLEAR_SINGLE_PACKAGE_DROPDOWN:
            return {
                ...state,
                packageDropdown: null
            }
        case packageConstants.GET_PRODUCT_DROPDOWN:
            return {
                ...state,
                loading: true
            }
        case packageConstants.GET_PRODUCT_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                productDropdownList: response.data
            }
        case packageConstants.GET_PRODUCT_DROPDOWN_FAILURE:
            return {
                ...state, 
                loading: false,
                error: response
            }
        default:
            return state;
    }
}