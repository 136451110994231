import React from "react";
import { Overlay, Popover, Col, Row, Form } from "react-bootstrap";
import filter from "../../../assets/images/filt.svg";
import { qutationDateFormat } from "../../../actions";
import { DatePicker } from "antd";
import moment from "moment";
import "antd/dist/antd.css";

class ReceiveFilter extends React.Component {
    constructor() {
        super();
        this.state = {
            status: {
                "YET_TO_RECEIVE": false,
                "PARTIALLY_PACKED": false,
                "PARTIALLY_RECEVIED": false,
            },
            show: false,
            target: null,
        };
        this.myRef = React.createRef();
        this.statusHandler = this.statusHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.resetHandler = this.resetHandler.bind(this);
    }
    statusHandler = (event) => {
        const { name, checked } = event.target;
        this.setState((prevState) => {
            const status = prevState.status;
            status[name] = checked;
            return status;
        });
    };
    submitHandler = (e) => {
        e.preventDefault();
        const { status } = this.state;
        const { onSubmit } = this.props;
        const addedStatus = Object.keys(status)
            .filter((key) => status[key]).map((item) => item)
            .join(",");
        let data = {
            "status": addedStatus,
        }
        onSubmit(data);
        this.setState({
            show: false,
            target: null
        })
    }


    resetHandler = () => {
        const { reset } = this.props;
        reset();
        this.setState({
            status: {
                "YET_TO_RECEIVE": false,
                "PARTIALLY_PACKED": false,
                "PARTIALLY_RECEVIED": false,
            },
            show: false,
            target: null
        })
    }

    handleClick = (event) => {
        this.setState({
            show: !this.state.show,
            target: event.target
        })
    };


    render() {
        const { show, target, ref, status } = this.state;

        return (
            <div ref={this.myRef} className="filter-background">
                <button onClick={this.handleClick} className="product-filter-button">
                    <img src={filter} alt="..." width="18px" style={{ paddingRight: "5px" }} /> Filter
                </button>
                <Overlay
                    show={show}
                    target={target}
                    placement="bottom"
                    container={ref}
                    containerPadding={20}
                >
                    <Popover id="popover-contained">
                        <Popover.Body>
                            <div className="filter-page">
                                <Form onSubmit={this.submitHandler}>
                                    <p className="role-id">Status</p>
                                    <div className="customer-check">
                                        <Form.Check
                                            checked={status["YET_TO_RECEIVE"]}
                                            onChange={this.statusHandler}
                                            inline label="Yet To Receive"
                                            name="YET_TO_RECEIVE"
                                            defaultValue="Yet To Receive"
                                            type="checkbox"
                                        />
                                    </div>
                                    <div className="customer-check">
                                        <Form.Check
                                            checked={status["PARTIALLY_PACKED"]}
                                            onChange={this.statusHandler}
                                            inline label="Partially Packed"
                                            name="PARTIALLY_PACKED"
                                            defaultValue=" Partially Packed"
                                            type="checkbox"
                                        />
                                    </div>
                                    <div className="customer-check">
                                        <Form.Check
                                            checked={status["PARTIALLY_RECEIVED"]}
                                            onChange={this.statusHandler}
                                            inline label="Partially Received"
                                            name="PARTIALLY_RECEIVED"
                                            defaultValue=" Partially Received"
                                            type="checkbox"
                                        />
                                    </div>
                                    <div>
                                        <Row>
                                            <Col md={6}>
                                                <button className="modal-price reset" type="button" onClick={this.resetHandler}>
                                                    Reset</button>
                                            </Col>
                                            <Col md={6}>
                                                <button type="submit" className="modal-btn modal-apply">
                                                    Apply
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </div>
                        </Popover.Body>
                    </Popover>
                </Overlay>
            </div >
        );
    }
}

export default ReceiveFilter;




