import { inwardConstants } from "../constants";

const initialState = {
    loading: false,
    load: false,
    returnInwardSuccess: [],
    data: [],
    error: [],
    inward_list: [],
    received_inward: [],
    csvData: [],
    receiveCsv : []
};

export default function inwardReducer(state = initialState, { type, response }) {
    switch (type) {
        case inwardConstants.GET_VERIFIED_PROJECTS:
        case inwardConstants.GET_INWARD_LOGS:
        case inwardConstants.GET_RECEIVED_INWARD:
            return {
                ...state,
                loading: true,
            };
        case inwardConstants.ADD_RETURN_INWARD_PRODUCT_INIT:
        case inwardConstants.DOWNLOAD_INWARD_CSV:
        case inwardConstants.DOWNLOAD_YET_TO_RECEIVE_CSV:
            return {
                ...state,
                load: true,
            };
        case inwardConstants.GET_VERIFIED_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: response.data,
            };
        case inwardConstants.GET_RECEIVED_INWARD_SUCCESS:
            return {
                ...state,
                loading: false,
                received_inward: response.data,
            };
        case inwardConstants.GET_INWARD_LOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                inward_list: response.data,
            };
        case inwardConstants.ADD_RETURN_INWARD_PRODUCT_SUCCESS:
            return {
                ...state,
                load: false,
                returnInwardSuccess: response,
            };
        case inwardConstants.DOWNLOAD_INWARD_CSV_SUCCESS:
            return {
                ...state,
                load: false,
                csvData: response,
            };
        case inwardConstants.DOWNLOAD_YET_TO_RECEIVE_CSV_SUCCESS:
            return {
                ...state,
                load: false,
                receiveCsv: response,
            };
        case inwardConstants.GET_INWARD_LOGS_FAILURE:
        case inwardConstants.GET_VERIFIED_PROJECTS_FAILURE:
        case inwardConstants.GET_RECEIVED_INWARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case inwardConstants.ADD_RETURN_INWARD_PRODUCT_FAILURE:
        case inwardConstants.DOWNLOAD_INWARD_CSV_FAILURE:
        case inwardConstants.DOWNLOAD_YET_TO_RECEIVE_CSV_FAILURE:
            return {
                ...state,
                load: false,
                error: response,
            };
        default:
            return state;
    }
}
