export const staffConstants = {
    STAFF_REQUEST: "ADD_STAFF_REQUEST",
    STAFF_SUCCESS: "ADD_STAFF_SUCCESS",
    STAFF_FAILURE: "ADD_STAFF_FAILURE",

    GET_STAFF: "GET_STAFF_LIST",
    GET_STAFF_SUCCESS: "GET_STAFF_LIST_SUCCESS",
    GET_STAFF_FAILURE: "GET_STAFF_LIST_FAILURE",

    GET_SINGLE_STAFF: "GET_SINGLE_STAFF_LIST",
    GET_SINGLE_STAFF_SUCCESS: "GET_SINGLE_STAFF_LIST_SUCCESS",
    GET_SINGLE_STAFF_FAILURE: "GET_SINGLE_STAFF_LIST_FAILURE",

    UPDATE_STAFF_REQUEST: "UPDATE_STAFF_REQUEST",
    UPDATE_STAFF_SUCCESS: "UPDATE_STAFF_SUCCESS",
    UPDATE_STAFF_FAILURE: "UPDATE_STAFF_FAILURE",

    UPDATE_STAFF_STATUS_REQUEST: "UPDATE_STAFF_STATUS_REQUEST",
    UPDATE_STAFF_STATUS_SUCCESS: "UPDATE_STAFF_STATUS_SUCCESS",
    UPDATE_STAFF_STATUS_FAILURE: "UPDATE_STAFF_STATUS_FAILURE",



    CHANGE_PAGE: "CHANGE_PAGE",
}
