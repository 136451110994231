export const changeSetPassword = (value) => {
  if (value === "edit" || value === "profile") {
    return "Change Password";
  } else {
    return "Set Password";
  }
};

export const changeSetPasswordBtn = (value) => {
  if (value === "edit" || value === "profile") {
    return "Update Password";
  } else {
    return "Set Password";
  }
};

export const changeSetPasswordTitle = (value) => {
  if (value === "edit" || value === "profile") {
    return "CHANGE PASSWORD";
  } else {
    return "SET NEW PASSWORD";
  }
};

export const changeTitleStr = (value) => {
  if (value === "edit") {
    return "EDIT STAFF DETAILS";
  } else {
    return "ADD NEW STAFF";
  }
};
