import { qutationConstants } from "../constants";

const initialState = {
    loading: false,
    load: false,
    footerLoad: false,
    data: [],
    error: [],
    token: "",
    quotationData: [],
    quotationTemplates: [],
    newQuotation: [],
    updateQuotation: [],
    deleteQuotation: [],
    groupList: [],
    group: [],
    footer: [],
    terms: [],
    termsSuccess: [],
    salesExecutives: [],
    quoteLocations: [],
    quoteFooter: [],
    hideColumns: [],
    hideColumnsStatus: [],
    addLocationSuccess: [],
    deleteLocationSuccess: [],
    salesExecutivesSuccess: "",
    addGroupSuccess: [],
    deleteGroupSuccess: [],
    generateQuotationSuccess: [],
    groupProducts: [],
    groupProductsSuccess: [],
    page: "add",
    deleteGroupProducts: [],
    qutationStatus: [],
    updateQutationStatus: [],
    downloadQutation: "",
    editLocationSuccess: [],
    cloneLocationSuccess: [],
    cloneGroupSuccess: [],
    updateFooterData: [],
    load: false,
    newForm: null,
    updateNewForm: null,
    quotationCsvData: null
};

export default function qutationReducer(state = initialState, { type, response }) {
    switch (type) {
        case qutationConstants.GET_QUTATION:
        case qutationConstants.GET_QUTATION_BY_ID:
        case qutationConstants.GET_IGNORE_FILEDS:
        case qutationConstants.GET_QUTATION_TEMPLATES:
        case qutationConstants.CREATE_QUOTATION_INIT:
        case qutationConstants.UPDATE_QUOTATION_INIT:
        case qutationConstants.GET_GROUPS_QUOTATION_INIT:
        case qutationConstants.CREATE_FOOTER_QUOTATION_INIT:
        case qutationConstants.ADD_TERMS_QUOTATION_INIT:
        case qutationConstants.GET_EXECUTIVES_INIT:
        case qutationConstants.ADD_SALESEXE_QUOTATION_INIT:
        case qutationConstants.GET_LOCATIONLIST_INIT:
        case qutationConstants.GET_FOOTERLIST_INIT:
        case qutationConstants.ADD_IGNORE_FIELDS_INIT:
        case qutationConstants.ADD_LOCATION_QUOTATION_INIT:
        case qutationConstants.DELETE_LOCATION_QUOTATION_INIT:
        case qutationConstants.DELETE_GROUP_QUOTATION_INIT:
        case qutationConstants.GENERATE_QUOTATION_INIT:
        case qutationConstants.PREVIEW_QUOTATION_INIT:
        case qutationConstants.GET_GROUP_PRODUCTS:
        case qutationConstants.ADD_GROUP_PRODUCTS:
        case qutationConstants.DELETE_GROUP_PRODUCTS:
        case qutationConstants.GET_QUOTATION_STATUS:
        case qutationConstants.UPDATE_QUOTATION_STATUS:
        case qutationConstants.CLONE_QUTATION:
        case qutationConstants.DELETE_QUTATION:
        case qutationConstants.DOWNLOAD_QUTATION:
        case qutationConstants.EDIT_LOCATION_QUOTATION_INIT:
            return {
                ...state,
                loading: true,
                previewQutation: undefined,
                updateQutationStatus: "",
            };
        case qutationConstants.UPDATE_FOOTER_INIT:
            return {
                ...state,
                footerLoad: true,
            };
        case qutationConstants.ADD_GROUP_QUOTATION_INIT:
        case qutationConstants.UPDATE_GROUP_QUOTATION_INIT:
        case qutationConstants.CLONE_LOCATION:
        case qutationConstants.CLONE_GROUP:
            return {
                ...state,
                load: true,
            };
        case qutationConstants.GET_QUTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                qutation_list: response.data.content,
                allData: response.data
            };

        case qutationConstants.GET_QUTATION_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                quotationData: response.data,
            };
        case qutationConstants.GET_IGNORE_FILEDS_SUCCESS:
            return {
                ...state,
                loading: false,
                hideColumns: response.data,
            };
        case qutationConstants.GET_QUTATION_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                quotationTemplates: response.data,
            };

        case qutationConstants.CREATE_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                newQuotation: response,
            };

        case qutationConstants.UPDATE_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                updateQuotation: response,
            };

        case qutationConstants.DELETE_QUTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteQuotation: response,
            };

        case qutationConstants.CLONE_QUTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                cloneQutation: response,
            };

        case qutationConstants.GET_GROUPS_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                groupList: response.data,
            };

        case qutationConstants.ADD_GROUP_QUOTATION_SUCCESS:
            return {
                ...state,
                load: false,
                addGroupSuccess: response,
                page: "add"

            };
        case qutationConstants.UPDATE_GROUP_QUOTATION_SUCCESS:
            return {
                ...state,
                load: false,
                updateGroupSuccess: response,
                page: "update",
                footer: []
            };
        case qutationConstants.CLONE_LOCATION_SUCCESS:
            return {
                ...state,
                load: false,
                cloneLocationSuccess: response,
            };
        case qutationConstants.CLONE_GROUP_SUCCESS:
            return {
                ...state,
                load: false,
                cloneGroupSuccess: response,
            };
        case qutationConstants.CREATE_FOOTER_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                footer: response,
            };
        case qutationConstants.UPDATE_FOOTER_SUCCESS:
            return {
                ...state,
                footerLoad: false,
                updateFooterData: response
            };
        case qutationConstants.ADD_TERMS_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                termsSuccess: response,
                footer: []

            };
        case qutationConstants.GET_EXECUTIVES_SUCCESS:
            return {
                ...state,
                loading: false,
                salesExecutives: response.data,
            };
        case qutationConstants.ADD_SALESEXE_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                salesExecutivesSuccess: response,
            };
        case qutationConstants.GET_LOCATIONLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                quoteLocations: response.data,
            };
        case qutationConstants.GET_FOOTERLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                footerList: response.data,
            };
        case qutationConstants.ADD_IGNORE_FIELDS_SUCCESS:
            return {
                ...state,
                loading: false,
                hideColumnsStatus: response,
            };
        case qutationConstants.ADD_LOCATION_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                addLocationSuccess: response,
                locationId: response.data.locationId,
                deleteLocationSuccess: ""
            };
        case qutationConstants.EDIT_LOCATION_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                editLocationSuccess: response,
                deleteLocationSuccess: ""
            };
        case qutationConstants.DELETE_LOCATION_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteLocationSuccess: response,
            };
        case qutationConstants.DELETE_GROUP_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteGroupSuccess: response,
                deleteLocationSuccess: ""
            };
        case qutationConstants.GENERATE_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                generateQuotationSuccess: response,
            };

        case qutationConstants.PREVIEW_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                previewQutation: response,
            };

        case qutationConstants.DOWNLOAD_QUTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                downloadQutation: response,
            };

        case qutationConstants.GET_GROUP_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                groupProducts: response.data,
            };

        case qutationConstants.ADD_GROUP_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                groupProductsSuccess: response,
            };
        case qutationConstants.DELETE_GROUP_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteGroupProducts: response,
            };
        case qutationConstants.CHANGE_GROUP_QUOTATION_INIT:
            return {
                page: "add"
            };

        case qutationConstants.UPDATE_GROUP_QUOTATION_INIT:
            return {
                page: "edit"
            };

        case qutationConstants.GET_QUOTATION_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                qutationStatus: response.data,
                updateQutationStatus: ""
            };

        case qutationConstants.UPDATE_QUOTATION_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                updateQutationStatus: response.data,
            };

        case qutationConstants.CHANGE_PAGE:
            return {
                productPage: "add",
            }

        case qutationConstants.CLEAR_CUSTOMER_DETAILS:
            return {
                ...state,
                quotationData: {},
            };
        case qutationConstants.CLEAR_QUOTATION_BODY:
            return {
                ...state,
                quoteLocations: [],
                groupList: []
            };
        case qutationConstants.GET_QUTATION_FAILURE:
        case qutationConstants.GET_QUTATION_BY_ID_FAILURE:
        case qutationConstants.GET_QUTATION_TEMPLATES_FAILURE:
        case qutationConstants.CREATE_QUOTATION_FAILURE:
        case qutationConstants.UPDATE_QUOTATION_FAILURE:
        case qutationConstants.GET_GROUPS_QUOTATION_FAILURE:
        case qutationConstants.ADD_GROUP_QUOTATION_FAILURE:
        case qutationConstants.UPDATE_GROUP_QUOTATION_FAILURE:
        case qutationConstants.CREATE_FOOTER_QUOTATION_FAILURE:
        case qutationConstants.ADD_TERMS_QUOTATION_FAILURE:
        case qutationConstants.AGET_EXECUTIVES_FAILURE:
        case qutationConstants.ADD_SALESEXE_QUOTATION_FAILURE:
        case qutationConstants.GET_LOCATIONLIST_FAILURE:
        case qutationConstants.GET_FOOTERLIST_FAILURE:
        case qutationConstants.ADD_IGNORE_FIELDS_FAILURE:
        case qutationConstants.ADD_LOCATION_QUOTATION_FAILURE:
        case qutationConstants.EDIT_LOCATION_QUOTATION_FAILURE:
        case qutationConstants.DELETE_LOCATION_QUOTATION_FAILURE:
        case qutationConstants.DELETE_GROUP_QUOTATION_FAILURE:
        case qutationConstants.GENERATE_QUOTATION_FAILURE:
        case qutationConstants.PREVIEW_QUOTATION_FAILURE:
        case qutationConstants.GET_GROUP_PRODUCTS_FAILURE:
        case qutationConstants.ADD_GROUP_PRODUCTS_FAILURE:
        case qutationConstants.DELETE_GROUP_PRODUCTS_FAILURE:
        case qutationConstants.GET_QUOTATION_STATUS_FAILURE:
        case qutationConstants.UPDATE_QUOTATION_STATUS_FAILURE:
        case qutationConstants.CLONE_QUTATION_FAILURE:
        case qutationConstants.DELETE_QUTATION_FAILURE:
        case qutationConstants.GET_IGNORE_FILEDS_FAILURE:
        case qutationConstants.DOWNLOAD_QUTATION_FAILURE:
        case qutationConstants.CLONE_LOCATION_FAILURE:
        case qutationConstants.CLONE_GROUP_FAILURE:
        case qutationConstants.UPDATE_FOOTER_FAILURE:
            return {
                ...state,
                loading: false,
                footerLoad: false,
                error: response,
                updateQutationStatus: "",
                load: false
            };

        case qutationConstants.NEW_QUTATION_TEMPLATE:
            return {
                ...state,
                loading: true,
            };
        case qutationConstants.NEW_QUTATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                newForm: response
            };
        case qutationConstants.NEW_QUTATION_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };

        case qutationConstants.UPDATE_QUTATION_TEMPLATE:
            return {
                ...state,
                loading: true,
            };
        case qutationConstants.UPDATE_QUTATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                updateNewForm: response
            };
        case qutationConstants.UPDATE_QUTATION_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case qutationConstants.DELETE_QUTATION_TEMPLATE:
            return {
                ...state,
                loading: true,
            };
        case qutationConstants.DELETE_QUTATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                updateNewForm: response
            };
        case qutationConstants.DELETE_QUTATION_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: response,
            };
        case qutationConstants.CLEAR_NEW_FORM:
            return {
                ...state,
                newForm: null,
                updateNewForm: null
            };
        case qutationConstants.QUOTATION_CSV_DOWNLOAD:
            return {
                ...state,
                load: true
            };
        case qutationConstants.QUOTATION_CSV_DOWNLOAD_SUCCESS:
            return {
                ...state,
                load: false,
                quotationCsvData: response
            };
        case qutationConstants.QUOTATION_CSV_DOWNLOAD_FAILURE:
            return {
                ...state,
                load: false,
                error: response
            };
        case qutationConstants.CLEAR_CSV:
            return {
                ...state,
                load: false,
                quotationCsvData: null
            }

        default:
            return state;
    }
}
