import React, { useRef, useState, useEffect } from "react";
import { Overlay, Popover, Col, Row, Form } from "react-bootstrap";
import filter from "../../../assets/images/filt.svg";
import { filterDateFormat } from "../../../actions";
import { DatePicker } from "antd";
import moment from "moment";
import "antd/dist/antd.css";


function SalesExecutiveFilter(props) {
    const [cur, setCur] = useState("");
    const [eff, setEff] = useState("");
    const [dataObj, setDataObj] = useState({});
    const { onSubmit, getStaffExe } = props;
    const ref = useRef(null);
    const handleInputChange = e => {
        if (e.target) {
            const { name, value } = e.target;
            setDataObj({ ...dataObj, [name]: value });
        }
        else setDataObj({ ...dataObj, ...e });
    }

    const effHandler = (val) => {
        setCur(filterDateFormat(val._d));
    }
    const currentHandler = (val) => {
        setEff(filterDateFormat(val._d));
    }

    const submitHandler = (e) => {
        e.preventDefault();
        let data = {
            "firstName": dataObj.firstName,
            "userId": dataObj.userId,
            "fromDate": eff,
            "toDate": cur
        }
        onSubmit(data);
    }

    const resetHandler = () => {
        getStaffExe();
        setDataObj({});
        setCur("");
        setEff("");
    }



    const disabledDate = (current) => current && current > moment().endOf("day");
    const checkDisabledDate = (current) => (current && current > moment().endOf("day")) || moment(eff).startOf("day") > current;

    return (
        <div ref={ref} className="filter-background">
            <button onClick={props.handleClick} className="filter-button" style={{ marginTop: "10px" }}>
                <img src={filter} alt="..." width="18px" style={{ paddingRight: "5px" }} /> Filter
            </button>

            <Overlay
                show={props.show}
                target={props.target}
                placement="bottom"
                container={ref}
                containerPadding={20}
            >
                <Popover id="popover-contained">
                    <Popover.Body>
                        <div className="filter-page">
                            <Form onSubmit={submitHandler}>
                                <p className="new-ss">Staff ID</p>
                                <Form.Group controlId="formBasicCurPw">
                                    <Form.Control
                                        type="number"
                                        placeholder="Search Staff ID"
                                        name="userId"
                                        onChange={handleInputChange}
                                        className="form-control"
                                        value={dataObj.userId}
                                    />
                                </Form.Group>

                                <p className="new-ss">Sales Executive</p>
                                <Form.Group controlId="formBasicCurPw">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search Sales Executive"
                                        name="firstName"
                                        onChange={handleInputChange}
                                        className="form-control"
                                        value={dataObj.firstName}
                                    />
                                </Form.Group>
                                <p className="new-ss">Custom Date</p>
                                <Form.Group controlId="formBasicDate">
                                    <DatePicker
                                        format="DD-MM-YYYY"
                                        placeholder={eff !== "" ? eff : "From Date"}
                                        className="form-control"
                                        onChange={currentHandler}
                                        disabledDate={disabledDate}
                                    />
                                </Form.Group><br></br>
                                <Form.Group controlId="formBasicDate">
                                    <DatePicker
                                        format="DD-MM-YYYY"
                                        placeholder={cur !== "" ? cur : "To Date"}
                                        className="form-control"
                                        disabledDate={disabledDate}
                                        onChange={effHandler}
                                    />
                                </Form.Group>

                                <div>
                                    <Row>
                                        <Col md={6}>
                                            <button className="modal-price reset" type="button" onClick={resetHandler}>
                                                Reset</button>
                                        </Col>
                                        <Col md={6}>
                                            <button type="submit" className="modal-btn modal-apply">
                                                Apply
                                            </button>
                                        </Col>
                                    </Row>

                                </div>
                            </Form>
                        </div>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    );
}


export default SalesExecutiveFilter;


