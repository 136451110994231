import { staffexecutiveConstants } from "../constants/staffexecutiveConstants"

export const getstaffExecutive = (pageNo, filterData) => {
    return {
        type: staffexecutiveConstants.GET_STAFF_EXECUTIVE,
        pageNo,
        filterData
    }
}

export const getSingleExecutive = (id, item) => {

    return {
        type: staffexecutiveConstants.SINGLE_EXECUTIVE,
        id,
        item
    }
}
