import React from "react";
import { Card, Form, Row, Col, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import back from "../../../assets/images/back (3).png";
import del from "../../../assets/images/trash_del.svg";
import Logo from "../../../assets/images/main.svg";
import { reduxForm } from "redux-form";
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from "react-redux";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import Orange from "../../../assets/images/green2.svg";
import { toast } from "react-toastify";
import ClonePopUp from "../clonePopup";
import {
    getExecutives,
    addSalesExecutives,
    getQutation,
    previewQuotation,
    generateQuotation,
    changeTitleStr,
    cloneQuotation,
    clearConfirmDropdownList,
    getConfirmQuotDropdown,
    updateQutationStatus,
    deleteQuotation
} from "../../../actions";
import View from "../View";
import ConvertBoq from "../../purchaseOrder/billofquantity/ConvertBoq";
const role = localStorage.getItem('userRole');

class PreparedBy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            empty: "",
            previewQutationTxt: undefined,
            salesExecutiveId: null,
            clonePopup: false,
            quotationData: [],
            cloneShow: false,
            errorPopup: false,
            cloneShow: false,
            cloned: null,
            cloneEdit: false,
            reviseEdit: false,
            cancelEdit: false,
            boqPopup: false,
            confirmed: false,
            changeName: false,
            deletePopup: false,
            status: "DRAFT"
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.customerChange = this.customerChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.clonePopupClose = this.clonePopupClose.bind(this);
        this.cloneQutationHandler = this.cloneQutationHandler.bind(this);
        this.generateQuotationHandler = this.generateQuotationHandler.bind(this);
        this.editQutationHandler = this.editQutationHandler.bind(this);
        this.confrimHandler = this.confrimHandler.bind(this);
        this.boqShow = this.boqShow.bind(this);
        this.boqClose = this.boqClose.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.statusHandler = this.statusHandler.bind(this);
        this.deleteQutationHandler = this.deleteQutationHandler.bind(this);
        this.quotPage = this.quotPage.bind(this);
    }
    quotPage() {
        const { history } = this.props;
        history.push(`/${role}/quotations/${this.props.match.params.add}/quotationBody/${this.props.match.params.id}/${this.props.match.params.quotId}`)
    }
    prevPage() {
        const { history } = this.props;
        history.push(`/${role}/quotations/${this.props.match.params.add}/termsAndConditions/${this.props.match.params.id}/${this.props.match.params.quotId}`)
    }
    customerChange(e) {
        this.props.salesExecutives?.map((item) => {
            if (item.salesExecutiveName === e[0]) {
                let formData = {
                    "prepared_by": this.props.quotationData.issuedStaff,
                    "salesExecutiveId": item?.userId
                }
                this.setState({
                    salesExecutiveId: item?.userId
                })
                this.props.dispatch(addSalesExecutives(Number(this.props.match.params.quotId), formData));
                this.props.dispatch(previewQuotation(Number(this.props.match.params.quotId)));
            }
        });
    }
    handleSubmit() {
        const { quotationData, salesExecutives } = this.props;
        if (quotationData?.salesExecutive !== null || this.state.salesExecutiveId !== null) {
            this.props.dispatch(previewQuotation(Number(this.props.match.params.quotId)));
            this.setState({
                show: true
            })
        } else {
            toast("Please Select Sales Executive")
        }
    }
    handleClose() {
        const { history } = this.props;
        if (this.state.status === "DRAFT") {
            this.setState({
                show: false,
                // previewQutationTxt: undefined,
                confirmed: false
            })
        } else {
            history.push("/" + role + "/quotations");
        }
    }
    clonePopupClose() {
        this.setState({
            clonePopup: false
        })
        this.props.dispatch(getQutation(this.props.match.params.quotId));
    }
    generateQuotationHandler() {
        this.props.dispatch(generateQuotation(this.props.match.params.quotId));
    }
    cloneQutationHandler() {
        this.props.dispatch(cloneQuotation(this.props.match.params.quotId, false));
        this.setState({
            clonePopup: true,
            cloned: false,
        })
    }
    editQutationHandler() {
        this.props.dispatch(cloneQuotation(this.props.match.params.quotId, true));
        this.setState({
            cloned: true,
        })
    }
    deleteQutationHandler() {
        this.setState({
            deletePopup: true,
        });
    }
    boqShow() {
        this.props.dispatch(clearConfirmDropdownList());
        this.props.dispatch(getConfirmQuotDropdown());
        this.setState({ boqPopup: true, show: false });
    }
    boqClose() {
        this.props.dispatch(clearConfirmDropdownList());
        this.setState({ boqPopup: false });
    }
    confrimHandler() {
        this.setState({
            confirmed: true
        })
        this.props.dispatch(updateQutationStatus(this.props.match.params.quotId, 7));
    }
    statusHandler() {
        this.setState({
            status: "CANCELLED"
        })
        this.props.dispatch(updateQutationStatus(this.props.match.params.quotId, 6));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.quotationData !== this.props.quotationData) {
            this.setState({
                quotationData: this.props.quotationData,
                salesExecutive: this.props.quotationData?.salesExecutive,
            });
        } if (prevProps.salesExecutivesSuccess !== this.props.salesExecutivesSuccess) {
            const { quotationData } = this.props;
            if (this.props.salesExecutivesSuccess.status === 200) {
                this.props.dispatch(previewQuotation(quotationData.quotationId));
                toast(this.props.salesExecutivesSuccess?.data);
            } else {
                toast(this.props.salesExecutivesSuccess?.data);
            }
        } if (prevProps.previewQutationTxt !== this.props.previewQutationTxt) {
            if (this.state.show) {
                this.setState({
                    previewQutationTxt: this.props.previewQutationTxt,
                });
            }
        } if (prevProps.generateQuotationSuccess !== this.props.generateQuotationSuccess) {
            this.props.dispatch(previewQuotation(this.props.match.params.quotId));
            this.props.dispatch(getQutation(this.props.match.params.quotId));
            if (this.props.generateQuotationSuccess.status === 200) {
                this.setState({
                    // show: false,
                    status: "GENERATED"
                })
                toast(this.props.generateQuotationSuccess.data);
                // setTimeout(() => {
                //     this.setState({
                //         show: true
                //     })
                // }, 2000);
            } else {
                this.setState({
                    errorPopup: true,
                })
            }

        } if (prevProps.cloneQutation !== this.props.cloneQutation) {
            if (this.props.cloneQutation.status === 200) {
                const { history } = this.props;
                this.setState({
                    show: false,
                });
                history.push("/" + role + "/quotations")
            } else {
                toast(this.props.cloneQutation.data);
            }
        } if (prevProps.updateQutationStatus !== this.props.updateQutationStatus) {
            this.props.dispatch(previewQuotation(this.props.match.params.quotId));
            if (this.props.updateQutationStatus !== "") {
                const { history } = this.props;
                this.setState({
                    changeName: true
                });
                if (this.state.status === "CANCELLED") {
                    history.push("/" + role + "/quotations");
                    this.setState({
                        show: false
                    })
                }
                this.props.dispatch(getQutation(this.props.match.params.quotId));
                toast(this.props.updateQutationStatus);
            }
        } if (prevProps.deleteQuotation !== this.props.deleteQuotation) {
            const { history } = this.props;
            this.setState({
                show: false,
                deletePopup: false
            });
            history.push("/" + role + "/quotations");
            toast(this.props.deleteQuotation.data);
        }
    }
    componentDidMount() {
        if (this.props.match.params.quotId !== "null") {
            this.props.dispatch(getExecutives());
            this.props.dispatch(getQutation(this.props.match.params.quotId));
        }
    }

    render() {
        const { handleSubmit, salesExecutives, generateQuotationSuccess } = this.props;
        const { history } = this.props;
        const { show, previewQutationTxt, clonePopup, cloneShow, cloned, boqPopup, changeName, confirmed, quotationData, deletePopup } = this.state;
        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <img src={Orange} alt="..." className="head-img" />
                            <h5 className='po_link' onClick={() => history.push("/" + role + "/quotations")}>Quotations</h5>
                            <i className="fas fa-arrow-right" style={{ margin: "10px 0 0 -10px" }}></i>
                            <h6 >{changeTitleStr(this.props.match.params.add)}</h6>
                            <hr className="line-new"></hr>
                            <div className="order-page-title">
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/customerDetails/${this.props.match.params.id}/${this.props.match.params.quotId}`}>
                                    Customer Details
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/contentBody/${this.props.match.params.id}/${this.props.match.params.quotId}`} >
                                    Content of the Letter
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/location/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Location
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/quotationBody/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Quotation Body
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/footer/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Footer
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/termsAndConditions/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Terms & Conditions
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/prepared/${this.props.match.params.id}/${this.props.match.params.quotId}`} className="active"  >
                                    Prepared by
                                </Link>
                            </div>
                            <form onSubmit={handleSubmit(this.handleSubmit)} style={{ padding: "20px", paddingLeft: "30px" }}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Label>Prepared by </Form.Label>
                                        <Form.Group className="mb-3" controlId="enterarea">
                                            <Form.Control
                                                type="text"
                                                name="area"
                                                disabled
                                                defaultValue={quotationData?.issuedStaff || ""}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label>Sales Executive <span style={{ color: "orange" }}>*</span></Form.Label>
                                        {salesExecutives?.length >= 0 ? <Typeahead
                                            defaultInputValue={quotationData?.salesExecutive || ""}
                                            onChange={this.customerChange}
                                            id="async-example"
                                            labelKey={salesExecutives.map(item => item.salesExecutiveName)}
                                            options={salesExecutives.map(item => item.salesExecutiveName)}
                                            placeholder={quotationData?.salesExecutive !== "" ? quotationData?.salesExecutive : "Search Sales Executive *"}
                                        /> : null}
                                    </Col>
                                </Row>
                                <div className="customer-sub">
                                    <div type="button" onClick={this.prevPage} className="cancel-btn qutation-btn col-md-2" ><center><img src={back} />Back</center></div>
                                    <button type="submit" className="modal-btn save col-md-3">Preview</button>
                                </div>
                            </form>
                            <br></br>
                        </Card>
                    </div>
                    <ClonePopUp show={cloneShow} onHide={this.closeClonePopup} cloned={cloned} />
                    <View
                        show={show}
                        onHide={this.handleClose}
                        data={quotationData}
                        preview={previewQutationTxt}
                        generate={this.generateQuotationHandler}
                        cloneHandler={this.cloneQutationHandler}
                        editHandler={this.editQutationHandler}
                        changed={changeName}
                        confirmed={confirmed}
                        confrimHandler={this.confrimHandler}
                        boqShow={this.boqShow}
                        deleteHandler={this.deleteQutationHandler}
                        statusHandler={this.statusHandler}
                    />
                    <Modal
                        show={clonePopup}
                        onHide={() => {
                            this.setState({
                                clonePopup: false
                            });
                        }}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <div className="modal-success">
                            <img src={Logo} alt=".." className="spin" width="200px" />
                            <h5 >Cloning Quotation...Please wait...</h5>
                        </div>
                    </Modal>
                    <Modal
                        show={this.state.errorPopup}
                        onHide={() => {
                            this.setState({
                                errorPopup: false
                            });
                        }}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <div className="modal-success">
                            <h5 >{generateQuotationSuccess?.data}</h5>
                        </div>
                        <button className="modal-btn sub-modal-btn error_popup" onClick={() => {
                            this.quotPage();
                            this.setState({
                                errorPopup: false
                            });
                        }} >
                            Ok
                        </button>
                    </Modal>
                    <Modal
                        show={deletePopup}
                        onHide={() => {
                            this.setState({
                                deletePopup: false
                            });
                        }}
                        size="sm"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <div className="modal-success">
                            <img src={del} alt=".." />
                            <h5>Are you sure you want to delete this Quotation?</h5>
                        </div>
                        <div className="txt-center">
                            <button onClick={() => {
                                this.setState({
                                    deletePopup: false
                                });
                            }}
                                className="no_btn"
                            >
                                Cancel
                            </button>
                            <button onClick={() => {
                                this.setState({
                                    deletePopup: false
                                });
                                this.props.dispatch(deleteQuotation(this.props.match.params.quotId));
                            }
                            } className="okay"> Okay
                            </button>
                        </div>
                    </Modal>
                    <div style={{ display: "none" }}>
                        <ConvertBoq
                            handleShow={this.boqShow}
                            show={boqPopup}
                            onHide={this.boqClose}
                            allData={() => console.log("Clicked")}
                            quotationId={Number(this.props.match.params.quotId)}
                            quotData={quotationData}
                            str="Quote"
                        />
                    </div>
                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        salesExecutives: state.qutation.salesExecutives,
        quotationData: state.qutation.quotationData,
        salesExecutivesSuccess: state.qutation.salesExecutivesSuccess,
        previewQutationTxt: state.qutation.previewQutation,
        generateQuotationSuccess: state.qutation.generateQuotationSuccess,
        cloneQutation: state.qutation.cloneQutation,
        updateQutationStatus: state.qutation.updateQutationStatus,
        deleteQuotation: state.qutation.deleteQuotation,
    }
}
const VendorDetailsForm = reduxForm({
    form: 'purchaseOrderFormRedux',
    destroyOnUnmount: false,
    enableReinitialize: true,
})(PreparedBy)

export default connect(mapStateToProps)(VendorDetailsForm);