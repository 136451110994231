export const staffexecutiveConstants = {

    GET_STAFF_EXECUTIVE: "GET_STAFF_EXECUTIVE_LIST",
    GET_STAFF_EXECUTIVE_SUCCESS: "GET_STAFF_EXECUTIVE_LIST_SUCCESS",
    GET_STAFF_EXECUTIVE_FAILURE: "GET_STAFF_EXECUTIVE_LIST_FAILURE",


    SINGLE_EXECUTIVE: "SINGLE_EXECUTIVE_LIST",
    SINGLE_EXECUTIVE_SUCCESS: "SINGLE_EXECUTIVE_LIST_SUCCESS",
    SINGLE_EXECUTIVE_FAILURE: "SINGLE_EXECUTIVE_LIST_FAILURE",

}