import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import {
    clearStockProduct,
    getProductList,
    getPoInwardList,
    getCustomersList,
    getVendorList,
} from "../../actions";
import { Link } from "react-router-dom";
import Orange from "../../assets/images/orange.svg";
import Green from "../../assets/images/green.svg";
import Blue from "../../assets/images/blue-round.svg";
import Red from "../../assets/images/red-round.svg";
import AddStock from "../product/AddStock";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import CheckAvailability from "./CheckAvailability";
import BulkUpload from "../inwardoutward/inward/bulkUpload";
const role = localStorage.getItem('userRole');

class DashboardCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showStock: false,
            checkStock: false,
            bulkUploadPopup: false
        }
        this.stockhandleClose = this.stockhandleClose.bind(this);
        this.stockhandleShow = this.stockhandleShow.bind(this);
        this.checkClose = this.checkClose.bind(this);
        this.checkShow = this.checkShow.bind(this);
        this.bulkUploadShow = this.bulkUploadShow.bind(this);
        this.bulkUploadClose = this.bulkUploadClose.bind(this);
    }
    stockhandleShow() {
        this.props.dispatch(clearStockProduct());
        this.props.dispatch(getProductList(0));
        this.props.dispatch(getCustomersList(0));
        this.props.dispatch(getVendorList());
        this.setState({
            showStock: true,
        });
    }
    stockhandleClose() {
        this.setState({
            showStock: false,
        });
    }
    bulkUploadShow() {
        this.props.dispatch(getPoInwardList());
        this.setState({ bulkUploadPopup: true, showStock: false });
    }
    bulkUploadClose() {
        this.setState({ bulkUploadPopup: false });
    }
    checkShow() {
        this.props.dispatch(clearStockProduct());
        this.setState({
            checkStock: true,
        });
    }
    checkClose() {
        this.setState({
            checkStock: false,
        });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.success_msg !== this.props.success_msg) {
            this.setState({
                success_msg: this.props.success_msg,
            });
            if (this.props.success_msg?.status === 200) {
                this.setState({
                    showStock: false,
                })
                toast(this.props.success_msg?.data);
            } else {
                toast(this.props.success_msg?.data);
            }
        }
    }
    componentDidMount() {
        this.props.dispatch(getProductList(0));
    }
    render() {
        const { data } = this.props;
        const { items, bulkUploadPopup } = this.state;
        if (!data) {
            // data.sentToCustomer = 0;
            // data.approved = 0;
        }

        return (
            <Row className="dashboard-tag">
                <Col md={9}>
                    <Row>
                        <Col md={4} xs={6}>
                            <Card className="background-image">
                                <h5>Create New Quotation!</h5>
                                <p>Generate new quotations by selecting any one<br /> of the templates!</p>
                                <Link to={"/" + role + "/quotations/select-template"}>
                                    <button className="dashboard-btn">Create Now</button>
                                </Link>
                            </Card>
                        </Col>
                        <Col md={4} xs={6}>
                            <Card className="green-back">
                                <h5>Create Purchase Order</h5>
                                <p>Create new purchase order by clicking on <br></br>Create Now button.!</p>
                                <Link to={"/" + role + "/vendor_po/vendor-detail/add"} onClick={() =>  localStorage.setItem("title", "Create Purchase Order") } >
                                    <button className="dashboard-btn">Create Now</button>
                                </Link>

                            </Card>
                        </Col>
                        <Col md={4} xs={6}>
                            <Card className="blue-back" >
                                <h5>Inward Products</h5>
                                <p>Inward products by simply tapping on<br></br> below button.</p>
                                <AddStock
                                    str="dashboard"
                                    show={this.state.showStock}
                                    handleShow={this.stockhandleShow}
                                    onHide={this.stockhandleClose}
                                    bulkPopup={this.bulkUploadShow}
                                    bulkShow={bulkUploadPopup}
                                    bulkPopupHide={this.bulkUploadClose}
                                    listData={() => console.log("")}
                                />
                            </Card>
                        </Col>
                        <Col md={4} xs={6}>
                            <Card className="black-back">
                                <h5>Check Stock Availability</h5>
                                <p>Select the Product Name and know the<br></br> available stock count.</p>
                                <CheckAvailability
                                    show={this.state.checkStock}
                                    handleShow={this.checkShow}
                                    onHide={this.checkClose}
                                    data={items}
                                />
                            </Card>
                        </Col>
                        <Col md={4} xs={6}>
                            <Card className="red-back">
                                <h5>Outward Products</h5>
                                <p>Outward products by simply tapping on<br></br> below button.</p>
                                <Link to={"/" + role + "/outward"}>
                                    <button className="dashboard-btn" >Outward</button>
                                </Link>
                            </Card>
                        </Col>
                        <Col md={4} xs={6}>
                            <Card className="light-blue_back">
                                <h5>Counter Sale</h5>
                                <p>Outward products by simply tapping on below <br></br> button.</p>
                                <Link to={"/" + role + "/outward"} >
                                    <button className="dashboard-btn">Counter Sale</button>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col md={3}>
                    <Card className="dashboard-card quotation-parts">
                        <h6>Purchase Order</h6>
                        <Link to={"/" + role + "/purchase_order/vendor-po"} >
                            <h4>View all</h4>
                            <i className="fas fa-arrow-right"></i>
                        </Link>
                        <Row >
                            <Col md={2} className="draft_spin">
                                <img src={Blue} alt="..." className="spin" />
                            </Col>
                            <Col md={4} className="draft_spin">
                                <p className="shake-text">Draft</p>
                                <h5>{data?.draft || "0"}</h5>
                            </Col>
                            <Col md={2} className="draft_spin">
                                <img src={Green} alt="..." className="spin" />
                            </Col>
                            <Col md={4} className="draft_spin" >
                                <p className="shake-text">Generated</p>
                                <h5>{data?.generated || "0"}</h5>
                            </Col>
                            <Col md={2} className="generate_spin" >
                                <img src={Red} alt="..." className="spin" />
                            </Col>
                            <Col md={4} className="generate_spin">
                                <p className="shake-text">Sent</p>
                                <h5>{data?.sent || "0"}</h5>
                            </Col>
                            <Col md={2} className="generate_spin">
                                <img src={Orange} alt="..." className="spin" />
                            </Col>
                            <Col md={4} className="generate_spin">
                                <p className="shake-text">Verified</p>
                                <h5>{data?.verified || "0"}</h5>
                            </Col>
                        </Row>
                    </Card>
                    <div style={{ display: "none" }}>
                        <BulkUpload
                            handleShow={this.bulkUploadShow}
                            show={bulkUploadPopup}
                            onHide={this.bulkUploadClose}
                            listData={() => console.log("")}
                        />
                    </div>
                </Col>
            </Row >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        success_msg: state.product.success_msg,
    };
};

export default connect(mapStateToProps)(DashboardCard);





