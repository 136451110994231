import { put, call, takeEvery } from "redux-saga/effects";
import { getreportPurchaseApi, getreportPurchaseDownloadApi } from "../services/reportPurchaseServices";
import { reportPurchaseConstants } from "../constants";

export function* reportPurchaseSaga(payload) {
    try {
        const response = yield call(getreportPurchaseApi, payload.pageNo, payload.filterData);
        yield put({ type: reportPurchaseConstants.GET_REPORT_PURCHASE_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportPurchaseConstants.GET_REPORT_PURCHASE_FAILURE, error });
    }
}

export function* reportPurchaseDownloadSaga(payload) {
    try {
        const response = yield call(getreportPurchaseDownloadApi, payload.pageNo, payload.filterData);
        yield put({ type: reportPurchaseConstants.GET_REPORT_PURCHASE_DOWNLOAD_SUCCESS, response });
    } catch (error) {
        yield put({ type: reportPurchaseConstants.GET_REPORT_PURCHASE_DOWNLOAD_FAILURE, error });
    }
}

export default function* reportPurchase() {
    yield takeEvery(reportPurchaseConstants.GET_REPORT_PURCHASE, reportPurchaseSaga);
    yield takeEvery(reportPurchaseConstants.GET_REPORT_PURCHASE_DOWNLOAD, reportPurchaseDownloadSaga);

}