import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import plus from "../../assets/images/plus-circle.svg";
import { toast } from "react-toastify";
import {
    effectiveDateFormat, baseUrl, authToken
} from "../../actions";
import moment from "moment";
import { DatePicker } from "antd";
import "antd/dist/antd.css";

function AddNewPrice(props) {
    const { getData } = props;
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const [dataObj, setDataObj] = useState({});


    const handleClose = () => setOpen(false);
    const handleShow = () => {
        setOpen(true);
    }
    const disabledPastDate = (current) => current && current < moment().endOf("day");


    const dateHandler = (val) => {
        let date = effectiveDateFormat(val._d)
        setData({ ...data, "effectiveDate": date })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const submitData = (e) => {
        e.preventDefault();
        if (Object.values(data).includes("") || (Object.keys(data).length < 2)) {
            toast("Fill all required fields");
            return false
        } if (data.newPrice < 0) {
            toast("Price must be greater than zero");
            return false
        }
        var formData = new FormData();
        formData.append("newPrice", data.newPrice);
        formData.append("effectiveFromDate", effectiveDateFormat(data.effectiveDate));
        formData.append("productId", props.productId);
        const config = {
            headers: { Authorization: `Bearer ${authToken}` }
        };
        axios.put(`${baseUrl}staff/products`, formData, config)
            .then(res => {
                setDataObj(res.data);
            })
    };

    if (dataObj.status === 200 || dataObj.status !== undefined) {
        toast("Price data updated");
        getData(data);
        handleClose();
        dataObj.status = undefined;
    } else if (dataObj.status !== 200 && dataObj.status !== undefined) {
        toast(dataObj.dataObj);
        dataObj.status = undefined;
    }


    useEffect(() => {
        dataObj.status = undefined;
    }, []);


    return (
        <>
            <div className="product-btn" onClick={handleShow}>
                <img src={plus} alt="..." /> Add New Price
            </div>
            <Modal
                show={open}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-staff main">
                    <div className="head">
                        <div>
                            <i class="fas fa-arrow-left" onClick={handleClose}></i>
                            <h4>ADD NEW PRICE</h4>
                        </div>
                    </div>
                    <Row className="modal-unit-popup">
                        <Col md={12} className="edit-product">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control
                                    type="number"
                                    placeholder="Enter New Price"
                                    name="newPrice"
                                    onChange={handleChange}

                                />
                            </Form.Group><br></br>
                            <Form.Group controlId="formBasicDate">
                                <DatePicker
                                    format="DD-MM-YYYY"
                                    placeholder="Select date"
                                    disabledDate={disabledPastDate}
                                    className="form-control"
                                    onChange={dateHandler}
                                />
                            </Form.Group><br></br>
                        </Col >
                        <Col md={12}>
                            <button className="modal-btn" onClick={submitData}>
                                Set Price
                            </button>
                        </Col>
                    </Row >
                </div >
            </Modal >
        </>

    )
}

export default AddNewPrice;

