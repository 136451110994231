import React from "react";
import { Modal } from "react-bootstrap";
import clone from "../../assets/images/gen.png";
import revise from "../../assets/images/gen2.png";



function ClonePopUp(props) {
    const { show, onHide, cloned } = props;

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal-success">
                    <div>
                        <img src={cloned ? revise : clone} alt="" className="new-popup" />
                    </div>
                    <h6>
                        {cloned ? 'Quotation Revised Successfully!' : 'Quotation Cloned Successfully!'}
                    </h6>
                </div>
            </Modal>
        </>
    );
}



export default ClonePopUp;
